(function($) {

    'use strict';

    var FilterSearch = function(element) {
        var facetsearch = element.data('facetsearch');
        var search = element.val().toLowerCase();

        $('#' + facetsearch + '_content li a').each(function(index) {
            if (!$(this).hasClass('filter-list-empty-result') && !$(this).hasClass('selected')) {
                var relation = $(this).children('.filter-list-name').html().replace(/\<\/?b\>/ig, '');
                var relation_lower = relation.toLowerCase();
                var indexfind = relation_lower.indexOf(search);
                if (indexfind >= 0) {
                    relation = relation.substring(0, indexfind) + '<b>' + relation.substring(indexfind, indexfind + search.length) + '</b>' + relation.substring(indexfind + search.length, relation.length);
                    $(this).parent().show();
                } else {
                    $(this).parent().hide();
                }

                $(this).children('.filter-list-name').html(relation);
            }
        });

        if ($('#' + facetsearch + '_content li:visible').length == 0) {
            $('#' + facetsearch + '_content li.filter-list-empty-result').show();
        }

        if ($('#' + facetsearch + '_content li:not(.filter-list-empty-result):visible').length > 0) {
            $('#' + facetsearch + '_content li.filter-list-empty-result').hide();
        }
    };

    $('#facet_brand_search').keyup(function() {
        FilterSearch($(this));
    });

})(jQuery); 
