(function ($) {
    var _formatMoney, _updateShipping;
    ("use strict");

    var CartSize = function (element, options) {
        this.$element = $(element);
        this.options = options;
    };

    CartSize.prototype.change = function () {
        var $cartItem = this.$element.closest("[data-cart-item]");
        var skuNew = this.$element.data("value");
        var skuOld = $cartItem.find("[data-value-size]").data("sku-new");
        var linkSelect = this.$element.parents(".selectbox-2");

        if (skuNew == skuOld) {
            return;
        }

        $cartItem.attr("data-cart-item", skuNew);
        $cartItem
            .find("[data-value-size]")
            .prop("data-sku-old", skuOld)
            .prop("data-sku-new", skuNew);

        var params = {
            sku: skuOld,
            sku_simple: skuNew,
            quantity: $cartItem.find("[data-qty]").val() || 1,
            gift_wrap: $cartItem.find(".add_gift_wrap").is(":checked") ? 1 : 0,
            b2014: 1,
        };

        linkSelect.removeClass("popover-click");
        $.getJSON(
            "/cart/index/changesize/",
            params,
            $.proxy(this, "loaded")
        ).always(function () {
            linkSelect.addClass("popover-click");
        });
    };

    CartSize.prototype.loaded = function (response) {
        var sku = this.$element.closest("[data-cart-item]").data("cart-item");
        if (window.location.href.split(".")[1] === "dafiti") {
            $(document).trigger("dft.cart-quantity.update", [response]);
            $(document).trigger("dft.cart-size.update", [response]);
        } else {
            $(document).trigger("dft.cart-size.changed", [response, sku]);
            $(document).trigger("dft.cart-add.load", [response]);
        }
    };

    CartSize.prototype.updateSize = function (response) {
        var $cartItem = $(
            '[data-cart-item="' + response.item.sku_simple + '"]'
        );
        var item = "";

        var sizeItems = function (size, sku) {
            item +=
                '<li class="selectbox-option clearfix" data-value="' +
                sku +
                '" data-item-size>' +
                size +
                "</li>";
        };

        if (UA.isMobile()) {
            sizeItems = function (size, sku) {
                var isSelected =
                    sku === response.item.sku_simple
                        ? 'selected="selected"'
                        : "";
                item +=
                    '<option class="selectbox-option" value="' +
                    sku +
                    '" data-value="' +
                    sku +
                    '"' +
                    isSelected +
                    ">" +
                    size +
                    "</option>";
            };
        }

        $.each(response.item.sizes_available, sizeItems);

        $cartItem.find("[data-cart-item-size]").html(item);
    };

    $.fn.cartSize = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data("dft.cart-size");
            var options = $.extend(
                {},
                CartSize.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );

            if (!data) {
                $this.data(
                    "dft.cart-size",
                    (data = new CartSize(this, options))
                );
            }

            if (typeof option == "string" && data[option]) {
                data[option](args);
            }
        });
    };

    $(document).on("click", "[data-item-size]", function (e) {
        e.preventDefault();

        $(e.currentTarget).cartSize("change");
    });

    $(document).on("change", "[data-cart-sizes]", function (e) {
        e.preventDefault();

        $(this).find(":selected").cartSize("change");
    });

    _updateShipping = function (response) {
        var $shippingBanner,
            $shippingValue,
            $shortValue,
            totalPrice,
            shortValue,
            subtotalPrice;
        //Shipping banner
        $shippingBanner = $("#free-shipping-minimum-value-banner");
        $shippingValue = $("[data-shipping-value]");
        $shortValue = $("[data-shipping-short-value]");
        if (response.item.freight_price_rule == null) {
            return;
        }
        if (
            response.item.freight_price_rule.is_free == true &&
            response.total.freight_amount == 0
        ) {
            $shippingBanner.addClass("hide");
            $shippingValue.html("GRÁTIS");
        } else {
            $shippingValue.html(
                "R$ " + _formatMoney(parseFloat(response.total.freight_amount))
            );
            totalPrice = response.total.price
                .replace(/[^0-9,]/gi, "")
                .replace(",", ".");
            subtotalPrice = totalPrice - response.total.freight_amount;
            shortValue =
                response.item.freight_price_rule.target_price - subtotalPrice;
            $shortValue.html("R$ " + _formatMoney(shortValue));
            $shippingBanner.removeClass("hide");
        }
    };

    $(document).on("dft.cart-size.update", function (e, response) {
        $(e.currentTarget).cartSize("updateSize", response);
        _updateShipping(response);
    });

    $(document).on("dft.cart-remove.item-removed", function (e, response) {
        _updateShipping(response);
    });

    $("#change-postcode-cart").on("click", function (e) {
        var $hasPostcode, $noPostcode, $itemShippingInfo;
        e.preventDefault();
        $hasPostcode = $("#has-postcode");
        $noPostcode = $("#no-postcode");
        $itemShippingInfo = $(
            ".cart-product-free-shipping, .cart-product-delivery-time"
        );
        $hasPostcode.addClass("hide");
        $noPostcode.removeClass("hide");
        $itemShippingInfo.remove();
    });

    _formatMoney = function (value) {
        if (typeof value === "number") {
            return value.toFixed(2).replace(".", ",");
        } else {
            return null;
        }
    };
})(jQuery);
