(function() {
    'use strict';

    var Nps = function(element) {
        this.$element = $(element);

        this.$rate = this.$element.find('[data-nps-rate]');
        this.$type = this.$element.find('[name=type]');
        this.$comment = this.$element.find('[data-nps-comment]');
        this.$errorMessage = this.$element.find('[data-nps-error-message]');
        this.$origin = this.$element.find('[data-origin]');
    };

    Nps.prototype.validate = function() {
        if (this.isInvalid()) {
            this.$comment.addClass('invalid');
            this.$errorMessage.removeClass('hide');
            return;
        }

        var data = {
            'hash' : this.$element.find('[name="hash"]').val(),
            'origin' : this.$element.find('[name=origin]').val(),
            'YII_CSRF_TOKEN' : this.$element.find('[name="YII_CSRF_TOKEN"]').val(),
            'comment' : this.$comment.val(),
            'rate' : this.$element.find('[data-nps-rate]:checked').val(),
            'type' : this.$element.find('[data-nps-reason]').val()
        };

        $.ajax({
            'url': '/customer/nps/vote/',
            'type' : 'POST',
            'data' : data,
            'success' : $.proxy(this, 'success')
        });
    };

    Nps.prototype.isInvalid = function() {
        const valid = false;
        if (!this.$rate.is(':checked')) {
            return !valid;
        }

        if (this.$type.val() === 'Outros') {
            return this.$comment.val().length === 0; // valid
        }

        return valid;
    };

    Nps.prototype.success = function() {
        var successMessage = $('[data-nps-success-message]');

        this.$element.addClass('hide');

        successMessage.focus().fadeIn('slow').removeClass('hide');
    };

    $(document).on('change click', '[data-nps-rate]', function(e) {
        $(this).removeClass('initial');
        $('.nps-rated').removeClass('nps-rated');
        $(this).toggleClass('nps-rated');
        $(".nps-rate-value-range").prop('value', this.value);
    });

    $(document).on('change', '[data-range-track]', function(e) {
        e.preventDefault();
        var rateId = '#rate-' + this.value;
        $(rateId).click();
    });

    $(document).on('submit', '[data-nps-form]', function(e) {
        e.preventDefault();

        var element = $(this);

        (new Nps(element).validate());

        return false;
    });

    $(document).on('keypress keyup blur', '#nps-comment', function() {
        var label = ($(this).val().length === $(this).prop('maxlength')-1)  ? ' caracter' : ' caracteres';
        $(".comment-count span").text((450 - $(this).val().length) + label);
    });
})();
