(function ($) {

    'use strict';

    var GoToTop = function (element, options) {
        this.$element       = $(element);
        this.options        = options;
    };

    GoToTop.DEFAULTS = {
        height: 550
    };

    GoToTop.prototype.init = function (limit) {
        var heightLimit = limit || this.options.height;

        $(window).scroll($.proxy(this, 'scrollEvent', heightLimit));
    };

    GoToTop.prototype.scrollEvent = function (limit) {
        if ($(document).scrollTop() > 1) {
            this.$element.toggleClass('is-active', $(document).scrollTop() > limit);
        }
    };

    GoToTop.prototype.scrollTop = function() {
        $('body,html,document').animate({'scrollTop': 0}, 500);
    };

    $.fn.goToTop = function (option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.cart-size');
            var options = $.extend({}, GoToTop.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.cart-size', (data = new GoToTop(this, options)));
            }

            if (typeof option == 'string' && data[option]) {
                data[option](args);
            }
        });
    };

    $(document).on('dft.scroll-event.active', function(e, limit) {
        $('[data-go-to-top]').goToTop('init', limit);
    });

    $(document).on('dft.go-to-top', function() {
        $(this).goToTop('scrollTop');
    });

    $(document).on('click', '[data-go-to-top]', function(e) {
        e.preventDefault();
        $(this).goToTop('scrollTop');
    });

    // YII pagination widget first and last link
    $(document).on('click', '.pagination .hidden a', function(e) {
        e.preventDefault();
    });

})(jQuery);
