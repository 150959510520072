(function ($) {

    'use strict';

    var DeliveryTimeForm = function (element, options) {

        this.$element = $(element);
        this.options = options;
        this.options.url = this.$element.attr('action');

        this.zipBegin = '[data-field="postcode-begin"]';
        this.zipEnd = '[data-field="postcode-end"]';

        this.$zipBegin = this.$element.find(this.zipBegin);
        this.$zipEnd = this.$element.find(this.zipEnd);

        this.$element.on('form:load', $.proxy(this, 'load'));
        this.$element.on('form:loaded', $.proxy(this, 'loaded'));
        this.$element.on('form:invalid', $.proxy(this, 'invalid'));

        this.$deliveryTimeModal = $('.delivery-time-modal');

        this.$element.find('.cart-delivery-check').on('keyup', this.zipBegin, $.proxy(this, 'autoTabForm'));
        this.$deliveryTimeModal.on('keyup', this.zipBegin, $.proxy(this, 'autoTabModal'));

        this.$element.on('keydown', this.zipEnd, $.proxy(this, 'validateZipEndKeydown'));
        this.$element.on('keydown', this.zipBegin, $.proxy(this, 'validateZipInput'));
        this.$element.on('keydown', this.zipEnd, $.proxy(this, 'validateZipInput'));
    };

    DeliveryTimeForm.DEFAULTS = {
        url: null,
        domain: null,
        target: null
    };

    DeliveryTimeForm.prototype.callModalKeyPress = function () {
        $(this.zipEnd).keypress(function (e) {
            if(e.which == 13 ) {
                $('.cart-delivery-button').click();
            }
        });
    };

    DeliveryTimeForm.prototype.autoTabForm = function () {
        var max = this.$element.find(this.zipBegin).attr('maxlength');

        if (this.$element.find(this.zipBegin).val().length == parseInt(max)) {
            this.$element.find(this.zipEnd).focus();
        }
    };

    DeliveryTimeForm.prototype.autoTabModal = function () {
        var max = this.$deliveryTimeModal.find(this.zipBegin).attr('maxlength');

        if (this.$deliveryTimeModal.find(this.zipBegin).val().length == parseInt(max)) {
            this.$deliveryTimeModal.find(this.zipEnd).focus();
        }
    };

    DeliveryTimeForm.prototype.activateDeliveryModal = function () {
        this.$element.find(this.zipBegin).focus();
        if (this.$zipBegin.val() != '' && this.$zipEnd.val() != '') {
            $('.delivery-time-modal').find('[data-field="postcode-begin"]').val(this.$element.find(this.zipBegin).val());
            $('.delivery-time-modal').find('[data-field="postcode-end"]').val(this.$element.find(this.zipEnd).val());
            $('.delivery-time-modal').find('.product-delivery-time-submit').trigger('click');
        }
    };

    DeliveryTimeForm.prototype.validateZipInput = function (e) {
        // ASCI Codes for keyboard and numeric keyboard
        // includes tab, enter and backspace
        var whiteList = [
            48, 49, 50,
            51, 52, 53,
            54, 55, 56,
            57, 96, 97, 98,
            99, 100, 101,
            102, 103, 104,
            105, 13, 8, 9
        ];

        if ($.inArray(e.which, whiteList) == -1) {
            return false;
        }

        if (e.keyCode == 13) {
            this.callModalKeyPress();
        }
    };

    DeliveryTimeForm.prototype.validateZipEndKeydown = function (e) {
        if (e.which == 8 && $(e.currentTarget).val() == '') {
            this.$element.find(this.zipBegin).focus();
        }
    };

    DeliveryTimeForm.prototype.load = function (e) {
        var responseEl = $(this.options.target);
        var url = this.options.url;

        url += '&postcode=' + this.$zipBegin.val() + '-' + this.$zipEnd.val();
        url += (this.$element.data('sku')) ? '&sku=' + this.$element.data('sku') : '';
        url += (this.options.domain) ? '&domain=' + this.options.domain : '';

        this.$element.attr('action', url);

        responseEl.find('.loading-dialog').removeClass('is-loaded');
        responseEl.find('[data-delivery-time-form-response]').html('');
    };

    DeliveryTimeForm.prototype.loaded = function(e, data) {
        var responseEl = $(this.options.target);
        responseEl.find('[data-delivery-time-form-response]').html(data);
        responseEl.find('.loading-dialog').addClass('is-loaded');
    };

    DeliveryTimeForm.prototype.invalid = function(e, data) {
        $(this.options.target).find('[data-delivery-time-form-response]').html('');
    };

    DeliveryTimeForm.prototype.changeDeliveryTimeUrl = function() {
        $('.product-delivery-time-form-row form').data('sku', this.getSelectedSku());
    };

    DeliveryTimeForm.prototype.getSelectedSku = function() {
        var sku = this.getSkuFromSelectedSize();

        if (sku == '') {
            sku = this.getFirstSkuThatHasStock();
        }

        return sku;
    };

    DeliveryTimeForm.prototype.getFirstSkuThatHasStock = function() {
        var sku = null;

        $('.add-to-cart-form-sizes [data-value]').each(function(key, li) {
            var liSku = $(li).data('value');

            if (liSku != '' && liSku != 'size-reminder') {
                sku = liSku;
                return false;
            }
        });

        return sku;
    };

    DeliveryTimeForm.prototype.getSkuFromSelectedSize = function() {
        return $('.add-to-cart-form-sizes input').val();
    };

    $.fn.deliveryTimeForm = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.delivery-time-form');
            var options = $.extend({}, DeliveryTimeForm.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.delivery-time-form', (data = new DeliveryTimeForm(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('focus', '[data-delivery-time-form]', function () {
        $(this).deliveryTimeForm();
    });

    $(document).on('modal.delivery-time-modal', function () {
        $(this).deliveryTimeForm('activateDeliveryModal');
    });

    $(document).on('click', '.product-delivery-time-submit', function() {
        $(this).deliveryTimeForm('changeDeliveryTimeUrl');
    });

    $(document).on('click','[data-inner-class-name="delivery-time-modal"]', function() {
        $('.freight-options-table-holder').remove();
        $('.delivery-time-modal').find('[data-field="postcode-begin"]').val($('[data-field="postcode-begin"]').val());
        $('.delivery-time-modal').find('[data-field="postcode-end"]').val($('[data-field="postcode-end"]').val());
        $('.delivery-time-modal .product-delivery-time-submit').click();
    });

})(jQuery);
