(function($){

    'use strict';

    var CartRemoveItem = function(element, options) {
        this.$element = $(element);
        this.options  = options;

        this.$storeType = this.$element.closest('[data-cart-store-type]');
        this.undoLinkTemplate = $('#cart-undo-remove-item-template').html();
    };

    CartRemoveItem.prototype.remove = function() {
        var sku_simple = this.$element.data('cart-remove-item');

        $.getJSON('/cart/remove/', {sku: sku_simple}, $.proxy(this, 'loaded'));
    };

    CartRemoveItem.prototype.loaded = function(response) {
        if (response.success === false) return;

        var sku = this.$element.data('cart-remove-item');

        this.showUndoLink(sku);
        this.removeGift(response);
        this.showEditColorLink(response);
        this.removeItem(sku);

        setTimeout($.proxy(this, 'cartEmpty'), 4000);

        $(document).trigger('dft.cart-remove.item-removed', [response]);
    };

    CartRemoveItem.prototype.removeItem = function(sku) {
        $('[data-cart-item="' + sku + '"]').remove();
    };

    CartRemoveItem.prototype.removeItemDuplicate = function(response) {
        var $cartItem = $('[data-cart-item="' + response.item.sku_simple + '"]');

        if ($cartItem.length > 1) {
            $cartItem.first().remove();
        }
    };

    CartRemoveItem.prototype.showUndoLink = function(sku) {
        if (!this.$element.data('cart-remove-undo-link')) return;

        var $cartItem = this.$storeType.find('[data-cart-item="' + sku + '"]');

        var args = {
            'sku_simple'    : sku,
            'name'          : $cartItem.find('.cart-product-name').text().trim(),
            'quantity'      : $cartItem.find('input[name="qty_'+ sku +'"]').val(),
            'gift_wrap'     : $cartItem.find('[data-cart-gift-wrap]').is(':checked')
        };

        args.url_params = $.param({
            'sku_simple'    : sku,
            'quantity'      : args.quantity,
            'gift_wrap'     : args.gift_wrap ? 1 : 0,
            'b2014'         : 1
        });

        Mustache.parse(this.undoLinkTemplate);
        $cartItem.before(Mustache.render(this.undoLinkTemplate, args));

        setTimeout($.proxy(this, 'removeUndoLink', sku), 4000);
    };

    CartRemoveItem.prototype.removeGift = function(response) {
        if (response.gifts.length === 0) {
            $('[data-cart-item-is-gift]').remove();
        }
    };

    CartRemoveItem.prototype.removeUndoLink = function(sku) {
        var removeLink = function() {
            $(this).remove();
        };

        $('[data-cart-undo-remove="' + sku + '"]').slideUp(400, removeLink);
    };

    CartRemoveItem.prototype.showEditColorLink = function(response) {
        var skus = response.item.relatives;

        for (var i = 0; i < skus.length; i += 1) {
            $('[data-cart-item-color="' + skus[i] + '"]').removeClass('hide').show();
        }
    };

    CartRemoveItem.prototype.cartEmpty = function() {
        if ($('[data-cart-item]').length < 1) {
            document.location.reload(true);
        }

        $(document).trigger('dft.cart-fix-title-position');
    };

    $.fn.cartRemoveItem = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.cart-remove-item');
            var options = $.extend({}, CartRemoveItem.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-remove-item', (data = new CartRemoveItem(this, options)));

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '[data-cart-remove-item]', function(e) {
        e.preventDefault();
        $(e.currentTarget).data('cart-remove-undo-link', true);
        $(e.currentTarget).cartRemoveItem('remove');
    });

    $(document).on('click', '[data-cart-undo-remove]', function(e) {
        var sku = $(this).data('cart-undo-remove');

        $(e.currentTarget).cartRemoveItem('removeUndoLink', sku);
    });

    $(document).on('dft.cart-remove.remove', function(e, params) {
        $(e.currentTarget).data('cart-remove-item', params.item.sku_related);
        $(e.currentTarget).cartRemoveItem('remove');
    });

    $(document).on('dft.cart-remove.item', function(e, sku) {
        $(e.currentTarget).cartRemoveItem('removeItem', sku);
    });

    $(document).on('dft.cart-remove.item-duplicated', function(e, response) {
        $(e.currentTarget).cartRemoveItem('removeItemDuplicate', response);
    });

})(jQuery);
