(function ($) {
    'use strict';
    var Recommendation = function (element, options) {
        this.$element = $(element);
        this.layoutActive = Boolean(document.location.hostname.indexOf('dafiti') > -1 && this.$element.data('page') == "detail");
        this.titleProductSimilars = this.layoutActive ? 'Do mesmo estilo' : 'Produtos similares';
        this.titleRecommendation = this.layoutActive ? 'Pensado pra você' : 'Recomendados para você';
        this.titleMoreLikeThis = 'Mais dessa marca';
        if (this.layoutActive) {
            this.templateHtml = $("script[type='text/recommendation-template-new']").html();
            $('.recommendations').data('display-size', 5);
        } else {
            this.templateHtml = $("script[type='text/recommendation-template']").html();
        }
        this.$type = this.$element.data('recommendation');
        this.$page = this.$element.data('page');
        this.$titleDynamic = this.$element.data('title-dynamic');
        this.$displaySize = this.$element.data('display-size');
        this.$colSize = 12 / this.$displaySize;
        this.options = options;
        this.$template = $(this.templateHtml).find('.carousel-item');
        this.$sizeOption = this.$template.find('[data-product-size]');
        this.$destination = this.$template.find('[data-field=link]').attr('href');
        this.$gender = { masculino: "1", feminino: "2", menino: "3", menina: "4", unissex: "5" };
        this.checkSecure();
        this.marrecoData = [];
    };

    Recommendation.DEFAULTS = {
        secure: false,
        url: null,
        mobile: false,
        lookbook: false,
        marrecoReturn: { type: '', result: '' },
        shoulUseMarrecoFallback: true,
        shoulUseSimilarsMarrecoFallback: true,
        shoulUseMoreLikeThisMarrecoFallback: true,
        shoulUseRecommendedMarrecoFallback: true
    };

    Recommendation.prototype.checkSecure = function () {
        if (this.options.secure) {
            return false;
        }
        if (document.location.protocol === "https:") {
            this.options.secure = true;
        }
    };

    Recommendation.prototype.lastViewed = function (data) {
        var context = this;

        var $thisLastView = $(this.templateHtml);
        var $items = $thisLastView.find('.carousel-items');
        $items.attr('data-product-length', data.length);

        !!data && $.each(data, function (index, item) {
            item.brand = {
                name: item.brand_name
            };
            item.page_url_sufix = item.product_url;
            $items.append(context.template(context.translateProduct(item)));
        });

        this.$element.append($thisLastView);

        if (data.length > 0) {
            this.$element.addClass('has-items');
        }

        context.$element.parent().find('.lookbook-fallback').removeClass('hide');
        if (data.length === 0) {
            context.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
        }
        var productsSize = data.length;

        if (productsSize == 0) {
            var fallback = context.$element.data('recommendation-fallback');
            if (typeof fallback !== undefined && fallback !== "") {
                $('[data-' + fallback + ']').data('recommendation', fallback);
                $('[data-' + fallback + ']').recommendation('render');
            }
            return;
        }

        context.$element.find('[data-recommendation-title]').html(context.$element.data('title'));
        context.$element.find('.product-template').remove();
        var showNavigation = productsSize > context.$displaySize;
        context.$element.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
        context.$element.trigger('dft.recommendation-done');
        context.$element.parent().find('.lookbook-fallback').addClass('hide');
    };

    Recommendation.prototype.setResult = function (products) {
        var that = this;
        if (this.$type == "last-viewed") {
            return this.lastViewed(products);
        }

        /**
         0 produtos similares
         1 recomendamos para voce
         */
        !!products.slots && $.each(products.slots, function (index, recommendations) {
            if (that.options.lookbook == true && index > 0) {
                return;
            }

            if (that.options.mobile && index > 0) {
                return;
            }
            // disable more like this for marreco to use feeds
            if (recommendations.name == this.titleProductSimilars && (that.options.shoulUseMarrecoFallback == false || that.options.shoulUseSimilarsMarrecoFallback == false)) {
                return;
            }

            // disable you might like for marreco to use feeds
            if (recommendations.name == this.titleRecommendation && that.options.shoulUseRecommendedMarrecoFallback == false) {
                return;
            }

            // var $thisRecomendation = $(that.templateHtml);
            // $thisRecomendation.find('[data-recommendation-title]').text(recommendations.name);
            // var $items = $thisRecomendation.find('.carousel-items');
            // $items.attr('data-product-length', recommendations.length);
            // $.each(recommendations.items, function (index, item) {
            //     $items.append(that.template(that.translateProduct(item)));
            // });

            // that.$element.parent().find('.lookbook-fallback').removeClass('hide');
            // $thisRecomendation.find('.product-template').remove();
            // var showNavigation = recommendations.items.length > that.$displaySize;
            // $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
            // $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
            // that.$element.append($thisRecomendation);

            var prods = that.transformFeedsProducts(recommendations.items);
            var $thisRecomendation = $(that.templateHtml);

            if (!!prods.length) {
                // setando o nome da sessão
                $thisRecomendation.find('[data-recommendation-title]').text(recommendations.name);
                var $items = $thisRecomendation.find('.carousel-items');
                $items.attr('data-product-length', products.length);
                !!prods && $.each(prods, function (index, item) {
                    $items.append(that.template(item));
                });

                that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                $thisRecomendation.find('.product-template').remove();
                var showNavigation = prods.length > that.$displaySize;
                $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
                $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
                if (recommendations.name == this.titleProductSimilars) {
                    that.$element.prepend($thisRecomendation);
                } else {
                    that.$element.append($thisRecomendation);
                }
            }

        });

        this.$element.addClass('has-items');
        this.$element.trigger('dft.recommendation-done');

    };

    Recommendation.prototype.render = function () {
        var that = this;
        that.FeedsSimilars();
    };

    Recommendation.prototype.Marreco = function () {
        var that = this;
        if (!!that.marrecoData.slots) {
            that.setResult(that.marrecoData);
        }
        $.ajax({
            'url': this.path(),
            'dataType': 'json',
            'global': false,
            error: function () {
                that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                that.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
            }
        })
            .success(function (response) {
                that.marrecoData = response;
                that.setResult(response);
            });
    }

    Recommendation.prototype.translateProduct = function (item) {
        if (this.$type == 'last-viewed') {
            return item;
        }
        item.sku_config = item.sku;
        item.brand_name = item.brand.name;
        item.original_price = item.price;
        if (item.special_price != 0) {
            item.price = item.special_price;
        }
        item.product_url = item.page_url_sufix;
        item.image_url = "https://dafitistatic.dafiti.com.br" + item.image_url_sufix.replace(/zoom/g, 'catalog');
        if (item.is_new_product) {
            item.tag = "Novo"; //is new price or discount. desconto tem prioridade
        }
        if (item.discount != 0) {
            item.tag = "-" + Math.round(item.discount, 2) + "%";
        }
        return item;
    };

    Recommendation.prototype.translateSizes = function (item) {
        if (this.$type == 'last-viewed') {
            return item.sizes_available;
        }
        return item.sizes;
    };

    Recommendation.prototype.path = function () {
        var lastViewed = $.cookie("last-viewed");
        var recentSkus = "";
        var path = this.$element.data('marreco-url');

        var currentSku = this.$element.data("sku");
        if (lastViewed != null) {
            recentSkus = JSON.parse(lastViewed).join(',');
        }
        if (this.$type == "lookbook" || this.$type == "top-sellers" || this.$type == "similar-product" || this.$type == "for-you") {
            path += 'skus_navigated=' + recentSkus + '&';
            if (typeof currentSku !== 'undefined') {
                path += 'page_sku=' + currentSku + '&';
            }
        }
        if (this.$type == "last-viewed") {
            path = "/product/list/?skus=" + recentSkus;
        }
        if (this.$type != "last-viewed") {
            path = path + this.attributes();
        }
        return "/Marreco/recomendation?url=" + btoa(path);
    };

    Recommendation.prototype.attributes = function () {
        var attributes = [];
        var hash = $.cookie("customerId");
        var ga = $.cookie("_ga");
        var client_hash = this.$element.data("client-hash");
        var lastViewed = $.cookie("last-viewed");
        var cartProducts = [];
        var cart = $.cookie('DAFITI-cart');

        if (lastViewed) {
            lastViewed = $.parseJSON(lastViewed);
        }
        if (cart) {
            cart = $.parseJSON(cart);
            !!cart && $.each(cart, function (index, value) {
                cartProducts.push(value.s);
            });
        }
        var recomendation = $.cookie('dafiti_recommendation');

        if (recomendation) {
            recomendation = $.parseJSON(recomendation);
        }

        if (this.$page == "detail") {
            if (typeof this.$element.data("data-sku") !== 'undefined') {
                attributes.push("product=" + this.$element.data("data-sku"));
            }

            if (typeof this.$element.data("product-categories") !== 'undefined') {
                attributes.push("product_categories_ids=" + this.$element.data("product-categories"));
            }

            if (typeof this.$element.data("product-category") !== 'undefined') {
                attributes.push("product_more_specific_category_id=" + this.$element.data("product-category"));
            }
        }

        if (lastViewed) {
            attributes.push("products_navigated=" + lastViewed.join(","));
        }
        if (cartProducts.length > 0) {
            attributes.push("products_carted=" + cartProducts.join(","));
        }
        if (typeof this.$element.data("skus") != undefined) {
            attributes.push("products_purchased=" + this.$element.data('skus'));
        }

        if (ga) {
            attributes.push("ga_client_id=" + ga.substring(6));
        }
        if (hash) {
            attributes.push("customer_id=" + hash);
        }
        if (typeof this.$element.data("max-return") !== 'undefined') {
            attributes.push("total_recommend=" + this.$element.data("max-return"));
        }

        if (recomendation === null) {
            return attributes.join('&');
        }

        if (typeof recomendation.wishlist != undefined) {
            attributes.push("products_wishlist_added=" + recomendation.wishlist);
        }
        if (typeof recomendation.recommendation != undefined) {
            attributes.push("products_reco_interacted=" + recomendation.recommendation);
        }
        if (typeof recomendation.campaign != undefined) {
            attributes.push("products_campaign_interacted=" + recomendation.campaign);
        }
        if (typeof recomendation.search != undefined) {
            attributes.push("products_search_interacted=" + recomendation.search);
        }

        return attributes.join('&');
    };

    Recommendation.prototype.template = function (item, title) {
        var product = this.$template.clone();
        this.product(item, product, title);
        this.sizes(this.translateSizes(item), product);

        product.find(".product-size-template").remove();
        return product;
    };

    Recommendation.prototype.product = function (item, product, title) {
        product.removeClass('product-template');
        product.addClass("col-md-" + (this.$colSize.toString().replace('.', '')));
        product.find('[data-field=tag]').text(item.tag).toggle(!!item.tag);

        product.find('[data-field=link]').attr('href', this.$destination + "/" + item.page_url_sufix);
        product.find('[data-field=brand]').text(item.brand.name);

        var discount = null;
        if (item.original_price > 0 && item.price > 0) {
            discount = 100 - ((item.price / item.original_price) * 100);
        }

        if (discount !== null && discount > 0) {
            product.find('[data-field=price-discount] span').text(Math.round(discount).toString());
        } else {
            product.find('[data-field=price-discount]').remove();
        }

        product.find('[data-product-name]').text(item.name);
        product.find('[data-wishlist-url]').data('sku-item', item.sku).data('favorite-id', item.favorite);
        if (item.favorite) {
            product.find('[data-wishlist-url]').addClass('active');
        }

        if (item.is_new_product) {
            product.find('[data-new]').attr('style', 'display:flex');
            product.find('[data-field=price-discount]').hide();
        }

        product.find('[data-field=sku]').val(item.sku);
        product.find('[data-field=sku-target]').attr('data-target', 'sku_' + item.sku);
        product.find('[data-field=sku-hidden]').attr('name', 'sku_' + item.sku);
        if (this.layoutActive) {
            product.find('[data-field=image]').attr("src", item.image_url);
        } else {
            product.find('[data-field=image]').attr("src", item.image_url_m);
        }
        product.find('[data-field=image]').attr("alt", item.name);
        product.find('.stock-available').attr('data-url', product.find('.stock-available').attr('data-url') + item.sku);
        product.attr('data-sku', item.sku);
        product.attr('data-categories', item.categories.toString().trim().toLowerCase());
        product.attr('data-variant', item.color.trim().toLowerCase());
        product.attr('data-seller', item.seller.trim().toLowerCase());
        product.attr('data-type', title.toLowerCase());
        product.find('.btn-cart').attr('data-sizes', JSON.stringify(item.sizes ? item.sizes : []));
        this.productTraking(product, item.sku, title);
        this.price(product, item);
    };

    Recommendation.prototype.productTraking = function (product, sku, title) {
        if (this.$type === "similar-product") {
            var locationPath = window.location.pathname;
            var locationUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search;

            var trackingSite = null;

            if (document.location.hostname.indexOf('dafiti') > -1)
                trackingSite = 'dafiti';
            else if (document.location.hostname.indexOf('kanui') > -1)
                trackingSite = 'kanui';
            else if (document.location.hostname.indexOf('tricae') > -1)
                trackingSite = 'tricae';
            else return;

            product.find('.product-image').on("click", function () {
                if (typeof (ga) !== 'undefined') {
                    if (this.layoutActive) {
                        dataLayer.push({
                            event: "productpage_ga3",
                            page_path: locationPath,
                            page_location: locationUrl,
                            page_title: "productPage",
                            category: "Enhanced Ecommerce",
                            action: "clickProduct",
                            label: "Imagem:" + title + "-PDP", // Ex.: 'Imagem_mais-dessa-marca-PDP' ou 'Imagem_do-mesmo-estilo-PDP' ou 'Imagem_pensado-pra-você-PDP'
                        });
                    } else {
                        ga(
                            'ga_' + trackingSite + '.send',
                            'event',
                            'recommendations',
                            'RecoMorelikethisClick',
                            sku,
                            { nonInteraction: false });
                    }
                }
            });

            product.find('.btn-add-to-cart, [data-add-to-cart-form-button]').on("click", function () {
                if (typeof (ga) !== 'undefined') {
                    if (this.layoutActive) {
                        dataLayer.push({
                            event: "productpage_ga3",
                            page_path: locationPath,
                            page_location: locationUrl,
                            page_title: "productPage",
                            category: "Enhanced Ecommerce",
                            action: "Cart",
                            label: "Add",
                        });
                    } else {
                        ga(
                            'ga_' + trackingSite + '.send',
                            'event',
                            'recommendations',
                            'RecoMorelikethisAddToCart',
                            sku,
                            { nonInteraction: false });
                    }
                }
            });
        }
    }

    Recommendation.prototype.price = function (product, item) {
        product.find('[data-field=is_special_price]').toggleClass('is-special-price', item.price != item.original_price);
        product.find('[data-field=price]').text(this.priceFormat(item.original_price) || this.priceFormat(item.price));
        product.find('[data-field=special_price]').text(this.priceFormat(item.price));
        if (product.hasClass('lookbook-product')) {
            if (item.original_price > item.price) {
                product.find('[data-field=price]').text(this.priceFormat(item.price));
            }
        }
    };

    Recommendation.prototype.sizes = function (sizes, product) {
        var that = this;
        var sku = product.find('[name=p]').val();
        var firstOption = this.$sizeOption.clone();
        var productSizes = product.find('[data-product-sizes]');
        firstOption.remove("product-size-template");
        productSizes.html(firstOption);
        if (productSizes.is('select')) {
            productSizes.append('<option value="" selected="selected" disabled="disabled">Tamanho</option>');
        }

        if (!jQuery.isEmptyObject(sizes)) {
            !!sizes && $.each(sizes, $.proxy(function (index, variant) {
                var option = this.$sizeOption.clone();
                option.text(index).find('[data-product-size-name]').text(index);
                if (that.$type != 'last-viewed') {
                    variant = variant.sku;
                }
                option.attr('data-value', variant);
                option.val(variant);
                option.removeClass("product-size-template");
                productSizes.append(option);
            }, this));
        } else {
            product.find('.stock-available').addClass('is-sold-out');
            product.find('.btn-add-to-cart, [data-add-to-cart-form-button]')
                .removeClass('primary').addClass('sold-out')
                .attr('type', 'button')
                .attr('data-remote', '/catalog/sizeReminder?sku=' + sku + '&b2014=1')
                .attr('data-toggle', 'modal')
                .attr('data-class-name', 'modal-size-reminder')
                .text('Avise-me');
            product.find('.add-to-cart-form-sizes').addClass('hide').next('.rc-arrow').addClass('hide');
            product.find('.stock-available-message').addClass('recommendation').text('Produto Esgotado');
        }
    };

    Recommendation.prototype.priceFormat = function (price) {
        price = parseFloat(price).toFixed(2);

        return "R$ " + price.toString().replace(/\./g, ",");
    };

    Recommendation.prototype.Feeds = function () {
        var that = this;

        // verify if feeds is enabled
        if (!!this.$element.data('feeds-url')) {
            that.options.shoulUseMarrecoFallback = false;

            var requisitionUrl = that.handleUrl(this.$element.data('feeds-url'));

            $.ajax({
                'url': requisitionUrl,
                'dataType': 'json',
                'global': false
            }).error(function (e) {
                if (!that.usesFeedsPage()) {
                    that.options.shoulUseMarrecoFallback = true;
                    //that.Marreco();
                    that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                    that.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
                }
                that.FeedsRecommendation();
            }).success(function (response) {
                if (!!response.length) {
                    that.renderFeedsData(response);
                } else {
                    if (!that.usesFeedsPage()) {
                        that.options.shoulUseMarrecoFallback = true;
                    }
                    that.FeedsRecommendation();
                }
            });
        } else {
            that.FeedsRecommendation();
        }
    }

    Recommendation.prototype.FeedsRecommendation = function () {
        var that = this;
        var requisitionUrl = '';

        that.uuid = getCookie("is_client") || getCookie("__utma") || "00000";
        that.url = this.$element.data('feeds-recommendation-url');

        that.url += !!that.url && that.url.includes("?") ? "&uuid=" + that.uuid : "?uuid=" + that.uuid;
        that.url += !!that.$page ? "&page=" + that.$page : "";
        var requisitionUrl = that.handleUrl(that.url);

        // verify if feeds recommendation is enabled
        if (!!this.$element.data('feeds-recommendation-url')) {
            that.options.shoulUseRecommendedMarrecoFallback = false;
            $.ajax({
                'url': requisitionUrl,
                'dataType': 'json',
                'global': false
            }).error(function (e) {
                if (!that.usesFeedsPage()) {
                    that.options.shoulUseRecommendedMarrecoFallback = true;
                    that.callMarreco();
                    that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                    that.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
                }
            }).success(function (response) {
                if (!!response.length) {
                    that.renderRecommendationFeedsData(response);
                } else if (!that.usesFeedsPage()) {
                    that.options.shoulUseRecommendedMarrecoFallback = true;
                }
                if (!that.usesFeedsPage()) {
                    that.callMarreco();
                }
            });
        } else {
            if (!that.usesFeedsPage()) {
                that.callMarreco();
            }
        }
    }

    Recommendation.prototype.callMarreco = function () {
        var that = this;
        //verify if needs to call marreco
        var mustCallMarreco = that.options.shoulUseRecommendedMarrecoFallback || (that.options.shoulUseSimilarsMarrecoFallback || that.options.shoulUseMarrecoFallback);

        //Call marreco if needed
        // if (mustCallMarreco) {
        //     that.Marreco();
        // }

    }

    Recommendation.prototype.FeedsSimilars = function () {
        var that = this;

        // verify if feeds recommendation is enabled
        if (!!this.$element.data('feeds-similars-url')) {
            that.options.shoulUseSimilarsMarrecoFallback = false;
            var requisitionUrl = that.handleUrl(this.$element.data('feeds-similars-url'));

            $.ajax({
                'url': requisitionUrl,
                'dataType': 'json',
                'global': false
            }).error(function (e) {
                if (!that.usesFeedsPage()) {
                    that.options.shoulUseSimilarsMarrecoFallback = true;
                    that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                    that.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
                }
                that.FeedsMoreLikeThis();
            }).success(function (response) {
                if (!!response.length) {
                    that.renderFeedsSimilarsData(response);
                } else {
                    if (!that.usesFeedsPage()) {
                        that.options.shoulUseSimilarsMarrecoFallback = true;
                    }
                    that.FeedsMoreLikeThis();
                }
            });
        }else{
            that.FeedsMoreLikeThis();
        }
    }

    Recommendation.prototype.FeedsMoreLikeThis = function () {
        var that = this;

        // verify if feeds recommendation is enabled
        if (!!this.$element.data('feeds-more-like-this-url')) {
            that.options.shoulUseMoreLikeThisMarrecoFallback = false;
            var requisitionUrl = that.handleUrl(this.$element.data('feeds-more-like-this-url'));
            $.ajax({
                'url': requisitionUrl,
                'dataType': 'json',
                'global': false
            }).error(function (e) {
                if (!that.usesFeedsPage()) {
                    that.options.shoulUseMoreLikeThisMarrecoFallback = true;
                    that.$element.parent().find('.lookbook-fallback').removeClass('hide');
                    that.$element.parent().find('.lookbook-fallback').prev("[data-recommendation=lookbook]").remove();
                }
                that.FeedsRecommendation();
            }).success(function (response) {
                if (!!response.length) {
                    that.renderFeedsMoreLikeThisData(response);
                } else {
                    if (!that.usesFeedsPage()) {
                        that.options.shoulUseMoreLikeThisMarrecoFallback = true;
                    }
                    that.FeedsRecommendation();
                }
            });
        }else{
            that.FeedsRecommendation();
        }
    }

    Recommendation.prototype.renderFeedsData = function (products) {
        var that = this;
        if (products.length == 0 && that.usesFeedsPage()) {
            return;
        } else if (products.length == 0) {
            that.options.shoulUseMarrecoFallback = true;
            // that.Marreco();
            return;
        }
        var prods = that.transformFeedsProducts(products);
        var $thisRecomendation = $(that.templateHtml);

        // setando o nome da sessão
        $thisRecomendation.find('[data-recommendation-title]').text(this.titleProductSimilars);
        var $items = $thisRecomendation.find('.carousel-items');
        $items.attr('data-product-length', products.length);

        !!prods && $.each(prods, function (index, item) {
            $items.append(that.template(item, 'Pensado Para Voce'));
        });

        var datarecomm = $thisRecomendation.find('[data-recommendation-title]');
        datarecomm.attr('params-recomendation', this.titleProductSimilars);

        that.$element.parent().find('.lookbook-fallback').removeClass('hide');
        $thisRecomendation.find('.product-template').remove();
        var showNavigation = prods.length > that.$displaySize;
        $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
        $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
        that.$element.append($thisRecomendation);

        this.$element.addClass('has-items');
        this.$element.trigger('dft.recommendation-done');
        // this call must be after feeds to prevent recommended products to be displayed before similar products
        that.FeedsRecommendation();
    }

    Recommendation.prototype.renderFeedsSimilarsData = function (products) {
        var that = this;
        if (products.length == 0 && that.usesFeedsPage()) {
            return;
        } else if (products.length == 0) {
            that.options.shoulUseMarrecoFallback = true;
            // that.Marreco();
            return;
        }
        var prods = that.transformFeedsProducts(products);
        var $thisRecomendation = $(that.templateHtml);

        // setando o nome da sessão
        $thisRecomendation.find('[data-recommendation-title]').text(this.titleProductSimilars);
        var $items = $thisRecomendation.find('.carousel-items');

        $items.attr('data-product-length', products.length);

        !!prods && $.each(prods, function (index, item) {
            $items.append(that.template(item, 'Do Mesmo Estilo'));
        });

        var datarecomm = $thisRecomendation.find('[data-recommendation-title]');
        datarecomm.attr('params-recomendation', this.titleProductSimilars);

        that.$element.parent().find('.lookbook-fallback').removeClass('hide');
        $thisRecomendation.find('.product-template').remove();
        var showNavigation = prods.length > that.$displaySize;
        $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
        $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
        that.$element.append($thisRecomendation);

        this.$element.addClass('has-items');
        this.$element.trigger('dft.recommendation-done');
        // this call must be after feeds to prevent recommended products to be displayed before similar products
        that.FeedsMoreLikeThis();
    }

    Recommendation.prototype.renderFeedsMoreLikeThisData = function (products) {
        var that = this;

        if (!this.layoutActive) {
            that.FeedsRecommendation();
            return;
        }

        if (products.length == 0 && that.usesFeedsPage()) {
            return;
        } else if (products.length == 0) {
            that.options.shoulUseMarrecoFallback = true;
            that.Marreco();
            return;
        }
        var prods = that.transformFeedsProducts(products);
        var $thisRecomendation = $(that.templateHtml);

        // setando o nome da sessão
        $thisRecomendation.find('[data-recommendation-title]').text(this.titleMoreLikeThis);
        var $items = $thisRecomendation.find('.carousel-items');

        $items.attr('data-product-length', products.length);

        !!prods && $.each(prods, function (index, item) {
            $items.append(that.template(item, 'Mais Dessa Marca'));
        });

        var datarecomm = $thisRecomendation.find('[data-recommendation-title]');
        datarecomm.attr('params-recomendation', this.titleMoreLikeThis);

        that.$element.parent().find('.lookbook-fallback').removeClass('hide');
        $thisRecomendation.find('.product-template').remove();
        var showNavigation = prods.length > that.$displaySize;
        $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
        $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
        that.$element.append($thisRecomendation);

        this.$element.addClass('has-items');
        this.$element.trigger('dft.recommendation-done');
        // this call must be after feeds to prevent recommended products to be displayed before similar products
        that.FeedsRecommendation();
    }

    Recommendation.prototype.renderRecommendationFeedsData = function (products) {
        var that = this;
        if (products.length == 0 && that.usesFeedsPage()) {
            return;
        } else if (products.length == 0) {
            that.options.shoulUseMarrecoFallback = true;
            // that.Marreco();
            return;
        }
        var prods = that.transformFeedsProducts(products);
        var $thisRecomendation = $(that.templateHtml);

        // setando o nome da sessão
        const title = that.$titleDynamic === undefined
            ? this.titleRecommendation
            : that.$titleDynamic;

        $thisRecomendation.find('[data-recommendation-title]').text(title);
        var $items = $thisRecomendation.find('.carousel-items');
        $items.attr('data-product-length', products.length);

        !!prods && $.each(prods, function (index, item) {
            $items.append(that.template(item, 'Pensado Para Voce'));
        });

        var datarecomm = $thisRecomendation.find('[data-recommendation-title]');
        datarecomm.attr('params-recomendation', this.titleRecommendation);

        that.$element.parent().find('.lookbook-fallback').removeClass('hide');
        $thisRecomendation.find('.product-template').remove();
        var showNavigation = prods.length > that.$displaySize;
        $thisRecomendation.find('.navigation-button').toggleClass('is-hidden', !showNavigation);
        $thisRecomendation.parent().find('.lookbook-fallback').addClass('hide');
        that.$element.append($thisRecomendation);

        this.$element.addClass('has-items');
        this.$element.trigger('dft.recommendation-done');
    }

    Recommendation.prototype.transformFeedsProducts = function (products) {
        var options = [];

        var getSizes = function (sizes) {
            var sizesLocal = {};
            !!sizes && $.each(sizes, function (index, meta) {
                if (meta.quantity != 0) {
                    sizesLocal[meta.size] = {
                        sku: meta.sku,
                        stock: meta.quantity
                    }
                }
            });
            return sizesLocal;
        }

        if (products.length > 0) {
            !!products && $.each(products, function (index, item) {
                const sizes = getSizes(item.sizes);
                const amountOfSizes = Object.keys(sizes).length;
                if (amountOfSizes > 0) {
                    options.push({
                        sku: item.sku,
                        name: item.title,
                        brand: { name: item.brand },
                        is_new_product: item.is_new,
                        favorite: item.favorite,
                        original_price: item.price,
                        price: ((item.special_price != 0) ? item.special_price : item.price),
                        image_url: (item.image + '-product.jpg'),
                        image_url_m: (item.image + '-product.jpg'),
                        page_url_sufix: item.link,
                        sizes: sizes,
                        color: item.color,
                        categories: item.categories,
                        seller: item.seller,
                    });
                }
            });
        }

        return options;
    }

    Recommendation.prototype.usesFeedsPage = function () {
        if (this.$page == "detail" || this.$page == "cart" || this.$page == "wishlist" || this.$page == "no-result") {
            return true;
        }
        return false;
    }

    Recommendation.prototype.handleUrl = function (url) {
        if (url.indexOf('?') > -1) {
            var urlSplitted = url.split('?');
            return urlSplitted[0].toLowerCase() + '?' + urlSplitted[1];
        }
        return url;
    }

    Recommendation.prototype.addWishList = function(){
        const sku = this.$element.data('sku-item');
        const element = this.$element;
        const existIdFavorite = this.$element.data('favorite-id');
        const action = existIdFavorite
            ? this.$destination + "/customer/wishlist/remove?id_customer_wishlist=" + existIdFavorite
            : this.$destination + "/customer/wishlist/add/?p=" + sku;

        if ($.cookie('customer_info')) {
            $.ajax({
                url: action,
                data: {sku: sku},
                type: 'GET',
                global: false,
                success: function(data){
                    var response = jQuery.parseJSON(data);

                    element.find('svg')
                        .css({
                            'animation': 'heartbeat 1s'
                        });

                    if (existIdFavorite) {
                        element.find('.black')
                            .css({
                                'animation': 'heartbeat_black 1s'
                            });

                        setTimeout(function(){
                            element.removeClass('active')
                                .find('svg')
                                .removeAttr('style')
                        }, 1000);
                    } else {
                        element.find('.black')
                            .css({
                                'animation': 'heartbeat_white 1s'
                            });

                        setTimeout(function(){
                            element.addClass('active')
                                .find('svg')
                                .removeAttr('style')
                        }, 1000);
                    }

                    element.data('favorite-id', response[sku] || '');
                }
            });
        } else {
            window.location = this.$destination + "/customer/wishlist/add/?p=" + sku;
        }
    }

    Recommendation.prototype.openCart = function() {
        this.$element.parents('.product-box-new-pdp').find('.product-box-form').toggle();
    }

    Recommendation.prototype.openCartModal = function() {
        $('.add-to-cart-buttom-recommendation').addClass('disabled');
        $(".add-to-cart-buttom-recommendation").attr("disabled", true );

        $('.rcm-choices').html('');

        const sizes = JSON.parse(this.$element.attr('data-sizes'));
        const divOutStock = $('<div>', {
            class: 'out-stock'
        });

        $.each(sizes, function (index, item) {
            const div = $('<div>', {
                text: index,
                class: 'rcm-sizes'
            });

            div.attr("data-sku", item.sku);

            if(item.stock == 624 || item.stock < 1){
                div.append(divOutStock);
            }

            $('.rcm-choices').append(div);
        });

        const product_sku = $(this.$element).closest('.carousel-item-new-pdp').data('sku');

        $('#openCartModal').find('[data-field=sku]').val(product_sku);

        const product_price = $(this.$element).parents('.product-box-new-pdp').find('[data-field="special_price"]').html();

        $('#openCartModal').find('.add-to-cart-price').html(product_price);

        $('#openCartModal').modal('open');
    }

    Recommendation.prototype.selectSize = function() {
        if(this.$element.find('.out-stock').length > 0){
            return false;
        }

        $('.rcm-sizes').removeClass('active');

        $('#openCartModal').find('[name=sku]').val(this.$element.data('sku'));

        this.$element.addClass('active');

        $(".add-to-cart-buttom-recommendation").attr("disabled", false);
        $('.add-to-cart-buttom-recommendation').removeClass('disabled');
    }

    Recommendation.prototype.submitCartModal = function() {
        var sku = this.$element.data('sku');
        var locationPath = window.location.pathname;
        var locationUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search;

        var trackingSite = null;

        if (document.location.hostname.indexOf('dafiti') > -1)
            trackingSite = 'dafiti';
        else if (document.location.hostname.indexOf('kanui') > -1)
            trackingSite = 'kanui';
        else if (document.location.hostname.indexOf('tricae') > -1)
            trackingSite = 'tricae';
        else return;

        if (typeof (ga) !== 'undefined') {
            if (this.layoutActive) {
                dataLayer.push({
                    event: "productpage_ga3",
                    page_path: locationPath,
                    page_location: locationUrl,
                    page_title: "productPage",
                    category: "Enhanced Ecommerce",
                    action: "Cart",
                    label: "Add",
                });
            } else {
                ga(
                    'ga_' + trackingSite + '.send',
                    'event',
                    'recommendations',
                    'RecoMorelikethisAddToCart',
                    sku,
                    { nonInteraction: false });
            }
        }

        $('#openCartModal').modal('close');
    }

    Recommendation.prototype.closeCart = function() {
        this.$element.find('.product-box-form').hide();
    }

    $.fn.recommendation = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.recommendation');
            var options = $.extend({}, Recommendation.DEFAULTS, $this.data(), typeof option == 'object' && option);
            if (!data) $this.data('dft.recommendation', (data = new Recommendation(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

    $(document).ready(function() {
        var path = window.location.pathname;
        if(path.includes('.html')){
            $(document).on('scroll', renderRecommendation);
        }else{
            $('[data-recommendation]').recommendation('render');
        }
    });

    function renderRecommendation(){
        if($('.recommendations')[0].getBoundingClientRect().top >= 0 && $('.recommendations')[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())) {
            $(document).off('scroll', renderRecommendation);
            $('[data-recommendation]').recommendation('render');
        }
    }

    $(document).on('click', '.product-box-image-new-pdp [data-wishlist-url]', function() {
        $(this).recommendation('addWishList');
    })

    $(document).on('click', '.product-box-new-pdp .cart', function() {
        $(this).recommendation('openCart');
    })

    $(document).on('mouseleave', '.product-box-new-pdp', function() {
        $(this).recommendation('closeCart');
    })

    $(document).on('click', '.btn-cart', function() {
        $(this).recommendation('openCartModal');
    })

    $(document).on('click', '.rcm-sizes', function() {
        $(this).recommendation('selectSize');
    })

    $(document).on('submit', '#openCartModal', function() {
        $(this).recommendation('submitCartModal');
    })

})(jQuery);
