(function ($) {

    'use strict';

    var Gallery = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.bind();
        this.type = $('body').hasClass('touch') ? new Mobile(this.$element) : new Desktop(this.$element);
    };

    Gallery.DEFAULTS = {
        zoom: true
    };

    Gallery.prototype.bind = function () {
        this.$element.find('.gallery-preview-img').on('load', $.proxy(this, 'changeActiveImage'));
        this.$element.find('.gallery-preview').on('swipeleft', $.proxy(this, 'next'))
            .on('swiperight', $.proxy(this, 'previous'))
            .on('movestart', function (e) {
                // If the movestart is heading off in an upwards or downwards
                // direction, prevent it so that the browser scrolls normally.
                if ((e.distX > e.distY && e.distX < -e.distY) ||
                    (e.distX < e.distY && e.distX > -e.distY)) {
                    e.preventDefault();
                }
            });
        this.$element.find('[data-lazyload]').lazyload({effect : 'fadeIn'});
    };

    Gallery.prototype.next = function () {
        this.preview(this.$element.find('.gallery-thumb').eq(this.selectedIndex() + 1));
    };

    Gallery.prototype.previous = function () {
        this.preview(this.$element.find('.gallery-thumb').eq(this.selectedIndex() - 1));
    };

    Gallery.prototype.pager = function (el) {
        var selected = $(el).data('slide-index');
        var $thumbs = this.$element.find('.gallery-thumb');

        for (var i in $thumbs) {
            if (selected == i) {
                this.preview($thumbs[i]);
                return;
            }
        }
    };

    Gallery.prototype.changeActiveImage = function () {
        this.resetPagerActive(this.$element.find('.gallery-pager .gallery-pager-link').eq(this.selectedIndex()));
    };

    Gallery.prototype.preview = function (el) {
        if (!el) return;

        var $el = $(el);

        if (!$el.closest('.gallery-modal-thumbs').length > 0) {
            this.$element.find('.gallery-preview .gallery-preview-img').attr('src', $el.attr('href'));
            this.$element.find('.gallery-preview').attr('data-img-zoom', $el.attr('data-img-zoom'));
        } else {
            this.$element.find('.gallery-modal-preview .gallery-modal-preview-img').attr('src', $el.data('img-modal'));
        }

        this.$element.find('.gallery-thumb').removeClass('selected');
        $el.addClass('selected');
    };

    Gallery.prototype.initSlideshow = function () {
        var imgs = this.$element.find('.slideshow img');

        imgs.on('panzoomzoom', function (e, panzoom, scale, opts) {
            var slideshow = $(this).closest('.slideshow');
            if (scale > 1) {
                $(this).panzoom('option', 'disablePan', false);
                slideshow.children('a').addClass('is-zoom');
            } else {
                $(this).panzoom('option', 'disablePan', true);
                slideshow.children('a').removeClass('is-zoom');
            }
            $(this).data('scale', scale);
        });
    };

    Gallery.prototype.zoom = function (e) {
        if (this.options.zoom == false) {
            return;
        }

        this.$element.addClass('is-zoom-active');
        var $preview = this.$element.find('.gallery-preview');

        var limitX = $preview.width();
        var limitY = $preview.height();

        $preview.find('.gallery-preview-img').css('display', 'none');
        $preview.css("background-image", "url('" + $preview.attr('data-img-zoom').replace(/'/g, '') + "')");


        var offset = $preview.offset();
        var magnifierLeft = e.pageX - offset.left;
        var magnifierTop = e.pageY - offset.top;

        if (magnifierLeft < 0) magnifierLeft = 0;
        if (magnifierTop < 0) magnifierTop = 0;
        if (magnifierLeft > limitX) magnifierLeft = limitX;
        if (magnifierTop > limitY) magnifierTop = limitY;

        var bgLeft = (magnifierLeft * 100) / limitX;
        var bgTop = (magnifierTop * 100) / limitY;

        $preview.css('background-position', bgLeft + '% ' + bgTop + '%');
    };

    Gallery.prototype.zoomEnd = function (e) {
        this.$element.removeClass('is-zoom-active');
        this.$element.find('.gallery-preview').css('background-image', 'none');
        this.$element.find('.gallery-preview-img').css('display', 'block');
    };

    Gallery.prototype.loadCatwalk = function () {
        Catwalk.load_catwalk(this, 'loadedCatwalk');
    };

    Gallery.prototype.loadedCatwalk = function (data, _this) {
        if (data != '') {
            this.$element.gallery('zoomEnd');
            $('.gallery-preview').after(data);
            _this.createCatwalkElements(_this.$element);
            _this.resetCarousel();
            _this.$element.on('click', '.gallery-thumb', function () {
                _this.toggleCatwalkIcon($(this), _this.$element);
            });
        }
    };

    Gallery.prototype.resetCarousel = function() {
        if(this.$element.find('.carousel-item').length >= 6) {
            this.$element.find('.gallery-thumbs').removeClass('no-controls');
            this.$element.find('.carousel').append('<a href="#" class="carousel-previous" data-disabled><i class="icon-arrow-up"></i></a>');
            this.$element.find('.carousel').append('<a href="#" class="carousel-next"><i class="icon-arrow-down"></i></a>');
        }
    };

    Gallery.prototype.createCatwalkElements = function (galleryEl) {
        galleryEl.find('.carousel-items').addClass('has-catwalk');
        galleryEl.find('.carousel-item').last().clone()
            .prependTo('.has-catwalk')
            .find('.gallery-thumb')
            .addClass('catwalk')
            .append('<div class="catwalk-play-small-icon icon-icon_circle_play"></div>');

        galleryEl.find('.gallery-preview')
            .append('<a class="catwalk-play-big-icon icon-icon_circle_play catwalk-actions-link-play"></a>');
    };

    Gallery.prototype.toggleCatwalkIcon = function (_this, galleryEl) {
        var $this = _this;
        if ($this.parent().is(':first-child')) {
            galleryEl.addClass('catwalk');
        } else {
            galleryEl.removeClass('catwalk');
        }
    };

    Gallery.prototype.resetPagerActive = function (el) {
        this.$element.find('.gallery-pager .active').removeClass('active');
        $(el).addClass('active');
    };

    Gallery.prototype.selectedIndex = function () {
        var selected = this.$element.find('.gallery-preview-img').attr('src');
        var $thumbs = this.$element.find('.gallery-thumb');

        for (var i in $thumbs) {
            if (selected == $($thumbs[i]).attr('href')) {
                return parseInt(i, 10);
            }
        }

        return 0;
    };

    Gallery.prototype.openModal = function() {
        this.type.openModal(this);
    };

    Gallery.prototype.openModal = function() {
        this.type.openModal(this);
    };

    Gallery.prototype.loadZoomImage = function () {
        this.$element.find('.gallery-product').lazyload();
        this.$element.find('.gallery-modal-preview-img').lazyload();
    };

    var Desktop = function(element) {
        this.$element = element;
    };

    Desktop.prototype.openModal = function (Gallery) {
        this.resetModal();
        this.createModal();
        Gallery.preview(this.$element.find('.gallery-modal-thumbs .selected'));

        this.$element.find('.gallery-modal').modal();
        this.scrollModalPreview();
    };

    Desktop.prototype.resetModal = function () {
        this.$element.find('.modal-dialog .gallery-modal-thumbs .gallery-items').remove();
    };


    Desktop.prototype.createModal = function () {
        this.$element.find('.gallery-thumbs .gallery-items').clone().appendTo('.modal-dialog  .gallery-modal-thumbs .carousel-items');
    };

    Desktop.prototype.scrollModalPreview = function () {
        this.$element.find('.gallery-modal-preview')
        .scrollTop((this.$element.find('.gallery-modal-preview-img').height() - $(window).height()) / 2);
    };

    var Mobile = function(element) {
        this.$element = element;
        this.$template = this.$element.find('.modal-dialog .gallery-modal-carousel .carousel').clone();
    };

    Mobile.prototype.openModal = function(Gallery) {
        this.resetModal();
        this.createModal();

        this.$element.find('.gallery-modal').modal();

        for (var i = Gallery.selectedIndex() - 1; i >= 0; i--) {
            $('.modal-dialog .carousel').carousel('next');
        }

        $( '.modal-dialog gallery-modal-carousel .carousel').find( '.slideshow-item' ).addClass('is-inactive');
        var selected =  $('.slideshow-item' )[ Gallery.selectedIndex() ];
        $( '.modal-dialog gallery-modal-carousel .carousel').find( selected ).removeClass('is-inactive').addClass('is-active');

        Gallery.initSlideshow();
    };

    Mobile.prototype.createModal = function () {
        this.$template.clone().appendTo(this.$element.find('.modal-dialog .gallery-modal-carousel'));

        this.$element.find('.gallery-thumb').each(function () {
            var $item;
            if ($(this).hasClass('catwalk')) {
                $item = $('<li class="carousel-item slideshow-item catwalk" />');
            } else {
                $item = $('<li class="carousel-item slideshow-item" />');
                $('<img />')
                    .attr('src', $(this).data('img-modal'))
                    .appendTo($item);
            }
            $item.appendTo('.modal-dialog .gallery-modal-carousel .carousel-items');
        });
    };

    Mobile.prototype.resetModal = function () {
        this.$element.find('.modal-dialog .gallery-modal-carousel .carousel').remove();
    };

    $.fn.gallery = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.gallery');
            var options = $.extend({}, Gallery.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.gallery', (data = new Gallery(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '.gallery .gallery-thumb', function (e) {
        if (!$(this).hasClass('catwalk')) {
            $('.product-img-box-video').removeClass('show');
            $('.gallery-preview').removeClass('is-hidden');
        }

        if($(this).attr('href') == '#'){
            $(this).attr("href", $(this).data('img-gallery'));
        }

        var gallery = $(e.currentTarget).closest('.gallery');
        gallery.gallery('preview', e.currentTarget);
        e.preventDefault();
    });

    $(document).on('click', '.gallery .gallery-pager-link', function (e) {
        var gallery = $(e.currentTarget).closest('.gallery');
        gallery.gallery('pager', e.currentTarget);
        e.preventDefault();
    });

    $(document).on('mousemove.zoom', '.gallery .gallery-preview', function (e) {
        var gallery = $(e.currentTarget).closest('.gallery');
        if (!$(this).parent().hasClass('catwalk')) {
            gallery.gallery('zoom', e);
        }
    });

    $(document).on('mouseleave.zoom', '.gallery .gallery-preview', function (e) {
        var gallery = $(e.currentTarget).closest('.gallery');
        gallery.gallery('zoomEnd', e);
    });

    $(document).on('click', '.gallery-preview', function (e) {
        e.preventDefault();
        if (!$(this).parent().hasClass('catwalk')) {
            $(this).closest('.gallery').gallery('openModal');
            $('.gallery-thumb.catwalk').closest('.carousel-item').addClass('hidden');
        }
    });

    $(document).on('touchstart', '.gallery-preview:not([data-disabled-zoom])', function (e) {
        $(e.currentTarget).parent('.gallery').gallery();
    });

    $(document).on('keyup', '.gallery-modal', function (e) {
        if (e.which == 39) {
            $(this).find('.carousel').carousel('next');
        } else if (e.which == 37) {
            $(this).find('.carousel').carousel('previous');
        }
    });

    $(document).on('click', '.catwalk-actions-link-play', function () {
        $('.video-controls').removeClass('small-catwalk');
        $('.gallery-preview').addClass('is-hidden');
        $('.product-img-box-video').addClass('show');
    });

    $(document).on('click', '.gallery-preview', function() {
        $('.gallery').gallery('loadZoomImage');
    });

})(jQuery);



