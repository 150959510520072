(function($) {

    'use strict';

    var EventsMonitoring = function(element) {
        this.$element = $(element);
    };

    EventsMonitoring.prototype.init = function() {
        this.start();
        this.stop();
    };

    EventsMonitoring.prototype.start = function() {
        var that = this;
        $(document).ajaxStart(function() {
            that.$element.addClass('is-active');
            that.$element.highlight('open');
        });
    };

    EventsMonitoring.prototype.stop = function() {
        var that = this;
        $(document).ajaxStop(function(handler) {
            that.$element.removeClass('is-active');
            that.$element.highlight('close');
        });
    };

    var element = $('[data-event-monitoring]');

    (new EventsMonitoring(element)).init();

})(jQuery);