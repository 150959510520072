(function() {
    const sponsoredProducts = Array.from(document.querySelectorAll('[data-tracking]'));

    const trackingDataArr = sponsoredProducts.map(function (sponsoredProduct) {
      return sponsoredProduct.attributes[0].nodeValue;
    });

    if (trackingDataArr && window.abg) {

      trackingDataArr.forEach(function(trackingData) {
        window.abg.reportImpression(trackingData);
      });

    }

  })();
