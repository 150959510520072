(function($) {

    'use strict';

    var CartAddItem = function(element, options) {
        this.$element   = $(element);
        this.options    = options;

        this.$storeType = $('[data-cart-store-type="default"]');
        this.$template  = $('#cart-item-template');
    };

    CartAddItem.prototype.add = function(args) {
        var params = args || this.getParams();

        $.getJSON('/cart/add/', params, $.proxy(this, 'loaded'));
    };

    CartAddItem.prototype.getParams = function() {
        return {
            'sku_simple' : this.$element.data('sku-simple'),
            'quantity' : this.$element.data('cart-quantity') || 1,
            'gift_wrap' : this.$element.data('cart-is-gift-wrapped') ? 1 : 0
        };
    };

    CartAddItem.prototype.loaded = function(response) {
        var $cartItem = $('[data-cart-item="'+ response.item.sku_simple +'"]');

        if (!$cartItem.length) {
            this.insertItem(response);
            this.alertUserSuccess(response);
        } else {
            this.updateItem(response);
        }

        this.updateItemsCount();

        $(document).trigger('dft.cart-add.item-added', [response]);
    };

    CartAddItem.prototype.loadedSize = function (response) {
        var $skuOld    = $('input[value="'+ response.item.sku_simple +'"]').data('sku-old');
        var $cartItem = $('[data-cart-item="' + $skuOld + '"]');

        if ($cartItem) {
            this.insertItem(response);
            this.alertUserSuccess(response);
        } else {
            this.updateItem(response);
        }

        this.updateItemsCount();

        $(document).trigger('dft.cart-add.item-added', [response]);
    };

    CartAddItem.prototype.updateItemsCount = function() {
        var totalItems = this.$storeType.find('[data-cart-item]').length;
        var textMessage = totalItems + (totalItems === 1) ? ' Item' : ' Itens';

        this.$storeType.find('[data-cart-items-count]').html(textMessage);

        $(document).trigger('dft.cart-fix-title-position');
    };

    CartAddItem.prototype.insertItem = function(response) {
        var template = this.$template.html();
        var mergeJson = this.getTemplateItemsValues(response);

        if (response.item.seller.id > 0) {
            this.$storeType = $('[data-cart-store-type="seller"]');
        }

        Mustache.parse(template);

        this.$storeType.append(Mustache.render(template, mergeJson));

        this.insertGifts(response, template);
        this.hideOriginalPrice(mergeJson);
    };

    CartAddItem.prototype.getTemplateItemsValues = function (response) {
        var mergeJson;
        var stock_available = response.item.stock_available;
        var item_qtd = [];
        var items_size = [];
        var is_min_stock;
        var $skuOld    = $('input[value="'+ response.item.sku_simple +'"]').data('sku-old');

        if (stock_available > 10) {
            stock_available = 10;
        }

        for (var i=1; i <= stock_available; i++) {
            item_qtd.push({
                'item_quantity' : i,
                'is_selected' : (i === response.item.quantity)
            });
        }

        if (stock_available > 3) {
            is_min_stock = {
                'is_min_stock' : true
            };
        }

        $.each(response.item.sizes_available, function (sizes, sku){
            var is_active = 0;

            if (response.item.size == sizes) {
                is_active = 1;
            }

            var items = {
                'size' : sizes,
                'sku' : sku,
                'is_active' : is_active
            };
            items_size.push(items);
        });

        mergeJson = response.item;
        mergeJson.items_stock = item_qtd;
        mergeJson.is_min_stock = is_min_stock;
        mergeJson.items_size_stock = items_size;
        mergeJson.sku_old = $skuOld;

        return mergeJson;
    };

    CartAddItem.prototype.insertGifts = function(response, template) {
        if (!response.gifts) return;

        $.each(response.gifts, $.proxy(this, 'renderItemAsGift', template));
    };

    CartAddItem.prototype.renderItemAsGift = function(template, sku, item) {
        item.is_gift = true;
        this.$storeType.append(Mustache.render(template, item));
    };

    CartAddItem.prototype.hideOriginalPrice = function(response) {
        var priceDiscount = response.price_discount;
        var sku = response.sku_simple;

        if (priceDiscount === 0) {
            $('[data-cart-item="'+ sku +'"]').find('[data-cart-item-price-original]').hide();
            $('[data-cart-item="'+ sku +'"]').find('[data-cart-item-price-discount]').hide();
        }
    };

    CartAddItem.prototype.updateItem = function (response) {
        var $cartItem = $('[data-cart-item="' + response.item.sku_simple + '"]');

        if (!response.success) {
            this.alertUserError(response);
        } else {
            $cartItem.find('input[name="qty_'+ response.item.sku_simple +'"]').val(response.item.quantity);
            $cartItem
            .find('[data-target="qty_'+ response.item.sku_simple +'"] .selectbox-option.selectbox-current')
            .html(response.item.quantity);
        }
    };

    CartAddItem.prototype.alertUserError = function(response) {
        var params = {
            'message' : response.msg,
            'shortMessage' : 'Produto removido!'
        };

        $(document).trigger('dft.message-error', params);
    };

    CartAddItem.prototype.alertUserSuccess = function(response) {
        var params = {
            'message' : response.msg,
            'shortMessage' : 'Produto adicionado!'
        };

        $(document).trigger('dft.message-success', params);
    };

    $.fn.cartAddItem = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.cart-add-item');
            var options = $.extend({}, CartAddItem.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-add-item', (data = new CartAddItem(this, options)));

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '[data-cart=add-item]', function(e) {
        e.preventDefault();

        $(e.currentTarget).cartAddItem('add');
    });

    $(document).on('dft.cart-add.add', function(e, args) {
        $(e.currentTarget).cartAddItem('add', args);
    });

    $(document).on('dft.cart-add.load-size', function (e, args) {
        $(e.currentTarget).cartAddItem('loadedSize', args);
    });

})(jQuery);
