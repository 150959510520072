(function($) {

    'use strict';

    var VerifyPasswordStrength = function(element, options) {
        this.$element       = $(element);
        this.options        = options;
    };

    VerifyPasswordStrength.prototype.valuePass = function() {
        var pass = this.$element.val();
        this.checkStrength(pass);
    };
    
    VerifyPasswordStrength.prototype.checkStrength = function(pass) {
        var strength = 0;
        var $result = $('[data-my-account=result]');

        $result.show();

        if (pass.length == 0) {
            $result.hide();
        } else if (pass.length < 7) {
            $result.show();
            $result.find('.bar').removeClass().addClass('bar short col-md-1 col-sm-1 col-xs-1');
            if ($('.result .text').length == 0) {
                $result.prepend('<p class="text">Nível de segurança: <span class="item">Muito curto</span></p>');
            } else {
                $result.find('.text').html('<p class="text">Nível de segurança: <span class="item">Muito curto</span></p>');
            }
        } else {

            if (pass.length >= 8) strength += 1;
            if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))  strength += 1;
            if (pass.match(/([a-zA-Z])/) && pass.match(/([0-9])/))  strength += 1;
            if (pass.match(/([!,%,&,@,#,$,^,*,?,_,~])/))  strength += 1;
            if (pass.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1;

            if (strength < 2 ) {
                $result.find('.bar').removeClass().addClass('bar weak col-md-3 col-sm-3 col-xs-3');
                $result.find('.text').html('<p class="text">Nível de segurança: <span class="item">Fraco</p>');
            } else if (strength == 2 || strength == 3) {
                $result.find('.bar').removeClass().addClass('bar good col-md-6 col-sm-6 col-xs-6');
                $result.find('.text').html('<p class="text">Nível de segurança: <span class="item">Bom</span></p>');
            } else if (strength == 4) {
                $result.find('.bar').removeClass().addClass('bar strong col-md-9 col-sm-9 col-xs-9');
                $result.find('.text').html('<p class="text">Nível de segurança: <span class="item">Forte</span></p>');
            } else {
                $result.find('.bar').removeClass().addClass('bar very-strong col-md-12 col-sm-12 col-xs-12')
                $result.find('.text').html('<p class="text">Nível de segurança: <span class="item">Muito Forte</span></p>');
            }
        }
    };

    VerifyPasswordStrength.prototype.disableCheckStrength = function() {
        var $result = $('[data-my-account=result]');
        $result.hide();
    }

    $.fn.verifyPasswordStrength = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.verify-password-strength');
            var options = $.extend({}, VerifyPasswordStrength.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.verify-password-strength', (data = new VerifyPasswordStrength(this, options)));

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('keyup', '[data-my-account=check-pass]', function(e) {
        e.preventDefault();
        $(e.currentTarget).verifyPasswordStrength('valuePass', e);
    });

    $(document).on('blur', '[data-my-account=check-pass]', function(e) {
        $(e.currentTarget).verifyPasswordStrength('disableCheckStrength');
    });

})(jQuery);
