(function ($) {

    'use strict';

    var Form = function (element, options) {
        this.$element = $(element);
        this.options = options;

        var form = this.$element.parsley({
            'namespace': 'data-form-',
            'uiEnabled': this.options.formUiEnable
        });

        form.subscribe('parsley:field:error', this.setInvalidField);
        form.subscribe('parsley:field:success', this.setValidField);
        form.subscribe('parsley:form:validated', $.proxy(this, 'validated'));
    };

    Form.DEFAULTS = {
        remote: false,
        target: false,
        formUiEnable : false
    };

    Form.prototype.validated = function(form) {
        var isValid = form.isValid();
        this.$element.toggleClass('is-valid', isValid);
        this.$element.toggleClass('is-invalid', !isValid);

        if (isValid) {
            if (this.options.remote !== false) {
                this.load(form);
            }
        } else {
            this.$element.trigger('form:invalid');
        }
    };

    Form.prototype.load = function(form) {
        this.$element.trigger('form:load');
        var el = form.$element;
        form.submitEvent.preventDefault();
        this.lockSubmit();
        $.ajax({
            type:   el.attr('method'),
            url:    el.attr('action'),
            data:   el.serializeArray()
        }).always($.proxy(this, 'loaded'));
    };

    Form.prototype.loaded = function(data) {
        if (data.status === 400) {
            $(this.options.target).html(data.responseText);
            $(this.options.target).addClass('error-message');
        } else if (this.options.target) {
            $(this.options.target).html(data);
        }
        this.releaseSubmit();
        this.$element.trigger('form:loaded', [data]);
    }

    Form.prototype.setValidField = function(field) {
        var el = field.$element;
        el.removeClass('is-invalid');
        el.addClass('is-valid');
    };

    Form.prototype.setInvalidField = function(field) {
        var el = field.$element;
        el.addClass('is-invalid');
        el.removeClass('is-valid');
        if (el.data('form-inside-message')) {
            el.attr('placeholder', el.data('form-inside-message'));
        }
    };
    
    Form.prototype.lockSubmit = function(){
        var actionButtons = this.$element.find('button, input[type="submit"]');
        actionButtons.attr('disabled', true);
    }

    Form.prototype.releaseSubmit = function(){
        var actionButtons = this.$element.find('button, input[type="submit"]');
        actionButtons.removeAttr('disabled');
    }

    $.fn.form = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.form');
            var options = $.extend({}, Form.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.form', (data = new Form(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('submit', '[data-form]', function(e){
        if (!$(this).data('dft.form')) {
            e.preventDefault();
            $(this).form().trigger('submit');
        }
    });

})(jQuery);
