(function($) {

	'use strict';

	var MenuStacked = function(element, options) {
		this.$element = $(element);
	}

	MenuStacked.DEFAULTS = {
	};

	MenuStacked.prototype.open = function(item) {
		this.$element.find('.menu-stacked-trigger').val([item]);
	}

	$.fn.menuStacked = function(option, item) {
		return this.each(function() {
			var $this   = $(this);
			var data    = $this.data('dft.menu-stacked');
			var options = $.extend({}, MenuStacked.DEFAULTS, $this.data(), typeof option == 'object' && option);

			if (!data) $this.data('dft.menu-stacked', (data = new MenuStacked(this, options)));
			if (typeof option == 'string') data[option](item);
		});
	}

})(jQuery);
