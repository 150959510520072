(function($) {

    'use strict';

    var Quickview = function (element, options) {
        this.$element = $(element);
        this.options  = options;
        this.$stock   = this.$element.find('[data-stock-available]');
        this.$quickviewModal = this.$element.find('.quickview-modal');
    }

    Quickview.DEFAULTS = {}

    Quickview.prototype.init = function(e) {
        this.destroy();
        this.catwalk();
        this.setData();
        this.gallery();
        this.$stock.stockAvailable('load');
        this.$quickviewModal.find('[data-lazyload]').lazyload();

        $('.stock-available-sizeguide-title').remove();
        $("[data-value='size-reminder']").remove();

        $(document).trigger('quickview').unbind('mousemove.zoom')
            .off('click', '.gallery-preview');
    }

    Quickview.prototype.destroy = function(e) {
        $('.quickview-modal').parent().each(function() {
            if (!$(this).hasClass('is-open')) {
                $(this).modal('destroy');
            }
        });
    };

    Quickview.prototype.catwalk = function() {
        $('#gallery').gallery('loadCatwalk');
    };

    Quickview.prototype.setData = function() {
        return this.$quickviewModal.attr('data', 'quickview-modal');
    }

    Quickview.prototype.gallery = function() {
        this.$quickviewModal.data('data-dismiss', 'modal');
    }

    $.fn.quickview = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.quickview');
            var options = $.extend({}, Quickview.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.quickview', (data = new Quickview(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).on('modal.quickview-modal', '.modal-2', function() {
        $(this).quickview('init');
    });
})(jQuery);
