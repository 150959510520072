(function ($) {

    'use strict';

    var AddCoupon = function (element) {
        this.$element = $(element);
    };

    AddCoupon.prototype.add = function () {
       var coupon = this.hasCoupon();

       if(coupon ) {
            $.ajax({
                url: '/index/coupon',
                cache: false
            }).success($.proxy(this, 'success'));
       }
    };

    AddCoupon.prototype.success = function(data) {
        if (data.hasAppliedCoupon === false) return;

        var messageType = (data.isValidCoupom) ? 'success' : 'error';
        var params = {
            'message' : data.message,
            'shortMessage' : 'Cupom aplicado!'
        };

        $(document).trigger('dft.message-' + messageType, params);
    };

    AddCoupon.prototype.hasCoupon = function () {
        name = 'coupon';
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null
    };

    $(document).on('dft.add-coupon', function(){
        var ac = new AddCoupon();
        ac.add();
    });

})(jQuery);
