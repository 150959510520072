var Checkout = window.Checkout || {};

Checkout.validate = function(config){

    var self = this;

    this.form = $(config.form);

    /* based on bob value where legal person = 1 and natural person = 2 */
    this.personType = ['','required-legal-person','required-natural-person'];

    this.personTypeMessages = {
        'required-legal-person': 'cnpj',
        'required-natural-person': 'cpf'
    }

    this.isValid = function(){
        $(this).valid();
    }

    this.addRule = function(xpath, text, ruleName, ruleValue){
        var message = {},
            config = {},
            $element = this.form.find(xpath);

        if ($element.length === 0) return false;

        message[ruleName]=text;
        config[ruleName]=ruleValue;
        config['messages']=message;

        $element.each(function(){
            $(this).on('blur',self.isValid).rules("add",config);
        });
    }

    this.addTaxIdRule = function(){
        var $tax_id = $(':input.check-tax-identification');

        if($tax_id.length === 0) return false;

        $tax_id.each(function(){
            $(this).on('blur',this.isValid).rules('add',{
                taxId:true,
                checkTaxIdAvailability:true,
                messages:{
                    taxId:function(){
                        return i18n['invalid_' + self.personTypeMessages[self.getPersonTypeName()]] +
                            '<br />'  +i18n['verify_' + self.personTypeMessages[self.getPersonTypeName()]];
                    },
                    checkTaxIdAvailability:function(){
                        return i18n[self.personTypeMessages[self.getPersonTypeName()]+'Exists'];
                    }
                }
            });
        });
    }

    this.addTaxIdRuleAccount = function(){
        var $tax_id = $('#'+this.form.attr("id")+' :input.check-tax-identification');
        if($tax_id.length === 0) return false;

        $tax_id.each(function(){
            $(this).on('blur',this.isValid).rules('add',{
                taxId:true,
                checkTaxIdAvailability:true,
                messages:{
                    taxId:function(){
                        return i18n.taxIdValid;
                    },
                    checkTaxIdAvailability:function(){
                        return i18n.taxExist;
                    }
                }
            });
        });
    }

    this.addRequiredRules = function($inputs,requiredHandler){
        $inputs.each(function(){
            $(this).on('blur',self.isValid).rules('add',{
                required:requiredHandler,
                messages:{
                    required:i18n.requiredField
                }
            });
        });
    }

    this.addPersonTypeRequiredRule = function() {
        var $inputs = this.form.find(':input.'+this.getPersonTypeName());

        if ($inputs.length === 0) return false;

        this.addRequiredRules($inputs,function(element){
            return $(element).hasClass(self.getPersonTypeName());
        });
    }

    this.addCepRules = function() {
        var $inputs = this.form.find(':input.mask-postcode');
        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            $(this).rules('add',{
                checkCepError:true,
                messages:{
                    checkCepError:i18n.invalidPostCode
                }
            });
        });
    }

    this.addRequiredLoginRules = function() {
        var $inputs = this.form.find(':input.validate-email');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            var that = this;
            $(this).on('blur',self.isValid).rules('add',{
                required:true,
                emailConditional:function(){

                    if($(that).val().indexOf('@') > 0) {
                        return true;
                    }
                    return false;
                },
                checkEmailLoginExists:function(){

                    if($(that).val().indexOf('@') > 0) {
                        return true;
                    }
                    return false;
                },
                taxId:function(){

                    if($(that).val().indexOf('@') > 0 ) {
                        return false;
                    }
                    return true;
                },
                messages:{
                    required : i18n.typeYourEmail,
                    emailConditional:i18n.invalidEmailOrTax,
                    taxId:i18n.invalidEmailOrTax,
                    checkEmailLoginExists:i18n.notRegisteredEmail
                }
            });
        });

        var $inputPass = this.form.find('#LoginForm_password');

        if ($inputPass.length === 0) return false;

        $inputPass.on('blur',self.isValid).rules('add',{
            required:true,
            messages:{
                required : i18n.typeYourPass
            }
        });
    }

    this.addRequiredEmailRules = function() {
        var $inputs = this.form.find(':input.validate-email');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            $(this).rules('add',{
                email:true,
                checkEmailExists:true,
                messages:{
                    email:i18n.invalidEmail,
                    checkEmailExists:i18n.emailExists
                }
            });
        });
    }

    this.addRequiredEmailRulesAccount = function() {
        var $inputs = this.form.find(':input.validate-email');
        var $inputs_hidden = this.form.find(':input#original_email');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            that = $(this);
            $(this).rules('add',{
                email:true,
                checkEmailExists:function(){
                    if(that.val() == $inputs_hidden.val()) {
                        return false;
                    }
                    return true;
                },
                messages:{
                    email:i18n.invalidEmail,
                    checkEmailExists:i18n.emailExists
                }
            });
        });
    }

    this.addEqualPasswordRules = function(){
        var $inputs = this.form.find(':input.confirm-password');
        formId = this.form.attr("id");
        if ($inputs.length === 0) return false;

        $inputs.each(function(){

            var id = $(this).attr("id");
            id = id.substring(0,id.length-1);

            $(this).rules('add',{
                equalTo : $('#'+formId+' #' + id ),
                messages:{
                    equalTo: i18n.unmatchedPassword
                }
            });
        });
    }

    this.addMinLengthBirthdayRules = function(){
        var $inputs = this.form.find('.nascimento select');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){

            $(this).on('blur',self.isValid).rules('add',{
                required : true,
                checkBirthdayError : function(){
                    var valid = true;

                    $inputs.each(function(){
                        if(!$(this).val())
                            valid = false;
                    });

                    return valid;

                },
                messages:{
                    checkBirthdayError: i18n.wrongBirthdate,
                    required:i18n.requiredField
                }
            });
        });
    }

    this.addMinLengthPasswordRules = function(){
        var $inputs = this.form.find(':input[type=password]');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){

            $(this).rules('add',{
                minlength : 6,
                messages:{
                    minlength: i18n.minlength
                }
            });
        });
    }

    this.addRequiredStateRegistrationRules = function() {
        var $inputs = this.form.find(':input.validate-state-registration'),
            $exempt = $(config.exemptStateRegistration);

        if ($inputs.length === 0 || $exempt.length === 0) return false;

        $inputs.rules('add',{
            required:function(){
                return (Checkout.helpers.getSelectedProfile(self.form, config.personTypeField) == '1' && !$exempt.is(':checked'));
            },
            messages:{
                required:i18n.requiredField
            }
        });

        $exempt.on('change',function(){
            $inputs.valid();
        });
    }

    this.getSelectedProfile = function(){
        var $hiddenCustomerPersonality = $('input:hidden#customerPersonality');

        if($hiddenCustomerPersonality.length !== 0) {
            return $hiddenCustomerPersonality.val();
        }

        return this.form.find(config.personTypeField).find('input:radio:checked').val();
    }

    this.getPersonTypeName = function(){
        var selectedType = Checkout.helpers.getSelectedProfile(this.form, config.personTypeField);
        return this.personType[selectedType];
    }

    this.addCreditCardSecurityCodeRule = function() {
        var $inputs = this.form.find('#CreditcardForm_cc_security_code');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            $(this).rules('add',{
                minlength : 1,
                maxlength : 4,
                messages:{
                    minlength: i18n.invalidSecurityCode,
                    maxlength: i18n.invalidSecurityCode
                }
            });
        });
    }

    this.addCreditCardNumberRule = function() {
        var $inputs = this.form.find('#CreditcardForm_cc_number');
        var $ccType = this.form.find('#cc_type');

        var $isTelesales = ($ccType.length === 0) ? false : true;
        var $minLength = $isTelesales ? 1 : 14;
        var $repeat = $isTelesales ? false : true;

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            $(this).rules('add',{
                minlength : $minLength,
                maxlength : 19,
                repeatNumber : $repeat,
                messages:{
                    minlength: i18n.invalidCreditCard,
                    maxlength: i18n.invalidCreditCard,
                    repeatNumber: i18n.invalidCreditCard
                }
            });
        });
    }

    this.addPostPaidTermsAcceptRule = function() {
        
        var $inputs = this.form.find('#postpaid_terms');

        $inputs.each(function(){
            $(this).rules('add',{
                required: true,
                messages:{
                    required: i18n.acceptPostpaidTerms 
                }
            });
        });

    }    

    this.addPhoneNumberRule = function() {
        var $inputs = this.form.find('.mask-phone-number');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){
            $(this).rules('add',{
                minlength : 14,
                maxlength : 15,
                messages:{
                    minlength: i18n.invalidPhone,
                    maxlength: i18n.invalidPhone
                }
            });
        });
    }

    this.addCreditCardRequiredRule = function() {
        var $inputs = this.form.find(':input.required-credit-card');

        if ($inputs.length === 0) return false;

        this.addRequiredRules($inputs,function(){
            return $(config.creditCardRadio).is(':checked');
        });
    }

    this.addStreetNumberRule = function() {
        var $inputs = this.form.find(':input.mask-street_number');
        if ($inputs.length === 0) return false;

        $inputs.each(function() {

            $(this).on('blur',self.isValid).rules('add', {
                min: 1,
                max: 1000000,
                messages: {
                    min: i18n.streetNumberMin,
                    max: i18n.streetNumberMax
                }
            });
        });
    }

    this.addCreditCardExpirationDate = function(){
        var $inputs = this.form.find('.ValidCard select');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){

            $(this).on('blur',self.isValid).rules('add',{
                required: false,
                expirationDate : function(){
                    var valid = true;

                    $inputs.each(function(){
                        if(!$(this).val())
                            valid = false;
                    });

                    return valid;

                },
                messages:{
                    expirationDate: i18n.requiredField
                }
            });
        });
    }

    this.addValidFreightScheduled = function(){
        var $inputs = this.form.find('.ValidFreightScheduled');

        if ($inputs.length === 0) return false;

        $inputs.each(function(){

            $(this).on('blur',self.isValid).rules('add',{
                required: false,
                expirationDate : function(){
                        var valid = true;

                        self.form.find('.ValidFreightScheduled').each(function(){
                            if(!$(this).val())
                                valid = false;
                        });

                        return valid;
                },
                messages:{
                    expirationDate: " "
                }
            });
        });
    }

    this.clearPersonTypeFields = function(){
        this.form.find(['.',this.personType[1],',.',this.personType[2]].join('')).clearField();
    }

    this.setValidationEvents = function(){
        this.form.find(config.personTypeField).find('input:radio').on('change',function(){
            self.form.validate({onkeyup:false}).resetForm();
            /* self.clearPersonTypeFields(); */
            self.addPersonTypeRequiredRule();
            self.addTaxIdRule();
            self.addCreditCardNumberRule();
            self.addCreditCardRequiredRule();
            self.addCreditCardSecurityCodeRule();
            self.addCreditCardExpirationDate();
            self.addStreetNumberRule();
            self.addPhoneNumberRule();
            self.addValidFreightScheduled();
            self.addPostPaidTermsAcceptRule();
        });

        this.form.on('loadedExtraFields',function(){
            self.form.validate({onkeyup:false});
            self.addPersonTypeRequiredRule();
            self.addCepRules();
            self.addCreditCardNumberRule();
            self.addCreditCardRequiredRule();
            self.addCreditCardSecurityCodeRule();
            self.addCreditCardExpirationDate();
            self.addStreetNumberRule();
            self.addPhoneNumberRule();
            self.addValidFreightScheduled();
            self.addPostPaidTermsAcceptRule();
        });
    }

    this.create = function(){
        this.form.validate({
            onkeyup:false,
            onfocusout:false,
            submitHandler: function(form) {

                $('#blend, #horizon').show();
                $('#horizon p.vSendOrder').show();
                window.setTimeout(
                    function () {
                        $('#horizon p.vSendOrder').slideUp(300);
                        $('#horizon p.vSendOrderTime').slideDown(300);
                    },
                    6500
                );

                form.submit();
           },
           errorPlacement: function(error, element) {
               $(element).parent().find('.error.inputDescription').remove();

               error.addClass('inputDescription');

               if(element.attr("id") == "BillingAddressForm_postcode") {
                   element.after(error);
               }else{
                   error.appendTo(element.parent());
               }

               element.parent('li').removeClass('validInput').addClass('invalidInput');
           },
           success: function(label, element){
               element = $(element);
               element.parent().find('label.required').removeClass('error');
               element.parent().find('label.error.inputDescription').remove();
               element.parent('li').removeClass('invalidInput').addClass('validInput');
           }
        });
        this.addPersonTypeRequiredRule();
        this.addRule(':input.required-field',i18n.requiredField,'required',true);
        this.addCepRules();
        this.addRequiredEmailRules();
        this.addEqualPasswordRules();
        this.addMinLengthPasswordRules();
        this.addRequiredStateRegistrationRules();
        this.addTaxIdRule();
        this.addCreditCardRequiredRule();
        this.setValidationEvents();
        this.addCreditCardNumberRule();
        this.addCreditCardSecurityCodeRule();
        this.addCreditCardExpirationDate();
        this.addMinLengthBirthdayRules();
        this.addStreetNumberRule();
        this.addPhoneNumberRule();
        self.addValidFreightScheduled();
        self.addPostPaidTermsAcceptRule();
    },

    this.createLogin = function(){
        this.form.validate({
            onkeyup:false,
            onfocus:true,
            submitHandler: function(form) {
                form.submit();
            },
            errorPlacement: function(error, element) {
                element = $(element);
                element.parent().find('.inputDescription').remove();
                error.addClass('inputDescription');
                error.appendTo(element.parent());
                element.parents('li:eq(0)').removeClass('validInput').addClass('invalidInput');
            },
            success: function(label, element){
                element = $(element);
                element.parent().find('.inputDescription').remove();
                element.parents('li:eq(0)').removeClass('invalidInput').addClass('validInput');
            }
        });

        this.addRequiredLoginRules();
    },

    this.customerAddress = function() {
        this.form.validate({
            onkeyup:false,
            onfocus:true,
            submitHandler: function(form) {
                if (!$(form).is('.ajax')) {
                    form.submit();
                }
            },
            errorPlacement: function(error, element) {
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                error.addClass('errorMessage');
                error.addClass('inputDescription');
                error.appendTo(elementParent);
                elementParent.removeClass('success').addClass('error');

                if(element.attr("id") == "AddressForm_postcode") {
                    if(element.hasClass('error')){
                        $('.cep-clear').val('');
                    }
                }
            },
            success: function(label, element){
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                elementParent.removeClass('error').addClass('success');
            }
        });
        this.addRule(':input.required-field',i18n.requiredField,'required',true);
        this.addCepRules();
        this.addPhoneNumberRule();
        this.addStreetNumberRule();
    },

    this.customerAccount = function() {
        this.form.validate({
            onkeyup:false,
            onfocus:false,
            submitHandler: function(form) {
                if($(form).attr('id') == 'form-account-edit') {
                    accountForm($(form));
                }

                if($(form).attr('id') == 'form-account-create' || $(form).attr('id') == 'form-account-create-natural' || $(form).attr('id') == 'form-account-create-legal') {
                    createAccountForm($(form));
                }
            },
            errorPlacement: function(error, element) {
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                error.addClass('errorMessage');
                error.addClass('inputDescription');
                error.appendTo(elementParent);
                elementParent.removeClass('success').addClass('error');
            },
            success: function(label, element){
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                elementParent.removeClass('error').addClass('success');
            }
        });
        this.addRule(':input.required-field',i18n.requiredField,'required',true);
        this.addCepRules();
        this.addPhoneNumberRule();
        this.addRequiredEmailRulesAccount();
        this.addStreetNumberRule();
        this.addEqualPasswordRules();
        this.addMinLengthPasswordRules();
        this.addTaxIdRuleAccount();
    }

    this.loginMyAccount = function() {
        this.form.validate({
            onkeyup:false,
            onfocus:true,
            submitHandler: function(form) {
                if (!$(form).is('.ajax')) {
                    form.submit();
                }
            },
            errorPlacement: function(error, element) {
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                error.addClass('errorMessage');
                error.addClass('inputDescription');
                error.appendTo(elementParent);
                elementParent.removeClass('success').addClass('error');
            },
            success: function(label, element){
                element = $(element);
                var elementParent = element.closest('.input-box');
                elementParent.find('.inputDescription').remove();
                elementParent.removeClass('error').addClass('success');
            }
        });
        this.addRule('.required-field',i18n.requiredField,'required',true);
        this.addEqualPasswordRules();
        this.addCepRules();
        this.addRequiredLoginRules();
    }

};
