(function ($) {
    'use strict';

    var CarrosselNoResultsEvents = function () {

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @param event
         * @param action
         */
        this.dataLayerImpressionItem = function (element, position, listName, event, action) {

            var object = {
                'event': event,
                'category': 'enhanced ecommerce',
                'action': action,
                'label': 'no result page - recomendados para voce',
                'ecommerce': {
                    'currencyCode': 'BRL',
                    'impressions': []
                }
            };

            return object;
        }

        /**
         *
         * @param element
         * @param position
         * @returns {[{quantity: string, list: string, dimension54: (string|string), dimension53: (string|string), dimension55: string, price: number, dimension52: (string|string), dimension51: (string|string), name: string, variant: *, id: *, position, category: (string|string), brand: string}]}
         */
        this.formatProducts = function (element, position) {
            const brand = element.find('h3').text();
            const name = element.find('p').text();
            const price = element.find('span').eq(1).text();
            const sku = element.data("sku");
            const categories = element.data("categories").split(",");
            const variant = element.data("variant");
            const seller = element.data("seller");

            return {
                'id': sku.toLowerCase(),
                'name': name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'price': Number(price.replace('R$ ', '').replace(',', '.')),
                'brand': brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension51': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension52': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension53': categories[3] !== undefined ? categories[3].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension54': categories[4] !== undefined ? categories[4].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""): '',
                'dimension55': 'dafiti br - ' + seller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'position': position,
                'list': 'no result page - recomendados para voce',
                'variant': variant.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
            };
        }

        /**
         *
         * @param element
        * @returns {{position: *, closest: (*|jQuery)}}
         */
        this.getClosestAndPosition = function (element) {
            var closest = $(element).closest('.product-guide-item');
            var position = closest.prevAll().length;

            return {'closest': closest, 'position': position};
        }

        /**
         *
         * @param element
         * @returns bool
         */
        this.isElementInViewport = function (element) {
            var container = $(element).closest('.product-guide-overflow').length > 0 ? $(element).closest('.product-guide-overflow') : $(element).closest('.product-guide-overflow-new-pdp-mobile');
            if(!$(element).is(':visible')) {
              return false;
            }

            return (
                $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left-20) &&
                $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right+20) &&
                $(element)[0].getBoundingClientRect().top >= 0 &&
                $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
            );
        }

        return this;
    }

    var _dataLayerFiltered = dataLayer.find(function (e) {
        return e.ET;
    });

        //WN-428: Taggeamento GA3 Reco No Result - View Item List
        if (_dataLayerFiltered != undefined && document.location.hostname.indexOf('dafiti') > -1 && _dataLayerFiltered.ET === 'catalogpage') {
            $(window).on("DOMSubtreeModified scroll", function(e){ 
            var NoResultEvents = new CarrosselNoResultsEvents(); 
            var object = {};
         
            $('.product-guide-item').each(function(i) {
                if(NoResultEvents.isElementInViewport($(this)) && !$(this).hasClass("impressionGA")){
                    $(this).addClass('impressionGA');
                    var getClosestAndPosition = NoResultEvents.getClosestAndPosition(this);

                    if(Object.keys(object).length === 0){
             
                    object = NoResultEvents.dataLayerImpressionItem(
                        getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                        'product_impressions_ga3',
                        'product impressions'
                        );
                    }
                    var formattedProduct = NoResultEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                    object.ecommerce.impressions.push(formattedProduct);
                }
               
            });
            
            if(Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }
        });

        $(document).on('click',  '.recommendations-prev, .recommendations-next ', function(e) {
            setTimeout(function(){
                var NoResultEvents = new CarrosselNoResultsEvents();
                var object = {};
                $('.product-guide-item').each(function(i) {
                    if(NoResultEvents.isElementInViewport($(this)) && !$(this).hasClass("impressionGA")){
                        $(this).addClass('impressionGA');
                        var getClosestAndPosition = NoResultEvents.getClosestAndPosition(this);
    
                         object = NoResultEvents.dataLayerImpressionItem(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'product_impressions_ga3',
                            'product impressions'
                        );
                        var formattedProduct = NoResultEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));
    
                        object.ecommerce.impressions.push(formattedProduct);
    
                        dataLayer.push(object);
                    }
                });
            }, 500);
        });
    }
})(jQuery);
