(function($) {

    'use strict';

    var LookAddToCart = function(element, options) {
        this.$element = $(element);
        this.options = options;
    };

    LookAddToCart.DEFAULTS = {}


    LookAddToCart.prototype.addItems = function() {
        var selected = $("[data-look-update] input:checked").closest(".lookbook-product");
        var selectedSizes = new Array();
        var isError = false;
        selected.each(function(index,item){
            var selectedSize = $(item).find("[data-look-selected-size]").val();
            if( selectedSize=="" ) {
                isError = true;
                $(item).find(".change-sizes-click").addClass('is-invalid');
            } else {
                selectedSizes.push(selectedSize);
            }
        });
        if( !isError && selectedSizes.length != 0 ) {
            window.location = "/cart/addmulti?simple="+selectedSizes.join();
        }
    }

    $.fn.lookAddToCart = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.look-add-to-cart');
            var options = $.extend({}, LookAddToCart.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.look-add=to-cart', (data = new LookAddToCart(this, options)));
            }

            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('click', '[data-look-add-to-cart]', function() {
        $(this).lookAddToCart('addItems');
        return false;
    });

    $(document).on('change','.add-to-cart-form-sizes',function() {
        $(this).removeClass('is-invalid');
    })
    
})(jQuery);
