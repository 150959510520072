(function($) {

    'use strict';
    var Adserver = function() {}

    Adserver.prototype.init = function() {
        this.adservers = $('[data-adserver]');
    }

    Adserver.prototype.random = function( maxNumber ) {
        var randomNumber = Math.floor((Math.random() * maxNumber)+1);
        return randomNumber;
    }

    Adserver.prototype.picAd = function() {
        var that = this;
        $.each(this.adservers, function(index,adserver) {
           var adserverParsed = JSON.parse($(adserver).html());
           var pickedWeight = that.random(adserverParsed.adsSum);
           var selectedPicAd = that.findId(adserverParsed.adsWeight,pickedWeight);
           var pickedCreativeWeight = that.random(adserverParsed.ads[selectedPicAd].creativesSum);
           var selectedPicCreative = that.findId(adserverParsed.ads[selectedPicAd].creativesWeight,pickedCreativeWeight);
           $(adserver).replaceWith(adserverParsed.ads[selectedPicAd].creatives[selectedPicCreative].link);
        });
    }

    Adserver.prototype.findId = function(weights, pickedWeight) {
        var l = weights.length;
        for(var i = 0; i< l; i++) {
            pickedWeight = pickedWeight - weights[i].p;
            if(pickedWeight <= 0) {
                return weights[i].id;
                break;
            }
        }
    }

    $(document).ready(function() {
        var ads = new Adserver();
        ads.init();
        ads.picAd();
    });
})(jQuery);
