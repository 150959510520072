(function($) {
    'use strict'

    $(document).ready(function() {
        $('.submenu-control').click(function(e) {
            const self = $(this)
            const ul = self.siblings('ul')

            if (ul.is(':visible')) {
                ul.slideUp(400, function () {
                    self.parent().parent().removeClass('expanded')
                })
            } else {
                ul.slideDown(400, function() {
                    self.parent().parent().addClass('expanded')
                })
            }
        })
    })
})(jQuery);