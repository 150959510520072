function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

var _dataLayerFiltered = dataLayer.filter(function (e) {
  return e.ET
})[0];

if(typeof configCatClient != 'undefined'){
  configCatClient.getValueAsync("hashcatalogo", "Default", userObject).then(function(value) {
    if(value === "ligado"){
      setCookie("hash_configuration", "300f7c13-a447-4c05-b98f-0941f32d4b9b", 1);
      if(_dataLayerFiltered != undefined && (_dataLayerFiltered.ET == "catalogpage" || _dataLayerFiltered.ET == "landingpage")){
        dataLayer.push({ 
          'event': 'ab_test_event_ga4', 
          'ab_test_name': 'machine_learning',
          'ab_test_variant': 'ligado'
        });
      }
    }else if(value === "desligado"){
      setCookie("hash_configuration", "", -1);
      if(_dataLayerFiltered != undefined && (_dataLayerFiltered.ET == "catalogpage" || _dataLayerFiltered.ET == "landingpage")){
        dataLayer.push({ 
          'event': 'ab_test_event_ga4', 
          'ab_test_name': 'machine_learning',
          'ab_test_variant': 'desligado'
        });
      }
    }else{
      setCookie("hash_configuration", "", -1);
    }
  });
}
