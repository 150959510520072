(function($) {

    'use strict';

    var CartIndexAction = function() {};

    CartIndexAction.prototype = new window.dft.ActionController();

    CartIndexAction.prototype.run = function() {
        $(document).on('dft.cart-quantity.update', $.proxy(this, 'updateTotal'));
        $(document).on('dft.cart-gift.checked', $.proxy(this, 'updateTotal'));
        $(document).on('dft.cart-size.update', $.proxy(this, 'updateTotal'));
        $(document).on('dft.cart-size.changed', $.proxy(this, 'updateSizeItem'));
        $(document).on('dft.cart-add.item-added', $.proxy(this, 'triggerEventsAddItem'));
        $(document).on('dft.cart-add.load', $.proxy(this, 'loadAdd'));
        $(document).on('dft.cart-remove.item-removed', $.proxy(this, 'updateTotal'));
        $(document).on('dft.add-to-cart-form.success', $.proxy(this, 'updateCartItems'));
        $(document).on('dft.cart-fix-title-position', $.proxy(this, 'fixCartTitlePosition'));

        this.fixCartTitlePosition();

        $(document).trigger('dft.events-monitoring');
    };

    CartIndexAction.prototype.updateTotal = function(e, total) {
        $(document).trigger('dft.cart-total', [total]);
        $(document).trigger('dft.cart-check-sale.update', [total]);
    };

    CartIndexAction.prototype.loadAdd = function(e, response) {
        $(document).trigger('dft.cart-add.load-size', [response]);
    };

    CartIndexAction.prototype.updateSizeItem = function(e, response, sku) {
        $(document).trigger('dft.cart-remove.item', [sku]);
        $(document).trigger('dft.cart-remove.item-duplicated', [response]);
    };

    CartIndexAction.prototype.triggerEventsAddItem = function(e, response) {
		$(document).trigger('dft.cart-total', [response]);
		$(document).trigger('dft.cart-color.hide-edit-link', [response]);
		$(document).trigger('dft.cart-color.item-added', [response]);
        $(document).trigger('dft.cart-check-sale.update', [response]);
	}

    CartIndexAction.prototype.updateCartItems = function(e, data) {
        $(document).trigger('dft.cart-add.update', [jQuery.parseJSON(data.responseText)]);
    };

    CartIndexAction.prototype.fixCartTitlePosition = function() {
        var defaultType = $('[data-cart-store-type="default"]');
        var sellerType = $('[data-cart-store-type="seller"]');

        if (defaultType.find('[data-cart-item]').length > 0) {
            defaultType.find('.cart-title-block').addClass('cart-title-block-fixed');
        } else if (sellerType.find('[data-cart-item]').length > 0) {
            sellerType.find('.cart-title-block').addClass('cart-title-block-fixed');
        }

        $('.cart-title-block').removeClass('hide');
    };

    $.fn.cartIndexAction = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.cart-index-action');
            var options = $.extend({}, CartIndexAction.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.cart-index-action', (data = new CartIndexAction(this, options)));
            }

            if (typeof option == 'string') data[option](args);
        });
    };

    routes['cart/index/index'] = function() {
        (new CartIndexAction()).run();
    };

    $(document).trigger('cart/index/index');

})(jQuery);
