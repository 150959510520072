(function ($) {
    'use strict';
    var isAddToCartEvent = false;

    var WishlistRecomendationEventsGa4 = function () {

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @param event
         */
        this.dataLayerObject = function (element, position, listName, event) {
            var object = {
                'event': event,
                'ecommerce': {
                    'items': []
                }
            };

            return object;
        }

        /**
         *
         * @param price
         */
        this.sanitizePrice = function (price) {
            return Number(price
                .replace('R$ ', '')
                .replace('.', '')
                .replace(',','.'));
        }

        /**
          *
          * @param discountedPrice
          * @param originalPrice
          */
        this.calculateDiscount = function (originalPrice, discountedPrice) {
            return discountedPrice === 0 ? 0 : originalPrice - discountedPrice;
        }

        /**
          *
          * @param discountedPrice
          * @param originalPrice
          */
        this.calculatePrice = function (originalPrice, discountedPrice) {
            return discountedPrice != 0 ? discountedPrice : originalPrice;
        }


        /**
         *
         * @param element
         * @param position
         * @param listName
         * @returns {[{quantity: string, list: string, dimension54: (string|string), dimension53: (string|string), dimension55: string, price: number, dimension52: (string|string), dimension51: (string|string), name: string, variant: *, id: *, position, category: (string|string), brand: string}]}
         */
        this.formatProducts = function (element, position, listName) {
            const brand = element.find('h3').text();
            const name = element.find('p').text();
            const price = element.find(".product-box-price-to").text();
            const originalPrice = element.find(".product-box-price-from").text();
            const discount = this.sanitizePrice(originalPrice) - this.sanitizePrice(price);
            const sku = element.data("sku");
            const categories = element.data("categories").split(",");
            const variant = element.data("variant");
            const seller = element.data("seller");

           var product = {
                'item_id': sku.toLowerCase(),
                'item_name': name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_brand': brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category2': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category3': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'price': this.sanitizePrice(price),
                'discount': parseInt(discount * 100) / 100,
                'index': position,
                'item_list_name': 'wishlist - recomendados para voce',
                'affiliation': 'dafiti br - ' + seller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_variant': variant.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
            };

            if (isAddToCartEvent) {
                product['quantity'] = 1;
            }

            return product;
        }

        /**
        *
        * @param element
        * @returns {{position: *, closest: (*|jQuery)}}
        */
        this.getClosestAndPosition = function (element) {
            var closest = $(element).closest('.product-guide-item');
            var position = closest.prevAll().length;

            return { 'closest': closest, 'position': position };
        }

        /**
        *
        * @param element
        * @returns bool
        */
        this.isElementInViewportGa4 = function (element) {
            var container = $(element).closest('.product-guide-overflow').length > 0 ? $(element).closest('.product-guide-overflow') : $(element).closest('.product-guide-overflow-new-pdp-mobile');

            if (!$(element).is(':visible')) {
                return false;
            }

            return (
                $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left - 10) &&
                $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right + 10) &&
                $(element)[0].getBoundingClientRect().top >= 0 &&
                $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
            );
        }

        return this;
    }

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    }

    var _dataLayerFiltered = dataLayer.find(function (e) {
        return e.ET;
    });

    if (_dataLayerFiltered != undefined && document.location.hostname.indexOf('dafiti') > -1 && _dataLayerFiltered.ET === 'myaccount') {

        $(document).on('scroll', window, function (e) {
            var wishlistRecomendationEvents = new WishlistRecomendationEventsGa4();
            var object = {};

            $('.carousel-item').each(function (i) {
                if (wishlistRecomendationEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                    $(this).addClass('impressionGA4');
                    var getClosestAndPosition = wishlistRecomendationEvents.getClosestAndPosition(this);

                    if (Object.keys(object).length === 0) {
                        object = wishlistRecomendationEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                    }

                    var formattedProduct = wishlistRecomendationEvents.formatProducts(getClosestAndPosition.closest,
                        (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                    object.ecommerce.items.push(formattedProduct);
                }
            });

            if (Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }
        });

        $(document).on('click', '.recommendations-prev, .recommendations-next', function (e) {
            setTimeout(function () {
                var wishlistRecomendationEvents = new WishlistRecomendationEventsGa4();
                $('.carousel-item').each(function (i) {
                    if (wishlistRecomendationEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                        $(this).addClass('impressionGA4');
                        var getClosestAndPosition = wishlistRecomendationEvents.getClosestAndPosition(this);

                        var object = wishlistRecomendationEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                        var formattedProduct = wishlistRecomendationEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                        object.ecommerce.items.push(formattedProduct);

                        dataLayer.push(object);
                    }
                });
            }, 500);
        });

        //Taggeamento GA4: Select Item
        $(document).on('click', 'div.carousel-item div.product-box div.product-box-image > a > img', function () {
            var wishlistRecomendationEvents = new WishlistRecomendationEventsGa4();
            var getClosestAndPosition = wishlistRecomendationEvents.getClosestAndPosition(this);

            var object = wishlistRecomendationEvents.dataLayerObject(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'select_item_ga4'

            );
            var formattedProduct = wishlistRecomendationEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

            object.ecommerce.items.push(formattedProduct);

            dataLayer.push(object);

        });

        //Taggeamento GA4: Add to Cart
        $(document).on('click', '.btn-add-to-cart-from-recommended', function () {
            var wishlistRecomendationEvents = new WishlistRecomendationEventsGa4();
            var getClosestAndPosition = wishlistRecomendationEvents.getClosestAndPosition(this);
            isAddToCartEvent = true;

            const hasSizeSelected = $('div.add-to-cart-form-sizes ul.selectbox-options li.selectbox-option.is-selected').length > 0;
            if (hasSizeSelected) {
                var object = wishlistRecomendationEvents.dataLayerObject(
                    getClosestAndPosition.closest,
                    (getClosestAndPosition.position + 1),
                    getClosestAndPosition.closest.data('type'),
                    'add_to_cart_ga4'

                );
                var formattedProduct = wishlistRecomendationEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                object.ecommerce.items.push(formattedProduct);

                dataLayer.push(object);
            }

        });

        //Taggeamento GA4: Add to Cart Msite
        $(document).on('click', '.dft.button.highlight.primary.fluid', function () {
            var wishlistRecomendationEvents = new WishlistRecomendationEventsGa4();
            var getClosestAndPosition = wishlistRecomendationEvents.getClosestAndPosition(this);
            isAddToCartEvent = true;

            setTimeout(function () {
                const sizeNotSelected = $('.add-to-cart-form').hasClass('is-invalid');

                if (!sizeNotSelected) {
                    var object = wishlistRecomendationEvents.dataLayerObject(
                        getClosestAndPosition.closest,
                        (getClosestAndPosition.position + 1),
                        getClosestAndPosition.closest.data('type'),
                        'add_to_cart_ga4'
                    );
                    var formattedProduct = wishlistRecomendationEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                    object.ecommerce.items.push(formattedProduct);

                    dataLayer.push(object);
                }
            }, 100)
        });
    }

})(jQuery);
