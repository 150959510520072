(function ($) {
  'use strict';
  if (document.location.hostname.indexOf('dafiti') > -1){

    function prepareDataLayerToPageViewEvent(dataLayer) {
      dataLayer.push({
        'event': 'page_view_ga3',
        'page_path': document.location.pathname,
        'page_location': document.location.href,
        'page_title':'ecommerce - product detail'
      });
    }

    function prepareDataLayerToAddToCartEvent(dataLayer) {
      var _dataLayerFiltered = dataLayer.filter(function(e) { return e.ET })[0];
      var _category = _dataLayerFiltered.Pcat.split(',');
      _category.pop();

      dataLayer.push({
        'event': 'add_to_cart_ga3',
        'category': 'enhanced ecommerce',
        'action': 'add to cart',
        'label': 'product detail - no list',
        'ecommerce': {
          'currencyCode': 'BRL',
          'add':{
            'products': [{
              'id': _dataLayerFiltered.sku.toLowerCase(),
              'name': _dataLayerFiltered.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'price': Number(_dataLayerFiltered.price),
              'brand': _dataLayerFiltered.brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'category': _category[0] != undefined ? _category[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension51': _category[1] != undefined ? _category[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension52': _category[2] != undefined ? _category[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension53': _category[3] != undefined ? _category[3].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension54': _category[4] != undefined ? _category[4].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension55': 'dafiti br - '+_dataLayerFiltered.marketplaceSeller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'position': 1,
              'list': 'product detail - no list',
              'variant': _dataLayerFiltered.color.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'quantity': '1'
            }]
          }
        }
      });
    }

    function prepareDataLayerToAddToWishlistEvent(dataLayer) {
      var _dataLayerFiltered = dataLayer.filter(function(e) { return e.ET })[0];
      var _category = _dataLayerFiltered.Pcat.split(',');
      _category.pop();

      dataLayer.push({
        'event': 'add_to_wish_list_ga3',
        'category': 'enhanced ecommerce',
        'action': 'add to wish list',
        'label': 'ecommerce - product detail',
        'ecommerce': {
          'currencyCode': 'BRL',
          'add':{
            'products': [{
              'id': _dataLayerFiltered.sku.toLowerCase(),
              'name': _dataLayerFiltered.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'price':  Number(_dataLayerFiltered.price),
              'brand': _dataLayerFiltered.brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'category': _category[0] != undefined ? _category[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension51': _category[1] != undefined ? _category[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension52': _category[2] != undefined ? _category[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension53': _category[3] != undefined ? _category[3].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension54': _category[4] != undefined ? _category[4].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
              'dimension55': 'dafiti br - '+_dataLayerFiltered.marketplaceSeller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'position': 1,
              'list': 'ecommerce - product detail',
              'variant': _dataLayerFiltered.color.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
              'quantity': '1'
            }]
          }
        }
      });
    }

    var PdpEventsGA3 = function () {
      this.prepareDataLayerToPageViewEvent = function (dataLayer) {
        return prepareDataLayerToPageViewEvent(dataLayer);
      };
      this.prepareDataLayerToAddToCartEvent = function (dataLayer) {
        return prepareDataLayerToAddToCartEvent(dataLayer);
      };
      this.prepareDataLayerToAddToWishlistEvent = function (dataLayer) {
        return prepareDataLayerToAddToWishlistEvent(dataLayer);
      };
    }

    $(document).on('click', '.buy-pdp', function () {
      var pdpEventsGA3 = new PdpEventsGA3();
      pdpEventsGA3.prepareDataLayerToAddToCartEvent(dataLayer);
    });

    $(document).on('click', '[data-wishlist-item]', function () {
      if ($(this).attr("data-wishlist-url") !== undefined && $(this).attr('data-wishlist-url').includes('remove')) {
        return false;
      }

      var pdpEventsGA3 = new PdpEventsGA3();
      pdpEventsGA3.prepareDataLayerToAddToWishlistEvent(dataLayer);
    });

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
    if (_dataLayerFiltered != undefined && _dataLayerFiltered.ET == 'productpage') {
      var pdpEventsGA3 = new PdpEventsGA3();
      pdpEventsGA3.prepareDataLayerToPageViewEvent(dataLayer);
    }

  }

})(jQuery);
