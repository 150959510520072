(function ($) {

    'use strict';

    var AddToCartForm = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.$template = this.$element.find('.selectbox-options .selectbox-option:eq(0)').clone();
        this.$templateBoxesSelector = this.$element.find(".radio-button-block .radio-button-size-block:eq(0)").clone();
        this.$chosenSize = this.$element.find('[data-size-chosen]');
        this.$loadingDialog = this.$element.find('.loading-dialog');
        this.$popoverGoToCart = $('[data-popover-go-to-cart]');
        this.headerOverlay = $('[data-header-overlay]');
    };

    AddToCartForm.DEFAULTS = {
        ajax: false,
        dataType: 'json',
        loadingText: 'Carregando...',
        successText: 'Sucesso',
        showSizeReminder: false,
        timeToClose: 5000
    };

    AddToCartForm.prototype.init = function (e) {
        e.preventDefault();

        this.validate();

        if (this.isValid() && this.options.ajax) {
            this.add();
            this.openGoToCartModal();
        }
    };

    AddToCartForm.prototype.validate = function () {
        var isInvalid = !this.isValid();

        this.$element.toggleClass('is-invalid', isInvalid);
        this.$element.find('.add-to-cart-form-sizes').toggleClass('is-invalid', isInvalid);
        this.$element.find('#selection-box-radio-button').toggleClass('is-invalid', isInvalid);
    };

    AddToCartForm.prototype.isValid = function() {
        return this.$element.parent().find('input[name="sku"]').val().length > 0 && this.$element.parent().find('[name="sku"]').val().length > 0;
    };

    AddToCartForm.prototype.openGoToCartModal = function () {
        if (UA.isMobile() === false && $('[data-go-to-cart-modal]').length > 0) {
            if (this.$popoverGoToCart.length > 0) {
                this.$popoverGoToCart.addClass('is-active');
                this.headerOverlay.addClass('is-active');
                $('[data="quickview-modal"] .modal-close').trigger('click');
            } else {
                $('body').modal({
                    'modalContent': '[data-go-to-cart-modal]',
                    'className': 'go-to-cart-container',
                    'highlightClassName': 'go-to-cart-hilight'
                });
                $('.is-open .modal-go-to-cart .loading-dialog').removeClass('is-loaded');
            }
        }
    };

    AddToCartForm.prototype.closePopover = function () {
        this.$popoverGoToCart.removeClass('is-active');
        this.headerOverlay.removeClass('is-active');
    };

    AddToCartForm.prototype.change = function () {
        var value = this.$element.find('[name=sku]').val();
        var selectedElement = this.$element.find(".selectbox-options").find(".is-active");
        if (value == 'size-reminder' || selectedElement.find('.selectbox-option-stock').hasClass('is-sold-out')) {
            this.$element.find('[name=sku]').val('');
            this.triggerSizereminder();
        }
    };

    AddToCartForm.prototype.orderByname = function (product_a, product_b) {
        if (!isNaN(product_a.name)) {
            return product_a.name - product_b.name;
        }
    };

    AddToCartForm.prototype.sizes = function (sizes) {
        var $options = this.$element.find('.selectbox-options');
        var productsCount = typeof sizes.length != 'undefined' ? sizes.length : 0;
        var position = 0;
        var $boxesBlock = this.$element.find(".radio-button-block");

        $options.find('.selectbox-option:not([data-value=size-reminder])').remove();

        sizes.sort(this.orderByname);

        for (var item in sizes) {
            $options.append(this.template(sizes[item]));
            $boxesBlock.append(this.templateBoxesSelector(sizes[item]));
            if (sizes[item].stock == 0) {
                this.showSizeReminder();
                productsCount--;
            } else {
                position = item;
            }
        }

        $options.append(this.$element.find('.selectbox-option[data-value=size-reminder]'));

        if (this.$chosenSize.length == 0) {
            this.$element.find('[name=sku]').val('');
        }

        if (productsCount === 0) {
            this.hideDeliveryClock();
        }

        if (productsCount == 1) {
            this.selectLastItem($options, position);
            this.selectLastItemBox(sizes[position].sku);
        }
    };

    AddToCartForm.prototype.selectLastItem = function ($options, position) {
        var $selectedProduct = $options.children('.selectbox-option').eq(position);
        var productContent = $selectedProduct.children().clone();

        $options.siblings('.selectbox-current').html(productContent);
        $options.siblings('[name=sku]').val($selectedProduct.data('value'));
    };

    AddToCartForm.prototype.selectLastItemBox = function (sku) {
        this.$element.find('li.radio-button-size-block[data-value="' + sku + '"] input[type=radio]').change();
    }

    AddToCartForm.prototype.showSizeReminder = function () {
        this.options.showSizeReminder = true;
        this.$element.find('.selectbox-option[data-value=size-reminder]').addClass('is-show');
        this.$element.find('#form-size-not-found').css('display', 'flex');
    };

    AddToCartForm.prototype.triggerSizereminder = function () {
        this.$element.find('#size-reminder-2-modal').trigger('click');
    };

    AddToCartForm.prototype.triggerNewSizeReminder = function () {
        $('#size-reminder-2-modal').trigger('click');
    };

    AddToCartForm.prototype.hideDeliveryClock = function () {
        $('#product-delivery-clock').addClass('hide');
    };

    AddToCartForm.prototype.template = function (item) {
        var result = this.$template.clone();
        var hasSoldOut = true;

        if ($('#stock-available[data-sold-out]').length) {
            hasSoldOut = false;
        }

        result.attr('data-value', parseInt(item.stock, 10) ? item.sku : '');
        result.find('[data-field=name]').text(item.name);

        if (item.display_stock) {
            result.find('[data-field=stock_message]').text(item.stock_message);
        }

        if (hasSoldOut) {
            result.find('.selectbox-option-name').toggleClass('is-sold-out', item.stock == 0);
            result.find('.selectbox-option-stock').toggleClass('is-sold-out', item.stock == 0);
        } else {
            if (item.stock == 0) {
                result = '';
            }
        }

        if ((this.$chosenSize.data('sizeChosen') == item.name) && (item.stock != 0)) {
            result.addClass('is-selected').addClass('is-active');
            this.$element.find('.add-to-cart-form-sizes').find('[name="sku"]').attr('value', item.sku);
            this.$element.find('.selectbox-current').html(result.html());
        }

        return result;
    };

    AddToCartForm.prototype.templateBoxesSelector = function (item) {
        var result = this.$templateBoxesSelector.clone();
        var hasSoldOut = true;
        var _element = this.$element;

        if ($('#stock-available[data-sold-out]').length) {
            hasSoldOut = false;
        }

        result.attr('hidden', false);

        result.find('input[type=radio]').change(function (e) {
            _element.find("input[name='sku']").attr('value', item.sku);
            _element.find(".radio-button-block li").each(function (key, el) {
                el.classList.remove('selected');
            });

            _element.find('li[data-value="' + item.sku + '"]').addClass('selected');

            $('#seller-text[data-field=delivery_type_html]').html(item.delivery_type_html);

            if (item.display_stock) {
                _element.find('#form-message[data-field=stock_message] span').text(item.stock_message)
                _element.find('#form-message[data-field=stock_message]').css('display', 'flex');
            } else {
                _element.find('#form-message[data-field=stock_message] span').text('')
                _element.find('#form-message[data-field=stock_message]').css('display', 'none');
            }

            _element.find('#form-forgot-message').css('display', 'none');

            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'label_ga3',
                    'category': 'productPage',
                    'action': 'sizeSelectionClick',
                    'label': item.sku + '/' + item.name
                });
            }

        });

        result.attr('data-value', parseInt(item.stock, 10) ? item.sku : '');
        result.find('[data-field=radio]').text(item.name);
        result.find("[data-field=name]").text(item.name);

        if (hasSoldOut) {
            result.find("[name=size_radio_button]").attr("disabled", 0 == item.stock);
            result.find(".selectbox-option-stock").toggleClass("is-sold-out", 0 == item.stock);
        } else if (0 == item.stock) {
            result = "";
        }

        if ((this.$chosenSize.data('sizeChosen') == item.name) && (item.stock != 0)) {
            result.find("[name=size_radio_button]").prop("checked", true)
            this.$element.find('[name="sku"]').attr("value", item.sku)
        }

        return result;
    }

    AddToCartForm.prototype.add = function () {
        if (this.isCartPage()) {
            this.submitOnEmptyCart();
            this.cartAdd();
            return;
        }
        this.ajax();
    };

    AddToCartForm.prototype.isCartPage = function () {
        return '/cart/' === window.location.pathname;
    };

    AddToCartForm.prototype.submitOnEmptyCart = function () {
        if ($('[data-cart-item]').length === 0) {
            this.$element.addClass('submitted');
            this.$element.submit();
        }
    };

    AddToCartForm.prototype.cartAdd = function () {
        var params = {
            'sku_simple': this.$element.find('[name="sku"]').val(),
            'b2014': 1
        };

        this.triggerLoadingEvent();

        $(document).trigger('dft.cart-add.add', [params]);
        $(document).one('dft.cart-add.item-added', $.proxy(this, 'triggerSuccessEvent'));
    };

    AddToCartForm.prototype.ajax = function () {
        var url = this.$element.attr('action');

        if (this.$element.data('sendSku')) {
            url += '?b2014=1&sku_simple=' + this.$element.find('[name=sku]').val();
        }

        this.$element.find('.add-to-cart-form-button').blur();
        this.triggerLoadingEvent();

        $.ajax({
            type: this.$element.attr('method'),
            dataType: this.options.dataType,
            url: url,
            cache: false,
            data: this.$element.serialize()
        })
            .success($.proxy(this, 'response'))
            .error($.proxy(this, 'response'));
    };

    AddToCartForm.prototype.response = function (response) {
        if (response.ok === false) {
            return this.redirectToCart();
        } else if (UA.isMobile() === true) {
            return this.redirectToCart(response);
        }
        $('.is-open .modal-go-to-cart .loading-dialog').addClass('is-loaded');
        this.triggerSuccessEvent();
        $(document).trigger('dft.add-to-cart-form.success', response);

        this.$popoverGoToCart.find('.loading-dialog').addClass('hide');
        this.$popoverGoToCart.find('.image').attr('src', response.item[0].image_url);
        this.$popoverGoToCart.find('.name').text(response.item[0].brand_name);
        this.$popoverGoToCart.find('.size').removeClass('hide').find('span').text(response.item[0].size);
        this.$popoverGoToCart.find('.price').removeClass('hide').find('span').text(response.item[0].price);
        this.$popoverGoToCart.find('[data-popover-go-to-cart-content-item]').removeClass('hide');
        window.setTimeout($.proxy(this, 'closePopover'), this.options.timeToClose);
    };

    AddToCartForm.prototype.triggerLoadingEvent = function () {
        this.$element.trigger('loading');
        this.loadingDialog(false, false, this.options.loadingText);
    };

    AddToCartForm.prototype.triggerSuccessEvent = function () {
        if (this.$element.attr('id') == "add-to-cart") {
            this.$element.trigger('success');
        }

        this.loadingDialog(false, true, this.options.successText);
    };

    AddToCartForm.prototype.loadingDialog = function (isLoaded, isSuccess, message) {
        var loadingDialog = this.$element.find('.loading-dialog');
        var parentBox = this.$element.parent();

        loadingDialog.toggleClass('is-loaded', isLoaded);
        loadingDialog.toggleClass('is-success', isSuccess);
        parentBox.toggleClass('is-added', isSuccess);

        this.$element.find('.loading-message').text(message);

        var removeLoadingIndicator = function () {
            loadingDialog.addClass('is-loaded');
            loadingDialog.removeClass('is-success');
            loadingDialog.removeClass('loading');
            parentBox.removeClass('is-added');
        };

        setTimeout(removeLoadingIndicator, 10000);
    };

    AddToCartForm.prototype.handleLoading = function () {
        this.$element.find('[data-add-to-cart-form-button]').attr('disabled', 'disabled');
    };

    AddToCartForm.prototype.handleSuccess = function () {
        this.$element.find('[data-add-to-cart-form-button]').attr('disabled', false);
    };

    AddToCartForm.prototype.redirectToCart = function (response) {
        if (response) {
            $(document).trigger('dft.add-to-cart-and-redirect', response);
        }
        window.location.href = '/cart/';
    };

    $.fn.addToCartForm = function (option, sizes) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.addtocart');
            var options = $.extend({}, AddToCartForm.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.addtocart', (data = new AddToCartForm(this, options)));
            if (typeof option == 'string') data[option](sizes);
        });
    };

    $(document).on('change', '.add-to-cart-form-sizes', function () {
        $(this).closest('.add-to-cart-form').addToCartForm('validate');
        $(this).closest('.add-to-cart-form').addToCartForm('change');
    });

    $(document).on('submit', '.add-to-cart-form:not(.submitted)', function (e) {
        $(this).addToCartForm('init', e);
    });

    $(document).on('loading', '.add-to-cart-form', function () {
        $(this).addToCartForm('handleLoading');
    });

    $(document).on('success', '.add-to-cart-form', function () {
        $(this).addToCartForm('handleSuccess');
    });

    $(document).on('click', '[data-header-overlay]', function () {
        if ($('[data-popover-go-to-cart]').hasClass('is-active')) {
            $(this).removeClass('is-active');
            $('[data-popover-go-to-cart]').removeClass('is-active');
        }
    });

    $(document).on('click', '#form-size-not-found', function(){
        $(this).addToCartForm('triggerNewSizeReminder');
    });

    $(document).on('click', '.radio-button-size-block[data-value=""]', function(e){
        e.preventDefault();
        $(this).addToCartForm('triggerNewSizeReminder');
    });

})(jQuery);
