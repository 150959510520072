(function($) {

    'use strict';
    var http = "http:",
        https = "https:",
        rscript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        proto = location.protocol,
        otherProtoUri = new RegExp("\\b" + (proto === http ? https : http) + "(//[a-z\\d.-]+)", "gi");

    $.fn.protocolModifyLoad = function(url, yesIKnowThisIsFragile, selector) {
        var self = this;

        if ( !this.length || !yesIKnowThisIsFragile) {
            return this;
        }

        $.ajax({
            url: url,
            type: "GET",
            dataType: "html",
            complete: function( res, status ) {
                // If successful, inject the HTML into all the matched elements
                if ( status === "success" || status === "notmodified" ) {
                    // Force occurences of the other protocol into the current one
                    var response = res.responseText.replace(otherProtoUri, proto + "$1");

                    // See if a selector was specified
                    self.html( selector ?
                        // Create a dummy div to hold the results
                        jQuery("<div>")
                            // inject the contents of the document in, removing the scripts
                            // to avoid any 'Permission Denied' errors in IE
                            .append(response.replace(rscript, ""))

                            // Locate the specified elements
                            .find(selector) :

                        // If not, just inject the full result
                        response);
                }
            }
        });

        return this;
    };
  
    var Modal = function(element, options) {
        this.$element = $(element);

        this.options = options;
        this.height = 0;
        this.width = 0;

        if (this.$element.prop("tagName") == "BODY") {
            this.$element = $(template);
            if (this.options.content) {
                this.$element.find('.modal-container').html(this.options.content);
            }
            $('body').append(this.$element);
        }

        if (this.options.modalContent) {
            this.addContentToModal($(this.options.modalContent).html());
        }

        if (this.options.remote) {
            if(this.$element.prop("tagName") == 'BODY') {
                this.$element = $(template);
                this.$element.appendTo('body');
            }
            this.$element
                .find('.modal-container')
                .load(this.options.remote, $.proxy(this, 'loaded'));
        }

        this.$element.addClass(this.options.className);

        this.$element.children().addClass(this.options.innerClassName);
        this.$element.toggleClass('is-fullscreen', this.options.fullscreen);

        this.$element.attr({
            tabIndex: -1,
            role: 'dialog'
        });

        this.$element
            .on('click', '[data-dismiss=modal]', $.proxy(this, 'close'))
            .on('click', $.proxy(function(e) {
                if (e.target !== e.currentTarget) return;
                this.close();
           }, this))
            .on('modal.resize', $.proxy(this, 'resize'));
    };

    Modal.DEFAULTS = {
        className: null,
        innerClassName: null,
        highlightClassName: null,
        show: true,
        fullscreen: false,
        shouldResize: false,
        redirectCloseModal: null
    };

    Modal.prototype.addContentToModal = function(content) {
        this.$element.find('.modal-container').append(content);
    };

    Modal.prototype.toggle = function() {
        this.isOpen() ? this.close() : this.open();
    };

    Modal.prototype.isOpen = function() {
        return this.$element.is('.is-open');
    };

    Modal.prototype.open = function() {
        if (this.$element.hasClass('modal-2-fade')){
            this.$element.addClass('modal-2-fade-active');
        }

        $('body').addClass('is-modal-open');
        this.$element.addClass('is-open').highlight('open');

        if (this.options.highlightClassName != '') {
            this.$element.prev().addClass(this.options.highlightClassName);
        }

        this.$element.bind('keyup', $.proxy(this, 'keyup'));

        if (this.options.doNotClose != undefined && this.options.doNotClose != '') {
          this.$element.unbind('click');
        }

        this.$element.trigger('focus');
        this.enforceFocus();

        if (this.options.shouldResize) {
            this.resize();
        }
    };

    Modal.prototype.close = function() {
        $(document).off('focusin.dft.modal');
        this.$element.unbind('keyup');

        this.$element.trigger('close');
        $('.carousel-item.hidden').removeClass('hidden');

        this.$element.removeClass('is-open').highlight('close');

        if (this.$element.hasClass('modal-2-fade')){
            var el = this.$element;
            setTimeout(function() {
                $('body').removeClass('is-modal-open');
                el.removeClass('modal-2-fade-active');
                el.find('.slideshow-item').removeClass('is-active is-inactive');
            }, 500);
        } else {
            $('body').removeClass('is-modal-open');
            $(document).trigger('modal.' + this.options.innerClassName + '.closed');
        }

        this.redirectOnCloseModal();
    };

    Modal.prototype.keyup = function(e) {
        var code = e.keyCode || e.which;
        if (code == 27) {
            this.close();
        }

        this.redirectOnCloseModal();
    };

    Modal.prototype.redirectOnCloseModal = function () {
        if (this.options.redirectCloseModal) {
            window.location.href = window.location.origin + this.options.redirectCloseModal;
        }
    };

    Modal.prototype.enforceFocus = function() {
        $(document)
            .off('focusin.dft.modal')
            .on('focusin.dft.modal', $.proxy(function(e) {
                if (this.$element[0] !== e.target && !this.$element.has(e.target).length) {
                    this.$element.trigger('focus')
                }
            }, this));
    };

    Modal.prototype.loaded = function(response) {
        try {
            var data = JSON.parse(response);

            if (data.urlTarget) {
                return window.location.href = response.urlTarget;
            }

        } catch (e) {}

        this.$element.find('[data-lazyload]').lazyload();
        this.$element.trigger('modal.' + this.options.innerClassName);
        this.centralize();
    };

    Modal.prototype.centralize = function() {
        var modalDialog = this.$element.find('.modal-dialog');
        if (this.height == 0 && this.width == 0) {
            this.height = modalDialog.height();
            this.width = modalDialog.width();
        }
        modalDialog.css('height', this.height);
        modalDialog.css('width', this.width);
    };

    /*ToDo : Change this to a listener which update the modal size when the content changes its size.
        Should insert a modal content that changes sizes and call this event.
    */
    Modal.prototype.resize = function() {
        var modalContainer = this.$element.find('.modal-container');
        var content = modalContainer.children('div');

        this.height = content.outerHeight();
        this.width = content.outerWidth();

        this.centralize();
    };

    Modal.prototype.destroy = function() {
        this.$element.removeData('dft.modal');
        $(this.options.trigger).removeData('dft.target');
        this.$element.remove();
    };

    $.fn.modal = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.modal');
            var options = $.extend({}, Modal.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.modal', (data = new Modal(this, options)));
            if (typeof option == 'string') data[option]();
            else if (options.show) data.open();
        });
    }

    var template = '<div class="modal-2" data-dismiss="modal"><div class="modal-dialog"><div class="modal-container"/></div></div>';

    $(document).on('click', '[data-toggle=modal]', function(e) {
        var $this = $(this);
        var href = $this.attr('href');
        var $target = $($this.data('dft.target') || $this.attr('data-target'));
        var option = $target.data('dft.modal') ? 'toggle' : $.extend({
            remote: !/#/.test(href) && href,
            shouldResize: $target.data('resize'),
            trigger: $this
        }, $this.data());

        if (!$target.length) {
            $target = $(template).appendTo('body');
        }

        $this.data('dft.target', $target.get(0));

        if ($('body').hasClass('mobile') && ($target.data('hide-mobile') || $this.data('hide-mobile'))) {
            return;
        }

        e.preventDefault();

        $target.modal(option).on('close', function() {
            $this.is(':visible') && $this.trigger('focus');
        });

        if (typeof $this.data('modal-no-cache') !== 'undefined') {
            $target.data('dft.modal', false);
            $this.data('dft.target', false);
        }
    });

    $('.mobile [data-auto-open="modal"]:not([data-hide-mobile])').modal();
    $('.desktop [data-auto-open="modal"], .tablet [data-auto-open="modal"]').modal();

})(jQuery);
