(function($) {

    'use strict';

    var ProductDeliveryClock = function(element, options) {
        this.$element = $(element);
        this.options = options || {};
    }

    ProductDeliveryClock.DEFAULTS = {
    };

    ProductDeliveryClock.prototype.start = function() {

        var that = this;
        //show the clock for the first time
        this.calcTime();

        var dt = new Date();

        //the second call is set to execute when the clock reaches 00 sconds
        setTimeout(function() {
            that.calcTime();
            //then each subsequent call is done after 60 seconds
            that.interval = setInterval(function() {
                that.calcTime();
            }, 60000);
        }, (60 - dt.getSeconds()) * 1000);

        return this;
    }

    ProductDeliveryClock.prototype.stop = function() {
        if(that.interval){
            clearInterval(that.interval);
        }
        that.interval = undefined;

        return this;
    }

    ProductDeliveryClock.prototype.calcTime = function(date) {

        var dt = date || new Date();
        var hr = dt.getHours();
        var min = dt.getMinutes();

        //get the number of minutes in for the period of the day (noon or mid-night)
        var finalTime = hr < 12 ? 720 : 1440;

        //calculate the number of minutes to the final minute of the period
        var diffTime = finalTime - ((hr * 60) + min);

        this.updateClock(this.formatDiffHour(diffTime), this.formatDiffMinute(diffTime), hr < 12);
    }

    ProductDeliveryClock.prototype.formatDiffHour = function(diff) {
        var hour = parseInt(diff / 60);

        return hour < 10 ? "0" + hour : "" + hour;
    }

    ProductDeliveryClock.prototype.formatDiffMinute = function(diff) {
        var minute = diff % 60;

        return minute < 10 ? "0" + minute : "" + minute;
    }

    //prints the time diff to the period
    ProductDeliveryClock.prototype.updateClock = function(hour, minute, isToday) {
        var text = minute + "min";

        if (hour > 0) {
            text = hour + "h" + text;
        }

        this.$element.find(".product-delivery-clock-time").html(text);

        if (isToday) {
            this.showTodayText();
        } else {
            this.showTomorrowText();
        }

    }

    ProductDeliveryClock.prototype.showTodayText = function() {
        if(this.$element.attr("data-today")){
            this.$element.show();
            this.$element.find(".product-delivery-clock-tomorrow").css("display", "none");
            this.$element.find(".product-delivery-clock-today").css("display", "inline-block");
        }else{
            this.$element.hide();
        }
    }

    ProductDeliveryClock.prototype.showTomorrowText = function() {
        if(this.$element.attr("data-tomorrow")){
            this.$element.show();
            this.$element.find(".product-delivery-clock-today").css("display", "none");
            this.$element.find(".product-delivery-clock-tomorrow").css("display", "inline-block");
        }else{
            this.$element.hide();
        }
    }

    $.fn.productDeliveryClock = function(options) {

        var $this   = $(this);
        var data    = $this.data('dft.product-delivery-clock');
        var options = $.extend({}, ProductDeliveryClock.DEFAULTS, $this.data(), typeof option == 'object' && option);

        if (!data) $this.data('dft.product-delivery-clock', (data = new ProductDeliveryClock(this, options).start()));
        if (typeof option == 'string') data[option](item);

    }

})(jQuery);