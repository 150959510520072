$(document).ready(function(){
    $(".delivery-detail").on('click', function(){

        $('.track-modal').hide();

        var link = $(this);
        var target = link.attr('target-track-status');
        var loading = $("#"+link.data("loading"));

        link.hide();
        loading.show();
        var itemid = link.data('itemid');
        var deliveryType = link.data('delivery-type');

        $.ajax({
            type:   'GET',
            url:    '/customer/order/getStatusTrackingByItem',
            data:   {'itemid': itemid, 'delivery-type' : deliveryType},
            success: function(data) {
                loading.hide();
                $("#container-"+target).html(data);
                link.show();

                $('#'+target).show();

                var width = 0;
                $('#'+target).find('.event-container').each(function(index, element){
                    width += $(element).width();
                });

                $('#'+target).width(width);

                $('#container-track-item-'+itemid).show();
            },
            error: function(data){
                loading.hide();
                link.show();
                messageModal(data.responseText, '750,110');
            }
        });

    });

    $('body').not('.container-track-block').on('click', function(){
        if ($('.container-track-block').is(':visible')) {
            $('.container-track-block').hide();
        }
    });

    $(".late-message-link").on('click', function(object){
        object.preventDefault();
        var taskId      = $(this).parent().find('input[name=task_id]').val();
        var slipNumber  = $(this).parent().find('input[name=slip_number]').val();
        var hostName    = $('input[name=host_name]').val();

        $('.late-message-link').html('Aguarde');

        $.ajax({
            url:'/customer/order/trackingtask/',
            data:{'slip_number':slipNumber, 'host_name':hostName, 'task_id':taskId},
            success: function(data){
                messageModal(data, '300,120');
                $('.late-message-link').css('display', 'none');
            },
            error: function(data){
                messageModal(data, '300,120');
            }
        });

    });

    $('.success-modal .okLate').on('click',function () {
        closeSuccess();
    });

    $('.radio-creditcard input').on('click',function () {
        $(".default-card").removeClass("default-card");
        $(this).parent().children().children('.creditcard-label').addClass("default-card");
    });



    $('.error-modal .okLate').on('click', function () {
        closeError();
    });

    $('.display-order-products').on('click',function() {
        products = $("#order-products-"+$(this).attr("rel"));
        products.slideToggle();
        link = $(this);
        if(link.hasClass("hide-order-products")) {
            link = $("#toggle-products-link-"+$(this).attr("rel"));
        }
        if(link.hasClass("displaying-order-products")) {
            link.removeClass("displaying-order-products");
            link.html(orderI18n.showProducts);
        } else {
            link.addClass("displaying-order-products");
            link.html(orderI18n.hideProducts);
        }
       return false;
    });

    function closeSuccess() {
        $('.success-modal').fadeOut();
        $('.account-modal-overlay').css('height', $(document).height()).fadeTo(1000, 0);
        $('.account-modal-overlay').hide();
    }

    function closeError() {
        $('.error-modal').fadeOut();
        $('.account-modal-overlay').css('height', $(document).height()).fadeTo(1000, 0);
        $('.account-modal-overlay').hide();
    }

    if(window.creditcardalert != undefined){
        creditCardForm(window.creditcardalert);
        window.creditcardalert = undefined;
    }


});

function accountForm(obj) {
    var form = obj;
    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        success: function(data) {

            if(data == 'success') {
                var current_value = $("#EditForm_email").val();
                $('#original_email').val(current_value);
                $('#form-newsletter').submit();
            } else {
                $('.error-container').fadeIn('slow');
            }

        }
    });
}

function createAccountForm(obj) {
    var form = obj;
    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        success: function(data) {
            if(data) {
                window.location.href="/customer/account/list/";
            }
        }
    });
}

function newsletterForm(obj) {
    var form = obj;
    $.ajax({
        type: "POST",
        url: '/customer/newsletter/manage/',
        data: form.serialize(),
        success: function(data) {

            if(data == 'success') {
                var passwordVal = $('#PasswordForm_password').val();
                if(passwordVal == ""){
                    messageModal(modalJustMessage('Dados atualizados com sucesso!', '/customer/account/list/'), '320,109');
                    return false;
                }
                $('#form-account-edit-password').submit();
            } else {
                $('.error-container').fadeIn('slow');
            }

        }
    });
}

function passwordForm(obj) {
    var form = obj;
    $.ajax({
        type: "POST",
        url: '/customer/account/changepass/',
        data: form.serialize(),
        success: function(data) {
            if(data == 'success') {
                messageModal(modalJustMessage('Dados atualizados com sucesso!', '/customer/account/list/'), '320,109');
            } else if ('error') {
                $('.error-container').fadeIn('slow');
            } else {
                messageModal(modalJustMessage('Houve um erro interno, tente mais tarde.', '/customer/account/list/'), '320,109');
            }
        }
    });
}

/**
 *
 * Function to customer change default card
 * @param creditcardMask string
 */

function creditCardChangeDefaul(creditcardMask) {

    var modalContent = "<div class='customer-modal-address edit-confirm delete-creditcard'>"+
        "<h2 class='title title-30 pd-lft boxTitle bl-dashed'>Alteração salva com sucesso</h2>"+
        "<p class='creditcard-confirmation-text'>Agora seu Cartão de Crédito Padrão é: <strong>" + creditcardMask + " <strong></p>"+
        "<a class='btn-34 gl-buttom i-btn2 bt-sizes btn-default' onclick='clModal()'  href='javascript:void(0)'>OK</a>"+
        "</div>";
    messageModal(modalContent, '300,125');
}

/**
 *
 * Function to customer add address
 * @param obj
 */

function addressForm(obj) {

    var form = obj;

    var title = 'Endereço cadastrado com sucesso';

    if(form.attr('action').indexOf('address_id') > 0) {
        title = 'Endereço editado com sucesso';
    }

    var modalContent = '<div class="customer-modal-address edit-confirm">'+
        '<h2 class="bl-dashed rlt">'+ title +'</h2>'+
        '<b>'+$('#AddressForm_first_name').val()+' '+$('#AddressForm_last_name').val() + '<br />'+'</b>'+
        $('#AddressForm_address1').val()+', '+$('#AddressForm_street_number').val() +' '+ $('#AddressForm_additional_info').val()+ '<br />'+
        $('#AddressForm_postcode').val()+', '+$('#AddressForm_neighborhood').val() + '<br />'+
        $('#AddressForm_fk_customer_address_region :selected').text()+ '<br /><br />'+
        '<a class="gl-buttom i-btn2 bt-sizes" href="/customer/address/">Fechar</a>'+
        '</div>';

    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        success: function(data) {
            if(data == 'success') {
                messageModal(modalContent, '300,164');
            } else {
                $('.error-container').fadeIn('slow');
            }

        }
    });
}

/**
 *
 * Function that open modal customer list
 * @param obj
 */

function confirmationModal (obj) {

    var content = $('#'+obj.id).closest('tr').find('.modal-content').html();
    messageModal(content, '320,199');

}


/**
 *
 * Event to close modal
 */

function clModal() {
    $('.cl-generalModal').click();
}

/**
 *
 * function that delete address
 * @param int id
 */

function deleteAddress (id){
    $.ajax({
        type: "GET",
        url: '/customer/address/delete',
        data: { address_id : id },
        success: function(data) {
            clModal();
            var modalContent = '<div class="customer-modal-address edit-confirm adress-delete">' +
                '<h2 class="bl-dashed rlt">'+data+'</h2><br/>' +
                '<a onclick="clModal()" class="gl-buttom i-btn2 bt-sizes" href="javascript::void(0)">Fechar</a>' +
                '</div>';

            messageModal(modalContent, '320,109');

            $('#custumer-address_'+id).closest('tr').fadeOut('slow');

        }
    });
}

/**
 *
 * @param content
 * @param size
 */
function messageModal(content, size) {
    $('body').modal('destroy');
    $('body').removeData('dft.modal');
    $('body').modal({'content': content});
}

/**
 *
 * @param title
 * @param link
 * @returns {string}
 */
function modalJustMessage(title, link) {
    return '<button type="button" class="modal-close icon-cross" data-dismiss="modal" aria-hidden="true"></button>' +
            '<div class="modal-content message-modal-content">' +
        '<h1>Atenção</h1>' +
        '<div class="row"><div class="col-md-12 message-box"></div></div>' +
        '<div class="row"><div class="col-md-12 message-box"><p>' + title + '</p></div></div>' +
    '</div>';
}

/**
 *
 * function to close delivery detail and unbind event from the body
 * @param target
 */
function closeDeliveryDetail(target){
    $('.track-modal').hide();
    setTimeout(function(){$('body').unbind('.openDeliveryDetail')}, 1);
    setTimeout(function(){$('.delivery-detail').removeClass('opened')}, 1);
}

function addClassOpened(obj){
    setTimeout(function(){obj.addClass('opened')}, 5);
}

function deleteCreditCard(creditcardId, count) {
    modalHeight = '126';
    if (count < 2){
        modalHeight = '168';
    }
    $('#wrapper').generalModal({
        dimensions: '348,' + modalHeight,
        directShow: true,
        targetPath: "/customer/creditCard/deleteconfirmation/?creditcard=" + creditcardId,
        closeObject: ".ok-address-alert"
    });

}
