(function ($) {
    "use strict";

    var Search = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.$input = this.$element.find(".search-input");
        this.$mobileTrigger = $(".search-mobile-link");
        this.$count = this.$element.find("[data-field=count]");
        this.$autocomplete = this.$element.find(".autocomplete");
        this.$noAutocomplete = this.$element.find(".no-autocomplete");
        this.$keywords = this.$element.find(".autocomplete-list");
        this.$carousel = this.$element.find(
            ".search-autocomplete .carousel-items"
        );
        this.$carouselTpl = this.$element
            .find(".search-autocomplete .carousel-item")
            .clone();
        this.$keywordsTpl = this.$element
            .find(".autocomplete-list-keywords")
            .clone();
        this.$template = this.$element.find(".autocomplete-item").clone();
        this.$searchInput = $(".search-input");
        this.timeout = null;
        this.lastSuggest = null;
        this.$element.on(
            "click touchend",
            "[data-clear-input]",
            $.proxy(this, "clearInput")
        );
    };

    Search.DEFAULTS = {
        jsonUrl: null,
    };

    Search.prototype.clearInput = function () {
        this.$input.val("").focus();
    };

    Search.prototype.keyup = function () {
        clearTimeout(this.timeout);
        this.timeout = setTimeout($.proxy(this, "suggest"), 350);
        if ($(".highlight-backdrop").length > 0) {
            $(".modal-close").trigger("click");
            $(".search-input").focus();
        }
    };

    Search.prototype.suggest = function () {
        if (this.term() && this.term() !== this.lastSuggest) {
            this.lastSuggest = this.term();
            this.getJSON().success($.proxy(this, "jsonLoaded"));
        } else if (this.term() == this.lastSuggest) {
            this.open();
        }
        $(document).one(
            "click",
            "body:not(.search-input)",
            $.proxy(this, "close")
        );
    };

    Search.prototype.getJSON = function () {
        return $.getJSON(this.options.jsonUrl, {
            q: this.term(),
        });
    };

    Search.prototype.term = function () {
        return $.trim(this.$input.val());
    };

    Search.prototype.jsonLoaded = function (data) {
        if (data[3] > 0) {
            this.$autocomplete.toggleClass("hide");
            this.$noAutocomplete.toggleClass("hide");
            this.keywords(data[1]);
            this.renderCarousel(data[2]);
            this.results(data[3]);
            this.wrapText(data[0]);
        } else {
            this.$autocomplete.toggleClass("hide");
            this.$noAutocomplete.toggleClass("hide");
        }
    };

    Search.prototype.wrapText = function (items) {
        $(".autocomplete-link:contains(" + items + ")").each(function () {
            var newText = $(this)
                .html()
                .replace(
                    new RegExp(items, "g"),
                    "<strong>" + items + "</strong>"
                );
            $(this).html(newText);
        });
    };

    Search.prototype.results = function (count) {
        this.$count.text(count);
    };

    Search.prototype.keywords = function (items, count) {
        if (items) {
            count = items.length;
        }

        this.$keywords.empty();

        for (var item in items) {
            item = items[item];

            this.$keywords.append(this.keywordsTpl(item));
        }

        this.$element.toggleClass("is-open", count > 0);
        $("[data-header-overlay]").addClass("is-active");
    };

    Search.prototype.renderCarousel = function (items) {
        this.$carousel.empty();

        for (var item in items) {
            item = items[item];

            this.$carousel.append(this.carouselTpl(item));
        }
    };

    Search.prototype.keywordsTpl = function (item) {
        var result = this.$keywordsTpl.clone();

        var link = "";
        var title = item;

        if (typeof item == "object") {
            link = item.link;
            title = item.itemText;
        } else {
            link = "/catalog/?q=" + item.replace(/\s/g, "+") + "&wtqs=1";
        }

        result.find("[data-keyword=keyword]").attr("href", link);
        result.find("[data-keyword=keyword]").attr("title", title);
        result.find("[data-keyword=keyword]").text(title);

        return result;
    };

    Search.prototype.carouselTpl = function (item) {
        var result = this.$carouselTpl.clone();

        result.find("[data-field=link]").attr("href", "/" + item.url);
        result.find("[data-field=image]").attr("src", item.image);
        result.find("[data-field=brand]").text(item.brand);
        result.find("[data-field=name]").text(item.name);
        result
            .find("[data-field=special_price]")
            .text(item.special_price ? item.special_price : item.price);

        return result;
    };

    Search.prototype.positionFocus = function () {
        var strLength = this.$searchInput.val().length;
        this.$searchInput.focus();
        this.$searchInput[0].setSelectionRange(0, strLength);
    };

    Search.prototype.open = function () {
        this.$element.addClass("is-open");
    };

    Search.prototype.close = function () {
        this.$element.removeClass("is-open");
    };

    $.fn.search = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data("dft.search");
            var options = $.extend(
                {},
                Search.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );

            if (!data)
                $this.data("dft.search", (data = new Search(this, options)));
            if (typeof option == "string") data[option]();
        });
    };

    $(document).on("keyup focus", ".search .search-input", function (e) {
        $(e.currentTarget).closest(".search").search("keyup");
    });

    $(document).on("change", "#catalog-search-active:checked", function () {
        $(this).search("positionFocus");
    });

    $(document).on(
        "click",
        ".search-mobile-link, .btn-cancel-search, .search-close-icon",
        function () {
            $(".header-search").toggleClass("is-active");
            $(".search-mobile-link").parent().toggleClass("is-active");
            $("[data-column-right]").toggleClass("is-active");

            if ($(".header-search").hasClass("is-active")) {
                $(".search-input").val("");
            }
        }
    );

    $(document).on("click", ".search.is-open", function (e) {
        e.stopPropagation();
    });

    var inputSearch = $("input.search-input");
    var timeoutChangeColor;
    inputSearch.focus(function () {
        clearTimeout(timeoutChangeColor);
        $(".sub-menu").css("z-index", 1);
        $(".help-menu").css("background", "transparent");
        $(".menu-group").css("transition", "opacity 1s");
        $(".menu-group").css("webkitTransition", "opacity 1s");
    });
    inputSearch.blur(function () {
        $(".sub-menu").css("z-index", 12);
        $(".menu-group").css("transition", "opacity 0s");
        $(".menu-group").css("webkitTransition", "opacity 0s");
        timeoutChangeColor = setTimeout(function () {
            $(".help-menu").css("background", "#fff");
        }, 1000);
    });
    $('#input-search').on('input', function() {
        $(this).val($(this).val().toLowerCase());
    });
})(jQuery);

$(function () {
    if (document.cookie.indexOf("customer_logged=1") > 0) {
        var verificationLogin = setInterval(function () {
            var isLogged = document.querySelector(".is-logged");
            if (typeof isLogged !== "undefined" && isLogged !== null) {
                clearInterval(verificationLogin);
                var imageFace = document.querySelector(".header-login-image");
                if (
                    typeof imageFace !== "undefined" &&
                    imageFace !== null &&
                    imageFace.getAttribute("src") !== null
                ) {
                    document
                        .querySelector(".l-site-title")
                        .setAttribute("class", "l-site-title col-md-2 a-left");
                    document
                        .querySelector(".l-header-right")
                        .setAttribute("class", "l-header-right col-md-4");
                }
            }
        }, 500);
    }
});
//
