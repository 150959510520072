(function($) {

    'use strict';

    var CatalogFilter = function(element, options) {
        this.$element = $(element);
        this.slider = new Array();
        this.options = options;
        this.options.promotionChanged = false;

        this.$element.on('mousedown touchstart', '[data-price-slider] [data-price-slider-icon]', $.proxy(this, 'priceSlideStart'));

        this.$element.on('focus', '[data-search]', $.proxy(this, 'searchFilterMobileFocus'));
        this.$element.on('keyup', '[data-search]', $.proxy(this, 'searchFilter'));
        this.$element.on('change', '[data-field=promotion]', $.proxy(this, 'promotionFilter'));
        this.$element.on('click', '[data-field=submit], .catalog-filter-list-link', $.proxy(this, 'trackFilter'));
    };

    CatalogFilter.DEFAULTS = {
        'maxHeightItems' : 7
    };

    CatalogFilter.prototype.priceSlideStart = function(e) {
        e.preventDefault();
        var sl = this.slider;
        this.$element.on('mousemove.price touchmove.price', $.proxy(this, 'priceSlideMove'));
        $(window).one('mouseup touchend', $.proxy(this, 'priceSlideEnd'));

        if (typeof sl['minValue'] == 'undefined') {
            var slVals = this.$element.find('[data-price-slider-value]');
            sl['minValue'] = parseInt($(slVals[0]).text());
            sl['maxValue'] = parseInt($(slVals[1]).text());
        }

        sl['active'] = $(e.currentTarget).parent().parent('[data-price-slider]');
        sl['active'].addClass('is-active');
        sl['position'] = parseInt(sl['active'].css('left'));

        if (sl['active'].data('price-slider') == 'min') {
            sl['minPosition'] = 0;
            sl['maxPosition'] = parseInt(sl['active'].parent().find('[data-price-slider=max]').css('left')) - 10;
        }
        if (sl['active'].data('price-slider') == 'max') {
            sl['minPosition'] = parseInt(sl['active'].parent().find('[data-price-slider=min]').css('left')) + 10;
            sl['maxPosition'] = parseInt(sl['active'].parent().width());
        }

        sl['mouseLastPos'] = e.clientX || e.changedTouches.item(0).clientX;
    };

    CatalogFilter.prototype.priceSlideMove = function(e) {
        e.preventDefault();
        var clientX = e.clientX || e.changedTouches.item(0).clientX;
        var sl = this.slider;

        if ((sl['maxValue'] - sl['minValue']) > 1) {
            sl['position'] = clientX - sl['mouseLastPos'] + sl['position'];
            if (sl['position'] < sl['minPosition']) {
                sl['position'] = sl['minPosition'];
            } else if (sl['position'] > sl['maxPosition']) {
                sl['position'] = sl['maxPosition'];
                sl['mouseLastPos'] = sl['mouseLastPos'];
            } else {
                sl['mouseLastPos'] = clientX;
            }
            sl['active'].css('left', sl['position']);
            var value = Math.round(sl['position'] / (sl['active'].parent().width() / (sl['maxValue'] - sl['minValue'])) + sl['minValue']);
            value = (parseInt(sl['active'].parent().width()) == sl['position']) ? sl['maxValue'] : value;
            sl['active'].find('[data-price-slider-value]').text(value);
        }
    };

    CatalogFilter.prototype.priceSlideEnd = function(e) {
        this.slider['active'].removeClass('is-active');
        this.$element.unbind('mousemove.price');
        this.$element.unbind('touchmove.price');
        this.updatePriceFilterUrl();
    };

    CatalogFilter.prototype.updatePriceFilterUrl = function() {
        var min         = this.$element.find('[data-field=price-min]').text();
        var max         = this.$element.find('[data-field=price-max]').text();
        var promotion   = this.$element.find('[data-field=promotion]');
        var url         = (this.options.promotionChanged) ? promotion.data('url') : $(window).attr('location').href;
        var actualParam = new RegExp('([\?&amp;])price=[^&amp;#]*').exec($(window).attr('location').href);
        var append      = (url.indexOf("?") > -1) ? '&' : '?';
        var range       = 'price=' + min + '-' + max;
        url = (actualParam) ? url.replace(actualParam[0], actualParam[1]+range) : url+append+range;
        this.$element.find('[data-field=submit]').attr('href', url);
    };

    CatalogFilter.prototype.promotionFilter = function(e) {
        this.options.promotionChanged = !this.options.promotionChanged;
        this.updatePriceFilterUrl();
    };

    CatalogFilter.prototype.trackFilter = function(event) {
        // Only track inactive filters (i.e. when enabling the filter)
        if($(event.currentTarget).hasClass('is-active')) return;

        var target = $(event.target);

        var trackingSite = null;
        
        if(document.location.hostname.indexOf('dafiti') > -1)
            trackingSite = 'dafiti';
        else if(document.location.hostname.indexOf('kanui') > -1)
            trackingSite = 'kanui';
        else if(document.location.hostname.indexOf('tricae') > -1)
            trackingSite = 'tricae';
        else return;

        var facet = target.closest('.catalog-filter-item').data('facet');
        var filter = null;

        if(target.filter('[data-field=submit]').length === 0)
        {
            // Common filters
            filter = target.closest('.catalog-filter-list-item').data('value');
        } else {
            // Apply price filters
            var min         = this.$element.find('[data-field=price-min]').text();
            var max         = this.$element.find('[data-field=price-max]').text();
            var promotion   = (this.options.promotionChanged) ? "special-price" : "all-price";

            var filter = [promotion, min, max].join("-");
        }
        
        if(facet !== null && filter !== null && trackingSite != 'dafiti')
            ga('ga_'+trackingSite+'.send', 'event', 'catalog', 'filter/'+facet, filter);
    };

    CatalogFilter.prototype.searchFilter = function(e) {
        var el = $(e.currentTarget);
        var elContainer = el.closest('.catalog-filter-accordion-content');
        var searchTerm = el.val();
        elContainer.find('.catalog-filter-list .catalog-filter-list-link-text').each(function(i){
            var relation = $(this).html().replace(/\<\/?b\>/ig, '');
            var find = relation.toLowerCase().indexOf(searchTerm.toLowerCase());
            if (find >= 0 && !$(this).parent().hasClass('is-disabled')) {
                relation = relation.substring(0,find)
                            + '<b>'
                            + relation.substring(find, find + searchTerm.length)
                            + '</b>'
                            + relation.substring(find + searchTerm.length, relation.length);
                $(this).html(relation);
                $(this).parent().parent().removeClass('hide');
            } else {
                $(this).parent().parent().addClass('hide');
            }
        });

        if (elContainer.find('.catalog-filter-list li:not(.hide)').length == 0) {
            el.siblings('.catalog-filter-search-empty').removeClass('hide');
        } else {
            el.siblings('.catalog-filter-search-empty').addClass('hide');
        }
    };

    CatalogFilter.prototype.searchFilterMobileFocus = function() {
        var offset = this.$element.parent().offset().top - this.$element.find("[data-search]").offset().top;
        offset = Math.abs(offset) - 10;
        this.$element.parent().scrollTop(offset);
    };

    CatalogFilter.prototype.changeFilterStatus = function() {
        var name = this.$element.attr('id');
        var filter = {};

        filter[name] = {
            'isOpen': this.$element.is(':checked')
        };

        this.setFilterStatus(filter);
    };

    CatalogFilter.prototype.setScrollPosition = function() {
        var name = this.$element.closest('.catalog-filter-item').find('.accordion-trigger').attr('id');
        var filter = {};

        filter[name] = {
            'scrollPosition': this.$element.closest('.catalog-filter-list').scrollTop()
        };

        this.setFilterStatus(filter);
    };

    CatalogFilter.prototype.showFilterStatus = function() {
        var filters = this.getFilterStatus();
        var selectedFilter = null;

        for (var item in filters) {
            selectedFilter = $('#' + item);

            selectedFilter.prop('checked', filters[item].isOpen);
            selectedFilter.closest('.catalog-filter').find('.catalog-filter-list').scrollTop(filters[item].scrollPosition);
        }
    };

    CatalogFilter.prototype.isScrollable = function() {
        var max = this.options.maxHeightItems;

        this.$element.find('.catalog-filter-list').each(function() {
            var element = $(this);

            if (element.find('.catalog-filter-list-item').length < max) {
                element.addClass('no-scroll');
            }
        });

        this.showFilterStatus();
    };

    CatalogFilter.prototype.hasLocalStorage = function() {
        return ('localStorage' in window && window.localStorage !== null);
    };

    CatalogFilter.prototype.getFilterStatus = function() {
        if (this.hasLocalStorage()) {
            return JSON.parse(localStorage.getItem('dft.filterStatus'));
        }

        return {};
    };

    CatalogFilter.prototype.setFilterStatus = function(filter) {
        var oldFilterStatus = this.getFilterStatus();
        var newFilterStatus = $.extend(true, oldFilterStatus, filter);

        if (this.hasLocalStorage()) {
            localStorage.setItem('dft.filterStatus', JSON.stringify(newFilterStatus));
        }
    };

    $.fn.catalogFilter = function(option, args) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.catalog-filter');
            var options = $.extend({}, CatalogFilter.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.catalog-filter', (data = new CatalogFilter(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    }

    $(document).on('click', '.catalog-filter', function(e) {
        $(this).catalogFilter();
    });

    $(document).on('click', '.catalog-filter-mobile-price-input', function(e) {
        $(this).select();
    });

    $(document).on('initCatalogFilters', function() {
        $('.catalog-filter').catalogFilter('isScrollable');
    });

    $(document).on('dft.catalog-ajax.loaded', function() {
        $('.catalog-filter').catalogFilter('isScrollable');
    });

    $(document).on('change', '.accordion-trigger', function(e) {
        $(e.currentTarget).catalogFilter('changeFilterStatus');
    });

    $(document).on('click', '.catalog-filter-list-link', function(e) {
        $(e.currentTarget).catalogFilter('setScrollPosition');
    });

    try {
        document.querySelectorAll(':checked');
    } catch (e) {

        $(document).on('click', '.accordion-link', function(e) {
            var $catalogFilterItem = $(this).closest('.accordion-item');
            if (!$catalogFilterItem.find('.accordion-trigger').is(":checked")) {
                $catalogFilterItem.addClass('accordion-item-active')
                    .find('.accordion-trigger').prop('checked', true);
            } else {
                $catalogFilterItem.removeClass('accordion-item-active')
                    .find('.accordion-trigger').prop('checked', false);
            }
        });

        $(document).on('click', '.catalog-filter-price-label', function(e) {
            var $catalogFilterPrice = $(this);
            if (!$catalogFilterPrice.find('#catalog-filter-price-promotion-input').is(":checked")) {
                $catalogFilterPrice.addClass('catalog-filter-price-label-active')
                    .find('#catalog-filter-price-promotion-input').prop('checked', true);
            } else {
                $catalogFilterItem.removeClass('catalog-filter-price-label-active')
                    .find('#catalog-filter-price-promotion-input').prop('checked', false);
            }
        });
    }

})(jQuery);
