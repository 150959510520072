(function ($) {
    'use strict';

    var HeaderLogin = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.render();
    }

    HeaderLogin.DEFAULTS = {
        name: null,
        card: false,
        picture: null,
        loginUrl: null
    };

    HeaderLogin.prototype.render = function () {
        if (this.options.name) {
            this.$element.popover({trigger: 'hover'});
            this.$element.addClass('is-logged');
            this.$element.find('[data-field=name]').text(this.options.name);
            this.$element.find('[data-field=card]').toggle(this.options.card);
            $('body').find("[data-my-account]").removeClass("hide");
            this.setPicture();
        }
    }

    HeaderLogin.prototype.setPicture = function (pictureUrl) {
        pictureUrl = (typeof pictureUrl == 'undefined') ? this.options.picture : pictureUrl;
        if(pictureUrl !== null) {
            this.$element.addClass('has-social-picture').find('[data-field=picture]').attr('src', pictureUrl);
        } else {
            this.$element.removeClass('has-social-picture').find('[data-field=picture]').attr('src', pictureUrl);
        }
    }

    HeaderLogin.prototype.login = function() {
        $.ajax({
            url: this.options.loginUrl,
            cache: false,
            global: false
        }).success($.proxy(this, 'loginLoaded'));
    }

    HeaderLogin.prototype.loginLoaded = function(form) {
        this.$element.find('.popover').empty().append(form);
        this.$element.popover({trigger: 'click'}).popover('open');
    }

    HeaderLogin.prototype.vipLayout = function(form) {
        if ( $.cookie('customer_logo_vip') != 1 && $.cookie('customer_freight_vip') != 1) {
            return false;
        }

        if ($.cookie('customer_logo_vip') == 1) {
           $('[data-field="main-logo"]').append('<i class="l-icon-vip icon-vip"></i>');
        }

        if ($.cookie('customer_freight_vip') == 1 && $('.product-delivery-time-trigger-not-seller').length) {
            $('#product-delivery-time').before('<p class="vip-delivery">Entrega Expressa Grátis <i class="l-icon-vip icon-vip"></i></p>');
        }

    }

    $.fn.headerLogin = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.header-login');
            var options = $.extend({}, HeaderLogin.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.header-login', (data = new HeaderLogin(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    }

    $(document).ready(function() {
        $('body').headerLogin('vipLayout');
    });

})(jQuery);
