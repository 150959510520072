(function ($) {
    'use strict';
    var AutoCompleteMail = function (element) {
       this.$emailElementId = '';
        this.$formElementId = '';
        this.$suggestionMail = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'icloud.com'];
        this.$currentSelectedDropDown = -1;
        this.$submitEvents = null;
    };
    /**
     * Auto complete
     *
     * @return void
     **/
    AutoCompleteMail.prototype.complete = function() {
        var mailField = $(this.$emailElementId).val();
        var domain = mailField.match(/[^@]+$/);
        var lastLetter = mailField.substring(mailField.length - 1, mailField.length);
        this.$currentSelectedDropDown = -1;

        if (mailField.indexOf('@') >= 0) {
            this.captureEventsOfSubmit();
            this.removeErrorMessage();

            $(this.$emailElementId).attr('autocomplete', 'off');

            $(this.$emailElementId  + "_sm").addClass("is-active");
            $(this.$emailElementId  + '_sm ul li').removeClass("selected");

            var contItem = 0;
            var that = this;
            $(this.$emailElementId  + "_sm ul li").each(function(){
                var obj = $(this);
                var domainArray = obj.html();

                if (domainArray.indexOf(domain) >= 0 && lastLetter != '@') {
                    obj.show();
                    obj.addClass('suggestion-mail-option');

                    if(domain == domainArray && lastLetter != '@'){
                        if (that.hasOneSuggestion(domain)) {
                            that.clearBox();
                        }
                    }
                    contItem++;
                } else {
                    obj.hide();
                    obj.removeClass('suggestion-mail-option');
                }
            });

            if (contItem == 0) {
                this.clearBox();
            }
        }else{
            this.clearBox();
            $(this.$emailElementId).attr('autocomplete', 'on');
        }
    };
    /**
     * Create box
     *
     * @return void
     **/
    AutoCompleteMail.prototype.createBox = function () {
        var div = $("<div style='width: 292px;top:32px;left:10px' id='" + this.$emailElementId.replace('#','')  + "_sm'  class='suggestion-mail' ><ul></ul></div>");

        //$(this.$emailElementId).wrapAll('<div class="suggestion-warp"/>');
        $(this.$emailElementId).after(div);

        for(var i in this.$suggestionMail){
            div.find('ul').append('<li class="suggestion-mail-option">' + this.$suggestionMail[i] + '</li>');
        }

        this.createNavigation();
    };

    /**
     * Close suggestion box after 200ms
     *
     * @return void
     **/
    AutoCompleteMail.prototype.clearBoxTimeOut = function () {
        this.clearBox();
    };
    /**
     * Updated mail field to suggestion selected
     *
     * @param string domain
     * @return void
     **/
    AutoCompleteMail.prototype.completeFieldMail = function (domain) {
        this.removeErrorMessage();
        var mail = this.getCurrentName(domain);
        $(this.$emailElementId).val(mail);
        $(this.$emailElementId).focus();
    };
    /**
     * Changed selected option for another reference
     *
     * @param object options
     * @return void
     **/
    AutoCompleteMail.prototype.changeSelected = function (options) {
        options.removeClass('selected');
        options.eq(this.$currentSelectedDropDown).addClass('selected');
        var mail = this.getCurrentName($(this.$emailElementId  + '_sm ul li.selected').html());
        $(this.$emailElementId).val(mail);
    };
    /**
     * Create navigation for keycodes up and down arrows
     *
     * @return void
     **/
    AutoCompleteMail.prototype.createNavigation = function (){

        var that = this;
        $(this.$emailElementId).bind('keyup', function(e) {
            var options = $(that.$emailElementId  + '_sm').find('.suggestion-mail-option');
            var totalItems = options.length;
            var noOption = - 1;
            var firstOption = 0;
            var lastOption = totalItems - 1;
            var currentSelected = that.$currentSelectedDropDown;

            if($(that.$emailElementId  + '_sm').hasClass('is-active')){
                if (e.keyCode == 40) {
                    if (currentSelected == lastOption) {
                        that.$currentSelectedDropDown = firstOption;
                    }else{
                        that.$currentSelectedDropDown++;
                    }
                    that.changeSelected(options);
                } else if (e.keyCode == 38) {
                    if(currentSelected == noOption || currentSelected == firstOption){
                        that.$currentSelectedDropDown = lastOption;
                    }else{
                        that.$currentSelectedDropDown--;
                    }
                    that.changeSelected(options);
                }
            }
        });
    };
    /**
     * Get mail input's value and concat with sended domain
     *
     * @param string domain
     * @return string
     **/
    AutoCompleteMail.prototype.getCurrentName = function(domain){
        var mailField = $(this.$emailElementId).val();
        var name = mailField.match(/^.*?(?=@)/);
        return name + '@' + domain;
    };
    /**
     * Capture events of submit
     *
     * @return void
     **/
    AutoCompleteMail.prototype.captureEventsOfSubmit = function () {
        if (this.$submitEvents == null) {
            var events = $(this.$formElementId).data('events');
            this.$submitEvents = [];

            if (events) {
                for(var i=0; i < events.submit.length; i++) {
                    this.$submitEvents.push(events.submit[i].handler);
                }
            }

            this.changeSubmitEvent();
        }
    };
    /**
     * Return events of submit
     *
     * @return void
     **/
    AutoCompleteMail.prototype.returnEventsOfSubmit = function () {
        if(this.$submitEvents != null) {
            $(this.$formElementId).unbind('submit');

            if (this.$submitEvents.length > 0) {
                var that = this;
                for (var i = 0; i < this.$submitEvents.length; i++) {
                    $(that.$formElementId).on('submit', that.$submitEvents[i]);
                }
            }

            this.$submitEvents = null;
        }
    };
    /**
     * Close box
     *
     * @return void
     **/
    AutoCompleteMail.prototype.clearBox = function () {
        if($(this.$emailElementId  + '_sm').hasClass('is-active')){
            $(this.$emailElementId  + "_sm ul li").hide();
            $(this.$emailElementId  + "_sm").removeClass('is-active');
            $(this.$emailElementId  + '_sm ul li').removeClass("selected");
        }
        this.returnEventsOfSubmit();
    };
    /**
     * Remove error message
     *
     * @return void
     **/
    AutoCompleteMail.prototype.removeErrorMessage = function () {
        if ($("label[for='" + $(this.$emailElementId).attr("id") + "'][class=error]")) {
            $("label[for='" + $(this.$emailElementId).attr("id") + "'][class=error]").hide();
            $(this.$emailElementId).removeClass("error");
        }
    };
    /**
     * Change submit event, and close box of mail suggestion
     *
     * @return void
     **/
    AutoCompleteMail.prototype.changeSubmitEvent = function () {
        $(this.$formElementId).unbind('submit');
        var that = this;
        $(this.$formElementId).on('submit', function(e){
            e.preventDefault();
            e.stopPropagation();
            that.clearBox();
            $(that.$emailElementId).focus();
        });
    };
    /**
     * Valid if exists one suggestion
     *
     * @param string domain
     * @return boolean
     **/
    AutoCompleteMail.prototype.hasOneSuggestion = function (domain) {
        var contSuggestion = 0;

        $(this.$emailElementId  + "_sm ul li").each(function(){
            var obj = $(this);
            var domainArray = obj.html();

            if (domainArray.indexOf(domain) >= 0) {
                contSuggestion++;
            }
        });

        return (contSuggestion == 1);
    };
    /**
     * Init method that manage another methods
     *
     * @param string mailElement
     * @param string formElement
     * @param string top default null
     * @return void
     **/
    AutoCompleteMail.prototype.bindMailElement = function (mailElement, formElement) {
        this.$emailElementId = mailElement;
        this.$formElementId = formElement;
        this.createBox();
        var that = this;
        $(this.$emailElementId).keyup(function(e){
            if (e.keyCode != 38 && e.keyCode != 40 && e.keyCode != 13) {
                that.complete();
            }
        });

        $(this.$emailElementId  + '_sm ul li').click(function(){
            that.completeFieldMail($(this).html());
        });

        $(this.$emailElementId).blur(function(){
            that.clearBoxTimeOut();
        });
    };

    AutoCompleteMail.prototype.changeFields = function (element) {
        this.$emailElementId = '#'+element;
    };
    $(document).ready(function(){
       var completeEmail = new AutoCompleteMail();
       completeEmail.bindMailElement("#myaccount_edit");
    });
})(jQuery);
