(function($) {

    'use strict';

    var BackToShop = function() {
        this.splitedDomain = "";
        this.joinedDomain = "";
    }

    BackToShop.prototype.init = function () {
        this.splitDomain();
        this.getDomain();
    }

    BackToShop.prototype.getShopKey = function() {

        if(typeof  this.splitedDomain.length == 'undefined' ||  this.splitedDomain.length < 2) {
            return 'BackToShop';
        }

        return 'BackToShop_' + this.joinedDomain.replace(/\./g,"_");
    }

    BackToShop.prototype.update = function() {
        jQuery.cookie(this.getShopKey(), window.location.href, {
            path: '/',
            expires: 7,
            domain: this.joinedDomain
        });
    }

    BackToShop.prototype.splitDomain = function() {
        var strUrl = window.location.href;
        var arrUrl = strUrl.toLowerCase().replace(/(http\:\/\/)|(https\:\/\/)/g, '').split('/');

        arrUrl = arrUrl[0].split('.');
        this.splitedDomain =  arrUrl;
    }

    BackToShop.prototype.getDomain = function() {
        var splitedDomain = this.splitedDomain;
        if( splitedDomain[0] == 'secure' || splitedDomain[0] == 'www' ) {
            splitedDomain.shift();
        }
        this.joinedDomain = "."+splitedDomain.join(".");
    }

    BackToShop.prototype.backToShop = function() {
        var strCookieKey = this.getShopKey();
        if (strCookieKey != 'BackToShop' && jQuery.cookie(strCookieKey)) {
            var url = jQuery.cookie(strCookieKey);
        } else {
            url = '/';
        }
        window.location = url;
    }

    $(document).on('dft.back-to-shop', function(){
        var bts = new BackToShop();
        bts.init();
        bts.update();
    });

    $(document).on('click', '[data-action="dft-back-to-shop"]', function(e) {
        e.preventDefault();

        var bts = new BackToShop();
        bts.init();
        bts.backToShop();
    });

})(jQuery);
