(function ($) {
    'use strict';

    function pushDataLayerFreeShippingGA4(resultsQuantity) {
        dataLayer.push({
            'event': 'select_content_ga4',
            'content_type': 'filter',
            'content_id': 'frete gratis - toggle button',
            'options_value': 'frete gratis',
            'results_quantity': resultsQuantity
        });
    }

    function pushDataLayerFreeShippingGA3(resultsQuantity) {
        dataLayer.push({
            'event': 'select_content_ga3',
            'category': 'filter',
            'action': 'click',
            'label': 'frete gratis - toggle button',
            'options_value': 'frete gratis',
            'results_quantity': resultsQuantity
        });
    }

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
    if ((_dataLayerFiltered !== undefined && _dataLayerFiltered.ET == 'catalogpage') && window.location.href.includes('?q=')) {

        var checkboxFreeShipping = document.getElementsByName('toggle-switch-free-shipping')[0];
        var isCheckedFreeShipping = false;

        $(document).on('click', '.free-toggle', function () {
            var currentURL = document.location.href;
            setInterval(function () {
                if (checkboxFreeShipping.checked && !isCheckedFreeShipping && document.location.href !== currentURL) {
                    currentURL = document.location.href;
                    var resultsQuantity = $('.items-products.select-options-item > .value').text();
                    pushDataLayerFreeShippingGA4(resultsQuantity);
                    pushDataLayerFreeShippingGA3(resultsQuantity);
                    isCheckedFreeShipping = checkboxFreeShipping.checked;
                }
            }, 1000);
        });
    }
})(jQuery);
