(function($) {

    'use strict';

    var ChangePassword = function(element, options) {
        this.$element = $(element);
        this.options = options;
    }

    ChangePassword.DEFAULTS = {
    };

    ChangePassword.prototype.change = function() {
        $.ajax({
            type: "POST",
            url: '/customer/account/changepass/',
            data: this.$element.serialize(),
            dataType: "json",
            success: $.proxy(this, 'validate')
        });
    }

    ChangePassword.prototype.validate = function(response) {
        if(response.hasErrors == false) {
            window.location.replace('/customer/account/list/?b2014=1');
            $('#PasswordForm_password_old').removeClass('parsley-error').next().children().remove();
            $('#PasswordForm_verification_code').removeClass('parsley-error').next().children().remove();
        } else {
            if (response.messages == 'Senha inválida.') {
                $('#PasswordForm_password_old').addClass('parsley-error').next().addClass('filled').html('<li class="parsley-required">'+response.messages+'</li>');
                $('#PasswordForm_verification_code').removeClass('parsley-error').next().children().remove();
            } else if (response.messages == 'Código de verificação incorreto!') {
                $('#PasswordForm_verification_code').addClass('parsley-error').next().addClass('filled').html('<li class="parsley-required">'+response.messages+'</li>');
                $('#PasswordForm_password_old').removeClass('parsley-error').next().children().remove();
            }
        }
    }

    $.fn.changePassword = function(option, args) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.change-password');
            var options = $.extend({}, ChangePassword.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.change-password', (data = new ChangePassword(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    }

    $(document).on('close', '.myaccount-change-password', function(e) {
        $('[data-myaccount-change-password-form]').parsley().reset();
        $('#PasswordForm_password_old').val('');
        $('#PasswordForm_password').val('');
        $('#PasswordForm_password2').val('');
    });

    $(document).on('submit', '[data-myaccount-change-password-form]', function(e) {
        e.preventDefault();
    });

    $.listen('parsley:form:validated', function(e){
        e.$element.toggleClass('error', !e.validationResult);
        $('[data-myaccount-change-password-form]').changePassword('change');
    });
}( jQuery ));
