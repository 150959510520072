(function ($) {

    'use strict';

    var forms = document.querySelectorAll('.carousel-overflow.recommendations-overflow.product-guide-overflow form')
    if (typeof dataLayer != "undefined" && dataLayer[0].subtotal === "") {
        forms.forEach(function (form) {
            form.querySelector('.btn.btn-add-to-cart.add-to-cart-form-button').addEventListener('click', function (event) {
                event.preventDefault()
                addProduct(event)
            })

        })

        var addProduct = function (event) {
            var skuProduct = event.target.parentElement.querySelectorAll('input')[0].value
            var skuSize = event.target.parentElement.querySelectorAll('input')[1].value
            var xhttp = new XMLHttpRequest()
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var productAdded = JSON.parse(xhttp.response.slice(1, this.response.length - 2)).ok
                    if (productAdded) {
                        location.reload()
                    }
                } else {
                    event.target.parentElement.className += ' is-invalid'
                    event.target.parentElement.querySelector('.selectbox-2.add-to-cart-form-sizes.change-sizes-click').className += ' is-invalid'
                }
            }
            xhttp.open('GET', 'https://secure.dafiti.com.br/cart/addJson/?p=' + skuProduct + '&sku=' + skuSize)
            xhttp.send()
        }
    }


})(jQuery);