(function ($) {
    'use strict';
    var isAddToWishlistEvent = false;

    var CatalogEventsGa4 = function () {
        /**
         *
         * @param element
         * @param position
         * @param listName
         * @param event
         */
        this.dataLayerObject = function (element, position, listName, event) {
            var object = {
                'event': event,
                'ecommerce': {
                    'items': []
                }
            };
            return object;
        }

          /**
           *
           * @param price
           */
        this.sanitizePrice = function (price) {
            return Number(price
                .replace('R$ ', '')
                .replace('.', '')
                .replace(',', '.'));
        }

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @returns {[{item_id: string, item_list_name, price: (*|number), item_affiliation: string, discount: string, index, item_name: string, item_category2: (string|string), item_category3: (string|string), item_brand: string, item_category: (string|string), item_variant: string}]}
         */
        this.formatProducts = function (element, position, listName) {
            const isSpecialPrice = element.querySelector('.product-box-price').classList.contains('is-special-price');
            const priceSpan = isSpecialPrice ? '.product-box-price-to' : '.product-box-price-from';
            const brand = element.querySelector('.product-box-brand span').textContent.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const name = element.querySelector('.product-box-title').textContent.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const price = element.querySelector(priceSpan).textContent;
            const originalPrice = element.querySelector('.product-box-price-from').textContent;
            const discount = this.sanitizePrice(originalPrice) - this.sanitizePrice(price);
            const sku = element.getAttribute('data-sku').toLowerCase();
            const categories = element.querySelector('.product-box-category').innerText.trim().split(",");
            const variant = element.querySelector('.product-box-colors span').textContent.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g);
            const seller = element.querySelector('.product-box-seller span').textContent.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g);

            var product = {
                'item_id': sku,
                'item_name': name,
                'item_brand': brand,
                'item_category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category2': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category3': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'price': this.sanitizePrice(price),
                'discount': parseInt(discount * 100) / 100,
                'index': position,
                'item_list_name': listName,
                'affiliation': 'dafiti br - ' + seller,
                'item_variant': variant
            };

            if (isAddToWishlistEvent) {
                product['quantity'] = 1;
            }

            return product;
        }
        /**
         *
         * @param element
         * @returns {{position: *, closest: (*|jQuery)}}
         */
        this.getClosestAndPosition = function (element) {
            var closest = $(element).closest('.product-box');
            var position = closest.prevAll().length;
            return { 'closest': closest, 'position': position };
        }
        /**
         *
         * @param element
         * @returns bool
         */
        this.isElementInViewportGa4 = function (element) {
            if (document.querySelector('.main-list') !== null) {
                var container = $(element).closest('.main-list').length > 0
                    ? $(element).closest('.main-list')
                    : $(element).closest('.main-list-mobile');//???
                if (!$(element).is(':visible')) {
                    return false;
                }
                return (
                    $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left - 10) &&
                    $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right + 10) &&
                    $(element)[0].getBoundingClientRect().top >= 0 &&
                    $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
                );
            }
        }
        return this;
    }


    function prepareDataLayerToPageViewEvent(dataLayer, title) {
        dataLayer.push({
            'event': 'page_view_ga4',
            'page_path': document.location.pathname.toLowerCase(),
            'page_location': document.location.href.toLowerCase(),
            'page_title': title.toLowerCase()
        });
    }
    var CatalogEventsGA4 = function () {
        this.prepareDataLayerToPageViewEvent = function (dataLayer, title) {
            return prepareDataLayerToPageViewEvent(dataLayer, title);
        };
        return this;
    };

    window.catalogEventsGA4 = CatalogEventsGA4;

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];

    if (_dataLayerFiltered !== undefined) {
        if ((isDafitiStore() && _dataLayerFiltered.ET == 'catalogpage' || _dataLayerFiltered.ET == 'landingpage') && !window.location.href.includes('?q=')) {
            var catalogEventsGA4 = new CatalogEventsGA4();

            var pageName = _dataLayerFiltered.pageName;

            var breadcrumpCategory = $(".breadcrumb2-item-link").text();
            var promotionCategory = "";



            if (breadcrumpCategory.includes('Feminino') || pageName.includes("feminino")) {
                promotionCategory = "- feminino";
            } else if (breadcrumpCategory.includes('Masculino') || pageName.includes('masculino')) {
                promotionCategory = "- masculino";
            } else if (breadcrumpCategory.includes('Infantil') || pageName.includes('infantil')) {
                promotionCategory = "- infantil";
            } else if (breadcrumpCategory.includes('Casa') || pageName.includes('casa')) {
                promotionCategory = "- casa";
            } else if (breadcrumpCategory.includes('Outlet') || pageName.includes('promocao')) {
                promotionCategory = "- outlet";
            } else {
                promotionCategory = "";
            }


            var title = 'ecommerce - product list - ';

            if (pageName === 'BRANDPAGE') {
                title += 'brand';
            } else if (pageName === 'TYPEPAGE') {
                var category = _dataLayerFiltered.pageCategory.split(',')[0];
                title += 'category - ' + category;
            } else if (pageName.includes('LandingPage')) {
                title += 'promotion ' + promotionCategory;
            } else if (pageName === 'CATEGORYPAGE') {
                var category = _dataLayerFiltered.pageCategory.split(',')[0];
                title += 'category - ' + category;
            }

            catalogEventsGA4.prepareDataLayerToPageViewEvent(dataLayer, title);
        }

        if ((isDafitiStore() && _dataLayerFiltered.ET == 'catalogpage' || _dataLayerFiltered.ET == 'landingpage')) {
            const getListName = function () {
                var result = 'promotion - catalog';

                if (Array.isArray(dataLayer)) {
                    for (var i = 0; i < dataLayer.length; i++) {
                        var object = dataLayer[i];

                        if (object.hasOwnProperty('search_type')) {
                            var searchType = object.search_type;

                            switch (searchType) {
                                case 'click suggestion':
                                    result = 'search result - sugestoes';
                                    break;
                                case 'click recent':
                                    result = 'search result - recentes';
                                    break;
                                case 'click ta em alta':
                                    result = 'search result - ta em alta';
                                    break;
                                case 'type':
                                    result = 'search result - type';
                                    break;
                            }
                        }
                    }
                }
                return result;

            }

            const sendVisibleProductsToDatalayer = function () {
                var catalogEvents = new CatalogEventsGa4();
                var object = {};
                $('.product-box').each(function (i) {
                    if (catalogEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                        $(this).addClass('impressionGA4');
                        var getClosestAndPosition = catalogEvents.getClosestAndPosition(this);
                        const listName = getListName();
                        if (Object.keys(object).length === 0) {
                            object = catalogEvents.dataLayerObject(
                                this,
                                getClosestAndPosition.position,
                                listName,
                                'view_item_list_ga4'
                            );
                        }

                        const formattedProduct = catalogEvents.formatProducts(
                            this,
                            getClosestAndPosition.position,
                            listName);
                        object.ecommerce.items.push(formattedProduct);
                    }
                });
                if (Object.keys(object).length !== 0) {
                    dataLayer.push(object);
                }
            }
            if (document.querySelector('.main-list') !== null) {
                var isMainListHided = setInterval(function () {
                    var isHided = document.querySelector('.main-list').classList.contains('hide');
                    if (!isHided) {
                        clearInterval(isMainListHided);
                        sendVisibleProductsToDatalayer();
                    }
                }, 250)
            }

            $(document).on('scroll', window, function (e) {
                sendVisibleProductsToDatalayer();
            });

            //Taggeamento GA4 - Select Item Catalog
            if (_dataLayerFiltered.productSku1 != '') {
                $(document).on('click', '.product-image', function (event) {
                    var CatalogEventsGA4 = new CatalogEventsGa4();
                    var getClosestAndPosition = CatalogEventsGA4.getClosestAndPosition(this);
                    const listName = getListName();

                    var productBoxElement = $(this).closest('.product-box')[0];

                    if (event.target === this) {
                        var object = CatalogEventsGA4.dataLayerObject(
                            this,
                            getClosestAndPosition.position,
                            listName,
                            'select_item_ga4'
                        );
                        const formattedProduct = CatalogEventsGA4.formatProducts(
                            productBoxElement,
                            getClosestAndPosition.position,
                            listName);
                        object.ecommerce.items.push(formattedProduct);
                        dataLayer.push(object);
                    }
                });
            }

            //Taggeamento GA4 - Add to Wishlist Catalog
            $(document).on('click', '.product-box-wishlist', function () {
                var CatalogEventsGA4 = new CatalogEventsGa4();
                var getClosestAndPosition = CatalogEventsGA4.getClosestAndPosition(this);
                const listName = getListName();
                isAddToWishlistEvent = true;

                var object = CatalogEventsGA4.dataLayerObject(
                    this,
                    getClosestAndPosition.position,
                    listName,
                    'add_to_wishlist_ga4'
                );

                var productBoxElement = $(this).closest('.product-box')[0];

                const formattedProduct = CatalogEventsGA4.formatProducts(
                    productBoxElement,
                    getClosestAndPosition.position,
                    listName
                );
                object.ecommerce.items.push(formattedProduct);
                dataLayer.push(object);
            });
        }
    }

    function isDafitiStore() {
        return document.location.hostname.indexOf('dafiti') > -1;
    }

})(jQuery);
