window.dft = window.dft || {};

window.dft.dataLayer = (function(){
	var checkDataLayer = function(){
		return typeof dataLayer === "object" ? true : false; 
	}
	var checkPush = function(){
		if (!checkDataLayer()){
			return false;
		}
		return typeof dataLayer.push === "function" ? true : false;
	};
	var pushEvent = function(object){
		if(!checkPush() || !typeof data.products == "object"){
			return false;
		}
		return dataLayer.push(object);
	};
	var prepareProducts = function(products){
		var dataLayerProducts = [];
		var dataLayerChanged = [];
		if (Object.prototype.toString.call(products) === "[object Object]"){
			dataLayerProducts.push(createProduct(products));
			if (products['sku_old']){
				products["sku_simple"] = products['sku_old'];
				dataLayerChanged.push(createProduct(products));
			}
		}
		if (Object.prototype.toString.call(products) === "[object Array]"){
			for (index = 0; index < products.length; ++index) {
	    		dataLayerProducts.push(createProduct(products[index]));
	    		if (products[index]['sku_old']){
					products[index]["sku_simple"] = products[index]['sku_old'];
					dataLayerChanged.push(createProduct(products));
				}	
			}
		}
		var arrayReturn = [dataLayerProducts, dataLayerChanged];
		return arrayReturn;
	};
	var createProduct = function(product){
		var object = {
			"id" : product["sku_simple"],
			"name" : product["name"],
			"brand" : product["brand_name"],
			"quantity" : product["quantity"],
			"variant" : product["size"],
			"price" : product["price"]
		};
		return object;
	};
	var prepareObject = function(event, label, products){
		var object = {
			"event" : event,
			"ecommerce" : {}
		};
		
		object.ecommerce[label] = {};
		object.ecommerce[label].products = products;
		
		if (event === "addToCart") {
			object.ecommerce.currencyCode = "BRL";
		}

		return object;
	};
	
 	return {
		soldOutItem: function(e, data){
			if (checkDataLayer()){
				var product = {
					'name': dataLayer[0].productName,
					'id': dataLayer[0].ProdId,
					'brand': dataLayer[0].brand
				};
				var soldOutObject = prepareObject("productSoldOut", "detail", [product]);
				pushEvent(soldOutObject);

				$(document).trigger('dft.product-info-gtm-sold-out', soldOutObject );
			}
		},
		addToCart : function(e, data){
			var products = prepareProducts(data.item);
			var object = prepareObject("addToCart", "add", products[0]);
			if (products[1].length > 0){
				var removeObject = prepareObject("removeFromCart", "remove", products[1]);
				pushEvent(removeObject);
			}
			
			$(document).trigger('dft.product-info-gtm-add', object );
		},
		removeFromCart : function(e, data){
			var products = prepareProducts(data.item);
			var object = prepareObject("removeFromCart", "remove", products[0]);

			$(document).trigger('dft.product-info-gtm-remove', object );
		},
		updateItemInCart : function(e, data){
			var products = prepareProducts(data.item);
			var object = prepareObject("updateItemInCart", "update", products[0]);
			$(document).trigger('dft.product-info-gtm-update', object );
		},
		init : function(){
			$(document).on('dft.product-sold-out', this.soldOutItem);
			$(document).on('dft.add-to-cart-and-redirect', this.addToCart);
			$(document).on('dft.add-to-cart-form-submit-to-cart-page', this.addToCart);
			$(document).on('dft.add-to-cart-form.success', this.addToCart);
			$(document).on('dft.cart-add.item-added', this.addToCart);
			$(document).on('dft.cart-quantity.update', this.updateItemInCart);
			$(document).on('dft.cart-remove.item-removed', this.removeFromCart);
		}
	}
})();

dft.dataLayer.init();