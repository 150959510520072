window.dft = window.dft || {};

window.dft.trackingMenu = (function() {
    var checkDataLayer = function() {
        return typeof dataLayer === "object" ? true : false;
    }

    var prepareObject = function(data) {
        if (data.event === 'select_content_ga3') {
            var object = {
                event: data.event,
                category: data.category,
                action: data.action,
                label: data.label
            };
        }

        if (data.event === 'select_content_ga4') {
            var object = {
                event: data.event,
                content_type: data.content_type,
                item_id: data.item_id,
                element: data.element
            };
        }

        return object;
    };

    return {
        pushEvent: function(e, data) {
            if (checkDataLayer()) {
                dataLayer.push(prepareObject(data));
            }
        },
        init: function() {
            $(document).on('dft.menu-tracking', this.pushEvent);
        }
    }
})();

dft.trackingMenu.init();