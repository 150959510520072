(function () {

    const sponsoredLabels = Array.from(document.querySelectorAll('[data-tracking]'));

    if (sponsoredLabels && window.abg) {

        sponsoredLabels.forEach(function (sponsoredProduct) {

            const sponsoredProductBox = sponsoredProduct.parentNode.children[0];

            sponsoredProductBox.addEventListener('click', function (event) {
                const trackingData = sponsoredProduct.attributes[0].value
                window.abg.reportClick(trackingData)
            });

        });
    }
})();