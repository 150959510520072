(function ($) {
    'use strict';


    var PdpCarrosselEvents = function () {
        /**
        * @param element
        * @param position
        * @param listName
        * @param event
        * @param action
        */
        this.dataLayerCarrinhoFavoritos = function (element, position, listName, event, action) {
            // var dataLayer = dataLayer || [];
            var object = {
                'event': event,
                'category': 'enhanced ecommerce',
                'action': action,
                'label': 'product detail - ' + listName,
                'ecommerce': {
                    'currencyCode': 'BRL',
                    'add': {}
                }
            };


            object.ecommerce.add.products = this.formatProducts(element, position, listName)


            dataLayer.push(object);
        }


        /**
        *
        * @param element
        * @param position
        * @param listName
        * @param event
        * @param action
        */
        this.dataLayerSelectItem = function (element, position, listName, event, action) {
            // var dataLayer = dataLayer || [];
            var object = {
                'event': event,
                'category': 'enhanced ecommerce',
                'action': action,
                'label': 'product detail - ' + listName,
                'ecommerce': {
                    'click': {
                        'actionField': { 'list': 'product detail - ' + listName },
                    }
                }
            };


            object.ecommerce.click.products = this.formatProducts(element, position, listName)


            dataLayer.push(object);
        }


        /**
        *
        * @param element
        * @param position
        * @param listName
        * @param event
        * @param action
        */
        this.dataLayerImressionItem = function (element, position, listName, event, action) {
            // var dataLayer = dataLayer || [];
            var object = {
                'event': event,
                'category': 'enhanced ecommerce',
                'action': action,
                'label': 'product page - ' + listName,
                'ecommerce': {
                    'currencyCode': 'BRL',
                    'impressions': []
                }
            };


            return object;
        }


        /**
        *
        * @param element
        * @param listName
        * @param position
        * @returns {[{quantity: string, list: string, dimension54: (string|string), dimension53: (string|string), dimension55: string, price: number, dimension52: (string|string), dimension51: (string|string), name: string, variant: *, id: *, position, category: (string|string), brand: string}]}
        */
        this.formatProducts = function (element, position, listName) {
            const brand = element.find('h3').text();
            const name = element.find('p').text();
            const price = element.find(".product-box-price-to").text();
            const sku = element.data("sku");
            const categories = element.data("categories").split(",");
            const variant = element.data("variant");
            const seller = element.data("seller");


            return {
                'id': sku.toLowerCase(),
                'name': name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'price': Number(price.replace('R$ ', '').replace(',', '.')),
                'brand': brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension51': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension52': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension53': categories[3] !== undefined ? categories[3].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension54': categories[4] !== undefined ? categories[4].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'dimension55': 'dafiti br - ' + seller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'position': position,
                'list': 'product detail - ' + listName.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'variant': variant.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'quantity': '1'
            };
        }


        /**
        *
        * @param element
        * @returns {{position: *, closest: (*|jQuery)}}
        */
        this.getClosestAndPosition = function (element) {
            var closest = $(element).closest('.product-guide-item');
            var position = closest.prevAll().length;


            return { 'closest': closest, 'position': position };
        }


        this.getObjectBtnCart = function (element) {
            var closest = element.closest;
            var position = element.position;


            return { 'closest': closest, 'position': position };
        };


        /**
        *
        * @param element
        * @returns bool
        */
        this.isElementInViewport = function (element) {
            var container = $(element).closest('.product-guide-overflow').length > 0 ? $(element).closest('.product-guide-overflow') : $(element).closest('.product-guide-overflow-new-pdp-mobile');


            if (!$(element).is(':visible')) {
                return false;
            }


            return (
                $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left - 10) &&
                $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right + 10) &&
                $(element)[0].getBoundingClientRect().top >= 0 &&
                $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
            );
        }


        return this;
    }


    var _dataLayerFiltered = dataLayer.filter(function (e) {
        return e.ET;
    })[0];


    // Verifica se está sendo executado na loja dafiti e se está na página de produtos.
    if (_dataLayerFiltered != undefined && document.location.hostname.indexOf('dafiti') > -1 && _dataLayerFiltered.ET === 'productpage') {
        //WN-345: Taggeamento GA3 - 3 Carrosseis PDP - Add To Cart
        $(document).on('click', '.product-box-info > .product-box-form form > button', function () {
            const hasSizeSelected = $(this).parent().find('ul, li').find('.is-selected').data("value");


            if (hasSizeSelected) {
                var pdpEvents = new PdpCarrosselEvents();
                var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


                pdpEvents.dataLayerCarrinhoFavoritos(
                    getClosestAndPosition.closest,
                    (getClosestAndPosition.position + 1),
                    getClosestAndPosition.closest.data('type'),
                    'add_to_cart_ga3',
                    'add to cart'
                );
            }
        });
        //WN-345: Taggeamento GA3 - 3 Carrosseis PDP - Add To Cart (Mobile)
        if (isMobileDevice()) {
            $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > div > a.cart.btn-cart', function () {
                var pdpEvents = new PdpCarrosselEvents();
                var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);
                $(document).on('click', '#addToCartModal > div.add-to-cart-recommendation > button', function () {
                    const hasSizeSelected = $('#addToCartModal > div.rcm-choices').find('.active').length;
                    var getObjectBtnCart = pdpEvents.getObjectBtnCart(getClosestAndPosition);


                    if (hasSizeSelected) {
                        pdpEvents.dataLayerCarrinhoFavoritos(
                            getObjectBtnCart.closest,
                            (getObjectBtnCart.position + 1),
                            getObjectBtnCart.closest.data('type'),
                            'add_to_cart_ga3',
                            'add to cart'
                        );
                    }
                });
            });
        }


        //WN-346: Taggeamento GA3 - 3 Carrosséis PDP - Add To Wishlist
        $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > div > a.favorite', function () {
            var pdpEvents = new PdpCarrosselEvents();
            var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


            pdpEvents.dataLayerCarrinhoFavoritos(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'add_to_wish_list_ga3',
                'add to wish list'
            );
        });


        //WN-344: Taggeamento GA3 - 3 Carrosséis PDP - Select Item
        $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > a', function () {
            var pdpEvents = new PdpCarrosselEvents();
            var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


            pdpEvents.dataLayerSelectItem(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'product_click_ga3',
                'product click'
            );
        });


        //WN-343: Taggeamento GA3 - 3 Carrosséis PDP - View Item
        $(document).on('scroll', window, function (e) {
            var pdpEvents = new PdpCarrosselEvents();
            var object = {};


            $('.carousel-item-new-pdp').each(function (i) {
                if (pdpEvents.isElementInViewport($(this)) && !$(this).hasClass("impressionGA")) {
                    $(this).addClass('impressionGA');
                    var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


                    if (Object.keys(object).length === 0) {
                        object = pdpEvents.dataLayerImressionItem(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'product_impressions_ga3',
                            'product impressions'
                        );
                    }
                    var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest,
                        (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));
                     

                        object.ecommerce.impressions.push(formattedProduct);
                }
            });

            if (Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }

        });


        $(document).on('click', '.recommendations-prev-new, .recommendations-next-new', function (e) {
            setTimeout(function () {
                var pdpEvents = new PdpCarrosselEvents();
                $('.carousel-item-new-pdp').each(function (i) {
                    if (pdpEvents.isElementInViewport($(this)) && !$(this).hasClass("impressionGA")) {
                        $(this).addClass('impressionGA');
                        var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


                        var object = pdpEvents.dataLayerImressionItem(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'product_impressions_ga3',
                            'product impressions'
                        );
                        var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));


                        object.ecommerce.impressions.push(formattedProduct);


                        dataLayer.push(object);
                    }
                });
            }, 500);
        });


        $('.recommendations').on('DOMSubtreeModified', function () {
            $('.product-guide-overflow-new-pdp-mobile').scroll(function (e) {
                
                var pdpEvents = new PdpCarrosselEvents();
                $('.carousel-item-new-pdp').each(function (i) {
                    if (pdpEvents.isElementInViewport($(this)) && !$(this).hasClass("impressionGA")) {
                        $(this).addClass('impressionGA');
                        var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);


                       var object =  pdpEvents.dataLayerImressionItem(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'product_impressions_ga3',
                            'product impressions'
                        );
                        var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));


                        object.ecommerce.impressions.push(formattedProduct);


                        dataLayer.push(object);
                    }
                });
            });
        });


    }


    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    }


    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    }
})(jQuery);
