(function($) {

    'use strict';

    if ('ontouchstart' in document) {
        $('body').removeClass('no-touch').addClass('touch');
    }

    if ($('#mobile-detector').is(':visible')) {
        $('body').removeClass('desktop').addClass('mobile');
    }

    if ($('#tablet-detector').is(':visible')) {
        $('body').removeClass('desktop').addClass('tablet');
    }

    $(document).on('focus', '[data-mask-input-cpf]', function() {
        VMasker($(this)).maskPattern('999.999.999-99');
    });

    $(document).on('keypress', '[data-mask-phone]', function() {
        VMasker($(this)).maskPattern("(99) 99999-9999");
    });

    $(document).on('focus', '[data-mask-input-postcode]', function() {
        VMasker($(this)).maskPattern('99999-999');
    });

    window.dft = window.dft || {};

    window.dft.urlParam = function(name) {
        var results = new RegExp('[\?&amp;]' + name + '=([^&amp;#]*)').exec(window.location.href);
        return (results && results.length) ? results[1] : null;
    };

    window.dft.getQuerystring = function(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    window.dft.hasAnchor = function(name) {
        return window.location.hash == "#" + name;
    };

    window.dft.urlFriendly = function(s) {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/\s/g), "-");
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        r = r.replace(new RegExp(/\W/g), "-");
        return r;
    };

    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    };

    String.prototype.isEmail = function() {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this);
    };

    String.prototype.isCPF = function() {
        var numeros, digitos, soma, i, resultado, digitos_iguais = 1;

        var tax_id = jQuery.trim(this).replace('/', '').replace('.', '').replace('.', '').replace('-', '').replace('_', '');
        if (tax_id.length < 11) {
            return false;
        }

        for (i = 0; i < tax_id.length - 1; i++) {
            if (tax_id.charAt(i) != tax_id.charAt(i + 1)) {
                digitos_iguais = 0;
                break;
            }
        }

        if (!digitos_iguais) {
            numeros = tax_id.substring(0, 9);
            digitos = tax_id.substring(9);
            soma = 0;

            for (i = 10; i > 1; i--) {
                soma += numeros.charAt(10 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(0)) {
                return false;
            }

            numeros = tax_id.substring(0, 10);
            soma = 0;

            for (i = 11; i > 1; i--) {
                soma += numeros.charAt(11 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(1)) {
                return false;
            }

            return true;
        } else {
            return false;
        }

    };

})(jQuery);
