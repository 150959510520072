(function($) {

    'use strict';

    var CustomerOrderDetailAction = function() {};

    CustomerOrderDetailAction.prototype = new window.dft.ActionController();
    
    CustomerOrderDetailAction.prototype.showLetter = function() {
        $('body').modal('destroy');
        $('body').removeData('dft.modal');
        var opt = {
            'modalContent': '[data-cancellation-letter]',
            'className': 'cancellation-letter-modal',
            'shouldResize': true
        }
        $('body').modal(opt);
    };
    
    CustomerOrderDetailAction.prototype.showTrackingUrlNotAvailable = function() {
        $('body').modal('destroy');
        $('body').removeData('dft.modal');
        var opt = {
            'modalContent': '[tracking-url-not-available]',
            'className': 'tracking-url-not-available-modal',
            'shouldResize': true
        }
        $('body').modal(opt);
    };

    $(document).on('click', '[data-show-cancellation-letter]', function(e) {
        e.preventDefault();
        (new CustomerOrderDetailAction()).showLetter();
    });

    $(document).on('click', '[show-tracking-url-not-available]', function(e) {
        e.preventDefault();
        (new CustomerOrderDetailAction()).showTrackingUrlNotAvailable();
    });

})(jQuery);

function copy(event) {
    event.preventDefault();
    var copyText = document.querySelector("#apiKeyValue");
    copyText.select();
    document.execCommand('copy');
}

var pixKeyButtton = document.querySelector("#pixKeyButtton");

if (pixKeyButtton) {
    pixKeyButtton.addEventListener("click", copy);
}