(function($) {

    'use strict';

    $(document).on('click', '[data-check-toggle-disable]', function() {
        if (this.checked) {
            $('[data-input-toggle-disable]').val('');
            $('[data-input-toggle-disable]').attr('readonly', true);
            $('[data-input-toggle-disable]').removeClass('is-invalid parsley-error');
            $('[data-input-toggle-disable]').siblings('.parsley-errors-list').removeClass('filled').html('');
            $('[data-input-toggle-disable]').attr('data-form-required', false);
        } else {
            $('[data-input-toggle-disable]').attr('data-form-required', true);
            $('[data-input-toggle-disable]').attr('readonly', false);
        }
    });

}(jQuery));
