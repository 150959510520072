(function ($) {

    'use strict';

    var LastViewed = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.$lastViewed = JSON.parse($.cookie("last-viewed"));
        this.$currentProduct = this.$element.data('last-viewed');
    }

    LastViewed.prototype.load = function() {
        if(this.$currentProduct != 'last-viewed' ) {
        this.addSku();
        this.limit();
        this.save();
        }
    };

    LastViewed.prototype.addSku = function() {
        if(this.$lastViewed == null ) {
            this.$lastViewed = [this.$currentProduct];
            return;
        }

        if($.inArray(this.$currentProduct,this.$lastViewed) != -1) {
            var index = this.$lastViewed.indexOf(this.$currentProduct);
            this.$lastViewed.splice(index, 1);
        };

        this.$lastViewed.unshift(this.$currentProduct);
    };

    LastViewed.prototype.limit = function() {
        if(this.$lastViewed.length > 10) {
            this.$lastViewed.pop();
        }
    }

    LastViewed.prototype.save = function() {
        $.cookie("last-viewed",JSON.stringify(this.$lastViewed),{domain: cookieDomain,path: '/'});
    }

    LastViewed.DEFAULTS = {
    }

    $.fn.lastViewed = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.lastViewed');
            var options = $.extend({}, LastViewed.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.lastViewed', (data = new LastViewed(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).ready(function() {
        $('[data-last-viewed]').lastViewed('load');
    });

})(jQuery);
