(function ($) {
    "use strict";
    function debounce(func, timeout) {
        var _this = this;

        var timer = void 0;
        return function () {
            for (
                var _len = arguments.length, args = Array(_len), _key = 0;
                _key < _len;
                _key++
            ) {
                args[_key] = arguments[_key];
            }

            clearTimeout(timer);
            timer = setTimeout(function () {
                func.apply(_this, args);
            }, timeout);
        };
    }

    function SearchSuggestion() {
        this.INPUT_SEARCH_CONTAINER_SELECTOR = ".input-search-container";
        this.INPUT_SEARCH_SELECTOR = ".search-suggestion-input";
        this.SEARCH_SUGGESTION_CONTAINER = "#search-suggestion-container";
        this.SUGGESTIONS_LIST_SELECTOR = "#search-suggestion-container ul";
        this.SUGGESTIONS_LOADING_SELECTOR =
            "#search-suggestion-container .suggestion-loading";
        this.SUGGESTIONS_NOT_FOUND_SELECTOR =
            "#search-suggestion-container .suggestion-not-found";
        this.SEARCH_ICON = "/images/search-suggestions_search-icon.svg";
        this.SEARCH_BUTTON_SELECTOR = ".open-search-button";
        this.AUTOCOMPLETE_MIN_SEARCH_LENGTH = 2;
        this.DEBOUNCE_DELAY = 300;
        this.SEER_BASE_URL =
            "https://e3emurvlr7.execute-api.us-east-1.amazonaws.com/default/serverless-suggestions";
        this.SEER_SUGGESTION_ATTRIBUTE = "ti";

        this.isSearchSuggestionContainerOnFocus = false;

        this.debouncedOnInputChangeListener =
            this.getDebouncedOnInputChangeListener(this.DEBOUNCE_DELAY);
    }

    SearchSuggestion.prototype.getDebouncedOnInputChangeListener = function (
        timeout
    ) {
        return debounce(this.onInputChangeListener.bind(this), timeout);
    };

    SearchSuggestion.prototype.addEventListeners = function () {
        this.addOnInputChangeListener();
        this.addOnSuggestionClickListener();
        this.addOnInputFocus();
        this.addOnInputBlur();
        this.addOnSearchSuggestionContainerFocus();
        this.addOnSearchSuggestionContainerBlur();
    };

    SearchSuggestion.prototype.addOnInputChangeListener = function () {
        this.getInputElement().keyup(
            function () {
                this.debouncedOnInputChangeListener(this.getInputValue());
            }.bind(this)
        );
    };

    SearchSuggestion.prototype.addOnSuggestionClickListener = function () {
        $(this.SUGGESTIONS_LIST_SELECTOR).on(
            "click",
            "li",
            function (event) {
                var target = $(event.target);
                var suggestionValue = target.text().trim();

                if (target.context.nodeName === "B") {
                    var parent = target.parent();
                    suggestionValue = parent.text().trim();
                }

                this.onSuggestionClickListener(suggestionValue);
            }.bind(this)
        );
    };

    SearchSuggestion.prototype.addOnInputFocus = function () {
        $(this.INPUT_SEARCH_SELECTOR).on(
            "focus",
            function () {
                if (this.canSearch()) {
                    this.showSearchSuggestionContainer();
                    this.makeSearchCall(this.getInputValue());
                }
            }.bind(this)
        );
    };

    SearchSuggestion.prototype.addOnInputBlur = function () {
        $(this.INPUT_SEARCH_SELECTOR).on(
            "blur",
            function (e) {
                if (!this.isSearchSuggestionContainerOnFocus) {
                    this.hideSearchSuggestionContainer();
                }
            }.bind(this)
        );
    };

    SearchSuggestion.prototype.addOnSearchSuggestionContainerFocus =
        function () {
            $(this.SUGGESTIONS_LIST_SELECTOR).on(
                "mouseenter",
                "li",
                function () {
                    this.isSearchSuggestionContainerOnFocus = true;
                }.bind(this)
            );
        };

    SearchSuggestion.prototype.addOnSearchSuggestionContainerBlur =
        function () {
            $(this.SUGGESTIONS_LIST_SELECTOR).on(
                "mouseleave",
                "li",
                function () {
                    this.isSearchSuggestionContainerOnFocus = false;
                }.bind(this)
            );
        };

    SearchSuggestion.prototype.onSuggestionClickListener = function (
        suggestionValue
    ) {
        this.fillInputSearch(suggestionValue);
        this.searchButtonClick();
        this.hideSearchSuggestionContainer();
    };

    SearchSuggestion.prototype.getInputElement = function () {
        return $(this.INPUT_SEARCH_SELECTOR);
    };

    SearchSuggestion.prototype.getSuggestionsListElement = function () {
        return $(this.SUGGESTIONS_LIST_SELECTOR);
    };

    SearchSuggestion.prototype.getInputValue = function () {
        return this.getInputElement().val();
    };

    SearchSuggestion.prototype.onInputChangeListener = function (value) {
        if (this.canSearch()) {
            this.showSearchSuggestionContainer();
            this.makeSearchCall(value);
        } else {
            this.hideSearchSuggestionContainer();
        }
    };

    SearchSuggestion.prototype.canSearch = function () {
        var inputValue = this.getInputValue();
        return inputValue.length >= this.AUTOCOMPLETE_MIN_SEARCH_LENGTH;
    };

    SearchSuggestion.prototype.showSearchSuggestionContainer = function () {
        $(this.SEARCH_SUGGESTION_CONTAINER).css("display", "flex");
    };

    SearchSuggestion.prototype.hideSearchSuggestionContainer = function () {
        $(this.SEARCH_SUGGESTION_CONTAINER).hide();
    };

    SearchSuggestion.prototype.makeSearchCall = function (value) {
        this.showLoading();
        this.hideNotFound();

        if (value && value.trim().length) {
            const find = "/";
            const re = new RegExp(find, "g");
            value = value.replace(re, "");

            $.ajax({
                url: this.buildSeerUrl(value),
                type: "GET",
            })
                .success(this.searchSuccess.bind(this))
                .fail(this.searchFail.bind(this))
                .complete(this.hideLoading.bind(this));
        } 
    };

    SearchSuggestion.prototype.buildSeerUrl = function (value) {
        return this.SEER_BASE_URL + "?context=search&search=" + value;
    };

    SearchSuggestion.prototype.searchSuccess = function (data) {
        try {
            var json = JSON.parse(data);

            if (json.length) {
                this.renderSuggestions(json);
            } else {
                this.showNotFound();
            }
        } catch (e) {
            console.error(
                "En unexpected erro occured while searching for suggestions..."
            );
        }
    };

    SearchSuggestion.prototype.searchFail = function (data) {
        console.log("failed...", data);
        // clear input data
        // close everything
    };

    SearchSuggestion.prototype.renderSuggestions = function (data) {
        var inputValue = this.getInputValue();

        data.forEach(
            function (suggestion) {
                this.getSuggestionsListElement().append(
                    this.buildSuggestionElement(inputValue, suggestion)
                );
            }.bind(this)
        );
    };

    SearchSuggestion.prototype.buildSuggestionElement = function (
        inputValue,
        suggestion
    ) {
        var suggestionContainer = document.createElement("li");
        var suggestionIcon = document.createElement("span");
        var suggestionLabel = document.createElement("p");
        var searchIcon =
            '<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.90149 0.699745C9.05149 0.699745 11.6345 3.25135 11.6345 6.43298C11.6345 7.78753 11.162 9.04758 10.3745 10.0241L12.8 12.4497L12.0755 13.1742L9.64999 10.7486C8.64199 11.6307 7.35049 12.1347 5.90149 12.1347C2.75149 12.1347 0.199987 9.5831 0.199987 6.40147C0.199987 3.21985 2.75149 0.699745 5.90149 0.699745ZM5.90149 11.1267C8.51599 11.1267 10.595 9.01608 10.595 6.43298C10.595 3.84987 8.51599 1.73929 5.90149 1.73929C3.28699 1.73929 1.20799 3.84987 1.20799 6.43298C1.20799 9.01608 3.31849 11.1267 5.90149 11.1267Z" fill="#CCCCCC"/></svg>';

        suggestionIcon.innerHTML = searchIcon;
        suggestionIcon.style.marginRight = "8.7px";
        suggestionLabel.innerHTML = this.highlightSuggestionText(
            inputValue,
            suggestion[this.SEER_SUGGESTION_ATTRIBUTE]
        );

        suggestionContainer.appendChild(suggestionIcon);
        suggestionContainer.appendChild(suggestionLabel);

        return suggestionContainer;
    };

    SearchSuggestion.prototype.highlightSuggestionText = function (
        inputValue,
        suggestionText
    ) {
        const subString = inputValue.toLowerCase();
        const fullString = suggestionText.toLowerCase();

        return fullString.replace(subString, "<b>" + subString + "</b>");
    };

    SearchSuggestion.prototype.showLoading = function () {
        this.clearSuggestionsList();
        $(this.SUGGESTIONS_LOADING_SELECTOR).css("display", "flex");
    };

    SearchSuggestion.prototype.hideLoading = function () {
        $(this.SUGGESTIONS_LOADING_SELECTOR).hide();
    };

    SearchSuggestion.prototype.showNotFound = function () {
        this.clearSuggestionsList();
        $(this.SUGGESTIONS_NOT_FOUND_SELECTOR).css("display", "flex");
    };

    SearchSuggestion.prototype.hideNotFound = function () {
        $(this.SUGGESTIONS_NOT_FOUND_SELECTOR).hide();
    };

    SearchSuggestion.prototype.clearSuggestionsList = function () {
        this.getSuggestionsListElement().html("");
    };

    SearchSuggestion.prototype.fillInputSearch = function (value) {
        $(this.INPUT_SEARCH_SELECTOR).val(value);
    };

    SearchSuggestion.prototype.searchButtonClick = function () {
        $(this.SEARCH_BUTTON_SELECTOR).click();
    };

    $(document).ready(function () {
        var searchSuggestion = new SearchSuggestion();
        searchSuggestion.addEventListeners();
    });
})(jQuery);
