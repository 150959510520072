(function($) {
    "use strict";

    var CatalogDetailAction = function() {};
    CatalogDetailAction.prototype = new window.dft.ActionController();

    CatalogDetailAction.prototype.run = function() {
        $("#stock-available").stockAvailable("load");

        $(document).on(
            "dft.cart-quantity.update",
            $.proxy(this, "updateTotal")
        );

        $(document).trigger("dft.add-coupon");
        $(document).trigger("dft.fashion-selfie.load");

        $('[data-lazyload]').lazyload();
    };



    $(document).on("click", ".in-popup", function() {
        var targetUrl = $(this).attr("href");
        var network = $(this)
            .parent()
            .attr("class");
        if (typeof _gaq != "undefined") {
            switch (network) {
                case "twitter":
                    _gaq.push(["_trackSocial", "twitter", "tweet", targetUrl]);
                    break;
                case "facebook":
                    _gaq.push(["_trackSocial", "facebook", "share", targetUrl]);
                    break;
                case "googleplus":
                    _gaq.push([
                        "_trackSocial",
                        "googleplus",
                        "plusone",
                        targetUrl
                    ]);
                    break;
                case "pinterest":
                    _gaq.push(
                        ["_trackSocial", "pinterest", "pinned"],
                        targetUrl
                    );
                    break;
            }
        }

        window.open(
            targetUrl,
            "social-network-window",
            "height=250,width=500,scrollbars=1"
        );
        return false;
    });

    routes["catalog/detail"] = function() {
        new CatalogDetailAction().run();
    };

    $(document).trigger("catalog/detail");

    /*
     * When mouse enter and is hovering the product image
     */
    $(document).on("mouseenter", ".catalog-page .product-image", function() {
        // data-hover-img is a parameter from product-image.
        // data-hover-img is the secondary image of a product
        if (
            $(this)
                .attr("data-hover-img")
                .indexOf("/-product") === -1
        ) {
            const hoverImg = $(this).attr("data-hover-img");
            // change the primary image to the secondary
            $(this).attr("src", hoverImg);
        }
    });

    /*
     * When mouse leave and isn't hovering the product image
     */
    $(document).on("mouseleave", ".catalog-page .product-image", function() {
        // data-original is a parameter from product-image.
        // data-original is related to primary image of a product
        if (
            $(this)
                .attr("data-hover-img")
                .indexOf("/-product") === -1
        ) {
            const originalImg = $(this).attr("data-original");
            // change the secondary image to the original
            $(this).attr("src", originalImg);
        }
    });

})(jQuery);
