(function() {

    'use strict';

    var AddressUpdate = function(element) {
        this.$element = $(element);

        this.addressType = this.$element.attr('data-address-type');
        this.addressId = this.$element.attr('data-address-id');
    };

    AddressUpdate.prototype.submit = function() {
        var $form = this.$element.closest('form');

        if (this.addressType === 'is_billing') {
            $form.find('[data-address-type="is_billing"]').val(1);
            $form.find('[data-address-type="is_shipping"]').val(0);
        } else {
            $form.find('[data-address-type="is_billing"]').val(0);
            $form.find('[data-address-type="is_shipping"]').val(1);
        }

        $form.submit();
    };

    AddressUpdate.prototype.update = function() {
        var data = {
            'is_billing': 0,
            'is_shipping': 1,
            'address_id': this.addressId
        };

        if (this.addressType === 'is_billing') {
            data.is_billing = 1;
            data.is_shipping = 0;
            this.setTypeAsBilling();
        } else {
            this.setTypeAsShipping();
        }

        $(document).trigger('dft.events-monitoring');

        $.ajax({
            'type': 'GET',
            'url': '/customer/address/updateDefaultAddressJson/',
            'dataType': 'json',
            'data': data,
            'success': $.proxy(this, 'showMessage')
        });
    };

    AddressUpdate.prototype.showMessage = function(response) {
        var $notification = $('.update-notification');

        $(document).trigger('dft.message-success', response);

        $('.touch .container-message').delay(5000).queue(function() {
            $(this).removeClass('hello').remove();
        });
    };

    AddressUpdate.prototype.setTypeAsBilling = function() {
        $(".account-address-actions").removeClass('is-billing');
        this.$element.parent().parent().prev().addClass('is-billing');
    };

    AddressUpdate.prototype.setTypeAsShipping = function() {
        $(".account-address-actions").removeClass('is-shipping');
        this.$element.parent().parent().prev().addClass('is-shipping');
    };

    $(document).on('change', '[data-address-type]', function() {
        var element = $(this);
        var address = new AddressUpdate(element);
        address.submit();
    });

})();
