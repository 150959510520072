(function ($) {

    'use strict';

    var SizeReminder = function (element, options) {
        this.$element = $(element);
        this.$element.on('form:loaded', $.proxy(this, 'loaded'))
    };

    SizeReminder.DEFAULTS = {};

    SizeReminder.prototype.loaded = function(e, data) {
        data = jQuery.parseJSON(data);
        var icon_content = '<i class="icon-cross error-icon show-mobile"></i>';
        var title_class = 'error-title';
        if (data.validation == true) {
            icon_content = '<i class="icon-check success-icon show-mobile"></i>';
            title_class = 'success-title';
        }
        this.$element.find('[data-message-title=true]').text(data.message_title).addClass(title_class).before(icon_content);
        this.$element.find('[data-message=true]').text(data.message);
        this.$element.find('.size-reminder-2-content').css('display', 'none');
    };

    SizeReminder.prototype.showSizeReminder = function() {
        this.$element.popover('open');
    };

    $.fn.sizeReminder = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.size-reminder');
            var options = $.extend({}, SizeReminder.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.size-reminder', (data = new SizeReminder(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click focus', '[data-size-reminder]', function(){
        $(this).sizeReminder();
    });

    $(document).on('click', '[data-size-reminder="stock-available"]', function() {
        $('#size-reminder-2-popover').sizeReminder('showSizeReminder');
    });

})(jQuery);
