(function($) {

    'use strict';

    var DeleteCreditCard = function(element, options) {
        this.$element = $(element);
        this.$parent = this.$element.closest('[data-card-info]');
        this.name_card = this.$parent.find('.name').text();
        this.template = $('script[type="text/credit-card-undo-template"]').html();
        this.options = options;
        this.init();
    };

    DeleteCreditCard.DEFAULTS = {
        oldcc: '',
        ccId: -1,
        cardType: "creditCard",
        time_delete: 8000,
        stop_delete: false,
    };

    DeleteCreditCard.prototype.init = function() {
        this.options.oldcc = this.$parent.children().detach();
        this.$parent.html(this.template);
        this.$parent.find('.name').text(this.name_card);
        this.$parent.children().slideDown();
        this.options.stop_delete = setTimeout($.proxy(this, 'deleteCreditCardNow'), this.options.time_delete);
        this.$parent.on('click', '[data-cc-undo]', $.proxy(this, 'deleteCancel'));
    };

    DeleteCreditCard.prototype.deleteCreditCardNow = function() {
        this.$parent
            .children('div')
            .animate({
                paddingTop: 0,
                paddingBottom: 0
            }, 500)
            .wrapInner('<div />')
            .children()
            .slideUp($.proxy(this, 'deleteAjax'));
    };

    DeleteCreditCard.prototype.deleteAjax = function() {
        var self = this;
        this.$parent.remove();
        $('[data-delete-card]').removeClass('hide');
        if ($('[data-delete-card]').length <= 1) {
            $('[data-delete-card]').addClass('hide');
        }
        $.ajax({
                url: this.$element.attr('href'),
                type: 'GET',
                data: {
                    creditcard: this.options.ccId
                },
                statusCode: {
                    401: function(data) {
                        var target = JSON.parse(data.responseText);
                        window.location = target.urlTarget;
                    }
                },
            })
            .success(function() {
                self.showNotification('Cartão removido!', 'success');
            })
            .fail(function() {
                self.showNotification('Houve um erro na exclusão do cartão.', 'error');
            });
    };

    DeleteCreditCard.prototype.deleteCancel = function(event) {
        event.preventDefault();
        clearInterval(this.options.stop_delete);
        this.$parent.html(this.options.oldcc);
    };


    DeleteCreditCard.prototype.showNotification = function(message, type) {
        this.$container = $('.myaccount-messages');

        $('.myaccount-messages').stop();
        this.$container.html('<div class="alert is-' + type + '"><i class="alert-icon-success icon-check-sharp"></i><i class="alert-icon-error icon-cross"></i>' + message + '</div>');

        $('.myaccount-messages').delay(5000).queue(function(next) {
            $('.myaccount-messages').empty();
            next();
        });
    };

    $.fn.creditCard = function(option, item) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.delete-card');
            var options = $.extend({}, DeleteCreditCard.DEFAULTS, $this.data(), typeof option == 'object' && option);

            $this.data('dft.delete-card', (data = new DeleteCreditCard(this, options)));
            if (typeof option == 'string' && data[option]) data[option](item);
        });
    };

    $(document).on('click', '[data-delete-card]', function(event) {
        event.preventDefault();
        $(this).creditCard();
    });

    $(document).on('change', '[data-default-card]', function(event) {
        event.preventDefault();
        $(document).trigger('dft.events-monitoring');
        var $el = $(event.currentTarget);
        var formCards = $el.parents('.form-cards');
        var parentThis = $(this).closest('[data-card-info]');
        var data = formCards.serialize();
        formCards.find('.default-message').addClass('hide-message');

        $.ajax({
                url: formCards.attr('action'),
                type: 'POST',
                data: data,
                statusCode: {
                    401: function(data) {
                        var target = JSON.parse(data.responseText);
                        window.location = target.urlTarget;
                    }
                },
            })
            .done(function() {
                var name = parentThis.find('.name').text();
                $(this).creditCard('“' + name + '” agora é seu cartão padrão.', 'success');
            })
            .fail(function() {
                $(this).creditCard('Houve um erro na alteração de seu cartão padrão.', 'error');
            });
    });

    $(document).on('click', '[data-resend-voucher]', function(event) {
        event.preventDefault();
        $(document).trigger('dft.events-monitoring');
        $.ajax({
                url: $(this).attr('href'),
                statusCode: {
                    401: function(data) {
                        var target = JSON.parse(data.responseText);
                        window.location = target.urlTarget;
                    }
                },
            })
            .done(function() {
                $(this).creditCard('showNotification', 'Códigos reenviados por e-mail!', 'success');
            })
            .fail(function() {
                $('[data-resend-voucher]').addClass('hide');
                $('[data-resend-voucher]').prev('.info').addClass('hide');
                $('[data-resend-voucher]').next().removeClass('hide');
            });
    });

})(jQuery);
