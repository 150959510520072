(function ($) {

    'use strict';

    var CartShowTurboPlus = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.block = false;
        this.toggle();
    }

    CartShowTurboPlus.DEFAULTS = {
        hide: true,
        showTurboPlus: ""
    };

    CartShowTurboPlus.prototype.toggle = function () {
        var that = this;
        if (this.block) {
            return;
        }

        this.block = true;
        $(this.options.showTurboPlus).slideToggle("slow", function () {
            that.block = false;
        });
        $(".showturbo-plus-open, .showturbo-plus-close, .region-showturboplus .icon-arrow").toggleClass("hide");
    }

    $.fn.showTurboPlus = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.cart-show-turbo-plus');

            if (data) {
                data.toggle();
                return;
            }

            var showMoreElement = $this.data().showTurboPlus;

            if (!showMoreElement) throw "No element configured.";

            var options = $.extend({}, CartShowTurboPlus.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-show-turbo-plus', (data = new CartShowTurboPlus(this, options)));

            if (typeof option == 'string') data[option](args);

        });
    };

    $(document).on('click', "[data-show-turbo-plus]", function () {
        $(this).showTurboPlus();
    });

})(jQuery);