(function($) {
  'use strict';

  var TrackingRule = function(element, options) {
    this.$element         = $(element);
    this.options          = options || {};
  };

  TrackingRule.DEFAULTS = {
    trackingRule: ''
  };

  TrackingRule.prototype.init = function() {
    this.$currentStep = this.$element.siblings("div.is-current");
    this.$stepRule    = this.$element.parent("div.steps");
    this.render();
  };

  TrackingRule.prototype.render = function(that) {
    this.$element.animate({
      width: this.$currentStep.offset().left - this.$stepRule.offset().left
    }, 500);
  };

  $.fn.trackingRule = function(option, args) {
    return this.each(function() {
      var $this = $(this);
      var $data = $this.data("dft.tracking-rule");
      var options = $.extend({}, TrackingRule.DEFAULTS, $this.data(), typeof option == 'object' && option);
      if (!$data) $this.data("dft.tracking-rule", ($data = new TrackingRule(this, options)));
      if (typeof option == 'string') $data[option][args];
      $data.init();
    });
  };

  $('[data-tracking-rule]').trackingRule();

})(jQuery);