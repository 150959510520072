$(function(){
    var $state_registration = $('#LegalPersonForm_state_registration'),
        $state_registration_exempt = $('#LegalPersonForm_state_registration_exempt'),
        $postCode = $('#AddressForm_postcode'),
        $state = $('#AddressForm_fk_customer_address_region');

    $.fn.checkLegalPersonStatus = function(){
        if(this.is(':checked')) {
            $state_registration.attr('disabled','disabled').val('');
        } else {
            $state_registration.removeAttr('disabled');
        }
    }

    $.fn.postCodeError = function(act) {
        this.parents('.field').find('.postCodeMismatch')[act]();
        return this;
    }

    $state_registration_exempt.checkLegalPersonStatus();

    $state_registration_exempt.change(function(){
        $(this).checkLegalPersonStatus();
    });

    VMasker( $('.phone-mask') ).maskPattern("(99) 99999-9999");
    VMasker( $postCode ).maskPattern('99999-999');

    $postCode.on('keyup',function() {
        var $t = $(this);

        if ($t.val().length === 9) {
            address.resolvePostcode('#AddressForm', $(this), function (data) {
                data = jQuery.parseJSON(data);

                if ( data && $('#legalUserHasAddress').length !== 0 && $state.val() != data.RegionId ) {
                    $t.postCodeError('show').val('')[0].focus();
                    return false;
                }

                $t.postCodeError('hide');
                return true;
            });
        } else if ($('#AddressForm_postcode').val().length < 9) {
            $('#AddressForm_fk_customer_address_region').find('option').removeAttr('disabled').css('display', '');
        }

    });

    $postCode.on('blur',function() {
        if ($(this).val().length < 9) {
            $('#AddressForm_address1').val('');
            $('#AddressForm_neighborhood').val('');
            $('#AddressForm_fk_customer_address_region').val('');
            $('#AddressForm_fk_customer_address_region').find('option').removeAttr('disabled').css('display', '');
            $('#AddressForm_city').val('');
        };
    });
});

$(document).ready(function(){
    if ( $('[data-postcode-check]').length > 0 ) {
        if ($('[data-postcode-check]').val().length == 9 ) {
            $('[data-postcode-check]').trigger('keyup');
        }
    };

    if($('#AddressForm_postcode').length > 0) {
        if ($('#AddressForm_postcode').val().length == 0) {
            $('#AddressForm_fk_customer_address_region').find('option').removeAttr('disabled').css('display', '');
        } else {
            $('#AddressForm_fk_customer_address_region').find('option').attr('disabled', 'disabled').css('display', 'none');
        }
    }
});
