(function() {
    var buttonId = '573i0000000WAt3';
    var url      = 'https://d.la2c1.salesforceliveagent.com/chat';

    // FOR DEVELOPMENT - CHANGE THE URL TO USE SALES FORCE TEST ENV.
    //var url      = 'https://d.la2c1cs.salesforceliveagent.com/chat';

    $(document).on('salesforce.loaded', function() {

        liveagent.addButtonEventHandler(buttonId, chatStatus);

        liveagent.init(url, '572i0000000W7KQ', '00Di0000000cSA5');

        // FOR DEVELOPMENT - CHANGE THE INFORMATION TO USE SALES FOR TEST ENV
        //liveagent.init(url, '572i0000000W7KQ', '00Df0000002N2vP');

        if (!window._laq) {
            window._laq = [];
        }

        window._laq.push(function(){
            liveagent.showWhenOnline(buttonId, $('.livechat-observer').get(0));
        });
    });

    $(document).on('click', '.chat-online', function() {
        liveagent.startChat(buttonId);
    });

    function chatStatus(event) {
        var chatButton = $('.icon-chat').parent();

        if (event === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
            chatButton.attr('title', chatButton.data('online-title'));
            chatButton.addClass('chat-online');
        }

        if (event === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
            chatButton.attr('title', chatButton.data('offline-title'));
            chatButton.removeClass('chat-online');
        }
    }
})();
