(function ($) {

    'use strict';

    $(document).ready(function () {
        var img = "";
        try {
            switch (typeof $ != "undefined" && typeof dataLayer != "undefined" && JSON.parse(dataLayer[0].brandID)[0]) {
                case 20970:
                    img = "/images/adidas-logos/adidasperformance.png";
                    break;
                case 27866:
                    img = "/images/adidas-logos/adidas.png";
                    break;
                case 21464:
                    img = "/images/adidas-logos/adidasoriginals.png";
                    break;
                default:
                    img = "";
                    break;
            }
        } catch (e) {
            img = "";
        }
        if (img != "") {
            var height = 45,
                padding = 20;
            if (window.innerWidth < 425) {
                height = 35;
                padding = 5;
            }
            $('[itemprop=brand]').html("<img src='" + img + "' style='max-height:" + height + "px;padding-bottom:" + padding + "px;max-width: 100px;' alt='adidas'>");
        }
    });

}(jQuery));