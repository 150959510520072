(function($){

    'use strict';

    var CartItemColor = function(element, options) {
        this.$element = $(element);
        this.options  = options;
    };

    CartItemColor.DEFAULTS = {};

    CartItemColor.prototype.updateRemoteUrl = function(sku) {
        var $cartItem = $('[data-cart-item="' + sku + '"]');
        var quantity = $cartItem.find('input[name="qty_'+ sku +'"]').val();
        var size = $cartItem.find('[data-cart-item-size]').text();

        $cartItem.find('[data-cart-item-color]').attr('href',
            '/cart/color/?sku_simple=' + sku + '&quantity=' + quantity + '&size=' + size
        );
    };

    CartItemColor.prototype.changeEditLink = function(response) {
        var $cartItemColor = $('[data-cart-item-color="' + response.item.sku_simple + '"]');

        if (response.item.has_more_colors) {
            $cartItemColor.show().removeClass('hide');
        } else {
            $cartItemColor.hide();
        }
    };

    CartItemColor.prototype.successMessageEditItem = function(params) {
        var $colorItem = $('[data-cart-item-more-colors="' + params.item.sku_simple + '"]');

        $colorItem.find('[data-cart-item-color-message="success"]').show();
        $colorItem.find('[data-cart-item-product-message="success"]').hide();

        this.eventsMessageModal($colorItem);
    };

    CartItemColor.prototype.successMessageAddItem = function(response) {
        var $colorItem = $('[data-cart-item-more-colors="' + response.item.sku_simple + '"]');

        $colorItem.find('[data-cart-item-color-message="success"]').hide();
        $colorItem.find('[data-cart-item-product-message="success"]').show();

        this.eventsMessageModal($colorItem);
    };

    CartItemColor.prototype.eventsMessageModal = function($colorItem) {
        $colorItem.find('[data-cart-item-color-message="buttons"]').hide();

        setTimeout(function() {
            $('.modal-2.is-open').modal('close');
        }, 2000);
    };

    CartItemColor.prototype.replaceItem = function() {
        var skuRelated = this.$element.data('sku-relateds').split(',')[0];
        var $cartItem = $('[data-cart-item="' + skuRelated + '"]');

        var params = {
            'item' : {
                'sku_related' : skuRelated,
                'sku_simple' : this.$element.data('sku-simple'),
                'quantity' : $cartItem.find('[name="qty_' + skuRelated + '"]').val(),
                'is_edit_messagem' : true
            }
        };

        $(document).trigger('dft.cart-remove.remove', [params]);
        $(document).one('dft.cart-remove.item-removed', function() {
            $(document).trigger('dft.cart-add.add', [params.item]);
        });

        this.successMessageEditItem(params);
    };

    CartItemColor.prototype.checkLastItem = function() {
        var $colorsItems = $('.is-open [data-cart-item-more-colors]');
        var that = this;

        $colorsItems.find('[data-cart="edit-color"]').each(function() {
            var skus = $(this).data('sku-relateds').split(',');

            if ($colorsItems.length === 1) {
                that.hideEditLink(skus);
            }
        });
    };

    CartItemColor.prototype.hideEditLink = function(skus) {
        for (var i = 0; i < skus.length; i += 1) {
            $('[data-cart-item-color="' + skus[i] + '"]').hide();
        }
    };

    $.fn.cartItemColor = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.cart-item-color');
            var options = $.extend({}, CartItemColor.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-item-color', (data = new CartItemColor(this, options)));

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('dft.cart-quantity.update', function(e, response) {
        $(e.currentTarget).cartItemColor('updateRemoteUrl', response.item.sku_simple);
        $(e.currentTarget).cartItemColor('changeEditLink', response);
    });

    $(document).on('click', '[data-cart="edit-color"]', function(e) {
        e.preventDefault();
        $(e.currentTarget).cartItemColor('replaceItem');
    });

    $(document).on('dft.cart-color.hide-edit-link', function(e, response) {
        $(e.currentTarget).cartItemColor('hideEditLink', response.item.relatives);
    });

    $(document).on('dft.cart-color.item-added', function(e, response) {
        $(e.currentTarget).cartItemColor('successMessageAddItem', response);
        $(e.currentTarget).cartItemColor('checkLastItem');
    });

})(jQuery);
