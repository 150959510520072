(function($){
    var Url = function() {}

    Url.prototype.getParam = function(name) {
        var results = new RegExp('[\?&amp;]' + name + '=([^&amp;#]*)').exec(window.location.href);
        return (results && results.length) ? results[1] : null;
    }

    $.url = function(method, args) {
        var data = $(window).data('dft.url');
        if (!data) $(window).data('dft.url', (data = new Url()));

        return data[method](args);
    };


})(jQuery);