window.routes = [];

(function() {

    'use strict';

    var Dispatcher = function(routes) {
        this.routes = routes;
    }

    Dispatcher.prototype.run = function(route) {
        var args = arguments.length ? Array.prototype.slice.call(arguments, 1) : [];
        (this.routes[route] || function() {}).apply(this, args);
    }

    window.Dispatcher = Dispatcher;

    var dispatcher = new Dispatcher(window.routes);
    $(document).on($('body').data('route') || 'index/index', function(){
        dispatcher.run($('body').data('route') || 'index/index', $('body').data());
    });

})();
