(function($) {

    'use strict';

    var ItemsPerRow = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.$target = $(this.$element.closest('.items-per-row').data('target'));
    };

    ItemsPerRow.DEFAULTS = {};

    ItemsPerRow.prototype.renderCollumns = function() {
        var perRow = this.$element.data('per-row');

        if (this.$target.is('.product-list-col-4') && perRow != 4) {
            this.$target.removeClass('product-list-col-4').addClass('product-list-col-' + perRow);
            perRow == 2 && this.loadImage();

        } else if (this.$target.is('.product-list-col-3') && perRow != 3) {
            this.$target.removeClass('product-list-col-3').addClass('product-list-col-' + perRow);
            perRow == 2 && this.loadImage();

        } else if (this.$target.is('.product-list-col-2') && perRow != 2) {
            this.$target.removeClass('product-list-col-2').addClass('product-list-col-' + perRow);
            this.loadImage();
        }

        this.setPreviousOption(perRow);
        this.$target.next('.loading-indicator').hide();
        this.$target.removeClass('hide');
        this.$element.closest('.items-per-row').find('.item-per-row').removeClass('act-item-per');
        this.$element.addClass('act-item-per');
    };

    ItemsPerRow.prototype.renderCollumnsNext = function() {
        if (this.$target.is('.product-list-col-4')) {
            this.$target.removeClass('product-list-col-4 hide').addClass('product-list-col-3');
            this.$element.children('span').removeClass('icon-two-col').addClass('icon-one-col');
            this.setPreviousOption(3);
        } else if (this.$target.is('.product-list-col-3')) {
            this.$target.removeClass('product-list-col-3 hide').addClass('product-list-col-4');
            this.$element.children('span').removeClass('icon-one-col').addClass('icon-two-col');
            this.setPreviousOption(4);
        }
    };

    ItemsPerRow.prototype.loadImage = function() {
        var $img = this.$target.find('.product-box .product-image');

        $img.each(function() {
            var newImg = $(this).attr('data-toggle-img');
            var oldImg = $(this).attr('src');

            var newHover = $(this).parent().attr('data-hover-toggle');
            var oldHover = $(this).parent().attr('data-hover-img');

            $(this).parent().attr('data-hover-toggle', oldHover)
                .attr('data-hover-img', newHover);

            $(this).attr('data-toggle-img', oldImg);
        });
    };

    ItemsPerRow.prototype.hasLocalStorage = function() {
        return ('localStorage' in window && window.localStorage !== null);
    };

    ItemsPerRow.prototype.getPreviousOption = function() {
        return (this.hasLocalStorage()) ? localStorage.getItem('dft.itemsPerRow') : false;
    };

    ItemsPerRow.prototype.setPreviousOption = function(option) {
        if (this.hasLocalStorage()) {
            localStorage.setItem('dft.itemsPerRow', option);
        }
    };

    ItemsPerRow.prototype.showPreviousOption = function() {
        var itemTarget = $('.items-per-row .item-per-row')[1]; // default option
        var previousOption = this.getPreviousOption();

        if (previousOption) {
            itemTarget = '[data-per-row="' + previousOption  + '"]';
        }

        $(itemTarget).itemsPerRow('renderCollumns');
    };

    ItemsPerRow.prototype.showPreviousOptionMobile = function() {
        var $target = $('.item-per-row-touch');
        var previousOption = this.getPreviousOption();
        this.$target.next('.loading-indicator').hide();
        this.$target.removeClass('hide');

        if (previousOption == 4) {
            $target.itemsPerRow('renderCollumnsNext');
        }
    };

    ItemsPerRow.prototype.loadImageAjax = function() {
        var previousOption = this.getPreviousOption();
        if (previousOption) this.loadImage();
    };

    $.fn.itemsPerRow = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.itemsPerRow');
            var options = $.extend({}, ItemsPerRow.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.itemsPerRow', (data = new ItemsPerRow(this, options)));
            }

            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('click', '.items-per-row .item-per-row', function(e) {
        e.preventDefault();
        $(e.currentTarget).itemsPerRow('renderCollumns');
    });

    $(document).on('click ', '.items-per-row .item-per-row-touch', function(e) {
        e.preventDefault();
        $(e.currentTarget).itemsPerRow('renderCollumnsNext');
    });

    $(document).on('dft.catalog-ajax.loaded', function() {
        $('.item-per-row:visible').itemsPerRow('loadImageAjax');
    });

})(jQuery);
