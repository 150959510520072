(function($){

    'use strict';

    var CartCheckSale = function(element, options) {
        this.$element           = $(element);
        this.options            = options;
        this.currentStatus      = $('[data-cart-check-sale]').data('cart-check-sale');
        this.$cartHeader        = $('[data-check-sale=cart-header]');
        this.$teleSalesAlert    = $('[data-check-sale=teleSalesAlert]');
        this.$items             = $('[data-check-sale=item]');
    };

    CartCheckSale.prototype.update = function (can_sale) {
         if (can_sale != this.currentStatus) {
             this.toggle(can_sale);
             this.currentStatus = can_sale;
        }
        if (!can_sale) {
            $(document).trigger("dft.go-to-top");
        }
    };

    CartCheckSale.prototype.toggle = function(can_sale) {
        this.$cartHeader.toggleClass('col-md-8').toggleClass('col-md-12');
        this.$items.toggleClass('hide', !can_sale);
        this.$teleSalesAlert.toggleClass('hide', can_sale);
    };

    $.fn.cartCheckSale = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.cart-check-sale');
            var options = $.extend({}, CartCheckSale.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-check-sale', (data = new CartCheckSale(this, options)));

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('dft.cart-check-sale.update', function (e, response) {
        $(this).cartCheckSale('update', response.can_sale);
    });

})(jQuery);

