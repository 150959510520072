(function($) {

    'use strict';

    var CatalogIndexAction = function() {};
    CatalogIndexAction.prototype = new window.dft.ActionController();

    CatalogIndexAction.prototype.run = function() {
        var $catalogProducts = $('[data-catalog-products]');
        var $catalogProductsImages = $catalogProducts.find('[data-lazyload]');
        $('.product-image').on('error', $.proxy(this, 'errorSrcImg'));
        $('#ordenation-selectbox').selectbox('current');

        $catalogProductsImages.lazyload({
            threshold : 600,
            effect : 'fadeIn',
        });

        this.initItemsPerRow();
        this.clearLastFilterStatus();
        this.initBackToShop();
        this.initFiltersMobile();
        this.fixPaginationOnMobile();

        $(document).trigger('dft.catalog-ajax.load');
        $(document).trigger('dft.add-coupon');
        $(document).trigger('dft.scroll-event.active');
    };

    CatalogIndexAction.prototype.ajaxRun = function() {
        $('.product-image').on('error', $.proxy(this, 'errorSrcImg'));
        $('#ordenation-selectbox').selectbox('current');

        this.initItemsPerRow();
        this.initBackToShop();
        this.initFiltersMobile();
        this.fixPaginationOnMobile();

        $(document).trigger('dft.scroll-event.active');
    };

    CatalogIndexAction.prototype.errorSrcImg = function(e) {
        $(e.currentTarget).attr('src', $(e.currentTarget).attr('data-error-src'));
    };

    CatalogIndexAction.prototype.initFiltersMobile = function(e) {
        $('[data-catalog-mobile-filter]').removeClass('is-disabled');
    };

    CatalogIndexAction.prototype.initBackToShop = function(e) {
        $(document).trigger('dft.back-to-shop');
    };

    CatalogIndexAction.prototype.initItemsPerRow = function(e) {
        $('.item-per-row:visible').itemsPerRow('showPreviousOption');
        $('.item-per-row-touch:visible').itemsPerRow('showPreviousOptionMobile');
    };

    CatalogIndexAction.prototype.clearLastFilterStatus = function(e) {
        localStorage.removeItem('dft.filterStatus');
        $(document).trigger('initCatalogFilters');
    };

    CatalogIndexAction.prototype.fixPaginationOnMobile = function(e) {
        if ($('.mobile .pagination-separator').length > 1) {
            $('.mobile .pagination-separator:last').prev().addClass('hide');
        }
    };

    routes['catalog/index'] = function() {
        (new CatalogIndexAction()).run();
    };

    $(document).on('dft.catalog-ajax.loaded', $.proxy((new CatalogIndexAction()), 'ajaxRun'));

    $(document).trigger('catalog/index');

})(jQuery);
