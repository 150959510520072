(function($) {

    'use strict';

    var ProductRuler = function(element, options) {
        this.$element  = $(element);
        this.$overflow = this.$element.find('.product-guide-overflow');
        this.$ruler    = this.$overflow.find('.product-guide-ruler');
        this.$item     = this.$ruler.find('.product-guide-item');
        this.done      = true;
        this.options   = options;
        this.bind();
    };

    ProductRuler.DEFAULTS = {
        done: true
    };

    ProductRuler.prototype.bind = function() {
        var il = this.$item.not('.product-template').length;
        this.$ruler.css('width', il * 47 + '%');
        this.$item.css({
            width: 100 / il + '%',
            display: 'block'
        });
        this.$overflow.scrollLeft(200);
        this.scrollto();
        $(window).scroll($.proxy(this,'scrollto'));
    };

    ProductRuler.prototype.scrollto = function() {
        if (this.$overflow.is(':in-viewport') && this.options.done) {
            this.$overflow.animate({
                scrollLeft: 0
            }, 400);
            this.options.done = false;
        }
    };

    $.fn.ruler = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.product-ruler');
            var options = $.extend({}, ProductRuler.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.product-ruler', (data = new ProductRuler(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('dft.recommendation-done', function() {
        $('[data-product-guide].has-items').ruler();
    });

})(jQuery);