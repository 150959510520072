(function($) {
  var $rangeEl = $('.nps-mobile .nps-rate-value-range');
  var $rateEl = $('.nps-mobile input[type=radio][name=rate]');

  function getLabelContent(val) {
    return $('.nps-mobile .nps-rate-label').filter(function() {
      return $(this).text() == val;
    });
  }

  function setSelectedText(text) {
    $('.selected-nps-rate').text(text || "");
  }

  function init() {
    getLabelContent(0).siblings("input[type=radio]").addClass("nps-rated");
    setSelectedText(getLabelContent(0).siblings('.nps-legend').text());
  }

  $rateEl.change(function() {
    $rangeEl.val(parseInt($rateEl.filter(":checked").val())).trigger("change");
  });

  $rangeEl.change(function() {
    var val = ($(this).val() - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min'));
    getLabelContent($(this).val()).click();
    setSelectedText(getLabelContent($(this).val()).siblings('.nps-legend').text());
    
    $(this).css('background-image', '-webkit-gradient(linear, left top, right top, '
      + 'color-stop(' + val + ', #15a18b), '
      + 'color-stop(' + val + ', #B6B6B6)'
      + ')');
  });

  

  init();


})(jQuery);