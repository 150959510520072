(function ($) {
    'use strict';

    function prepareDataLayerToAddToCartEvent(dataLayer) {
        var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
        var _category = _dataLayerFiltered.Pcat.split(',');
        _category.pop();
        var discount = Number(_dataLayerFiltered.original_price - _dataLayerFiltered.price);

        dataLayer.push({
            'event': 'add_to_cart_ga4',
            'ecommerce': {
                'items': [{
                    'item_id': _dataLayerFiltered.sku.toLowerCase(),
                    'item_name': _dataLayerFiltered.productName.toLowerCase(),
                    'item_brand': _dataLayerFiltered.brand.toLowerCase(),
                    'item_category': _category[0] != undefined ? _category[0].trim().toLowerCase() : '',
                    'item_category2': _category[1] != undefined ? _category[1].trim().toLowerCase() : '',
                    'item_category3': _category[2] != undefined ? _category[2].trim().toLowerCase() : '',
                    'price': Number(_dataLayerFiltered.price),
                    'discount': Number(discount.toFixed(2)),
                    'index': 1,
                    'item_list_name': 'product detail - no list',
                    'item_variant': _dataLayerFiltered.color.toLowerCase(),
                    'item_affiliation': 'dafiti br - ' + _dataLayerFiltered.marketplaceSeller.trim().toLowerCase(),
                    'quantity': '1'
                }]
            }
        });
    }

    function prepareDataLayerToAddToWishlistEvent(dataLayer) {
        var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
        var _category = _dataLayerFiltered.Pcat.split(',');
        _category.pop();
        var discount = Number(_dataLayerFiltered.original_price - _dataLayerFiltered.price);

        dataLayer.push({
            'event': 'add_to_wishlist_ga4',
            'ecommerce': {
                'items': [{
                    'item_id': _dataLayerFiltered.sku.toLowerCase(),
                    'item_name': _dataLayerFiltered.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_brand': _dataLayerFiltered.brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_category': _category[0] != undefined ? _category[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category2': _category[1] != undefined ? _category[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category3': _category[2] != undefined ? _category[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'price': Number(_dataLayerFiltered.price),
                    'discount': Number(discount.toFixed(2)),
                    'index': 1,
                    'item_list_name': 'product detail - no list',
                    'item_variant': _dataLayerFiltered.color.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_affiliation': 'dafiti br - ' + _dataLayerFiltered.marketplaceSeller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'quantity': 1
                }]
            }
        });
    }


    function prepareDataLayerToRemoveFromWishlistEvent(dataLayer) {
        var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
        var _category = _dataLayerFiltered.Pcat.split(',');
        _category.pop();
        var discount = Number(_dataLayerFiltered.original_price - _dataLayerFiltered.price);

        dataLayer.push({
            'event': 'remove_from_wishlist_ga4',
            'ecommerce': {
                'items': [{
                    'item_id': _dataLayerFiltered.sku.toLowerCase(),
                    'item_name': _dataLayerFiltered.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_brand': _dataLayerFiltered.brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_category': _category[0] != undefined ? _category[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category2': _category[1] != undefined ? _category[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category3': _category[2] != undefined ? _category[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'price': Number(_dataLayerFiltered.price),
                    'discount': Number(discount.toFixed(2)),
                    'index': 1,
                    'item_list_name': 'product detail - no list',
                    'item_variant': _dataLayerFiltered.color.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_affiliation': 'dafiti br - ' + _dataLayerFiltered.marketplaceSeller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'quantity': 1
                }]
            }
        });
    }

    function prepareDataLayerToPageViewEvent(dataLayer) {
        dataLayer.push({
            'event': 'page_view_ga4',
            'page_path': document.location.pathname,
            'page_location': document.location.href,
            'page_title': 'ecommerce - product detail'
        });
    }

    function prepareDataLayerToViewItemEvent(dataLayer) {
        var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
        var _category = _dataLayerFiltered.Pcat.split(',');
        _category.pop();
        var discount = Number(_dataLayerFiltered.original_price - _dataLayerFiltered.price);

        dataLayer.push({
            'event': 'view_item_ga4',
            'ecommerce': {
                'items': [{
                    'item_id': _dataLayerFiltered.sku.toLowerCase(),
                    'item_name': _dataLayerFiltered.productName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_brand': _dataLayerFiltered.brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_category': _category[0] != undefined ? _category[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category2': _category[1] != undefined ? _category[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'item_category3': _category[2] != undefined ? _category[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                    'price': Number(_dataLayerFiltered.price),
                    'discount': Number(discount.toFixed(2)),
                    'index': 1,
                    'item_list_name': 'product detail - no list',
                    'item_variant': _dataLayerFiltered.color.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'item_affiliation': 'dafiti br - ' + _dataLayerFiltered.marketplaceSeller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                    'quantity': 1
                }]
            }
        });
    }

    var PdpEventsGA4 = function () {
        this.prepareDataLayerToAddToCartEvent = function (dataLayer) {
            return prepareDataLayerToAddToCartEvent(dataLayer);
        };

        this.prepareDataLayerToPageViewEvent = function (dataLayer) {
            return prepareDataLayerToPageViewEvent(dataLayer);
        };

        this.prepareDataLayerToAddToWishlistEvent = function (dataLayer) {
            return prepareDataLayerToAddToWishlistEvent(dataLayer);
        };

        this.prepareDataLayerToRemoveFromWishlistEvent = function (dataLayer) {
            return prepareDataLayerToRemoveFromWishlistEvent(dataLayer);
        };

        this.prepareDataLayerToViewItemEvent = function (dataLayer) {
            return prepareDataLayerToViewItemEvent(dataLayer);
        };

    };

    window.PdpEventsGA4 = PdpEventsGA4;

    $(document).on('click', '.buy-pdp', function () {
        var pdpEventsGA4 = new PdpEventsGA4();
        pdpEventsGA4.prepareDataLayerToAddToCartEvent(dataLayer);
    });
    $(document).on('click', '[data-wishlist-item]', function () {
        var pdpEventsGA4 = new PdpEventsGA4();
        
        if ($(this).attr("data-wishlist-url") !== undefined && $(this).attr('data-wishlist-url').includes('remove')) {
            pdpEventsGA4.prepareDataLayerToRemoveFromWishlistEvent(dataLayer);
        }else{
            pdpEventsGA4.prepareDataLayerToAddToWishlistEvent(dataLayer);
        }

    });


    window.PdpEventsGA4 = PdpEventsGA4;

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];
    if (_dataLayerFiltered != undefined && _dataLayerFiltered.ET == 'productpage') {
        var pdpEventsGA4 = new PdpEventsGA4();
        pdpEventsGA4.prepareDataLayerToPageViewEvent(dataLayer);
        pdpEventsGA4.prepareDataLayerToViewItemEvent(dataLayer);
    }

})(jQuery);
