(function ($) {
    "use strict";

    var CartQuantity = function (element, options) {
        this.$element = $(element);
        this.options = options;
    };

    CartQuantity.DEFAULTS = {};

    CartQuantity.prototype.update = function () {
        var $cartItem = this.$element.closest("[data-cart-item]");
        var args = {
            sku_simple: this.$element
                .closest("[data-cart-item-sku]")
                .data("cart-item-sku"),
            quantity: this.$element.data("value") || this.$element.val(),
        };

        $cartItem.find("[data-qty]").val(args.quantity);
        $.getJSON("/cart/index/update", args, $.proxy(this, "loaded"));
    };

    CartQuantity.prototype.loaded = function (response) {
        $(document).trigger("dft.cart-quantity.update", [response]);
        $(document).trigger("dft.cart-size.update", [response]);
        document.getElementsByClassName(
            "cart-preview-item-count"
        )[0].innerText =
            response.total.quantity.default + response.total.quantity.seller;
    };

    $.fn.cartQuantity = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data("dft.cart-quantity");
            var options = $.extend(
                {},
                CartQuantity.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );

            if (!data) {
                $this.data(
                    "dft.cart-quantity",
                    (data = new CartQuantity(this, options))
                );
            }

            if (typeof option == "string") data[option](args);
        });
    };

    $(document).on("click", "[data-cart-item-quantity]", function (e) {
        e.preventDefault();
        $(this).cartQuantity("update");
    });

    $(document).on("change", "[data-cart-item-quantity-mobile]", function (e) {
        $(this).find(":selected").cartQuantity("update");
    });
})(jQuery);
