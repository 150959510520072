(function($) {

    'use strict';

    var Tab = function(element, options) {
        this.$element = $(element);
        this.options = options;
    }

    Tab.DEFAULTS = {}

    Tab.prototype.show = function() {
        var $this = this.$element,
            $ul = $this.closest('.tab'),
            selector = $this.data('target');

        if (!selector) {
            selector = $this.attr('href');
            selector = selector && selector.replace(/.*(?=#[^\s]*$)/, '');
        }

        if ($this.parent('.brands-list-item').hasClass('active')) return

        var isAll = selector == '#all' ? true : false,
            $target = $(selector);

        this.activate($this.closest('.tab-item'), $ul);
        this.activate($target, $target.parent(), isAll);
    }

    Tab.prototype.activate = function(element, container, isAll) {
        var $activeContainer = container.find('> .active'),
            $iptSearch = $('[data-search-input]');

        $activeContainer.removeClass('active');
       
        isAll ? container.children().filter( ":not(#brands-no-results)" ).addClass('active') : element.addClass('active');
        container.find('.hide').removeClass('hide');

        $iptSearch.val('');

    }

    $.fn.tab = function(option) {
        return this.each(function() {
            var $this = $(this),
                data = $this.data('dft.tab'),
                options = $.extend({}, Tab.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.tab', (data = new Tab(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).on('click', '[data-toggle=tab]', function(e) {
        e.preventDefault();
        var $tabItemLink = $(e.currentTarget);

        $tabItemLink.parent().hasClass('block') || $tabItemLink.tab('show');

    });

    $(window).load(function() {
        var $tabItemLink = $('.tab-link');

        $tabItemLink.each(function() {
            var selector = $(this).data('target');

            if (!selector) {
                selector = $(this).attr('href')
                selector = selector && selector.replace(/.*(?=#[^\s]*$)/, '')
            }

            if (!$(selector).length) $(this).parent().addClass('block');
        })
    });

})(jQuery); 
