(function($){
	'use strict';

	var Newsletter = function(element,options){
		this.$element = $(element);
		this.options = options;
		this.form = false;

		this.$element.on('form:loaded', '[data-newsletter-form]', $.proxy(this, 'loadedSubscription'));
		this.$element.on('click', '[data-associate]', $.proxy(this, 'associateData'));
	}

	Newsletter.prototype.load = function(){
		if (!this.form){
			$.ajax({
				method: "get",
				url: '/newsletter/getNewsletterBox',
				data: { 
					source : this.options.source
				},
				success: $.proxy(this, 'loaded'),
				error: function(){
					this.$element.trigger("newsletter.loadFail");
				}
			})
		}
	}
	Newsletter.prototype.loaded = function(data){
		this.form = true;
		this.$element.append(data);
		this.$element.trigger('newsletter.form:loaded');
	}

	Newsletter.prototype.loadedSubscription = function(e,result){
		result = $.parseJSON(result);
		if(result.validation){
			// this.$element.find('[data-newsletter-coupon]').html(result.coupon);
			this.$element.find('[data-response-hide]').hide();	
			this.$element.find('[data-response-show]').addClass('is-active');
			this.$element.trigger('newsletter.subscription:success');
		}else{
			if (this.options.customResponse === false){
				this.$element.find('[data-newsletter-error]').html(result.error);
			}
			this.$element.find('.popover-arrow').popover('open');
			this.$element.trigger('newsletter.subscription:failure');
		}
	}
	Newsletter.prototype.associateData = function(e){
		var $element = $(e.currentTarget);
		var name = $element.data('associate');
		var value = $element.val();
		this.$element.find('[name="'+ name +'"]').val(value);
	}

	Newsletter.DEFAULTS = {
		customResponse : false,
		source : "footer"
	}

	$.fn.Newsletter = function(option, args) {
		return this.each(function() {

			var $this = $(this);
			var data = $this.data('dft.newsletter');
			var options = $.extend({}, Newsletter.DEFAULTS, $this.data(), typeof option == 'object' && option);

			if (!data) $this.data('dft.newsletter', (data = new Newsletter(this, options)));
            if (typeof option == 'string') data[option](args);
            else if (options.show) data.open();
		});
	}

	$(document).ready(function(){
		$('[data-newsletter]').one('click','[data-associate]',function(e){
			e.preventDefault();
			$(this).parents('[data-newsletter]').Newsletter();
			$(this).trigger('click');
		});
	});

})(jQuery);