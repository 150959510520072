(function($) {
    'use strict';

    var OpenHistory = function(element, options) {
        this.$element = $(element);
        this.$targetElement = $("#" + this.$element.data("openHistory"));
        this.options = options || {};
        if (this.$targetElement.length) {
            if (this.$element.hasClass("opened")) {
                this.options.openedHeight = this.$targetElement.height();
            } else {
                this.options.closedHeight = this.$targetElement.height();
            }
        }
        this.setInitialHeight();
    };

    OpenHistory.DEFAULTS = {
        lessTitle: '',
        moreTitle: '',
        closedHeight: '',
        openedHeight: '',
        minItemsSize: 1
    };

    OpenHistory.prototype.init = function() {
        this.setInitialHeight();
        this.$element.click($.proxy(this, 'toggle'));
    };

    OpenHistory.prototype.setInitialHeight = function() {
        var li = this.$targetElement.find("li");
        if(li.length){
            this.options.closedHeight = li.outerHeight(true) * this.options.minItemsSize;
            this.options.openedHeight = li.toArray().reduce(function(sum,element) {
                return sum + $(element).outerHeight(true);
            }, 0);
            if (li.length >= 2) {
                this.$targetElement.addClass('closed');
            }
            this.$targetElement.css({
                maxHeight: this.options.closedHeight
            });
        }
    };

    OpenHistory.prototype.toggle = function() {
        if (this.$element.hasClass('opened')) {
            this.$targetElement.animate({
                maxHeight: this.options.closedHeight
            }, 300, $.proxy(this, 'close'));
        } else {
            this.$targetElement.animate({
                maxHeight: this.options.openedHeight
            }, 300, $.proxy(this, 'open'));
        }
    };

    OpenHistory.prototype.open = function() {
        this.$targetElement.removeClass('closed');
        this.$element.removeClass("closed");
        this.$element.addClass("opened");
        this.$element.text(this.options.lessTitle);
    };

    OpenHistory.prototype.close = function() {
        var li = this.$targetElement.find("li");
        if (li.length >= 2) {
            this.$targetElement.addClass('closed');
        }
        this.$element.removeClass("opened");
        this.$element.addClass("closed");
        this.$element.text(this.options.moreTitle);
    };

    $.fn.openHistory = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var $data = $this.data("dft.open-history");
            var options = $.extend({}, OpenHistory.DEFAULTS, $this.data(), typeof option == 'object' && option);
            if (!$data) $this.data("dft.open-history", ($data = new OpenHistory(this, options)));
            if (typeof option == 'string')
                $data[option][args];
            $data.init();
        });
    };

    $(document).ready(function() {
        $('[data-open-history]').openHistory();
    });

})(jQuery);
