(function($){
    'use strict';

    var RatingsAndReviews = function(element){
        this.$element = $(element);
        this.$buttonLoadForm = this.$element.find('[data-render-form]');
        this.listenSubmit();

    }

    RatingsAndReviews.prototype.listenSubmit = function(){
        this.$element.on('form:loaded', '[data-form]', $.proxy(this, 'parseData'));
    };

    RatingsAndReviews.prototype.parseData = function(e,data){
        var result = $.parseJSON(data);
        if (result.success){
            var $returnBlock = this.$element.find('.review-return');
            $returnBlock.find('.review-message').text(result.msg);
            this.$element.find('form').addClass('fade');
            $returnBlock.removeClass('hide');
            this.$element.find('form').form('lockSubmit');
            
            setTimeout(function(){
                $('[data-dismiss]').modal('close');
            },5000);
        };
    };

    $.fn.RatingsAndReviews = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.ratings-and-reviews');

            if (!data) $this.data('dft.ratings-and-reviews', (data = new RatingsAndReviews(this)));
            if (typeof option == 'string') data[option]();
        });
    };

    $(document).one('modal.rating-form-box',function(){
        $('[data-reviews]').RatingsAndReviews();
    });

    $(document).ready(function(){

       if (encodeURI(dft.getQuerystring("rOpen"))){

               var uname = encodeURI(dft.getQuerystring("name"));
               var uemail = encodeURI(dft.getQuerystring("email"));

               if (!uemail.isEmail()){
                       uemail = "";
               }
               var url = $('[data-activateratings]').data('remote') + "&name=" + uname + "&email=" + uemail;
               $('[data-activateratings]').data('remote',url);
               $('[data-activateratings]').trigger('click');
               window.location.hash="#ratings-reviews";
       }
    });
})(jQuery);
