'use strict';

function addOrUpdateParam(url, param, value) {
    var regex = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');
    var separator = url.indexOf('?') !== -1 ? '&' : '?';

    if (url.match(regex)) {
        return url.replace(regex, '$1' + param + '=' + value + '$2');
    } else {
        return url + separator + param + '=' + value;
    }
}

function removeUrlParameter(url, param) {
    var regex = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');

    if (url.match(regex)) {
        url = url.replace(regex, function (_, p1, p2) {
            return p1 === '?' ? p1 : p2;
        });

        if (url[url.length - 1] === '?') {
            url = url.substring(0, url.length - 1);
        }

        return url;
    } else {
        return url;
    }
}

function simulateAnchorClick(anchorElement, url) {
    anchorElement.href = url;
    anchorElement.click();
}

function attachToggleSwitchListener() {
    var linkFreeShipping = document.getElementById('link-free-shipping');
    var input_toggle_switch = document.querySelector('toggle-switch-component[name="toggle-switch-free-shipping"]');

    if (input_toggle_switch) {
        input_toggle_switch.addEventListener('changeToggleSwitch', function(event) {
            var isChecked = event.detail.isChecked;
            var url_atual = window.location.href;

            if (isChecked) {
                url_atual = addOrUpdateParam(url_atual, "enable_free_shipping", "true")
                simulateAnchorClick(linkFreeShipping, url_atual);
            } else {
                url_atual = removeUrlParameter(url_atual, 'enable_free_shipping');
                simulateAnchorClick(linkFreeShipping, url_atual);
            }
        });
    }
}

attachToggleSwitchListener();
