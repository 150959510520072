(function ($)  {
    $( document ).ready(function() {
        $(".carousel").each(function (index, item) {
            if($(item).find(".carousel-item:hidden").length != 0) {
                $(item).find(".carousel-arrow").show();
            } 
        })
    });
})(jQuery);   

