$(document).ready(function(){
    cookietotal = 0;
    $.each(document.cookie.split(/; */), function() {
        var splitCookie = this.split('=');
        cookietotal = cookietotal+splitCookie[1];
    });

    if(encodeURIComponent(cookietotal).length>3000){
        $.each(document.cookie.split(/; */), function() {
            var splitCookie2 = this.split('=');
            if(splitCookie2[0].match('Test')){
                document.cookie = splitCookie2[0]+'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
        });
    }
    
    //calculate cookie size (UTF-8 encoding)
    var cookie_size = encodeURI(document.cookie).split(/%..|./).length - 1;        
    // if cookie size > 6k notify
    if(cookie_size >= 6144) _gaq.push(['_trackEvent', 'cookies', 'cookie_size',cookie_size]);    
    
});