(function($) {
    'use strict';

    var SizeGuide = function(element, options){
        this.$element = $(element);
        this.options = options;
        this.genderId = 0;
        this.categoryId = this.options.category;
        this.brandId = this.options.brand;
        this.categoryName = "";
        this.brandName = "";
        this.changeSelectBoxClass = false;
        this.classColored = ' class="colored"'

        this.btnSubmit = this.$element.find('[data-action=submit]');

        this.$element.on('click','[data-action=submit]', $.proxy(this, 'submitButton'));

        this.LockSubmit();
        this.ListenChanges();

        if (this.categoryId != 0){
            this.catalogResults();
        }else{
            this.init();            
        }
    }

    SizeGuide.DEFAULTS = {
        category : 0,
        brand : 0
    }

    SizeGuide.prototype.ListenChanges = function(){
        this.$element.on('change' ,'[data-field]', $.proxy(this, 'markSelected'));
        this.$element.on('change', '[data-field=gender]', $.proxy(this, 'changeGender'));
        this.$element.on('change', '[data-field=category]', $.proxy(this, 'changeCategory'));
        this.$element.on('change' ,'[data-field=brand]', $.proxy(this, 'changeBrand'));

        this.cursorDefault();
    }
    SizeGuide.prototype.LockChanges = function(){
        this.$element.off('change', '[data-field=gender]', $.proxy(this, 'changeGender'));
        this.$element.off('change', '[data-field=category]', $.proxy(this, 'changeCategory'));
        this.$element.off('change', '[data-field=brand]', $.proxy(this, 'changeBrand'));

        this.cursorLoading();
    }
    SizeGuide.prototype.init = function(e){

    }

    SizeGuide.prototype.cursorLoading = function(){
        $('body, .size-guide *').addClass('wait');
    }
    SizeGuide.prototype.cursorDefault = function(){
        $('body, .size-guide *').removeClass('wait');
    }

    SizeGuide.prototype.catalogResults = function(e) {
        this.$element.addClass('loading');
        this.$element.trigger('dft.size-guide.resize');
        
        var that = this;
        
        $.ajax({
            url: '/catalog/dynamicSizeGuideOpen',
            data: {category: this.categoryId, brand: this.brandId},
            dataType: 'json',
            success: function(data){
                if (data.selectbox){
                    $('.selectbox-block').remove();
                    $.each(data.selectbox, function(index,value){
                        var field = value.type == "subcategory" ? "category" : value.type; 
                        that.RenderDropdown(value.name, field, value.required, value.options, value.selected);
                    })

                    that.genderId = that.$element.find('[data-field=gender]').find('.is-selected').data('value');
        
                    //var $categorybox = that.$element.find('[data-field=category]').last();
                    //$categorybox.selectbox('select',$categorybox.find('.is-selected'));
                    

                    if(data.table){
                         that.categoryName = that.$element.find('.selectbox-category').find('[data-selected]').last().find('.is-selected').text();
                         that.brandName = that.$element.find('.selectbox-brand').find('[data-selected]').last().find('.is-selected').text();
                         that.ReleaseSubmit();
                         that.RenderTable(data.table);
                    }
                    that.$element.removeClass('loading');
                    that.$element.trigger('dft.size-guide.resize');
                }else{
                    that.$element.removeClass('loading');
                }
            }
        });      
    }
    SizeGuide.prototype.changeGender = function(e) {
        if( $(e.currentTarget).data('selected') == ''){
            this.genderId = 0;
            return false;
        }

        $(e.currentTarget).parent('.selectbox-block').nextAll('.selectbox-category, .selectbox-brand').remove();

        this.genderId = $(e.currentTarget).data('selected');
        var that = this;

        this.LockChanges();
        this.LockSubmit();
        
        $.ajax({
            url: '/catalog/dynamicSizeGuideAjax',
            data: {gender: this.genderId},
            dataType: 'json',
            success: function(data){
                
                if (that.$element.find('[data-field=category]').length == 0)
                    that.RenderDropdown('Categoria', 'category', true, data.categories, null);

                that.$element.trigger('dft.size-guide.resize');
                that.ListenChanges();
            }
        });   
    }
    SizeGuide.prototype.changeCategory = function(e) {
        var $currentTarget = $(e.currentTarget).data('selected');
        if( $currentTarget == ''){
            var $prevSelection = $(e.currentTarget).parent('.selectbox-block').prev('.selectbox-block');
            this.categoryId = $prevSelection.find('.selectbox-2').data('selected');
            this.categoryName = $prevSelection.find('.is-selected').text();
        }else{
            this.categoryId = $(e.currentTarget).data('selected');    
            this.categoryName = $(e.currentTarget).find('.is-selected').text();
        }
        
        var that = this;

        $(e.currentTarget).parent('.selectbox-block').nextAll('.selectbox-category').remove();

        this.LockChanges();
        that.LockSubmit();
        
        $.ajax({
            url: '/catalog/dynamicSizeGuideAjax',
            data: {gender: this.genderId, category: this.categoryId},
            dataType: 'json',
            success: function(data){
                
                if(data.categories && data.categories.length !== 0 && $currentTarget != "")
                    that.RenderDropdown('Subcategoria','category', false, data.categories, null);
                
                if (that.$element.find('[data-field=brand]'))
                    that.$element.find('.selectbox-brand').remove();

                that.brandId = 0;
                that.brandName = "";
                if(data.brands)
                    that.RenderDropdown('Marca','brand', false,data.brands, null);
                
                that.$element.trigger('dft.size-guide.resize');
                that.ListenChanges();
                var isRequired = $(e.currentTarget).data('required');
                if( isRequired ){
                   that.LockSubmit();
                }else{
                    that.ReleaseSubmit();
                }
            }

        });
    }
    SizeGuide.prototype.changeBrand = function(e){
        if( $(e.currentTarget).data('selected') == ''){
            this.brandId = 0;
            this.brandName = "";    
            return false;
        }
        this.brandId = $(e.currentTarget).data('selected');
        this.brandName = $(e.currentTarget).find('.is-selected').text();
    }
    SizeGuide.prototype.RenderDropdown = function(name,field,isRequired,data, selected){
        var artigo = 'uma';
        var selectBoxClass = ' right';
        if (field == 'gender') {
            artigo = 'um';
        }
        if (this.changeSelectBoxClass) {
            selectBoxClass = ' right';
            this.changeSelectBoxClass = false;
        } else {
            selectBoxClass = '';
            this.changeSelectBoxClass = true;
        }

        var required = isRequired ? " *" : "";
        var attrSelected = selected == null ? "" : " data-selected";
        var template =  "<div class=\"selectbox-"+field+selectBoxClass+" selectbox-block\">" +
                        "<label class=\"selectbox-label\">"+ name + required + "</label>" + 
                        "<div class=\"selectbox-2 popover-click\" data-field=\""+ field +"\" data-required=\""+ isRequired +"\" data-trigger=\"click\" "+ attrSelected +" >" + 
                        "<i class=\"selectbox-icon icon-chevron-down\" data-toggle=\"popover\"></i>" + 
                        "<div class=\"selectbox-option selectbox-current\" data-toggle=\"popover\">Selecione " + artigo + " "+ name  +"</div>" +
                        "<ul class=\"selectbox-options selectbox-options-scroll popover\">" + 
                            this.RenderOptions(data, selected) + 
                        "</ul>" +                       
                        "</div>" +
                        "</div>";
        var $selectbox = $(template);
        
        if (selected !== null){
            $selectbox.selectbox('select',$selectbox.find('.is-selected'));    
            $selectbox.find('.selectbox-2').addClass('selected');
        }
        
        if(field == 'category' && this.$element.find('.selectbox-category').length != 0){ 
            $selectbox.insertAfter(this.$element.find('.selectbox-category').last());
        }else{
            this.$element.find('.size-guide-select').append($selectbox);    
        }
        
    }    
    SizeGuide.prototype.RenderOptions = function(data, selected){
        var activeClass = " is-selected is-active";
        var options = "";
        if (selected == 0){
            options += "<li class=\"selectbox-option"+ activeClass +"\" data-value=\"\"></li>"
        } else {
            options += "<li class=\"selectbox-option\" data-value=\"\">Selecione...</li>"
        }
        
        $.each(data,function(value,text){
            if (selected == value){
                options += "<li class=\"selectbox-option"+ activeClass +"\" data-value=\""+value+"\">"+text+"</li>"; 
            } else {
                options += "<li class=\"selectbox-option\" data-value=\""+value+"\">"+text+"</li>"; 
            }   
        });

        return options;   
    }
    
    SizeGuide.prototype.submitButton = function(){
        var that = this;

        that.cursorLoading();

        $.ajax({
            url: '/catalog/dynamicSizeGuideTableAjax',
            data: {brand: this.brandId, category: this.categoryId},
            dataType: 'json',
            success: function(data){
                that.RenderTable(data);
                that.cursorDefault();
            }
        });

    }
    SizeGuide.prototype.RenderTable = function(data) {
        this.$element.addClass('size-guide-full');
        var tableTemplate = '';
        var tableHeader = '';
        if (data.columns){
            tableHeader = "<div class=\"sgt-caption\">"+ this.brandName + " " + this.categoryName  + "</div>";
            tableTemplate = "<table class=\"size-guide-table\">" +
                        "<thead>"+
                        "   <tr>"+
                        this.RenderTableHeadCell(data.columns) +
                        "   </tr>"+
                        "</thead>"+
                        "<tbody>"+
                        this.RenderTableLine(data) +
                        "</tbody>" +
                        "</table>";
        } else {
            tableTemplate = '<div class="box-filter-content-paragraph no-size-guide">Não há guia de tamanhos</div>';
        }
        this.$element.find('.size-guide-result').html(tableTemplate);
        this.$element.find('.size-guide-result').before(tableHeader);
        this.$element.trigger('dft.size-guide.resize');
    }
    SizeGuide.prototype.RenderTableHeadCell = function(data) {
        var html = "";
        html += "<th class='sgt-head'>Numeração</th>";
        $.each(data, function(key,head){
            html += "<th class='sgt-head popover-hover'>"+ head.title ;
            html += head.how_to_measure_text ? "<a href=\"#\" class=\"sgt-mesures popover-arrow arrow arrow-1-up\" data-toggle=\"popover\">Como medir?</a><div class=\"popover\">"+ head.how_to_measure_text +"</div>" : ""
            html += "</th>";
        });
        return html;
    }
    SizeGuide.prototype.RenderTableLine = function(data) {
        //receive data and parse it to render each line in table.
        var html = "";

        //THIS IS JUST FOR GETTING A BETTER OBJECT FORMAT OTHER THAN THE ONE I RECEIVED FROM THE SERVICE
        var columns = [];
        var numbering = [];

        $.each(data.numberings,function(key,head){
            numbering.push(head.size);
        });

        columns.push(numbering);

        $.each(data.columns,function(key,value){
            var sideColumns = [];
            $.each(value.cells,function(key,value){
                sideColumns.push(value);
            });
            columns.push(sideColumns);
        });

        var lines = data.numberings.length;
        for(var i = 0; i < lines; i++ ){
            if (Boolean(this.classColored)) {
                this.classColored = '';
            } else {
                this.classColored = ' class="colored"';
            }
            html += "<tr"+ this.classColored +">";
            $.each(columns,function(key,value){
                if (typeof value[i] == "string"){
                    html += "<td class=\"stg-numbering\"><span class=\"box-number\">"+ value[i] + "</span></td>";
                }else{
                    html += "<td class=\"sgt-cell\">" + value[i].min;
                    if (value[i].min != value[i].max){
                        html += " - " + value[i].max;
                    }
                    html += "</td>";
                }
            });  
            
            html += "</tr>";
        }

        return html;   
    }    
    //Todo move this to another place, create a locksubmit generic helper/function to forms

    SizeGuide.prototype.LockSubmit = function(){
        this.btnSubmit.attr('disabled',true);
    }
    SizeGuide.prototype.ReleaseSubmit = function(){
        this.btnSubmit.attr('disabled',false);
    }
    //this should be part of selectbox.js.
    SizeGuide.prototype.markSelected = function(e) {
         if( $(e.currentTarget).data('selected') == ''){
            $(e.currentTarget).removeClass('selected');
        }else{
            $(e.currentTarget).addClass('selected');
        }
    }


    //data = informações para renderizar a tabela. 
    $.fn.sizeGuide = function(){
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.size-guide');
            var options = $.extend({}, SizeGuide.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.size-guide', (data = new SizeGuide(this, options)));
            if (typeof option == 'string') data[option]();
            else if (options.show) data.open();
        });
    }

    $(document).on('modal.size-guide-modal', function(){
        $('[data-plugin=size-guide]').sizeGuide();
    });
    
})(jQuery);
