(function($) {

    'use strict';

    var ConfirmPassword = function(element, options) {
        this.$element   = $(element);
        this.options    = options;

        this.$verificationCode        = $("input[id$='verification_code']");
        this.$password                = this.$element.find('input[name="CheckPasswordForm[password]"]');
        this.$form                    = $('[data-confirm-password]');
        this.$birthDay                = $('#EditForm_day');
        this.$birthMonth              = $('#EditForm_month');
        this.$birthYear               = $('#EditForm_year');
        this.$minimumAge              = 18;
        this.$actualYear              = new Date().getFullYear();
        this.$actualMonth             = new Date().getMonth() + 1;
        this.$actualDay               = new Date().getDate();
        this.$birthDaySelect          = this.$birthDay.parent();
        this.$birthMonthSelect        = this.$birthMonth.parent();
        this.$birthYearSelect         = this.$birthYear.parent();
        this.$birthDaySelectOptions   = this.$birthDaySelect.find('ul').children();
        this.$birthMonthSelectOptions = this.$birthMonthSelect.find('ul').children();
        this.$birthYearSelectOptions  = this.$birthYearSelect.find('ul').children();
        this.$birthdayRequired        = 'Preencha os dados do campo de data de nascimento.';
        this.$invalidMinimumAge       = 'A idade mínima requirida é de 18 anos.';
    };

    ConfirmPassword.DEFAULTS = {
        'url' : '/customer/address/checkpassword/',
    };

    ConfirmPassword.prototype.showModalForm = function() {
        var errorClasses = '.is-invalid, .parsley-error, .parsley-email-exists';

        this.validateBirthday();

        if (this.$element.find(errorClasses).length > 0) {
            return;
        }

        $('body').modal('destroy');
        $('body').removeData('dft.modal');
        $('body').modal({
            'remote': this.options.url,
            'className' : 'myaccount-confirm-password-modal'
        });
    };

    ConfirmPassword.prototype.load = function() {
        if (this.$password.val().length === 0) {
            return false;
        }
        var args = {
            'password': this.$password.val()
        };
        $(this).confirmPassword('loaded', args);
    };

    ConfirmPassword.prototype.loaded = function(args) {
        this.$element.find('.errorMessage').remove();

        $("#integrity-form").val(args.password);
        $("#password-form").val(args.password);
        
        if(this.$verificationCode.val()){
            $("#verification-code-form").val(this.$verificationCode.val());
        }

        this.$form.addClass('password-confirmed');
        this.$form.addClass('is-valid');
        this.$form.submit();
    };

    ConfirmPassword.prototype.invalidField = function(response, field) {
        var message = JSON.parse(response.responseText).message;

        field.after('<div class="errorMessage">' + message + '</div>');
    };

    ConfirmPassword.prototype.invalidPassword = function(response) {
        var message = JSON.parse(response.responseText).message;

        this.$password.removeClass('is-valid').addClass('is-invalid');
        this.$password.after('<div class="errorMessage">' + message + '</div>');
    };

    ConfirmPassword.prototype.calculateYearDifference = function() {
        if (this.$birthYear.val() === '' || this.$birthYear.val() === null) {
            return 0;
        }
    
        return this.$actualYear - parseInt(this.$birthYear.val());
    };
    
    ConfirmPassword.prototype.validateBirthday = function() {
        this.removeInvalidBirthMessage();
    
        this.setAsValid(this.$birthDaySelect);
        this.setAsValid(this.$birthMonthSelect);
        this.setAsValid(this.$birthYearSelect);

        if (
            this.$birthDay.val() === null  ||
            this.$birthDay.val() === ''    ||
            this.$birthMonth.val() == null ||
            this.$birthMonth.val() === ''  ||
            this.$birthYear.val() == null  ||
            this.$birthYear.val() === ''
            ) {
            this.addInvalidBirthMessage(this.$birthdayRequired);
            return;
        }
    
        this.validateMinimumAge();
    };
    
    ConfirmPassword.prototype.validateMinimumAge = function () {
        var yearDifference = this.calculateYearDifference();
    
        if (yearDifference > this.$minimumAge) {
            return;
        }
    
        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) < this.$actualMonth) {
            return;
        }
    
        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) == this.$actualMonth && parseInt(this.$birthDay.val()) < this.$actualDay) {
            return;
        }
    
        if (yearDifference == this.$minimumAge && parseInt(this.$birthMonth.val()) == this.$actualMonth && parseInt(this.$birthDay.val()) == this.$actualDay) {
            return;
        }
    
        this.addInvalidBirthMessage(this.$invalidMinimumAge);
    };
    
    ConfirmPassword.prototype.removeInvalidBirthMessage = function () {
        $('#parsley-id-' + this.$birthYear.attr('data-form-id')).remove();
        $('#parsley-id-' + this.$birthYear.attr('id')).remove();
    };
    
    ConfirmPassword.prototype.addInvalidBirthMessage = function (message) {
        this.setAsInvalid(this.$birthYearSelect);
        this.setAsInvalid(this.$birthMonthSelect);
        this.setAsInvalid(this.$birthDaySelect);
        this.addInvalidValidationMessage(this.$birthYearSelect.parent().parent(), this.$birthYear.attr('id'), message);
    };
    
    ConfirmPassword.prototype.disableSelectMonthAndDayOnBirth = function () {
        this.$birthMonthSelect.find('selectbox-icon').data('toggle', '');
        this.$birthMonthSelect.find('selectbox-option').data('toggle', '');
        this.$birthDaySelect.find('selectbox-icon').data('toggle', '');
        this.$birthDaySelect.find('selectbox-option').data('toggle', '');
    };
    
    ConfirmPassword.prototype.enableSelectMonthAndDayOnBirth = function () {
        this.$birthMonthSelect.find('selectbox-icon').data('toggle', 'popover');
        this.$birthMonthSelect.find('selectbox-option').data('toggle', 'popover');
        this.$birthDaySelect.find('selectbox-icon').data('toggle', 'popover');
        this.$birthDaySelect.find('selectbox-option').data('toggle', 'popover');
    };
    
    ConfirmPassword.prototype.enableOptionsDisabledOnTheDateOfBirth = function() {
        for (var i = 0; i < this.$birthMonthSelectOptions.length; i++) {
            if (parseInt(this.$birthMonthSelectOptions[i].dataset.value) > this.$actualMonth) {
                this.setAsEnable($(this.$birthMonthSelectOptions[i]));
            }
        }
    
        for (var i = 0; i < this.$birthDaySelectOptions.length; i++) {
            if (parseInt(this.$birthDaySelectOptions[i].dataset.value) >= this.$actualDay) {
                this.setAsEnable($(this.$birthDaySelectOptions[i]));
            }
        }
    };
    
    ConfirmPassword.prototype.disableOptionsThatAllowMinorOnTheDateOfBirth = function() {
        var yearDifference = this.calculateYearDifference();

        this.enableSelectMonthAndDayOnBirth();

        if (yearDifference < this.$minimumAge) {
            this.disableSelectMonthAndDayOnBirth();
        }
    
        if (yearDifference > this.$minimumAge) {
            this.enableOptionsDisabledOnTheDateOfBirth();
        }

        if (yearDifference == this.$minimumAge && this.$birthMonth.val() < this.$actualMonth) {
            this.enableOptionsDisabledOnTheDateOfBirth();
        }

        if (yearDifference == this.$minimumAge) {
            for (var i = 0; i < this.$birthMonthSelectOptions.length; i++) {
                if (parseInt(this.$birthMonthSelectOptions[i].dataset.value) > this.$actualMonth) {
                    this.setAsDisable($(this.$birthMonthSelectOptions[i]));
                }
            }
        }

        if (yearDifference == this.$minimumAge && this.$birthMonth.val() == this.$actualMonth) {
            for (var i = 0; i < this.$birthDaySelectOptions.length; i++) {
                if (parseInt(this.$birthDaySelectOptions[i].dataset.value) >= this.$actualDay) {
                    this.setAsDisable($(this.$birthDaySelectOptions[i]));
                }
            }
        }
    };

    ConfirmPassword.prototype.addInvalidValidationMessage = function(element, errorId, message) {
        element.append(
            '<ul class="parsley-errors-list filled" id="parsley-id-' + errorId +
            '"><li class="parsley-required" style="padding-left:11px;">' + message +
            '</li></ul>'
        );
    };

    ConfirmPassword.prototype.setAsDisable = function(element) {
        element.addClass('is-disabled');
    };

    ConfirmPassword.prototype.setAsEnable = function(element) {
        element.removeClass('is-disabled');
    };

    ConfirmPassword.prototype.setAsInvalid = function(element) {
        element.removeClass('is-valid').addClass('is-invalid');
    };

    ConfirmPassword.prototype.setAsValid = function(element) {
        element.removeClass('is-invalid').addClass('is-valid');
    };

    $.fn.confirmPassword = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.confirm-password');
            var options = $.extend({}, args, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.confirm-password', (data = new ConfirmPassword(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('submit', '[data-confirm-password]:not(.password-confirmed)', function(e) {
        e.preventDefault();

        var data = this.getAttribute("data-confirm-password");
        var url = '/customer/' + data + '/checkpassword/';
        var args = {
            'url': url
        };

        if (($(this).attr('data-confirm-password') == 'account' && $(this).is('.is-email-valid')) ||
            ($(this).attr('data-confirm-password') == 'address')) {
            $(this).confirmPassword('showModalForm', args);
        }
    });

    $(document).on('submit', '[data-myaccount-confirm-password-form]', function(e) {
        e.preventDefault();

        var args = {
            'url':  $(this).attr('action')
        };
        $(this).confirmPassword('load', args);
    });

    $('#EditForm_year').parent().find('ul').children().click(function(e) {
        $('#EditForm_year').val(e.target.dataset.value);
        $(this).confirmPassword('disableOptionsThatAllowMinorOnTheDateOfBirth');
        $(this).confirmPassword('validateBirthday');
    });

    $('#EditForm_month').parent().find('ul').children().click(function(e) {
        $('#EditForm_month').val(e.target.dataset.value);
        $(this).confirmPassword('disableOptionsThatAllowMinorOnTheDateOfBirth');
        $(this).confirmPassword('validateBirthday');
    });

    $('#EditForm_day').parent().find('ul').children().click(function(e) {
        $('#EditForm_day').val(e.target.dataset.value);
        $(this).confirmPassword('validateBirthday');
    });
})(jQuery);