(function ($) {
    'use strict';

    var _dataLayerFiltered = dataLayer.filter(function (e) {
        return e.ET
    })[0];

    if (isDafitiStore() && _dataLayerFiltered != undefined && (_dataLayerFiltered.ET === 'catalogpage' || _dataLayerFiltered === 'landingpage') && _dataLayerFiltered.productSku1 != '') {
        var ET = _dataLayerFiltered.ET;
        var searchquery = _dataLayerFiltered.searchquery;
        var keyword = _dataLayerFiltered.keyword;
        var url = window.location.href;
        var brandName = _dataLayerFiltered.brandName ? _dataLayerFiltered.brandName : 0;
        var pageType = _dataLayerFiltered.pageType;
        var campaignName = _dataLayerFiltered.campaignName ? _dataLayerFiltered.campaignName : 0;
        var pageName = _dataLayerFiltered.pageName ? _dataLayerFiltered.pageName : 0;
        var pcat = _dataLayerFiltered.Pcat;
        var catalogPageOrigin;
        var testUrl = /Preview=1/.test(url);
        var page_path = window.location.pathname;
        var modalLine = 0;

        if (ET === "catalogpage" && searchquery.length == 0 && keyword.length == 0 && !testUrl && pageType !== "BRANDPAGE" && page_path !== "/search-results") {
            catalogPageOrigin = "Catalog Page - Menu";
        } else if (ET === "catalogpage" && page_path === "/search-results" && !testUrl) {
            catalogPageOrigin = "Search Result Page";
            var category1 = "Search Result";
        } else if (ET === "catalogpage" && testUrl) {
            catalogPageOrigin = "Ta Em Alta Page";
            var category1 = "Ta em Alta";
        } else if ((pageType === "BRANDPAGE" && brandName.length > 0 && page_path !== "/search-results") || (ET === "landingpage" && brandName.length > 0)) {
            catalogPageOrigin = "Brand Page";
            if (isMobileDevice()) {
                var category1 = category.toString().replace(/,/gi, '/');
            }
        } else if (ET === "landingpage" || (campaignName.length > 0 || typeof (campaignName) === "number")) {
            catalogPageOrigin = "Promotion Page";
            var category1 = pageName.split(".")[1];
        }

        (function () {
            var waitForJQuery = setInterval(function () {
                if (typeof $ != 'undefined') {
                    applyContent();
                    clearInterval(waitForJQuery);
                }
            }, 100);
        })();

        function applyContent() {
            var elementList = document.querySelectorAll('.product-box');
            tracking_ga_impre(elementList)
        }

        window.addEventListener('scroll', function () {
            var elementList = document.querySelectorAll('.product-box');
            tracking_ga_impre(elementList);
        });

        function tracking_ga_impre(elementList, i, eventType) {
            if (elementList.length > 0) {
                if ((typeof elementList[0].parentNode.parentNode.parentNode.parentNode.children[0].attributes[2] === 'undefined') || (typeof elementList[0].parentNode.parentNode.parentNode.parentNode.parentNode.children[0].attributes[2] === 'undefined')) {

                    var dataPush = {
                        'event': 'product_impressions_ga3',
                        'category': 'enhanced ecommerce',
                        'action': 'product impressions',
                        'label': 'product list',
                        'ecommerce': {
                            'currencyCode': 'BRL',
                            'impressions': []
                        }
                    };

                    var itensModal = 0;
                    elementList.forEach(function (item, position) {
                        if (isMobileDevice()) {
                            var position1 = position;
                        } else {
                            var position1 = position + 1;
                        }

                        var color = (item.querySelector('.product-box-colors').innerText).trim();
                        var sellerName = (item.querySelector('.product-box-seller').innerText).trim();
                        var originalPrice = item.querySelector('.product-box-price-from');
                        var descountPrice = item.querySelector('.product-box-price-to');
                        const categories = item.querySelector('.product-box-category').innerText.trim().split(",");
                        var price = descountPrice ? descountPrice.innerText.split(' ')[1] : originalPrice.innerText.split(' ')[1];
                        var id = productId(item)

                        var name = item.querySelector('.product-image').alt;
                        var category2 = pcat;
                        var list = catalogPageOrigin;

                        if (category2 === undefined || category2 === "") {
                            var category = category1;
                        } else {
                            var category = category2.replace(/,/gi, "/").replace(/\|/gi, "/");
                        }

                        if ((category == '' || category == undefined) && document.getElementsByClassName("search-input")[0].value != '') {
                            var category = "Search Result";
                        }

                        var docViewTop = window.scrollY
                        var docViewBottom = docViewTop + window.outerHeight
                        var elementTop = item.offsetTop
                        var elementBottom = elementTop + item.offsetHeight

                        if (((elementTop >= docViewTop) && (elementBottom < (docViewBottom - 200)) && item.className.indexOf('impressionGA') === -1) || ($('.modal-2').hasClass('is-open') && (item.className.indexOf('impressionGA') === -1))) {

                            item.classList.add('impressionGA');

                            if ($('.modal-2').hasClass('is-open')) {
                                list = catalogPageOrigin + ' - Modal';
                                var brand = item.querySelector('.product-box-brand').innerText;
                            }
                            else {
                                var brand = item.querySelector('.product-box-brand > span:nth-child(1)').innerText;
                            }

                            if ((itensModal == 3) && (modalLine == 0)) {
                                item.classList.remove('impressionGA');
                                return false;
                            } else if ((itensModal == 3) && (modalLine != 0)) {
                                return false;
                            }

                            dataPush.ecommerce.impressions.push({
                                'id': id.toLowerCase(),
                                'name': name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                                'price': parseFloat(price.replace(',', '.')),
                                'brand': brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                                'category': categories[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                                'dimension51': categories[1] ? categories[1].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "",
                                'dimension52': categories[2] ? categories[2].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "",
                                'dimension53': categories[3] ? categories[3].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "",
                                'dimension54': categories[4] ? categories[4].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : "",
                                'dimension55': 'dafiti br - ' + sellerName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                                'position': position1,
                                'list': list.toLowerCase(),
                                'variant': color.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")

                            });

                            if ($('.modal-2').hasClass('is-open')) {
                                itensModal++;
                            }

                        }

                    });
                    if (Object.values(dataPush.ecommerce.impressions).length !== 0) {
                        dataLayer.push(dataPush);
                    }
                }
            }
        }

        function productId(item) {
            return item.querySelector('.product-box-image').getAttribute('id') ? item.querySelector('.product-box-image').getAttribute('id') : item.getAttribute('data-sku');
        }

        function isDafitiStore() {
            return document.location.hostname.indexOf('dafiti') > -1;
        }

        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
        }
    }

    function prepareDataLayerToPageViewEvent(dataLayer, title) {
        dataLayer.push({
            'event': 'page_view_ga3',
            'page_path': document.location.pathname.toLowerCase(),
            'page_location': document.location.href.toLowerCase(),
            'page_title': title.toLowerCase()
        });
    }
    var CatalogEventsGA3 = function () {
        this.prepareDataLayerToPageViewEvent = function (dataLayer, title) {
            return prepareDataLayerToPageViewEvent(dataLayer, title);
        };
        return this;
    };

    window.catalogEventsGA3 = CatalogEventsGA3;

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];

    if (_dataLayerFiltered !== undefined) {
        if ((isDafitiStore() && _dataLayerFiltered.ET == 'catalogpage' ||
            _dataLayerFiltered.ET == 'landingpage') &&
            !window.location.href.includes('?q=')) {
            var catalogEventsGA3 = new CatalogEventsGA3();

            var pageName = _dataLayerFiltered.pageName;

            var breadcrumpCategory = $(".breadcrumb2-item-link").text();
            var promotionCategory = "";

            if (breadcrumpCategory.includes('Feminino') || pageName.includes("feminino")) {
                promotionCategory = "- feminino";
            } else if (breadcrumpCategory.includes('Masculino') || pageName.includes('masculino')) {
                promotionCategory = "- masculino";
            } else if (breadcrumpCategory.includes('Infantil') || pageName.includes('infantil')) {
                promotionCategory = "- infantil";
            } else if (breadcrumpCategory.includes('Casa') || pageName.includes('casa')) {
                promotionCategory = "- casa";
            } else if (breadcrumpCategory.includes('Outlet') || pageName.includes('promocao')) {
                promotionCategory = "- outlet";
            } else {
                promotionCategory = "";
            }

            var title = 'ecommerce - product list - ';

            if (pageName === 'BRANDPAGE') {
                title += 'brand';
            } else if (pageName === 'TYPEPAGE') {
                var category = _dataLayerFiltered.pageCategory.split(',')[0];
                title += 'category - ' + category;
            } else if (pageName.includes('LandingPage')) {
                title += 'promotion ' + promotionCategory;
            } else if (pageName === 'CATEGORYPAGE') {
                var category = _dataLayerFiltered.pageCategory.split(',')[0];
                title += 'category - ' + category;
            }

            catalogEventsGA3.prepareDataLayerToPageViewEvent(dataLayer, title);
        }

        function isDafitiStore() {
            return document.location.hostname.indexOf('dafiti') > -1;
        }

    }

})(jQuery);
