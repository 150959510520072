var address = {
    cepCheckUrl: "/cep",

    resolvePostcode: function(form, field, callback, asyncronous) {

        if (asyncronous !== true) {
            asyncronous = false;
        }

        field.removeClass('error');
        var zip = field.val().replace(/[_-]+/g,'');
        
        var error = $("<label class='error inputDescription' for='"+ field.attr("id") +"' generated='true'>Não foi possível encontrar o CEP informado.</label>");
        var errorLi = field.parent('li');
        
        if (zip.length == 8) {
            var yiitken = $('#YII_CSRF_TOKEN').val();
            var loader = $(field).parent().parent().parent().find('.fieldpart-postcode-ajaxLoader');
            var link = $(field).parent().parent().parent().find('.fieldpart-postcode-link');
            var self = this;

            var successHandler = function(data) {
                data = jQuery.parseJSON(data);

                address.checkEnableDisableField(form + '_address1',data.Street);
                address.checkEnableDisableField(form + '_neighborhood',data.Neighborhood);
                address.checkEnableDisableField(form + '_fk_customer_address_region',data.RegionId);
                address.checkEnableDisableField(form + '_city',data.City,true);
                
                if (data.Street == null || data.Street == '') {
                    $(form + '_address1').focus();
                } else {
                    $(form + '_street_number').focus();
                }
                field.removeClass('error').removeClass('invalid');
                field.parent().find('.error.inputDescription').remove();
                errorLi.removeClass('invalidInput').addClass('validInput');
                if (data.RegionId > 0) {
                    $(form + '_fk_customer_address_region').find('option[value!="'+data.RegionId+'"]').css('display','none');
                    $(form + '_fk_customer_address_region').find('option[value!="'+data.RegionId+'"]').attr('disabled', 'disabled');
                    $(form + '_fk_customer_address_region').val(parseInt(data.RegionId));
                }
                
                if ($(field).attr('id') == checkout.loadFreightPostCodeField) {
                    var zip = field.val().replace(/[_-]+/g,'');
                    if (zip.length == 8) {
                        checkout.postCode = zip;
                        checkout.loadFreightOptions(zip, true);
                    }
                }
            };

            $.ajax({
                async: asyncronous,
                type: 'GET',
                url: self.cepCheckUrl,
                dataType: 'text',
                data: {postcode:field.val(), YII_CSRF_TOKEN:yiitken, vCep:1 },
                beforeSend: function(){
                    loader.show();
                    field.removeClass('error');
                    field.parent().find('.error.inputDescription').remove();
                    link.hide();
                    $(form + '_fk_customer_address_region').find('option').css('display','');
                    $(form + '_fk_customer_address_region').find('option').removeAttr('disabled');
                },
                error: function(){
                    field.removeClass('error');
                    field.parent().find('.error.inputDescription').remove();
                    field.after(error);
                    field.addClass('error');
                    errorLi.removeClass('validInput').addClass('invalidInput');
                },
                success: function( data ){

                    loader.hide();
                    link.show();

                    if (data == 'error' || data == 'unresolved') {
                        field.addClass('error');
                        $('#Postcode_errorMessage').show();
                        errorLi.removeClass('validInput').addClass('invalidInput');
                    } else {
                        $('#Postcode_errorMessage').hide();
                        if( callback ) {
                            var status = callback( data );
                            if(status) {
                                successHandler( data );
                            }
                        } else {
                            successHandler( data );
                        }
                    }
                },
                complete: function(){
                    loader.hide();
                    link.show();
                }
            });
        } else {
            $(field).parent().find('.error.inputDescription').remove();
            field.removeClass('error');
            errorLi.removeClass('invalidInput').addClass('validInput');
            $(form + '_fk_customer_address_region').find('option').css('display','');
            $(form + '_fk_customer_address_region').find('option').removeAttr('disabled');
        }
     },
     checkEnableDisableField : function(fieldName,value,errorRemove){
         
         if(errorRemove){
             $(fieldName).removeClass("error");
         }
         
         if ($(fieldName).val() != value && value !== null ) { 
            $(fieldName).val(value);
         }

         if( $(fieldName).valid() && value !== null && value !== "") {
             $(fieldName).attr('readonly', true);
             $(fieldName).addClass('is-readonly');
         } else {
             $(fieldName).removeAttr('readonly');
             $(fieldName).removeClass('is-readonly');
         }
         
     }
};