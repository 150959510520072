$(document).ready(function() {
    var customer_info = $.parseJSON($.cookie('customer_info'));
    if (customer_info && customer_info.n) {
        customer_info.p = $.cookie('social_pic');
        $('.header-login').headerLogin({
            name: customer_info.n,
            card: customer_info.c,
            picture: customer_info.p
        });
    }

    $('.menu-mobile .menu-stacked').menuStacked('open', $('.menu-link.is-current').data('menu-key'));

    $('.product-delivery-clock').productDeliveryClock();

    $('.product-delivery-clock-link').click(function(){
        var popover = $('#product-delivery-clock').data('dft.popover');
        $('#product-delivery-time').popover();
        setTimeout(function(){
            $('#product-delivery-time').data('dft.popover').open();
        });
    });

    $(document).on('click', '.menu-mobile .menu-stacked-trigger:checked + label', function(e) {
        e.preventDefault();

        var selector = '#' + $(this).attr('for');

        $(selector).attr('checked', false);
    });

    $(document).on('click', '.search-mobile-link', function() {
        setTimeout(function() {
            $('.search .search-input').focus();
        }, 350);
    });

    $(document).on('click', '.header-login .header-login-facebook', function(e) {
        e.preventDefault();
        socialid.onLoad();
        socialid.login.startLogin('facebook');
        $('.header-login').popover('close');
    });

    $(document).on('click', '.header-login .header-login-twitter', function(e) {
        e.preventDefault();
        socialid.onLoad();
        socialid.login.startLogin('twitter');
        $('.header-login').popover('close');
    });

    $(document).on('dft.size-guide.resize', '.size-guide', function() {
        $(this).closest('.modal-2').trigger('modal.resize');
    });
    $(document).trigger('dft.scroll-event.active');
});

$(document).one('socialid-onload', function() {
    var ts = Math.round((new Date()).getTime() / 1000);
    socialid.events.onLoginSuccess.addHandler(function(data) {
        $.cookie('chrono_social', ts, 1);
        $.ajax({
            url: '/customer/social/setUserSession/',
            data: {token: data.token},
            dataType: 'json',
            type: 'GET'
        });
    });
});

// new relic insights

window.onerror = function (errorMsg, scriptUrl, lineNumber, columNumber, errorObj) {
  if (typeof newrelic == 'object') {
    newrelic.addPageAction('windowError', { errorMsg: errorMsg, scriptUrl: scriptUrl, lineNumber: lineNumber, columNumber: columNumber, stacktrace: errorObj });
  }
}

$.ajaxSetup({
  error: function(xhr, statusCode, errorMsg) {
    if (typeof newrelic == 'object') {
      newrelic.addPageAction('ajaxError', { responseText: xhr.responseText, statusCode: status, errorMsg: errorMsg, xhr: xhr});
    }
  }
});


