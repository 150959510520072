(function ($) {

    'use strict';

    var Highlight = function (element, options) {
        this.$element = $(element);
        this.zindex = this.$element.css('z-index');
        this.$backdrop = null;
        this.options = options;
        //status can be 'in' or 'out'
        this.status = 'in';
    }

    Highlight.DEFAULTS = {
        show: true
    };

    Highlight.prototype.real_open = function () {
        this.backdrop();
        this.$backdrop.addClass('is-open');
        this.$element.addClass('highlight');
        if (this.$element.css('z-index') != "20") {
            this.$element.css('z-index', "20");
        }

    }

    Highlight.prototype.open = function () {
        //if already showing or don't have delay, show it
        if (this.$element.hasClass('highlight') || !this.options.indelay) {
            return this.real_open();
        }

        //if have a delay, wait to show
        var that = this;
        setTimeout(function () {
            if (that.status === 'in') {
                that.real_open();
            }
        }, this.options.indelay);
    }

    Highlight.prototype.real_close = function () {
        this.removeBackdrop();
        this.$element.removeClass('highlight');
        this.$element.css('z-index', '-1');
    }

    Highlight.prototype.close = function () {
        if (!this.options.outdelay) {
            this.real_close();
        }

        //mouse out delay is used to prevent blinking when mouse
        //inand out repeatedly
        var that = this;
        setTimeout(function () {
            //if the mouse is still out, close
            if (that.status === 'out') {
                that.real_close();
            }
        }, this.options.outdelay);
    }

    Highlight.prototype.backdrop = function () {
        if (!this.$backdrop) {
            this.$backdrop = $('<div class="highlight-backdrop" />')
        }
        this.$backdrop.insertBefore(this.$element);
    }

    Highlight.prototype.removeBackdrop = function () {
        if (this.$backdrop) this.$backdrop.remove();
        this.$backdrop = null;
    }

    $.fn.highlight = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.highlight');
            var options = $.extend({}, Highlight.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.highlight', (data = new Highlight(this, options)));
            if (typeof option == 'string') data[option]();
            else if (options.show) data.open();
        });
    }

    $(document).on('mouseover', '.no-touch [data-toggle=highlight]', function () {
        var $this = $(this);
        var $target = $($this.attr('data-target'));
        var $highlight = $target.data('dft.highlight');

        if ($highlight) {
            $highlight.status = 'in';
        }

        var option = $highlight ? 'open' : $this.data();

        $target.highlight(option);
    });

    $(document).on('mouseout', '.no-touch [data-toggle=highlight]', function () {
        var $this = $(this);

        var $target = $($this.attr('data-target'));
        var $highlight = $target.data('dft.highlight');
        if ($highlight) {
            $highlight.status = 'out';
        }

        $target.highlight('close');
    });

})(jQuery);
