function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
function getCookie(name) {
    var pattern = RegExp(name + "=.[^;]*");
    var matched = document.cookie.match(pattern);
    if (matched) {
        var cookie = matched[0].split("=");
        return cookie[1];
    }
    return false;
}
(function ($) {
    "use strict";

    var CartTotal = function (element, options) {
        this.$element = $(element);
        this.options = options;

        this.$totalPrice = $("[data-cart-total-price]");
        this.$totalGift = $("[data-cart-total-gift]");
        this.$totalInstallments = $("[data-cart-total-installments]");
        this.$totalInstallmentsQty = $(
            "[data-cart-total-installments-quantity] span"
        );
        this.$totalDiscount = $("[data-cart-total-discount]");
        this.$totalRuleDiscount = $("[data-cart-total-rule-discount]");
        this.$itemsCountDefault = $('[data-cart-items-count="default"]');
        this.$itemsCountSeller = $('[data-cart-items-count="seller"]');
        this.$totalCountMobile = $("[data-cart-total-count]");
        this.$subTotal = $("[data-cart-subtotal-price]");

        this.$totalInstallmentsHasFee = $("[data-cart-total-installments-fee]");
        this.$totalInstallmentsQtyHasFee = $(
            "[data-cart-total-installments-quantity-fee] span"
        );
        this.$totalInstallmentsTotalHasFee = $("[installments-total-has-fee]");
        this.$totalInstallmentsNoFeeLabel = $("[installments-label-no-fee]");
        this.$totalInstallmentsyHasFeeBox = $("[data-cart-total-installments-quantity-fee]");
    };

    CartTotal.DEFAULTS = {};

    CartTotal.prototype.init = function (response) {
        this.renderItemsPrices(response);
        this.renderTotalPrice(response);
        this.renderSubTotalPrice(response);
        this.renderTotalGift(response);
        this.renderTotalInstallments(response);
        this.renderTotalDiscount(response);
        this.renderRuleDiscount(response);
        this.renderItemsCount(response);
    };

    CartTotal.prototype.renderItemsPrices = function (response) {
        var $cartItem = $(
            '[data-cart-item="' + response.item.sku_simple + '"]'
        );

        $cartItem.find("[data-cart-item-price]").html(response.item.price);
        $cartItem
            .find("[data-cart-item-price-original]")
            .html(response.item.original_price);
        $cartItem
            .find("[data-cart-item-price-subtotal]")
            .html(response.item.subtotal);
        $cartItem
            .find("[data-cart-item-price-discount] span")
            .html(response.item.price_discount);
    };

    CartTotal.prototype.renderItemsCount = function (response) {
        var totalQty = 0;
        var getQuantityText = function (qty) {
            return (qty += qty > 1 ? " Itens" : " Item");
        };

        $.each(response.total.quantity, function (index, value) {
            totalQty += value;
        });

        this.$itemsCountDefault.html(
            getQuantityText(response.total.quantity.default)
        );
        this.$itemsCountSeller.html(
            getQuantityText(response.total.quantity.seller)
        );
        this.$totalCountMobile.html(getQuantityText(totalQty));

        $(".cart-preview").cartPreview("setReload", totalQty);
    };

    CartTotal.prototype.renderTotalPrice = function (response) {
        this.$totalPrice.html(response.total.price);
    };
    CartTotal.prototype.renderSubTotalPrice = function (response) {
        this.$subTotal.html(response.total.subtotal);
    };
    CartTotal.prototype.renderTotalInstallments = function (response) {
        this.$totalInstallmentsQty.html(response.total.installments.quantity);
        this.$totalInstallments.html(response.total.installments.price);
        this.$totalInstallmentsNoFeeLabel.html(response.total.installments.no_fee_label);

        if(response.total.installments.hasFee.quantity > 0) {
            this.$totalInstallmentsyHasFeeBox.show();
            this.$totalInstallmentsQtyHasFee.html(response.total.installments.hasFee.quantity);
            this.$totalInstallmentsHasFee.html(response.total.installments.hasFee.value);
            this.$totalInstallmentsTotalHasFee.html(response.total.installments.hasFee.total);
        }else{
            this.$totalInstallmentsyHasFeeBox.hide();
        }

    };

    CartTotal.prototype.renderTotalGift = function (response) {
        var $cartItem = $(
            '[data-cart-item="' + response.item.sku_simple + '"]'
        );
        var $totalGiftPrice = $("[data-cart-total-gift-price]");

        $cartItem
            .find("[data-cart-item-total-gift]")
            .html(response.item.gift_wrap.price);
        $cartItem
            .find("[data-cart-item-gift]")
            .toggleClass("is-selected", response.item.gift_wrap.price != 0);

        $totalGiftPrice
            .closest("[data-cart-total-itens]")
            .toggleClass("hide", response.total.gift_wrap === 0)
            .toggleClass("summary-content", response.total.gift_wrap !== 0);
        $totalGiftPrice.html(response.total.gift_wrap);
    };

    CartTotal.prototype.renderTotalDiscount = function (response) {
        this.$totalDiscount.html(response.total.total_discount);
        this.$totalDiscount
            .closest("[data-cart-total-itens]")
            .toggleClass("hide", response.total.total_discount === 0)
            .toggleClass("summary-content", response.total.gift_wrap !== 0);
    };

    CartTotal.prototype.renderRuleDiscount = function (response) {
        this.$totalRuleDiscount.html(" - " + response.total.cart_rule_discount);
        this.$totalRuleDiscount
            .closest("[data-cart-total-itens]")
            .toggleClass("hide", response.total.cart_rule_discount === 0);
    };

    $.fn.cartTotal = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data("dft.cart-total");
            var options = $.extend(
                {},
                CartTotal.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );

            if (!data) {
                $this.data(
                    "dft.cart-total",
                    (data = new CartTotal(this, options))
                );
            }

            if (typeof option == "string") data[option](args);
        });
    };

    $(document).on("dft.cart-total", function (e, response) {
        $(e.currentTarget).cartTotal("init", response);
    });
    /** MENSAGEM DE CARRINHO ABANDONADO */
    var amanha;
    var messageAbandonProduct = localStorage.getItem("message_abandon_product");
    if (getCookie("cart_count") === "0") return;
    if (
        new Date() >= new Date(messageAbandonProduct) ||
        messageAbandonProduct === undefined
    ) {
        setTimeout(function () {
            amanha = addDays(new Date(), 1);
            localStorage.setItem("message_abandon_product", amanha);

            document.querySelector(".message-abandon").style.opacity = "1";
            setTimeout(function () {
                document.querySelector(".message-abandon").style.opacity = "0";
                setTimeout(function () {
                    document.querySelector(".message-abandon").style.display =
                        "none";
                }, 2000);
            }, 5000);
        }, 2500);
    }
    /** FINAL DE MENSAGEM DE CARRINHO ABANDONADO */
})(jQuery);
