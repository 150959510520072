(function ($) {

    ("use strict");

    var CartDelivery = function (element, options) {
        this.$element = $(element);
        this.options = options;
    };

    CartDelivery.prototype.loaded = function (response) {
        console.log(response);
    };

    CartDelivery.prototype.change = function () {

        var containerErrorMessage = $("[container-cart-delivery-error-message]");
        var inputZipcode = $("[cart-delivery-input-zipcode]");
        var containerDeliveryOptions = $("[freight-delivery-options-holder]");

        containerDeliveryOptions.html('');

        var params = {
            postcode: inputZipcode.val(),
            b2014: 1,
        };

        $.ajax({
            url: '/cart/GetFreightOptions/',
            data: params,
            //dataType: 'text/html',
            type: 'GET',
        }).done(function (response) {
            containerDeliveryOptions.html(response);
            containerErrorMessage.hide();
            inputZipcode.removeClass("is-invalid");

        }).fail(function(jqXHR, textStatus, msg){
            inputZipcode.addClass("is-invalid");
            containerErrorMessage.show();
        });
    };

    $.fn.cartGetDeliveryOptions = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data("dft.cart-delivery");

            var options = $.extend(
                {},
                CartDelivery.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );

            if (!data) {
                $this.data(
                    "dft.cart-delivery",
                    (data = new CartDelivery(this, options))
                );
            }

            if (typeof option == "string" && data[option]) {
                data[option](args);
            }
        });
    };

    $(document).on("click", "[calculate-delivery-button]", function (e) {
        e.preventDefault();
        $(e.currentTarget).cartGetDeliveryOptions("change");
    });

    $(document).on("keyup", "#cart-delivery-input-zipcode", function (e) {
        var value = $(e.currentTarget).val();
        if (!value) return ""
        value = value.replace(/\D/g,'')
        value = value.replace(/(\d{5})(\d)/,'$1-$2')
        $(e.currentTarget).val(value);
    });


})(jQuery);