(function ($) {
    'use strict';

    var _dataLayerFiltered = dataLayer.filter(function (e) { return e.ET })[0];

    if (_dataLayerFiltered !== undefined) {
        if(_dataLayerFiltered.ET === 'catalogpage' || _dataLayerFiltered.ET === 'landingpage' || _dataLayerFiltered.ET === 'productpage') {
            dataLayer.push({
                'store_country': 'br'
            });

            if($.cookie('id_customer')){
                dataLayer.push({
                    'user_id': $.cookie('id_customer')
                });
            }
        }
    }

})(jQuery);
