(function($) {

    'use strict';

    var Faq = function (element) {
        this.$element = $(element);
        this.bindLink();
    }

    Faq.DEFAULTS = {
    }

    Faq.prototype.getParam = function(url) {
      var param = [], i, val, params = url.split("/");
        val = params[0].split("=");
        var items = [];
            items.push(val[0], val[1]);
        return items;
    }

    Faq.prototype.bindLink = function() {
        $('.faq-list-answers > .answers-item').on('toggle.question', $.proxy(this, 'toggleQuestion'));

        $('.faq-list-answers > .answers-item').on('click', '.faq-answers-link', function(e) {
            $(e.delegateTarget).trigger('toggle.question');
            $('.faq-messages').addClass('hide');
        });
    }

    Faq.prototype.toggleQuestion = function(e) {
        var $answersItem = $(e.currentTarget);
        $answersItem.siblings('.opened').trigger('toggle.question');
        $answersItem.toggleClass('opened');

        var open = this.$element.find('.faq-list-answers > .opened');

        $.each(open, function() {
            $(this).removeClass('col-questions').addClass('col-questions-full');
        });
        $('.faq-list-answers > .col-questions, .ttl').toggleClass('hide');
        $('.answers-item.opened').toggleClass('answers-item-no-border');


        $answersItem.find('.faq-answer-prev').toggleClass('hide');
        $answersItem.find('.faq-answer').toggleClass('hide');

        $answersItem.find('> a > i').toggleClass('i-right-arrow', !$answersItem.hasClass('opened'));
        $answersItem.find('> a > i').toggleClass('i-down-arrow', $answersItem.hasClass('opened'));
    }

    Faq.prototype.setItemSelectedTopic = function(idTopic) {
        this.$element.find('[data-content]').each(function() {
            $(this).removeClass('active');
            if ($(this).data('content') == idTopic) {
                $(this).addClass('active');
                $(this).parent().addClass('opened');
            }
        });
    }

    Faq.prototype.setItemSelectedQuestion = function() {
        var itemsParam = this.getParamUrl();

        this.$element.find('[data-question]').each(function() {
            $(this).removeClass('active');
            if ($(this).data('question') == itemsParam.idQuestion) {
                $(this).addClass('active');
            }
        });
    }

    Faq.prototype.ajaxLoadTopic = function(idTopic){
        if (typeof idTopic == 'string') {
            var params = idTopic.split("-"),
                idTopic = params[0],
                idQuestion = params[1];
        }

        this.setItemSelectedTopic(idTopic);

        $.ajax({
            type: 'GET',
            url: '/cms/faq/questions/',
            data: {
                'topic': idTopic
            },
            success: function(data){
                var objResponse = $.parseJSON(data);
                $(".container-answers").html(objResponse.html);
                $('.content-home').removeClass('hide');
                $('.faq').faq('bindLink');

                if (!idQuestion == 'undefined' || idQuestion > 0) {
                    $('#'+idQuestion+'').parent().trigger('toggle.question');
                }
            }
        });
    }

    Faq.prototype.ajaxQuestionBySlug= function(slug) {
        var result;
         $.ajax({
            'url': '/cms/faq/getQuestionByExternalAccess',
            'type': 'GET',
            'data': { 'external_access' : slug},
            'dataType' : 'json',
             'async': false,
            'success': function(data) {
               result = data;
            }
        });

       return result;
    }

    Faq.prototype.ajaxMenuTopic = function(idTopic, idQuestion){
        $.ajax({
           'url': '/cms/faq/questionsByTopic',
           'type': 'GET',
           'data' : {
              'topic' : idTopic
           },
           'dataType' : 'json',
           'success': function(result) {
                var html = '';

                $.each(result, function(i) {
                    var idQuestion = result[i].id,
                        titleQuestion = result[i].question,
                        external_access = result[i].external_access,
                        itemLink = (external_access) ? external_access : 'question=' + idTopic + '-' + idQuestion;

                    html += '<li class="faq-sub-menu-item"><a href="#' + itemLink +'" data-question="'+idQuestion+'" title="'+titleQuestion+'" class="faq-sub-menu-link">'+titleQuestion+'</a></li>';
                });

                $('.opened .faq-sub-menu-accordion').html(html);
                $(document).trigger('select-question');
           }
        });
    }

    Faq.prototype.menuItemsOpened = function() {
        $('.faq-menu-item').removeClass('opened');
        $('.faq-messages').addClass('hide');
        this.$element.parent().addClass('opened');
    }

    Faq.prototype.getParamUrl = function() {
        var url = window.location.hash.substring(1),
            itemName = url.slice(0, -2),
            itemName = this.getParam(url),
            paramName = itemName[0],
            paramId = itemName[1];

        if (typeof paramId == 'string') {
            var params = paramId.split("-"),
                idTopic = params[0],
                idQuestion = params[1];
        }

        if (typeof idTopic == 'undefined' && typeof idQuestion == 'undefined' && url != '') {
            var params = this.ajaxQuestionBySlug(url);
            idTopic = params.question.idTopic;
            idQuestion = params.question.id;
            paramId = params.question.idTopic+"-"+params.question.id;
        }

        return  {
            'paramId': paramId,
            'idTopic': idTopic,
            'idQuestion' : idQuestion
        };
    }

    Faq.prototype.getParamAjaxLoad = function() {
        var itemsParam = this.getParamUrl();

        if (typeof itemsParam.idTopic == 'string') {
            this.ajaxLoadTopic(itemsParam.paramId);
            this.ajaxMenuTopic(itemsParam.idTopic, itemsParam.idQuestion);
        }
    }

    $.fn.faq = function (option, args) {
        return this.each(function () {
            var $this = $(this),
                data = $this.data('dft.faq'),
                options = $.extend({}, Faq.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.faq', (data = new Faq(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    }

    $(document).on('select-question', function(e) {
        $('.faq').faq('setItemSelectedQuestion');
    });

    $(document).on('click', '.faq-menu-link', function(e) {
        $(e.currentTarget).faq('menuItemsOpened');
    });

    $(window).on('hashchange',function() {
        $(".faq").faq('getParamAjaxLoad');
    });

    $(document).ready(function() {
        var url = window.location;

        if (url.hash.length === 0 ) {
            $(".content-home").removeClass('hide');
        } else {
            $(".faq").faq('getParamAjaxLoad');
        }

        $('.faq').faq();
    });

})(jQuery);
