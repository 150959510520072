(function($) {

    'use strict';

    var CustomerOrderIndexAction = function(element) {
        this.orderId = $(element).data('myaccountShowDetails');
        this.exchange = $(element).data('myaccountExchange');
        this.orderLegacyFrom = $(element).data('myaccountOrderLegacyFrom');
    };

    CustomerOrderIndexAction.prototype = new window.dft.ActionController();

    CustomerOrderIndexAction.prototype.run = function() {
        $(document).trigger('dft.events-monitoring');

        if (!window.dft.urlParam('page')) {
            $('[data-myaccount-show-details]:first').trigger('click');
        }
    };

    CustomerOrderIndexAction.prototype.toggleDetails = function() {
        var $orderDetail = $('[data-myaccount-order-details="' + this.orderId + '"]');

        if ($orderDetail.css('display') === 'none') {
            this.loadDetails();
        }

        $orderDetail.toggle(0, this.toggleButton(this.orderId)).removeClass('hide');
    };

    CustomerOrderIndexAction.prototype.toggleButton = function(orderId) {
        var $button = $('[data-myaccount-show-details="' + orderId + '"]');
        var message = $button.data('toggleMessage');

        $button.data('toggleMessage', $button.text());
        $button.text(message);
        $button.toggleClass('up'); // arrow up
    };

    CustomerOrderIndexAction.prototype.loadDetails = function() {
        var data = {
            'orderId' : this.orderId,
            'exchange' : this.exchange,
            'orderLegacyFrom': this.orderLegacyFrom
        };

        $.ajax({
            'url': '/customer/order/moreDetails/',
            'type': 'GET',
            'cache': true,
            'data': data,
            statusCode: {
                401: function(data) {
                    var target = JSON.parse(data.responseText);
                    window.location = target.urlTarget;
                }
            },
            'success': $.proxy(this, 'showDetails'),
            'error': $.proxy(this, 'showErrorMessage')
        });
    };

    CustomerOrderIndexAction.prototype.showDetails = function(response) {
        $('[data-myaccount-order-details="' + this.orderId + '"]').html(response).removeClass('hide');
    };

    CustomerOrderIndexAction.prototype.showErrorMessage = function() {
        var params = {
            'message' : 'Não foi possível completar a ação, tente novamente em alguns instantes.',
            'shortMessage' : 'Erro interno!',
        };

        $(document).trigger('dft.message-error', params);
        $(window).scrollTop(0);
    };

    $(document).on('click', '[data-myaccount-show-details]', function() {
        var element = $(this);

        (new CustomerOrderIndexAction(element)).toggleDetails();
    });

    routes['customer/order/index'] = function() {
        (new CustomerOrderIndexAction()).run();
    };

    $(document).trigger('customer/order/index');

})(jQuery);
