(function($) {

    'use strict';

    var CartPersistence = function(element, options) {
        this.$element = $(element);
        this.options = options;
    };

    CartPersistence.DEFAULTS = {}

    CartPersistence.prototype.isChecked = function() {
        if (!$(this.$element).prop('checked')) {
            $(this.$element).cartPersistence('messageUnchecked');
        } else {
            $(this.$element).cartPersistence('messageChecked');
        }
    };

    CartPersistence.prototype.messageHover = function() {
        this.$element.html(this.$element.data('hover'));
    };

    CartPersistence.prototype.messageOut = function() {
        this.$element.html(this.$element.data('checked') + ' <i class="icon-check-sharp"></i>');
    };

    CartPersistence.prototype.messageChecked = function() {
        var label = this.$element.next('label');
        label.addClass('is-checked');
        label.html(label.data('checked') + ' <i class="icon-check-sharp"></i>');
    };

    CartPersistence.prototype.messageUnchecked = function() {
        var label = this.$element.next('label');
        label.removeClass('is-checked');
        label.html(label.data('unchecked'));
    };

    $.fn.cartPersistence = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.cart-persistence');
            var options = $.extend({}, CartPersistence.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.cart-persistence', (data = new CartPersistence(this, options)));
            }

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '[data-input-persistence]', function() {
        $(this).cartPersistence('isChecked');
    });

    $(document).on('mouseenter', '[data-label-persistence].is-checked', function() {
        $(this).cartPersistence('messageHover');
    });

    $(document).on('mouseleave', '[data-label-persistence].is-checked', function() {
        $(this).cartPersistence('messageOut');
    });

})(jQuery);
