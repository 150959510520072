(function() {

    'use strict';

    var PENDING_ACTION_ID = null;

    var AddressDelete = function(element) {
        this.$element = $(element);

        this.addressId = this.$element.attr('data-myaccount-address-delete');
        this.url = this.$element.attr('href');
    };

    AddressDelete.prototype.checkIsDefaultAddress = function() {
        var addressId = this.$element.closest('[data-address]').data('address');
        var isDefaultAddress = $('[data-address-type="is_billing"][data-address-id="' + addressId + '"]').is(':checked');
        var isDefaultShipping = $('[data-address-type="is_shipping"][data-address-id="' + addressId + '"]').is(':checked');

        if (!isDefaultAddress && !isDefaultShipping) {
            return this.showUndoLink();
        }

        $('[data-message-default-address]').text($('[data-message-default-address]').data('message-default-address'));
        if (!isDefaultAddress && isDefaultShipping) {
            $('[data-message-default-address]').text($('[data-message-default-address]').data('message-shipping-address'));
        }

        $('body').modal({
            'modalContent': '[data-myaccount-address-modal]',
            'innerClassName': 'my-account-address-modal',
        });
    };

    AddressDelete.prototype.showUndoLink = function() {
        var container = this.$element.closest('.my-account-addresses-list');
        var element = container.clone();

        var addressTitle = this.$element.closest('.my-account-addresses-list').find('.my-account-address-title').text();
        var undoLink = $('<div class="my-account-address-undo"></div>');

        undoLink.append('<p class="my-account-address-title">"' + addressTitle + '"</p> foi excluído da sua lista de endereços.');
        undoLink.append('<a href="javascript:void(0);" data-myaccount-address-undo class="my-account-address-undo-link"><i class="icon-arrow-circle"></i>Desfazer</div>');

        element.html(undoLink);
        element.append(undoLink);

        container.after(undoLink);
        container.addClass('hide');

        PENDING_ACTION_ID = setTimeout($.proxy(this, 'removeFromBackend'), 3000);

        window.onbeforeunload = function() {
            return 'Ainda existem ações pendentes. Aguarde.';
        };
    };

    AddressDelete.prototype.removeFromBackend = function() {
        var data = {
            'address_id': this.address_id
        };

        $.get(this.url, data, $.proxy(this, 'removeUndoLink'));
    };

    AddressDelete.prototype.removeUndoLink = function(response) {
        var address = this.$element.closest('.my-account-addresses-list');
        var params = {
            'message' : response,
            'shortMessage' : ''
        };

        address.next('.my-account-address-undo').remove();
        address.next('.horizontal-separator').remove();
        address.remove();

        $(document).trigger('dft.message-success', params);

        window.onbeforeunload = function() {};
    };

    AddressDelete.prototype.undoRemove = function() {
        var undoLink = this.$element.closest('.my-account-address-undo');

        undoLink.prev().removeClass('hide');
        undoLink.remove();

        window.clearTimeout(PENDING_ACTION_ID);
        window.onbeforeunload = function() {};
    };

    $(document).on('click', '[data-myaccount-address-delete]', function(e) {
        e.preventDefault();

        var element = $(this);

        (new AddressDelete(element)).checkIsDefaultAddress();
    });

    $(document).on('click', '[data-myaccount-address-undo]', function(e) {
        var element = $(this);

        (new AddressDelete(element)).undoRemove();
    });

})();
