(function($) {
    'use strict';
    $('.product-list-col-3.col-md-9.main-list').bind('DOMNodeInserted DOMNodeRemoved', function() {
        $(".product-box-image").each(function() {
            var findDafitiEco = $(this).find(".tag-dft-eco");
            var isDafitiEco = findDafitiEco.length;

            var divCatalogLoading = document.querySelector('.catalog-ajax-loading');
            var compStyles = window.getComputedStyle(divCatalogLoading);
            var displayCatalogLoading = compStyles.getPropertyValue('display');

            if (isDafitiEco == '1') {
                $(this).addClass('has-dft-eco');
            }

            if (displayCatalogLoading == "block") {
                $(".tag-dft-eco").css({ "position": "unset" });
            }
        });



    });
})(jQuery);