(function($) {
    'use strict';

    var CatalogFilterMobile = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.options.promotionChanged = false;
        this.options.priceChanged = false;

        this.$filters = this.$element.find('.catalog-filter-item');
        this.$activeFilter = null;
        this.$content = $('#catalog-filter-content');
        this.$header = $('#catalog-filter-mobile-header');
        this.$footer = $('#catalog-filter-footer');
        this.$boxFilter = $('[data-catalog-filters]');
        this.$body = $('body');

        this.init();
    };

    CatalogFilterMobile.prototype.init = function() {
        this.$boxFilter.addClass('is-active');
        this.$element.data('catalog', 'ajax-filter');
        this.setClearFilterStatus();

        this.$element.on('click', '.catalog-filter-item', $.proxy(this, 'setActiveFilter'));
        $(this.$filters[0]).trigger('click');

        this.$content.on('click', '.catalog-filter-list-link, [data-field=submit]', $.proxy(this, 'load'));
        this.$header.on('click', '.catalog-filter-mobile-tag', $.proxy(this, 'load'));
        this.$footer.on('click', '[data-apply], [data-clear]', $.proxy(this, 'closeFilter'));
        this.$footer.on('click', '[data-cancel]', $.proxy(this, 'cancelFilter'));

        this.$content.on('change', '[data-field=promotion]', $.proxy(this, 'promotionFilter'));
        this.$content.on('change', '[data-field=price-max], [data-field=price-min]', $.proxy(this, 'priceFilter'));
    };

    CatalogFilterMobile.prototype.setClearFilterStatus = function() {
        var shouldDisable = this.$header.find('.catalog-filter-mobile-tag').length == 0;
        this.$footer.find('[data-clear]').toggleClass('is-disabled', shouldDisable);
    };

    CatalogFilterMobile.prototype.closeFilter = function(e) {
        if($(e.currentTarget).hasClass('is-disabled')) {
            return e.preventDefault();
        }
        this.$body.removeClass('fixed');
        this.$boxFilter.removeClass('is-active');
    };

    CatalogFilterMobile.prototype.cancelFilter = function(e) {
        var $el = this.$footer.find('[data-cancel]');
        $el.attr('href', window.location.href);
        this.load(e);
        this.closeFilter(e);
    }

    CatalogFilterMobile.prototype.updatePriceFilterUrl = function() {
        var min         = this.$content.find('.catalog-filter-mobile-price-input[data-field=price-min]').val();
        var max         = this.$content.find('.catalog-filter-mobile-price-input[data-field=price-max]').val();
        var promotion   = this.$content.find('[data-field=promotion]');
        var url         = (this.options.promotionChanged) ? promotion.data('url') : this.$footer.find('[data-apply]').attr('href');
        url = (typeof url == 'undefined') ? window.location.href : url;
        var actualParam = new RegExp('([\?&amp;])price=[^&amp;#]*').exec(this.$footer.find('[data-apply]').attr('href'));
        var append      = (url.indexOf("?") > -1) ? '&' : '?';
        var range       = 'price=' + min + '-' + max;
        url = (actualParam) ? url.replace(actualParam[0], actualParam[1]+range) : url+append+range;
        this.$content.find('.catalog-filter-mobile-price').attr('data-url', url);
        this.$footer.find('[data-apply]').attr('href', url);
    };

    CatalogFilterMobile.prototype.promotionFilter = function(e) {
        this.options.promotionChanged = !this.options.promotionChanged;
        this.updatePriceFilterUrl();
    };

    CatalogFilterMobile.prototype.priceFilter = function() {
        this.options.priceChanged = true;
        this.updatePriceFilterUrl();
    };

    CatalogFilterMobile.prototype.setActiveFilter = function(event) {
        event.stopPropagation();
        event.preventDefault();

        this.$filters.removeClass('is-active');
        this.$activeFilter = $(event.currentTarget);
        this.$activeFilter.addClass('is-active');
        if(this.options.priceChanged) {
            var url = this.$footer.find('[data-apply]').attr('href');
            this.$content.find('[data-field=submit]').attr('href', url).trigger('click');
        } else {
            this.renderActiveFilterItems();
            this.renderActiveItemsTags();
        }
    };

    CatalogFilterMobile.prototype.load = function(event) {
        var $el = $(event.currentTarget);
        var url = $el.attr('href');

        event.stopPropagation();
        event.preventDefault();

        if (this.ajaxActive || $el.hasClass('is-disabled')) return ;

        this.ajaxActive = true;

        var sep = (url.indexOf('?') > -1) ? '&' : '?';
        var categoryItem = ($el.hasClass('catalog-filter-category')) ? $el : false;

        $.getJSON(url + sep + 'ajaxFilters=true', $.proxy(this, 'loaded', categoryItem, url)).fail($.proxy(this, 'loaded'));
    };

    CatalogFilterMobile.prototype.loaded = function(categoryItem, url, response) {
        if (!(response instanceof Array)) {
            this.ajaxActive = false;
            this.options.priceChanged = false;
            return;
        }

        this.populateFilter(response);

        if(categoryItem)
            this.setCategoryActive(categoryItem, response);

        this.renderActiveFilterItems();
        this.renderActiveItemsTags();

        this.$footer.find('[data-apply]').attr('href', url);
        this.options.priceChanged = false;
        this.ajaxActive = false;
        this.setClearFilterStatus();
    };

    CatalogFilterMobile.prototype.setCategoryActive = function($el, data) {
        var $ul = this.$content.find('.catalog-filter-list');
        var $template = $ul.find('.catalog-filter-list-item').first().clone();
        $template.find('.is-active').removeClass('is-active');

        if($el.hasClass('is-active')){
            $el.parent().nextAll().children('.is-active').parent().remove();
            $el.parent().remove();
        } else {
            var activeItens = $ul.find('.catalog-filter-list-item .catalog-filter-list-link.is-active');
            if (activeItens.length) {
                $el.attr('data-href', $el.attr('href'));
                $el.attr('href', $(activeItens[activeItens.length-1]).data('href'));
            } else {
                $el.attr('data-href', $el.attr('href'));
                $el.attr('href', window.location.href);
            }
            $el.addClass('is-active');
            $el.find('.catalog-filter-list-link-text').append(' (tudo)');
        }

        $ul.find('.catalog-filter-list-item .catalog-filter-list-link:not(.is-active)').parent().remove();

        var newCategory = data[0];
        if(newCategory.Filter[0].linkClass == 'catalog-filter-category') {
            for (var i = 0; i < newCategory.Filter.length; i++) {
                var actual = $template.clone();
                actual.find('.catalog-filter-list-link')
                    .attr('href', newCategory.Filter[i].link)
                    .addClass(newCategory.Filter[i].linkClass);

                actual.find('.catalog-filter-list-link-text').text(newCategory.Filter[i].name);

                $ul.append(actual);
            }
        }
        this.$activeFilter.find('.catalog-filter-accordion-content').html($ul);
    };

    CatalogFilterMobile.prototype.populateFilter = function(data) {
        for(var i = 0; i < data.length; i++) {
            if (typeof data[i].Filter.isPromotion != 'undefined') {
                this.populatePrice(data[i]);
                continue;
            }

            var color = false;
            var $ul = $('#'+ data[i].inputId).parent().find('.catalog-filter-list');
            var $ancorElements = $ul.find('.catalog-filter-list-item');
            var $template = $($ancorElements[0]).clone();
            $template.find('.catalog-filter-list-link').removeClass('is-disabled is-active');
            $ancorElements.remove();

            if (data[i].Filter[0].color) {
               $template.find('.catalog-filter-list-icon').removeClass().addClass('catalog-filter-list-icon');
                color = true;
            }

            for (var j = 0; j < data[i].Filter.length; j++) {
                var actual = $template.clone();
                actual.find('.catalog-filter-list-link')
                        .attr('href', data[i].Filter[j].link)
                        .addClass(data[i].Filter[j].linkClass);

                if(color) actual.find('.catalog-filter-list-icon').addClass('i-filter-color-'+data[i].Filter[j].color);
                actual.find('.catalog-filter-list-link-text').text(data[i].Filter[j].name);

                $ul.append(actual);
            }
        }
    };

    CatalogFilterMobile.prototype.populatePrice = function(priceJSON) {
        var $priceBlock = $('#'+ priceJSON.inputId).parent();

        $priceBlock.find('.catalog-filter-slider [data-field="price-max"]').text(priceJSON.Filter.priceMax);
        $priceBlock.find('.catalog-filter-mobile-price [data-field="price-max"]')
            .val(priceJSON.Filter.priceMax)
            .attr('max', priceJSON.Filter.priceMax)
            .attr('min', priceJSON.Filter.priceMin);

        $priceBlock.find('.catalog-filter-slider [data-field="price-min"]').text(priceJSON.Filter.priceMin);
        $priceBlock.find('.catalog-filter-mobile-price [data-field="price-min"]')
            .val(priceJSON.Filter.priceMin)
            .attr('max', priceJSON.Filter.priceMax)
            .attr('min', priceJSON.Filter.priceMin);
    };

    CatalogFilterMobile.prototype.renderActiveItemsTags = function() {
        var context = this;
        context.$header.html('');
        var $activeEls = this.$element.find('.catalog-filter-list-link.is-active:not(.catalog-filter-category)');
        if($activeEls.length > 0) {
            $('[data-clear].btn-mobile-filter').removeClass('is-disabled');
            $activeEls.each(function(){
                var $el = $(this);
                var $link = $("<a class='catalog-filter-mobile-tag'><i class='catalog-filter-mobile-tag-icon icon-cross'></i></a>");
                $link.prepend($el.find('.catalog-filter-list-link-text').text());
                $link.attr('href', $el.attr('href'));
                context.$header.append($link);
            });
            context.$header.addClass('is-active');
        } else {
            context.$header.removeClass('is-active');
        }
    };

    CatalogFilterMobile.prototype.renderActiveFilterItems = function() {
        var $el = this.$activeFilter.find('.catalog-filter-accordion-content').clone();
        $el.find('#catalog-filter-price-promotion-input').attr('id', 'catalog-filter-price-promotion-input2');
        $el.find('[for=catalog-filter-price-promotion-input]').attr('for', 'catalog-filter-price-promotion-input2');
        this.$content.html($el);
    };

    $.fn.catalogFilterMobile = function(option) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.catalog-filter-mobile');
            var options = $.extend({}, CatalogFilterMobile.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.catalog-filter-mobile', (data = new CatalogFilterMobile(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('click', '.mobile [data-catalog-mobile-filter]', function(){
        $('#catalog-filter[data-catalog=ajax]').catalogFilterMobile();
        $('body').addClass('fixed');
        $('[data-catalog-filters]').addClass('is-active');
    });

})(jQuery);
