(function($) {

    'use strict';

    var FaqSocial = function(element, options) {
        this.$element = $(element);
        this.options = options;
    };

    FaqSocial.DEFAULTS = {};

    FaqSocial.prototype.rate = function(rating) {
        var $button = this.$element;

        $.ajax({
            type: 'GET',
            url: '/cms/faq/rating/',
            data: {
                'rating': (rating === 'like') ? 1 : 0,
                'id': this.$element.data('questionId')
            },
            success: function(response) {
                $button.addClass('active');

                if (rating === 'dislike') {
                    $('[data-toggle=faq-contact]').removeClass('hide');
                }
            }
        });
    };

    $.fn.faqSocial = function (option, param) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.faq-social');
            var options = $.extend({}, FaqSocial.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.faq-social', (data = new FaqSocial(this, options)));
            if (typeof option == 'string') data[option](param);
        });
    };

    $(document).on('click', '[data-toggle=faq-like]', function(e) {
        e.preventDefault();
        $(this).faqSocial('rate', 'like');
    });

    $(document).on('click', '[data-toggle=faq-dislike]', function(e) {
        e.preventDefault();
        $(this).faqSocial('rate', 'dislike');
    });

})(jQuery);

