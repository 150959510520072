(function($) {

    'use strict';

    var FilterResult = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.$targetList = $('[data-search=' + this.options.target + ']');
        this.type = (this.$targetList.data('search') === 'glossary') ? new GlossaryFilter(this.$targetList) : new FacetFilter(this.$targetList);

        this.$element.on('keyup', $.proxy(this, 'filter'));
    };

    FilterResult.prototype.filter = function() {
        var searchTerm = this.$element.val();

        this.$targetList.find('span').each(function() {
            var $this = $(this),
                relation = $this.html().replace(/\<\/?strong( class="bold")?\>/ig, ''),
                find = relation.toLowerCase().indexOf(searchTerm.toLowerCase());

            if (find >= 0 && !$this.parent().hasClass('is-disabled')) {

                relation = relation.substring(0, find) + '<strong class="bold">' + relation.substring(find, find + searchTerm.length) + '</strong>' + relation.substring(find + searchTerm.length, relation.length);
                $this.html(relation).parent().removeClass('hide');
            } else {
                $this.parent().addClass('hide');
            }
        });

        this.type.show(this.$targetList);
        this.type.noResults(this.$targetList);
    };

    var FacetFilter = function() {};

    FacetFilter.prototype.show = function($targetList) {
        var $message = $('.catalog-filter-search-empty');

        ($targetList.find('.brands-list-item').length === $targetList.find('.hide').length) ? $message.removeClass('hide') : $message.addClass('hide');
    };

    FacetFilter.prototype.noResults = function() {
        /*to be continue*/
    };

    var GlossaryFilter = function() {};

    GlossaryFilter.prototype.show = function($targetList) {
        $targetList.each(function() {
            var $this = $(this);

            if ($this.find('.brands-list-item').length === $this.find('.hide').length) {
                $this.removeClass('active');

            } else {
                $this.addClass('active');

            }
        });
    };

    GlossaryFilter.prototype.noResults = function($targetList) {
        $('#brands-no-results').toggleClass('brands-list', $targetList.hasClass('active'));
    }

    $.fn.filterResult = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.filter-result');
            var options = $.extend({}, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.filter-result', (data = new FilterResult(this, options)));
        });
    };

    $(document).on('focus', '[data-search-input]', function() {
        $(this).filterResult();
    });

})(jQuery); 
