(function($) {

    'use strict';

    var LookUpdate = function(element, options) {
        this.$element = $(element);
        this.options = options;
    };

    LookUpdate.DEFAULTS = {}

    LookUpdate.prototype.update = function() {
        var selectedItems = $("[data-look-update] input:checked");
        var selectedSkus = new Array();
        selectedItems.each(function(index,item){
            selectedSkus.push($(item).val())
        });
        $.getJSON( "/look/price", {'skus': selectedSkus}, $.proxy(this, 'success'));
    };

    LookUpdate.prototype.success = function(response) {
        var $totalLook = $('[data-look-total]');
        $totalLook.find('[data-look-total-price]').html(response.total);
        $totalLook.find('[data-look-total-installments-quantity]').html(response.quantity);
        $totalLook.find('[data-look-total-installments]').html(response.price);
    }


    $.fn.lookUpdate = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.look-update');
            var options = $.extend({}, LookUpdate.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.look-update', (data = new LookUpdate(this, options)));
            }

            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('click', '[data-look-update]', function() {
        $(this).lookUpdate('update');
    });

    $(document).on('dft.recommendation-done', '[data-look-update]' ,function() {
        $(this).lookUpdate('update');
    });

})(jQuery);
