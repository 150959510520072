(function ($) {

    'use strict';

    var Popover = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.$element.addClass('popover-' + options.trigger);

        this.$element.on('click', '[data-dismiss=popover]', $.proxy(this, 'close'));

		if (this.options.highlight) {
			this.$element.attr({
				tabIndex: -1,
				role: 'dialog'
			});
		}

        if (this.options.remote) {
            $.get(this.options.remote, $.proxy(this, 'loaded'));
        }
    }

    Popover.DEFAULTS = {
        trigger: 'hover',
        highlight: false,
        remote: null
    }

    Popover.prototype.loaded = function (data) {
        this.$element.find('.popover').html(data);
        this.$element.trigger('loaded');
    }

    Popover.prototype.open = function (e) {
        if(typeof e !== 'undefined') e.stopPropagation();
        clearPopovers(this.$element);
        this.$element.addClass('is-open');
        if (!this.options.highlight && this.options.trigger != 'click') {
            this.$element.one('mouseleave', $.proxy(this, 'close'));
        }
        if (this.options.trigger == 'click') {
            $(document).one('click', $.proxy(this, 'close'));
        } else {
            $(document).one('touchstart', clearPopovers);
        }
        if (this.options.highlight) {
            this.$element.find('.popover:eq(0)').highlight('open');
			this.$element.find('.highlight-backdrop').attr('data-dismiss', 'popover');
			this.$element.trigger('focus');
			this.enforceFocus();
        }
    }

    Popover.prototype.enforceFocus = function() {
        $(document)
            .off('focusin.dft.popover')
            .on('focusin.dft.popover', $.proxy(function(e) {
                if (this.$element[0] !== e.target && !this.$element.has(e.target).length) {
                    this.$element.trigger('focus')
                }
            }, this));
    }

    Popover.prototype.close = function () {
        $(document).off('focusin.dft.popover');
        this.$element.removeClass('is-open');
        this.$element.trigger('close');
        if (this.options.highlight) {
            this.$element.find('.popover:eq(0)').highlight('close');
        }
    }

    Popover.prototype.toggle = function () {
        this.isOpen() ? this.close() : this.open();
    }

    Popover.prototype.isOpen = function () {
        return this.$element.is('.is-open');
    }

    function clearPopovers(el) {
        var popoverEls = $('.popover-hover.is-open, .popover-manual.is-open, .popover-click.is-open');
        if(typeof el === 'undefined') {
            popoverEls.popover('close');
        } else {
            popoverEls.each(function() {
                if (!$(this).has(el).length) {
                    $(this).popover('close');
                }
            });
        }
    }

    $.fn.popover = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.popover');
            var options = $.extend({}, Popover.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.popover', (data = new Popover(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).on('click', '.touch .popover-manual [data-toggle=popover], .touch [data-trigger=manual] [data-toggle=popover]', function (e) {
        var popover = $(e.currentTarget).parent('.popover-manual, [data-trigger=manual]');
        if (!popover.is('.is-open')) {
            e.preventDefault();
        }
    });

    $(document).on('click', '.touch .popover-hover [data-toggle=popover], [data-trigger=hover] [data-toggle=popover]', function (e) {
        var popover = $(e.currentTarget).parent('.popover-hover');
        if (!popover.is('.is-open')) {
            e.preventDefault();
        }
        popover.popover('open');
    });

    $(document).on('touchstart', '.popover-hover.is-open, .popover-manual.is-open', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', '.popover-click [data-toggle=popover], [data-trigger=click] [data-toggle=popover]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(e.currentTarget).parent('.popover-click, [data-trigger=click]').popover('toggle');
    });

    $(document).on('click', '.popover-click.is-open', function (e) {
        e.stopPropagation();
    });

})(jQuery);
