(function(){
    var ActionController = function ActionController(){
        this.ABTests = [];
    }

    ActionController.prototype.setupABTest = function(options, triggerFunction){
        if ($.url('getParam', options.param) == options.value) {
            this.ABTests.push(options);
            triggerFunction();
        };
    }

    window.dft = window.dft || {};
    
    window.dft.ActionController = ActionController;
})();
