(function ($) {
    'use strict';

    var PdpCarrosselEventsGa4 = function () {

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @param event
         */
        this.dataLayerObject = function (element, position, listName, event) {
            var object = {
                'event': event,
                'ecommerce': {
                    'items': []
                }
            };

            return object;
        }

        /**
         *
         * @param price
         */
         this.sanitizePrice = function (price) {
            return Number(price.replace('R$ ', '').replace(',', '.'));
        }

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @returns {[{quantity: string, list: string, dimension54: (string|string), dimension53: (string|string), dimension55: string, price: number, dimension52: (string|string), dimension51: (string|string), name: string, variant: *, id: *, position, category: (string|string), brand: string}]}
         */
        this.formatProducts = function (element, position, listName) {
            const brand = element.find('h3').text();
            const name = element.find('p').text();
            const price = element.find(".product-box-price-to").text();
            const originalPrice =  element.find(".product-box-price-from").text();
            const discount = this.sanitizePrice(originalPrice) - this.sanitizePrice(price);
            const sku = element.data("sku");
            const categories = element.data("categories").split(",");
            const variant = element.data("variant");
            const seller = element.data("seller");

            return {
                'item_id': sku.toLowerCase(),
                'item_name': name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_brand': brand.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category2': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category3': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'price': this.sanitizePrice(price),
                'discount': parseInt(discount*100)/100,
                'index': position,
                'item_list_name': 'product detail - ' + listName,
                'item_affiliation': 'dafiti br - ' + seller.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                'item_variant': variant.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
            };
        }

        /**
        *
        * @param element
        * @returns {{position: *, closest: (*|jQuery)}}
        */
        this.getClosestAndPosition = function (element) {
            var closest = $(element).closest('.product-guide-item');
            var position = closest.prevAll().length;

            return {'closest': closest, 'position': position};
        }

        /**
        *
        * @param element
        * @returns bool
        */
        this.isElementInViewportGa4 = function (element) {
            var container = $(element).closest('.product-guide-overflow').length > 0 ? $(element).closest('.product-guide-overflow') : $(element).closest('.product-guide-overflow-new-pdp-mobile');

            if(!$(element).is(':visible')) {
                return false;
            }

            return (
                $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left-10) &&
                $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right+10) &&
                $(element)[0].getBoundingClientRect().top >= 0 &&
                $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
            );
        }

        this.getObjectBtnCart = function (element) {
            var closest = element.closest;
            var position = element.position;

            return {'closest': closest, 'position': position};
        };

        return this;
    }

    var _dataLayerFiltered = dataLayer.find(function (e) {
        return e.ET;
    });

    // Verifica se está sendo executado na loja dafiti e se está na página de produtos.
    if (_dataLayerFiltered != undefined && document.location.hostname.indexOf('dafiti') > -1 && _dataLayerFiltered.ET === 'productpage') {
        //WN-403: Taggeamento GA4 - 3 Carrosséis PDP - View Item List
        $(document).on('scroll', window, function(e) {
            var pdpEvents = new PdpCarrosselEventsGa4();
            var object = {};

            $('.carousel-item-new-pdp').each(function(i) {
                if(pdpEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")){
                    $(this).addClass('impressionGA4');
                    var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

                    if(Object.keys(object).length === 0){
                        object = pdpEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                    }

                    var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, 
                        (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                    object.ecommerce.items.push(formattedProduct);
                }
            });

            if(Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }
        });

        $(document).on('click', '.recommendations-prev-new, .recommendations-next-new', function(e) {
            setTimeout(function(){
                var pdpEvents = new PdpCarrosselEventsGa4();
                $('.carousel-item-new-pdp').each(function(i) {
                    if(pdpEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")){
                        $(this).addClass('impressionGA4');
                        var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

                        var object = pdpEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                        var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                        object.ecommerce.items.push(formattedProduct);

                        dataLayer.push(object);
                    }
                });
            }, 500);
        });

        $('.recommendations').on('DOMSubtreeModified', function(){
            $('.product-guide-overflow-new-pdp-mobile').scroll(function(e) {
                var pdpEvents = new PdpCarrosselEventsGa4();
                $('.carousel-item-new-pdp').each(function(i) {
                    if(pdpEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")){
                        $(this).addClass('impressionGA4');
                        var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

                        var object = pdpEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                        var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                        object.ecommerce.items.push(formattedProduct);

                        dataLayer.push(object);
                    }
                });
            });
        });

        //WN-404: Taggeamento GA4 - 3 Carrosséis PDP - Select Item
        $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > a', function () {
            var pdpEvents = new PdpCarrosselEventsGa4();
            var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

            var object = pdpEvents.dataLayerObject(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'select_item_ga4'
            );
            var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

            object.ecommerce.items.push(formattedProduct);

            dataLayer.push(object);
        });

        //WN-406: Taggeamento GA4 - 3 Carrosséis PDP - Add To Wishlist
        $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > div > a.favorite', function () {
            var pdpEvents = new PdpCarrosselEventsGa4();
            var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

            var object = pdpEvents.dataLayerObject(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'add_to_wish_list_ga4'
            );
            var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

            object.ecommerce.items.push(formattedProduct);

            dataLayer.push(object);
        });

        //WN-405: Taggeamento GA4 - 3 Carrosseis PDP - Add To Cart
        $(document).on('click', '.product-box-info > .product-box-form form > button', function () {
            const hasSizeSelected = $(this).parent().find('ul, li').find('.is-selected').data("value");

            if (hasSizeSelected) {
                var pdpEvents = new PdpCarrosselEventsGa4();
                var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

                var object = pdpEvents.dataLayerObject(
                    getClosestAndPosition.closest,
                    (getClosestAndPosition.position + 1),
                    getClosestAndPosition.closest.data('type'),
                    'add_to_cart_ga4'
                );
                var formattedProduct = pdpEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                object.ecommerce.items.push(formattedProduct);

                dataLayer.push(object);
            }
        });
        //WN-405: Taggeamento GA4 - 3 Carrosseis PDP - Add To Cart (Mobile)
        if (isMobileDevice()) {
            $(document).on('click', '.product-box-image-new-pdp.product-box-image-small-new-pdp > div > a.cart.btn-cart', function () {
                var pdpEvents = new PdpCarrosselEventsGa4();
                var getClosestAndPosition = pdpEvents.getClosestAndPosition(this);

                $(document).on('click', '#addToCartModal > div.add-to-cart-recommendation > button', function () {
                    const hasSizeSelected = $('#addToCartModal > div.rcm-choices').find('.active').length;
                    var getObjectBtnCart = pdpEvents.getObjectBtnCart(getClosestAndPosition);

                    if (hasSizeSelected) {
                        var object = pdpEvents.dataLayerObject(
                            getObjectBtnCart.closest,
                            (getObjectBtnCart.position + 1),
                            getObjectBtnCart.closest.data('type'),
                            'add_to_cart_ga4'
                        );
                        var formattedProduct = pdpEvents.formatProducts(getObjectBtnCart.closest, (getObjectBtnCart.position + 1), getObjectBtnCart.closest.data('type'));

                        object.ecommerce.items.push(formattedProduct);

                        dataLayer.push(object);
                    }
                });
            });
        }

        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
        }

    }

})(jQuery);
