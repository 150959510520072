(function ($) {
    'use strict';
    
    var  isProductRemoveEvent = false; 

    var WishlistEventsGa4 = function () {

        /**
         *
         * @param element
         * @param position
         * @param listName
         * @param event
         */
        this.dataLayerObject = function (element, position, listName, event) {
            var object = {
                'event': event,
                'ecommerce': {
                    'items': []
                }
            };

            return object;
        }

    /**
    *
    * @param price
    */
        this.sanitizePrice = function (price) {
        return Number(price
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.'));
    }

    /**
      *
      * @param discountedPrice
      * @param originalPrice
      */
        this.calculateDiscount = function (originalPrice, discountedPrice) {
            return discountedPrice === 0 ? 0 : originalPrice - discountedPrice;
        }

    /**
      *
      * @param discountedPrice
      * @param originalPrice
      */
          this.calculatePrice = function (originalPrice, discountedPrice) {
                return discountedPrice != 0 ? discountedPrice : originalPrice;
            }
        

        /**
         *
         * @param element
         * @param position
         * @returns {[{quantity: string, list: string, dimension54: (string|string), dimension53: (string|string), dimension55: string, price: number, dimension52: (string|string), dimension51: (string|string), name: string, variant: *, id: *, position, category: (string|string), brand: string}]}
         */
        this.formatProducts = function (element, position) {

            const brand = element.find('h3').text().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const name = element.find(".product-box-title").text().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const discountedPrice = element.find(".product-box-price-to").text();
            const originalPrice = element.find(".product-box-price-from").text();
            const discount = this.calculateDiscount(this.sanitizePrice(originalPrice), this.sanitizePrice(discountedPrice));
            const price = this.calculatePrice(this.sanitizePrice(originalPrice), this.sanitizePrice(discountedPrice));
            const sku = element.find(".product-box-sku").text().toLowerCase();
            const categories = element.find(".product-box-categories").text().split(",");
            const variant = element.find(".product-box-color").text().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const seller = element.find(".product-box-seller").text().trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

             var product = {
                'item_id': sku,
                'item_name': name,
                'item_brand': brand,
                'item_category': categories[0] !== undefined ? categories[0].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category2': categories[1] !== undefined ? categories[1].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'item_category3': categories[2] !== undefined ? categories[2].trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : '',
                'price': price,
                'discount': parseInt(discount * 100) / 100,
                'index': position,
                'item_list_name': 'wishlist - no list',
                'affiliation': 'dafiti br - ' + seller,
                'item_variant': variant,
            };
            
            if (isProductRemoveEvent) {
                product['quantity'] = 1;
            } 
            
            return product;
        }

        /**
        *
        * @param element
        * @returns {{position: *, closest: (*|jQuery)}}
        */
        this.getClosestAndPosition = function (element) {
  
            var closest = $(element).closest('.my-account-wishlist-item');
            var position = closest.prevAll().length;
            return { 'closest': closest, 'position': position };
        }

        this.getPositionMobile = function (element) {
  
            var closest = $(element).closest('.row');
            var position = closest.prevAll().length;            
            return { 'closest': closest, 'position': position };
        }

        /**
        *
        * @param element
        * @returns bool
        */
        this.isElementInViewportGa4 = function (element) {
      
            var container = $(element).closest('.my-account-wishlist').length > 0 ? $(element).closest('.my-account-wishlist') : $(element).closest('.product-box-small');

            if (!$(element).is(':visible')) {
                return false;
            }

            return (
                $(element)[0].getBoundingClientRect().left >= ($(container)[0].getBoundingClientRect().left - 50) &&
                $(element)[0].getBoundingClientRect().right <= ($(container)[0].getBoundingClientRect().right + 50) &&
                $(element)[0].getBoundingClientRect().top >= 10 &&
                $(element)[0].getBoundingClientRect().bottom <= (window.innerHeight || $(window).height())
            );
        }


        return this;
    }

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    }

    var _dataLayerFiltered = dataLayer.find(function (e) {
        return e.ET;
    });

    if (_dataLayerFiltered != undefined && document.location.hostname.indexOf('dafiti') > -1 && _dataLayerFiltered.ET === 'myaccount') {

      if (!isMobileDevice()){
        $(window).on("DOMSubtreeModified scroll", function (e) {
            var wishlistEvents = new WishlistEventsGa4();
            var object = {};

            $('.my-account-wishlist-item').each(function (i) {
                if (wishlistEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                    $(this).addClass('impressionGA4');
                    var getClosestAndPosition = wishlistEvents.getClosestAndPosition(this);

                    if (Object.keys(object).length === 0) {
                        object = wishlistEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                    }

                    var formattedProduct = wishlistEvents.formatProducts(getClosestAndPosition.closest,
                        (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                    object.ecommerce.items.push(formattedProduct);
                }
            });

            if (Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }
        });
      } else {
        $(window).on("DOMSubtreeModified scroll", function (e) {
            var wishlistEvents = new WishlistEventsGa4();
            var object = {};

            $('.my-account-wishlist-item').each(function (i) {
                if (wishlistEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                    $(this).addClass('impressionGA4');
                    var getPositionMobile = wishlistEvents.getPositionMobile(this);

                    if (Object.keys(object).length === 0) {
                        object = wishlistEvents.dataLayerObject(
                            getPositionMobile.closest,
                            (getPositionMobile.position),
                            getPositionMobile.closest.data('type'),
                            'view_item_list_ga4'
                        );
                    }

                    var formattedProduct = wishlistEvents.formatProducts(getPositionMobile.closest,
                        (getPositionMobile.position), getPositionMobile.closest.data('type'));

                    object.ecommerce.items.push(formattedProduct);
                }
            });

            if (Object.keys(object).length !== 0) {
                dataLayer.push(object);
            }
        });
      }
     
        $(document).on('click', '.page next  , .page ', function (e) {
            setTimeout(function () {
                var wishlistEvents = new WishlistEventsGa4();
                $('.my-account-wishlist-item').each(function (i) {
                    if (wishlistEvents.isElementInViewportGa4($(this)) && !$(this).hasClass("impressionGA4")) {
                        $(this).addClass('impressionGA4');
                        var getClosestAndPosition = wishlistEvents.getClosestAndPosition(this);

                        var object = wishlistEvents.dataLayerObject(
                            getClosestAndPosition.closest,
                            (getClosestAndPosition.position + 1),
                            getClosestAndPosition.closest.data('type'),
                            'view_item_list_ga4'
                        );
                        var formattedProduct = wishlistEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), getClosestAndPosition.closest.data('type'));

                        object.ecommerce.items.push(formattedProduct);

                        dataLayer.push(object);
                    }
                });
            }, 500);
        });

        $(document).on('click', 'div.my-account-wishlist-item div.product-box div.product-box-image > a > img', function () {
            var wishlistEvents = new WishlistEventsGa4();
            var getClosestAndPosition = wishlistEvents.getClosestAndPosition(this);
          
            var object = wishlistEvents.dataLayerObject(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'select_item_ga4'
            );
            var formattedProduct = wishlistEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1),
            getClosestAndPosition.closest.data('type'));

            object.ecommerce.items.push(formattedProduct);

            dataLayer.push(object);
        });

        $(document).on('click', '.secondary', function () {
            var wishlistEvents = new WishlistEventsGa4();
            var getClosestAndPosition = wishlistEvents.getClosestAndPosition(this);
            isProductRemoveEvent = true;

            var object = wishlistEvents.dataLayerObject(
                getClosestAndPosition.closest,
                (getClosestAndPosition.position + 1),
                getClosestAndPosition.closest.data('type'),
                'remove_from_wishlist_ga4'
            );
            var formattedProduct = wishlistEvents.formatProducts(getClosestAndPosition.closest, (getClosestAndPosition.position + 1), 
            getClosestAndPosition.closest.data('type'));

            object.ecommerce.items.push(formattedProduct);

            dataLayer.push(object);
        });
    }

})(jQuery);
