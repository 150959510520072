(function($){
	'use strict';

	var AnchorSlideTo = function(element, options){
		this.$element = $(element);
		this.options = options;
		this.anchor = this.$element.attr("href");
	};
	AnchorSlideTo.DEFAULTS = {
      interval : 800
    };

    AnchorSlideTo.prototype.slideTo = function(){
    	if (this.anchor || this.anchor !== ""){
			$('html, body').animate({
				scrollTop:$(this.anchor).offset().top
			}, this.options.interval);
		}
    }

	$.fn.anchorSlideTo = function(option) {
		return this.each(function() {
			var $this = $(this);
			var data = $this.data('dft.AnchorSlideTo');
			var options = $.extend({}, AnchorSlideTo.DEFAULTS, $this.data(), typeof option == 'object' && option);
			if (!data) $this.data('dft.AnchorSlideTo', (data = new AnchorSlideTo(this, options)));
			data['slideTo']();
		});
	}
	$(document).on('click','[data-anchorto]',function(){
		$(this).anchorSlideTo();
		return false;
	});
})(jQuery);