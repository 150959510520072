var taxIdValidate = {
    cleanMask: function(tax_id){
        tax_id = jQuery.trim(tax_id).replace('/','').replace('.','').replace('.','').replace('-','').replace('_','');
        return tax_id;
    },
    cpf: function(tax_id){
        var numeros, digitos, soma, i, resultado, digitos_iguais = 1;

        if (tax_id.length < 11){
            return false;
        }

        for (i = 0; i < tax_id.length - 1; i++){
            if (tax_id.charAt(i) != tax_id.charAt(i + 1)){
                digitos_iguais = 0;
                break;
            }
        }

        if (!digitos_iguais){
            numeros = tax_id.substring(0,9);
            digitos = tax_id.substring(9);
            soma = 0;

            for (i = 10; i > 1; i--){
                soma += numeros.charAt(10 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(0)){
                return false;
            }

            numeros = tax_id.substring(0,10);
            soma = 0;

            for (i = 11; i > 1; i--) {
                soma += numeros.charAt(11 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(1)) {
                return false;
            }

            return true;
        } else {
            return false;
        }
    },
    cnpj: function(tax_id){

        var numbers, digits, sum, i, result, pos, size, same_digit = 1;

        if (tax_id.length < 14 && tax_id.length < 15){
            return false;
        }

        for (i = 0; i < tax_id.length - 1; i++){
            if (tax_id.charAt(i) != tax_id.charAt(i + 1)){
                same_digit = 0;
                break;
            }
        }

        if (!same_digit){
            size = tax_id.length - 2
            numbers = tax_id.substring(0,size);
            digits = tax_id.substring(size);
            sum = 0;
            pos = size - 7;

            for (i = size; i >= 1; i--){
                sum += numbers.charAt(size - i) * pos--;
                if (pos < 2){
                    pos = 9;
                }
            }

            result = sum % 11 < 2 ? 0 : 11 - sum % 11;

            if (result != digits.charAt(0)){
                return false;
            }

            size = size + 1;
            numbers = tax_id.substring(0,size);
            sum = 0;
            pos = size - 7;

            for (i = size; i >= 1; i--){
                sum += numbers.charAt(size - i) * pos--;
                if (pos < 2){
                    pos = 9;
                }
            }

            result = sum % 11 < 2 ? 0 : 11 - sum % 11;

            if (result != digits.charAt(1)){
                return false;
            }
            return true;
        }else{
            return false;
        }
    }
};

jQuery.validator.addMethod("repeatNumber", function(value, element) {
    var status = true;

    for (var i = 0; i < 10; i++) {

        if (value.replace(new RegExp(i,'g'),"") == "") {
            status = false;
        }

    }

    return status;
});

jQuery.validator.addMethod("taxId", function(tax_id, element, param) {

    if ( !this.depend(param, element) ) {
        return true;
    }

    var tax_id_cleaned = taxIdValidate.cleanMask(tax_id);

    if(tax_id_cleaned.length === 11) {
        return (taxIdValidate.cpf(tax_id_cleaned));

    } else if(tax_id_cleaned.length >= 14) {
        return (taxIdValidate.cnpj(tax_id_cleaned));
    }

});

jQuery.validator.addMethod("emailConditional", function(value, element, param) {

    if ( !this.depend(param, element) ) {
        return true;
    }

    return this.optional(element) || /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(value);
});

jQuery.validator.addMethod("checkTaxIdAvailability",function(value, element){
    var status;

    status = false;
    checkout.sendForgotPasswordByCpf(value,function(){
        window.location.reload();
    },function(){
        status = true;
    });

    return status;
});

jQuery.validator.addMethod("checkEmailExists",function(value, element,param){
    var status;
    if ( !this.depend(param, element) ) {
        return true;
    }
    status = false;
    checkout.checkEmailExist(value,function(){
        status = true;
    },function(){
        window.location.reload();
    });

    return status;
});

jQuery.validator.addMethod("checkEmailLoginExists",function(value, element, param){

    if ( !this.depend(param, element) ) {
        return true;
    }

    var status;

    status = true;

    checkout.checkEmailExist(value,function(){
        $('#new_email_inp').val( value ).focus();
        status = false;
    },undefined);

    return status;
});

jQuery.validator.addMethod("checkCepError",function(value, element){

    var status = true;

    if($(element).hasClass('error')){
        status = false;
    }

    return status;
});

jQuery.validator.addMethod("expirationDate",function(value, element, param){
    if ( !this.depend(param, element) ) {
        return false;
    }

    $(element).parents('li:eq(0)').find('select.error').removeClass('error');

    return param;
});

jQuery.validator.addMethod("checkBirthdayError",function(value, element,param){

    if ( !this.depend(param, element) ) {
        $(element).parents('li:eq(0)').removeClass('invalidInput').removeClass('validInput');
        return "dependency-mismatch";
    }

    var dayId   = "CheckoutNaturalCustomerInformationForm_birthday_day";
    var monthId = "CheckoutNaturalCustomerInformationForm_birthday_month";
    var yearId  = "CheckoutNaturalCustomerInformationForm_birthday_year";

    var day   = $("#"+dayId).val();
    var month = $("#"+monthId).val();
    var year  = $("#"+yearId).val();

    if( ($(element).attr('id') == dayId || $(element).attr('id') == monthId || $(element).attr('id') == yearId)
        && (!day || !month || !year)) {
        return true;
    }

    value = day + '/' + month + '/' + year;

    try {
        /* Change the below values to determine which format of date you wish to check. It is set to dd/mm/yyyy by default. */
        var DayIndex = 0;
        var MonthIndex = 1;
        var YearIndex = 2;

        value = value.replace(/-/g, "/").replace(/\./g, "/");
        var SplitValue = value.split("/");
        var OK = true;
        if (!(SplitValue[DayIndex].length == 1 || SplitValue[DayIndex].length == 2)) {
            OK = false;
        }
        if (OK && !(SplitValue[MonthIndex].length == 1 || SplitValue[MonthIndex].length == 2)) {
            OK = false;
        }
        if (OK && SplitValue[YearIndex].length != 4) {
            OK = false;
        }
        if (OK) {
            var Day = parseInt(SplitValue[DayIndex], 10);
            var Month = parseInt(SplitValue[MonthIndex], 10);
            var Year = parseInt(SplitValue[YearIndex], 10);

            if (OK = ((Year > 1900) && (Year < new Date().getFullYear()))) {
                if (OK = (Month <= 12 && Month > 0)) {
                    var LeapYear = (((Year % 4) == 0) && ((Year % 100) != 0) || ((Year % 400) == 0));

                    if (Month == 2) {
                        OK = LeapYear ? Day <= 29 : Day <= 28;
                    }
                    else {
                        if ((Month == 4) || (Month == 6) || (Month == 9) || (Month == 11)) {
                            OK = (Day > 0 && Day <= 30);
                        }
                        else {
                            OK = (Day > 0 && Day <= 31);
                        }
                    }
                }
            }
        }
        return OK;
    }
    catch (e) {
        return false;
    }
});

$.fn.clearField = function() {
    return this.each(function() {
        $(this).each(function() {
            var type = this.type, tag = this.tagName.toLowerCase();
            if (type == 'text' || type == 'password' || tag == 'textarea'){
                this.value = '';
            } else if (type == 'checkbox' || type == 'radio'){
                this.checked = false;
            } else if (tag == 'select') {
                this.selectedIndex = 0;
            }
        });
    });
};
