(function($) {

    'use strict';
    var Wishlist = function(element,option) {
        this.$element = $(element);
        this.$container = $('[data-wishlist-item]');
        var option = option;
    }

    Wishlist.prototype.send = function () {
        var current_url = this.$element.attr('href');
        this.$element.attr('href',current_url+"&sku="+($(this.$element.data('sku-source')).val() || ""));
    };

    Wishlist.prototype.addToWishlistAjax = function () {
        var currentElement = this;
        var currentSku = this.$container.attr('data-sku-item');
        $.ajax({
            url: this.$element.attr('data-wishlist-url'),
            data: {sku: ($(this.$element.data('sku-source')).val() || "")},
            type: 'GET',
            global: false,
            beforeSend: function() {
                currentElement.addWishlistLoading();
            },
            success: function(data){
                var response = jQuery.parseJSON(data);
                currentElement.addWishlistItemRemoveInfo(response[currentSku]);
            }
        });
    };

    Wishlist.prototype.removeFromWishlistAjax = function () {
        var currentElement = this;
        currentElement.$container = this.$element;
        if ( $.cookie('customer_info') ) {
            $.ajax({
                url: this.$element.attr('data-wishlist-url'),
                type: 'GET',
                global: false,
                beforeSend: function() {
                    currentElement.addWishlistLoading();
                },
                success: function(){
                    currentElement.addWishlistItemAddInfo();
                }
            });
        }
    };

    Wishlist.prototype.addFromCatalog = function() {
        if ( $.cookie('customer_info') ) {
            $.ajax({
                url: this.$element.attr('data-wishlist-url'),
                beforeSend: function() {
                    //this.$element.find('.wishlist-heart').removeClass('icon-heart').addClass('icon-loading');
                }.bind(this),
                success: function(response) {
                    this.$element.find('.wishlist-heart').addClass('added-item icon-heart');
                }.bind(this)
            });
        } else {
            location.href = this.$element.attr('data-wishlist-url');
        }
    }

    Wishlist.prototype.addWishlistLoading = function () {
        this.$container.find('.wishlist-heart').addClass('hidden').removeClass('icon-heart');
        this.$container.find('.icon-loading').removeClass('hidden');
        this.$container.find('[data-wishlist-button-text]').text('');
    };

    Wishlist.prototype.addWishlistItemAddInfo = function () {
        var currentSku = this.$element.attr('data-sku-item');
        this.$container.find('[data-wishlist-button-text]').text('Adicionar aos Favoritos');
        if ( $.cookie('customer_info') && !this.$container.attr('data-catalog-add-wishlist') ) {
            var link = this.$container.attr('data-wishlist-url').replace('/removeAjax/', '/add/');
            this.$container.attr('data-wishlist-url', link.split('?')[0] + '?p=' +  currentSku);
            this.$container.removeAttr('data-remove-wishlist-ajax').attr('data-add-wishlist-ajax', true);
        }
        this.$container.find('.icon-loading').addClass('hidden');
        this.$container.find('.wishlist-heart').addClass('icon-heart').removeClass('added-item hidden');
        this.$container.removeClass('added-item');
    };

    Wishlist.prototype.addWishlistItemRemoveInfo = function (idCustomerWishlist) {
        this.$container.addClass('added-item');
        if ( $.cookie('customer_info') && !this.$container.attr('data-catalog-add-wishlist') ) {
            var link = this.$container.attr('data-wishlist-url').replace('/add/', '/removeAjax/');
            this.$container.attr('data-wishlist-url', link.split('?')[0] + '?id_customer_wishlist='+idCustomerWishlist );
            this.$container.removeAttr('data-add-wishlist-ajax').attr('data-remove-wishlist-ajax', true);
        };
        this.$container.find('.icon-loading').addClass('hidden');
        this.$container.find('[data-wishlist-button-text]').text('Favorito');
        this.$container.find('.wishlist-heart').removeClass('hidden').addClass('icon-heart added-item');
    };

    Wishlist.prototype.checkWishlistItem = function () {
        var currentElement = this;
        currentElement.$container = this.$element;
        var currentSku = this.$element.attr('data-sku-item');
        if ( $.cookie('customer_info') ) {
            if(!this.$container.attr('data-catalog-add-wishlist')){
                this.$container.attr('data-wishlist-url', this.$container.attr('href')).removeAttr('href');
                this.$container.attr('data-add-wishlist-ajax', this.$container.attr('data-add-wishlist')).removeAttr('data-add-wishlist'); 
            }

            $.ajax({
                url: '/customer/wishlist/userWishlistSkus/',
                type: 'GET',
                global: false,
                beforeSend: function() {
                    currentElement.addWishlistLoading();
                },
                success: function(data) {
                    var response = jQuery.parseJSON(data);
                    if(Boolean(response[currentSku])) {
                        currentElement.addWishlistItemRemoveInfo(response[currentSku]);
                    } else {
                        currentElement.addWishlistItemAddInfo();
                    }
                }
            });
        } else {
            currentElement.addWishlistItemAddInfo();
        }
    };

    Wishlist.prototype.addRemoveLayover = function () {
        var imgHeight = this.$element.siblings('.product-box').height();
        var imgWidth = this.$element.siblings('.product-box').width();
        this.$element.siblings('.my-account-wishlist-remove-layover')
            .removeClass('hide')
            .css({'height': imgHeight+'px' ,'width': imgWidth +'px'});
    };

    Wishlist.prototype.cancelRemoveLayover = function () {
        this.$element.closest('.my-account-wishlist-remove-layover').addClass('hide');
    }

    Wishlist.DEFAULTS = {
    };

    $.fn.wishlist = function(option) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.wishlist');
            var options = $.extend({}, Wishlist.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.wishlist', (data = new Wishlist(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }
    $(document).on('click', '[data-add-wishlist]',function() {
        $(this).wishlist('send');
    });

    $(document).on('click', '[data-add-wishlist-ajax]',function() {
        $(this).wishlist('addToWishlistAjax');
    });

    $(document).on('click', '[data-remove-wishlist-ajax]',function() {
        $(this).wishlist('removeFromWishlistAjax');
    });

    $(document).on('click', '[data-add-wishlist-remove-layer]',function() {
        $(this).wishlist('addRemoveLayover');
    });   

    $(document).on('click', '[data-cancel-wishlist-remove]',function() {
        $(this).wishlist('cancelRemoveLayover');
    });

    $(document).on('click', '[data-catalog-add-wishlist]', function() {
        $(this).wishlist('addFromCatalog')
    });

    $(document).ready(function(){
        if ($('[data-wishlist-item]').length > 0) {
            $('[data-wishlist-item]').wishlist('checkWishlistItem');
        }

        if ($('[data-catalog-add-wishlist]').length > 0) {
            $('[data-catalog-add-wishlist]').wishlist('checkWishlistItem');
        }
        
        $('.my-account-wishlist').find('[data-lazyload]').lazyload({effect : 'fadeIn'});
    });

})(jQuery);