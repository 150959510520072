(function($) {

    'use strict';
    var category1 = '';
    var category2 = '';
    var category3 = '';
    var mergeCategory = '';
    var eventObject = {
        event: 'select_content_ga3',
        category: 'menu'
    };
    var eventObjectGa4 = {
        event: 'select_content_ga4',
        content_type: 'click - menu'
    };
    var classNameTitle = '';
    var typeHtmlTitle = '';

    var prepareEvent = function(e) {

        var tag = e.target.tagName;
        var action = e.target.tagName == 'IMG' ? 'image' : 'text';

        eventObject.action = 'click - ' + action;
        eventObjectGa4.element = action;

        category1 = e.target.closest('.menu-item').querySelector('a').innerText.toLowerCase();
        mergeCategory = category1;
        if (tag == 'IMG') {
            category2 = e.target.alt.toLowerCase();
            mergeCategory = category1 + ' - ' + category2;
        } else if (tag != 'SPAN') {
            category3 = e.target.innerText.toLowerCase();
            mergeCategory = category1 + ' - ' + category3;
            if (classNameTitle == 'menu-dropdown-title' && typeHtmlTitle == 'span') {
                mergeCategory = category1 + ' - ' + category2 + ' - ' + category3;
            }
        }

        eventObject.label = mergeCategory;
        eventObjectGa4.item_id = mergeCategory;
    }

    var sendEvent = function() {
        $(document).trigger(
            'dft.menu-tracking',
            eventObject
        );
        $(document).trigger(
            'dft.menu-tracking',
            eventObjectGa4
        );
    }

    var listenMenu = function() {
        $(document).on('click', '.menu-item a', function(e) {
            classNameTitle = $(this).parent('li').parent('ul').children().eq(0)[0].className;
            typeHtmlTitle = $(this).parent('li').parent('ul').children().eq(0).children().eq(0)[0].localName;

            if (classNameTitle == 'menu-dropdown-title' && typeHtmlTitle == 'span') {
                category2 = $(this).parent('li').parent('ul').children().eq(0).children().eq(0)[0].innerText.toLowerCase();
            }

            prepareEvent(e);
            sendEvent();
        });
    }

    return {
        init: function() {
            listenMenu();
        }
    }

})(jQuery).init();