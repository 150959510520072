(function($) {

    'use strict';

    var CustomerAddressEditAction = function() {};
    CustomerAddressEditAction.prototype = new window.dft.ActionController();

    CustomerAddressEditAction.prototype.run = function() {
        $(document).trigger('dft.events-monitoring');
    };

    routes['customer/address/edit'] = function() {
        (new CustomerAddressEditAction()).run();
    };

    routes['customer/address/create'] = function() {
        (new CustomerAddressEditAction()).run();
    };

    $(document).trigger('customer/address/edit');
    $(document).trigger('customer/address/create');

})(jQuery);
