    (function($) {

    'use strict';

    var Message = function(element) {
        this.$element = $(element);
        this.$container = $('[data-show-message]');
    };

    Message.prototype.clearMessages = function() {
        this.$container.find('.message').remove();
    };

    Message.prototype.remove = function() {
        this.$element.closest('.message').addClass('bye');
        var self = this;
        window.setTimeout(function() { self.$element.closest('.message').remove(); }, 300);
    };

    Message.prototype.show = function(type, args) {
        var template = $(this.template(type));
        var message = args.message;
        if (args.shortMessage) {
            message = '<strong>' + args.shortMessage + '</strong> ' + args.message;
        }
        this.clearMessages();
        template.find('.message-description').html(message);
        this.$container.prepend(template);
    };

    Message.prototype.template = function(type) {
        return  '<div class="message is-' + type + '">' +
                    '<div class="message-icon"> <i class="' + this.getIconType(type) + '"></i> </div>' +
                    '<button class="message-close-button" data-message-remove><i class="message-close icon-cross"></i></button>' +
                    '<span class="message-description"></span> ' +
                '</div>';
    };

    Message.prototype.getIconType = function(type) {
        var availableTypes = {
            'success': 'icon-check-circle',
            'error': 'icon-cross-circle'
        };

        return availableTypes[type];
    };

    /**
     * Event listeners
     */
    $(document).on('dft.message-error', function(e, params) {
        (new Message().show('error', params));
    });

    $(document).on('dft.message-success', function(e, params) {
        (new Message().show('success', params));
    });

    $(document).on('click', '[data-message-remove]', function() {
        var element = $(this);

        (new Message(element).remove());
    });

})(jQuery);
