(function($) {

    'use strict';

    var HeaderFixed = function(element) {
        this.$element = $(element);
        this.$container = $('[data-show-message]');
        this.$headerFull = $('.header-full');
        this.$documentHeight = $(document).height();
        this.windowHeight = $(window).height();
        this.body = $('body');
        var self = this;

        if (this.$documentHeight > (this.windowHeight * 2)) {
            $(window).scroll(function() {
                if ($(this).scrollTop() > 0) {
                    self.addFixedHeader();
                } else {
                    self.removeFixedHeader();
                }
            });
        }
    };

    HeaderFixed.prototype.removeFixedHeader = function() {
        this.$headerFull.removeClass('header-fixed');
        this.body.removeClass('header-abtest-fixed');
    };

    HeaderFixed.prototype.addFixedHeader = function() {
        this.$headerFull.addClass('header-fixed');
        this.body.addClass('header-abtest-fixed');
    };

    jQuery(document).ready(function($) {
        if ($('[data-fixed-header]').length > 0)
            (new HeaderFixed());
    });

})(jQuery);


jQuery(document).ready(function($) {

    'use strict';

    var AUTOCOMPLETE_MIN_SEARCH_LENGTH = 2;
    var INPUT_SEARCH_SELECTOR = '[data-search-input="main-search"]';
    var $headerFull = $('.header-full');

    function canShowTopSearch(context) {
        return $(context).val().length < AUTOCOMPLETE_MIN_SEARCH_LENGTH;
    }

    function showOverlay() {
        $('[data-header-overlay]').addClass('is-active');
    }

    function hideOverlay() {
        $('[data-header-overlay]').removeClass('is-active');  
    }

    function showTopSearch() {
        $('.menu-item').css('pointer-events', 'none');
        $('.help-menu').addClass('is-active');
        
        $('.default-autocomplete-box').addClass('is-active');
    }

    function hideTopSearch() {
        $('.menu-item').css('pointer-events', 'auto');
        $('.help-menu').removeClass('is-active');
        $('.default-autocomplete-box').removeClass('is-active');
    }

    $(document).on('touchstart', '.tablet .menu-item', function() {
        $('.menu-item').removeClass('is-active is-hover');
        $('.menu.sub-menu').removeClass('is-active');
        $('.help-menu').removeClass('is-active');
        $('[data-header-overlay]').removeClass('is-active');
        if ($(this).find('.menu-dropdown').length > 0) {
            $(this).toggleClass('is-active is-hover');
            if ($(this).hasClass('is-active')) {
                $('.menu.sub-menu').addClass('is-active');
                $('.help-menu').addClass('is-active');
                $('[data-header-overlay]').addClass('is-active');
            }
        }
    });

    $headerFull.find('.menu-item').hover(function() {
        if ($(this).find('.menu-dropdown').length > 0) {
            $(this).addClass('is-hover');
            $(this).find('.menu-dropdown').addClass('is-active');
            $('.menu.sub-menu').addClass('is-active');
            $('.help-menu').addClass('is-active');
            $('[data-header-overlay]').addClass('is-active');
            $('[data-popover-go-to-cart]').removeClass('is-active');
        }
    }, function() {
        $(this).removeClass('is-hover');
        $('.menu.sub-menu').removeClass('is-active');
        $('.help-menu').removeClass('is-active');
        $('[data-header-overlay]').removeClass('is-active');
        $(this).find('.menu-dropdown').removeClass('is-active');
    });

    $(INPUT_SEARCH_SELECTOR).focus(function(e) {
        if (canShowTopSearch(this)) {
            showTopSearch();
        }
        showOverlay();
        e.preventDefault();
    });

    $(INPUT_SEARCH_SELECTOR).blur(function () {
        hideTopSearch();
        hideOverlay();
    });

    $(INPUT_SEARCH_SELECTOR).keyup(function () {
        if (!canShowTopSearch(this)) {
            hideTopSearch();
        } else {
            showTopSearch();
        }
    });

    $(document).on('touchstart', 'body.tablet', function(e) {

        if ($(e.target).hasClass('search-input')) {
            if ($(e.target).is(':focus'))
                return false;
        }

        if ($(e.target).hasClass('menu-link')) {
            if ($(e.target).next('.menu-dropdown').length > 0)
                return false;
        }
        if (!$(e.target).hasClass('menu-dropdown-link')) {
            $('.menu-item').removeClass('is-active is-hover');
            $('.menu.sub-menu').removeClass('is-active');
            $('.help-menu').removeClass('is-active');
            $('[data-header-overlay]').removeClass('is-active');
            $('[data-search-input="main-search"]').blur();
        }
    });

    $(document).on('mouseover', '.cart-preview-link, .header-login-welcome', function() {
        $('[data-popover-go-to-cart]').removeClass('is-active');
        $('body').trigger('click');
        $('.search').removeClass('is-open');
        $('[data-search-input="main-search"]').trigger('blur');
    });

    $(document).on('click', '.touch.tablet .cart-preview-link, .header-login-welcome', function(event) {
        event.preventDefault();
    });

});
