(function($) {

    'use strict';

    var StockAvailable = function(element, options) {
        this.$element = $(element);
        this.$button = this.$element.find(options.formButton || '.add-to-cart-form-button');
        this.options = options;
        this.ajaxActive = false;
        this.hasColorCarousel = !!$(element).parent().children('.catalog-detail-colors').length;
        this.$colorCarousel = $('.catalog-detail-colors').find('.carousel-items');
    };

    StockAvailable.DEFAULTS = {
        url: null,
        async: true,
        formButton: null
    };

    StockAvailable.prototype.load = function() {
        if (!this.ajaxActive) {
            this.ajaxActive = true;
            $.ajax({
                url: this.options.url,
                async: true,
                cache: false,
                global: false
            }).success($.proxy(this, 'loaded'))
                .error($.proxy(this, 'loaded'));
        }
    };

    StockAvailable.prototype.loaded = function(data) {
        var isSoldOut = this.checkIsSoldOut(data.sizes);

        if (isSoldOut) {
            $(document).trigger('dft.product-sold-out');
        }

        var sku = this.$button.siblings('[name=p]').val();
        var sizes = typeof data.sizes == 'undefined' ? [] : data.sizes;

        this.$element.find('.add-to-cart-form').addToCartForm('sizes', sizes);
        this.$element.toggleClass('is-sold-out', isSoldOut);

        if (isSoldOut) {
            this.$button.removeAttr('data-add-to-cart-form-button')
                .addClass('sold-out')
                .attr('type', 'button')
                .attr('data-remote', '/catalog/sizeReminder?sku=' + sku)
                .attr('data-toggle' , 'modal')
                .attr('data-class-name' , 'modal-size-reminder')
                .text('Avise-me');
        }

        if (window.location.pathname.search('/wishlist/') > 0 && isSoldOut) {
            this.$button.text('Produto esgotado').removeAttr('disabled');
        }

        this.$element.find('[data-field=price]').text(data.price);
        this.$element.find('[data-field=installments-count]').text(data.installments && data.installments.count);
        this.$element.find('[data-field=installments-value]').text(data.installments && data.installments.value);
        this.specialPriceAvailable(data.specialPrice);

        var installmentCampaign = this.checkIfHaveInstallmentCampaign(data.campaigns);
        this.setPercentageInstallmentCampaign(installmentCampaign);

        this.setCashDiscountFinalPrice(installmentCampaign, data.price);
        this.setCashDiscountOriginalPrice(data.price);

        $('.appears-cash-discount-info').mouseover(function(){
            $('.info-cash-discount-popup').show();
        }).mouseout(function(){
            $('.info-cash-discount-popup').hide();
        });

        if (isSoldOut) {
            this.$button.html(this.$button.data('disabled-title'));
            this.$element.find('.netotiate-row').remove();
        }
        if(this.hasColorCarousel && typeof data.colors !== "undefined") {
            this.colorsCarousel(data.colors);
        }
        this.$element.addClass('is-loaded');
        this.$element.find('.loading-dialog').addClass('is-loaded');
        this.$element.trigger('loaded');
        this.ajaxActive = false;
        this.sizeGuide();
    };

    StockAvailable.prototype.setCashDiscountFinalPrice = function(installments, price) {
        if (installments) {
            var discountPrice = installments.discountPrice;
            this.$element.find('[data-field=finalPrice]').text(discountPrice);
        } else {
            this.$element.find('[data-field=finalPrice]').text(price);
        }
    };

    StockAvailable.prototype.setCashDiscountOriginalPrice = function(installments, price) {
        if (installments) {
            this.$element.find('[data-field=cashDiscountOriginalPrice]').text(price);
        }
    };

    StockAvailable.prototype.sizeGuide = function() {
        var hasSizeGuide = this.$element.find('[data-size-guide]').children().hasClass('stock-available-sizeguide-title');
        if (!hasSizeGuide) {
            this.$element.find('[data-sizes]').removeClass('col-xs-9').addClass('col-xs-12');
        }
    }

    StockAvailable.prototype.colorsCarousel = function(colors) {
        var colorElementBase  = this.$colorCarousel.find('[data-color]');
        const isLoaded = colorElementBase.hasClass('is-loaded');

        if (colors.length > 0) {
            if (isLoaded) return;
            
            $('[data-color-carousel]').removeClass('hide');
            if( colors.length > 4) {
                $('[data-color-navigation]').removeClass('hide');
            }

            var that = this;
            colorElementBase.addClass('is-loaded');
            $.each(colors,function(index,color) {
                var colorElement = colorElementBase.clone();

                colorElement.find('[data-color-link]').attr('href',color.link);
                colorElement.find('[data-color-link]').attr('title',color.name);
                colorElement.find('[data-color-img]').attr('src',color.image_url);
                colorElement.appendTo(that.$colorCarousel);
            });

            colorElementBase.remove();
            this.$element.find('[data-color-carousel]').toggle();
        } else {
            colorElementBase.closest('[data-color-carousel]').addClass('hide');
            $('[data-color-carousel-line]').addClass('hide');
        }
    };

    StockAvailable.prototype.specialPriceAvailable = function(specialPrice) {
        if (specialPrice) {
            this.$element.find('[data-field=specialPrice]').text(specialPrice);
        } else {
            this.$element.find('[data-text=from]').remove();
            this.$element.find('[data-text=to]').remove();
        }
    };

    StockAvailable.prototype.checkIfHaveInstallmentCampaign = function (campaigns) {
        return (typeof campaigns == "undefined" || typeof campaigns.installments == "undefined"
                || campaigns.installments.length == 0)
            ? false
            : campaigns.installments;
    };

    StockAvailable.prototype.setPercentageInstallmentCampaign = function(installments) {
        if (installments) {
            var element = this.$element.find('[data-field=percentage]');
            if (element.length > 0) {
                element.text(installments.discountPercentage);
            }
        } else {
            this.$element.find('[data-text=percentage]').remove();
        }
    };

    StockAvailable.prototype.checkIsSoldOut = function(sizes) {
        var count = 0;

        for (var i in sizes) {
            count += parseInt(sizes[i].stock);
        }

        return count == 0;
    };

    $.fn.stockAvailable = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.stockavailable');
            var options = $.extend({}, StockAvailable.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.stockavailable', (data = new StockAvailable(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

})(jQuery);
