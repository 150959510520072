(function($) {

    'use strict';

    var CheckEmailExists = function(element, options) {
        this.$element   = $(element);
        this.options    = options;

        this.$form = this.$element.closest('form');
        this.pageContext = this.$element.data('checkEmailExists');
        this.originalEmail = '';
    };

    CheckEmailExists.DEFAULTS = {
        'messages' : {
            'required' : 'Campo obrigatório',
            'email' : {
                 'invalid' : 'Endereço de e-mail inválido.',
                 'exists' : 'Este email já está cadastrado'
            }
        }
    };

    // saves a temporary copy so only checks if the customer try with a diferent email
    CheckEmailExists.prototype.saveCachedEmail = function() {
        this.originalEmail = this.$element.val();
    };

    CheckEmailExists.prototype.validateType = function() {
        this.removeErrors();

        if (this.$element.val().isEmail() === false) {
            this.addError(this.options.messages.email.invalid);
            return false;
        }

        return true;
    };

    CheckEmailExists.prototype.validateOnDatabase = function() {
        if (this.validateType() === false) return;

        this.$form.removeClass('is-email-valid');

        if (this.$element.val() === this.originalEmail) {
            return this.$form.addClass('is-email-valid');
        }

        this.load();
    };

    CheckEmailExists.prototype.load = function() {
        var params = {
            'email' : this.$element.val(),
            'page' : this.pageContext
        };

        $.getJSON('/checkout/index/CheckEmailExist/', params, $.proxy(this, 'loaded'));
    };

    CheckEmailExists.prototype.loaded = function(data) {
        if (data.response) {
            return this.addError(this.options.messages.email.exists);
        }

        this.removeErrors();
        this.$form.addClass('is-email-valid');
        this.$form.submit(); 
    };

    CheckEmailExists.prototype.addError = function(message) {
        this.$form.removeClass('is-email-valid');
        this.$element.removeClass('is-valid').addClass('is-invalid');
        window.ParsleyUI.addError(this.$element.parsley(), 'email-exists', message);
    };

    CheckEmailExists.prototype.removeErrors = function() {
        this.$element.removeClass('is-invalid');
        window.ParsleyUI.removeError(this.$element.parsley(), 'email-exists');
    };

    $.fn.checkEmailExists = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.check-email-exists');
            var options = $.extend({}, CheckEmailExists.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.check-email-exists', (data = new CheckEmailExists(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('blur', '[data-check-email-exists]', function(e) {
        $(this).checkEmailExists('validateType');
    });

    $(document).on('click', '#myaccount-submit-edit', function(e) {
        $('[data-check-email-exists]').checkEmailExists('validateOnDatabase');
    });

    $(document).ready(function(e) {
        $('[data-check-email-exists]').checkEmailExists('saveCachedEmail');
    });

})(jQuery);
