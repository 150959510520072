(function($) {

    'use strict';

    var CartGift = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.templateModalGift = $('script[type="text/template-modal-cms-info-gift"]').html();
    };

    CartGift.DEFAULTS = {};

    CartGift.prototype.update = function() {
        var args = {
            'sku_simple': this.$element.data('cart-gift-wrap'),
            'status': (this.$element.prop('checked')) ? 1 : 0
        };

        $.getJSON('/cart/giftWrap/update', args, $.proxy(this, 'loaded'));
    };

    CartGift.prototype.loaded = function(response) {
        this.$element.closest('[data-cart-store-type]')
            .find('[data-cart-gift-label="' + response.item.sku_simple + '"]')
            .toggleClass('is-active', response.item.gift_wrap.is_selected);

        $(document).trigger('dft.cart-gift.checked', [response]);

        if (this.templateModalGift && response.item.gift_wrap.is_selected) {
            $('body').modal('destroy');
            $('body').removeData('dft.modal');
            $('body').modal({
                'modalContent': this.templateModalGift
            });
        }
    };

    $.fn.cartGift = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.cart-gift');
            var options = $.extend({}, CartGift.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('dft.cart-gift', (data = new CartGift(this, options)));
            }

            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '[data-cart-gift-wrap]', function() {
        $(this).cartGift('update');
    });

})(jQuery);
