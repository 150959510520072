(function($){
	'use strict';

	var ReviewItems = function(element,options){
		this.$element = $(element);
		this.options = options;
		this.count = this.$element.find('.rating-item').length;
		this.$button = $('[data-reviews-loadmore]');
		this.buttonText = this.$button.text();
		this.loaderText = "carregando ...";
	}

	ReviewItems.prototype.load = function(){
		this.$button.text(this.loaderText);
		$.ajax({
			method: "get",
			url : "/catalog/PaginatedReviews",
			data : {
				sku : this.options.sku,
				offset : this.count
			},
			success: $.proxy(this,'loaded')
		});
	}

	ReviewItems.prototype.loaded = function(data){
		this.$element.append(data);
		$('html, body').animate({
        	scrollTop: $(".rating-item").eq(this.count).offset().top
    	}, 800);
		this.count = this.$element.find('.rating-item').length;
		this.$button.text(this.buttonText);
		if (this.count == this.options.max){
			this.$button.hide();
		}
	}
	
	$.fn.ReviewItems = function(option, args) {
		return this.each(function() {
			var $this = $(this);
			var data = $this.data('dft.review-items');
			var options = $.extend({}, $this.data(), typeof option == 'object' && option);
            if (!data) $this.data('dft.review-items', (data = new ReviewItems(this, options)));
            if (typeof option == 'string') data[option](args);
            else if (options.show) data.open();
		});
	}

	$(document).ready(function(){
		$('[data-reviews-loadmore]').on('click', function(){
			$('.rating-itens').ReviewItems('load');
			return false;
		});
	});

})(jQuery);