(function($){
    'use strict';
    var Tutorial = function (element, options, steps) {
        this.$element = element;
        this.options = options;
        this.steps = steps;
        this.index = 0;
        this.identifier = 'dft.Tutorial.' + this.options.name;
        //check if there is a timer to start the tutorial. 
        if (this.options.timer && !this.getLocalStorage()) {
            var timer = this.options.timer * 1000;
            window.setTimeout($.proxy(this, 'init'), timer);
        }
        if (this.options.triggeredBy) {
            this.$element.on(this.options.triggeredBy.event, this.options.triggeredBy.selector, $.proxy(this, 'init'));
        }
    }
    Tutorial.DEFAULTS = {
        timer : false
    }
    //inicia o tutorial.
    Tutorial.prototype.init = function() {
        var that = this;
        this.index = 0;
        this.readStep(this.steps[0]);
        this.$element.on("step_complete.tutorial",$.proxy(this, 'moveNextStep'));
        this.$element.on("finish.tutorial", $.proxy(this, 'endTutorial'));
        this.$element.on("click.tutorial", "[data-dismiss=tutorial]", $.proxy(this, 'endTutorial'));
        return false;
    }
    //Inicia um step.
    Tutorial.prototype.readStep = function(step) {
        var that = this;
        var $highlight = this.$element.find(step.highlightItem);
        var $selector = $(step.selector);

        //exibe um tooltip baseado no posicionamento do $highlight.
        this.createTooltip(step.title, step.text, step.position, $highlight);
        $highlight.highlight('open');

        //escuta o evento de prosseguir
        var step_event = step.event + ".tutorial"
        this.$element.one(step_event, step.selector, function(){
            that.$element.trigger("step_complete.tutorial");
            $highlight.highlight('close');
        });
    }
    Tutorial.prototype.moveNextStep = function() {
        //close tooltips and highlights.
        $('.tutorial-tip').remove();
        //move next and if it's the last position end
        this.index++;
        if (this.index == this.steps.length){
            this.$element.trigger("finish.tutorial");
        }else{
            this.readStep(this.steps[this.index]);
        }
    }
    Tutorial.prototype.endTutorial = function() {
        var that = this;
        $('.tutorial-tip').remove();
        this.$element.off(".tutorial");
        $.each(this.steps, function(index,value){
            that.$element.off(value.event + ".tutorial", value.selector)
        });
        this.$element.off(".tutorial", this.steps[this.index].selector);
        this.$element.find('.highlight').highlight('close');
        this.setLocalStorage();
    }
    Tutorial.prototype.createTooltip = function(title, text, position, highlight) {
        var $highlight = highlight;
        var template =  "<div class=\"tutorial-tip " + position +"\">" +
                        "<h1 class=\"title\">" + title + "</h1>" +
                        "<button type=\"button\" class=\"tutorial-close icon-cross\" data-dismiss=\"tutorial\" aria-hidden=\"true\"></button>" +
                        text +
                        "</div>";

        var $html =  $(template);
        $html.appendTo(this.$element);

        var highlightSize = {
            width : $highlight.width(),
            height : $highlight.height(),
            paddingLeft : parseInt($highlight.css('padding-left').replace('px','')),
            paddingRight : parseInt($highlight.css('padding-right').replace('px','')),
            paddingTop : parseInt($highlight.css('padding-top').replace('px','')),
            paddingBottom : parseInt($highlight.css('padding-bottom').replace('px',''))
        }
        var tooltipSize = {
            width : $html.outerWidth(),
            height: $html.outerHeight()
        }
        var hOff = $highlight.offset();
        var offset = this.calculatePosition(position, hOff, highlightSize, tooltipSize);
        $html.css({top: offset.top + 'px' , left : offset.left + 'px'});

        $('html, body').animate(
            {scrollTop : offset.top - (highlightSize.height/2) },
        1500);

    }
    Tutorial.prototype.calculatePosition = function(position, offset, highlightSize, tooltipSize){
        if (position === "left"){
            offset.left -= tooltipSize.width + 10;
            if (highlightSize.height < tooltipSize.height){
                offset.top  -= tooltipSize.height/2 - highlightSize.height/2;
            }else{
                offset.top  += highlightSize.height/4;
            }
        }
        if (position === "right"){
            offset.left += tooltipSize.width + 10;
            if (highlightSize.height < tooltipSize.height){
                offset.top  -= tooltipSize.height/2 - highlightSize.height/2;
            }else{
                offset.top  += highlightSize.height/4;
            }
        }
        if (position === "top"){
            offset.left += highlightSize.paddingLeft + ((highlightSize.width - tooltipSize.width)/2);
            offset.top -=  tooltipSize.height + 10;
        }
        if (position === "bottom"){
            offset.left += highlightSize.paddingLeft + ((highlightSize.width - tooltipSize.width)/2);
            offset.top += highlightSize.height + 10;
        }
        return offset;
    }

    Tutorial.prototype.hasLocalStorage = function() {
        return ('localStorage' in window && window['localStorage'] !== null);
    };

    Tutorial.prototype.getLocalStorage = function() {
        return (this.hasLocalStorage()) ? localStorage.getItem(this.identifier) : false;
    };

    Tutorial.prototype.setLocalStorage = function() {
        if (this.hasLocalStorage()) {
            localStorage.setItem(this.identifier, false);
        }
    };
    $.dfTutorial = function(options,steps) {
        if (!options.name){
            return false;
        }
        var $this = $('body');
        var data = $this.data('dft.tutorial');
        var option = $.extend({}, Tutorial.DEFAULTS, typeof options == 'object' && options);
        if (option.name === "" && typeof option.name != "string"){
            return false;
        }

        if (!data) $this.data('dft.tutorial', (data = new Tutorial($this, option, steps)));
        if (typeof option == 'string') data[option]();
        else if (options.show) data.open();
    }
})(jQuery);
