(function ($) {
    "use strict";

    var ProductCampaigns = function (element, options) {
        this.$element = $(element);
        this.options = options;
    };

    ProductCampaigns.DEFAULTS = {
        productCampaignSku: "",
        seeMore: "",
    };

    ProductCampaigns.prototype.productCampaignsTracking = function (
        element,
        sku
    ) {
        if (window.dataLayer) {
            element.find(".campaign-item > a").on("click", function () {
                var id = $(this).closest(".campaign-item").data("id");
                dataLayer.push({
                    event: "label_ga3",
                    category: "productPage",
                    action: "click - campanhas participantes",
                    label: sku + " - " + id,
                });
            });
            dataLayer.push({
                event: "label_ga3",
                category: "productPage",
                action: "view - campanhas participantes",
                label: sku,
            });
        }
    };

    ProductCampaigns.prototype.fetchCampaigns = function () {
        return $.get(
            "/product/campaigns?sku=" + this.options.productCampaignSku
        );
    };

    ProductCampaigns.prototype.getTemplate = function () {
        var html = "";
        var that = this;
        var campaigns = this.campaigns.slice(0, 3);

        campaigns.forEach(function (item) {
            if (item.landingpage) {
                html +=
                    '<li data-id="' +
                    item.id +
                    '" class="campaign-item"><i class="icon-tag"></i>' +
                    item.name +
                    '<a href="' +
                    item.landingpage +
                    '" title="' +
                    item.description +
                    '">' +
                    that.options.seeMore +
                    "</a></li>";
            }
        });

        return html;
    };

    ProductCampaigns.prototype.render = function () {
        if (!this.campaigns.length) {
            return false;
        }
        var $content = this.$element.find(".list-campaigns");
        var sku = $content
            .closest("#product-campaigns")
            .data("product-campaign-sku");
        var html = this.getTemplate();
        $content.html(html);
        this.productCampaignsTracking($content, sku);
        this.$element.removeClass("hide");
    };

    ProductCampaigns.prototype.init = function () {
        var that = this;
        var promise = this.fetchCampaigns();
        promise.done(function (result) {
            that.campaigns = result.campaigns || [];
            that.render();
        });
    };

    $.fn.campaigns = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var $data = $this.data("dft.product-campaigns");
            var options = $.extend(
                {},
                ProductCampaigns.DEFAULTS,
                $this.data(),
                typeof option == "object" && option
            );
            if (!$data)
                $this.data(
                    "dft.product-campaigns",
                    ($data = new ProductCampaigns(this, options))
                );
            if (typeof option == "string") $data[option](args);
            $data.init();
        });
    };

    $("[data-product-campaign-sku]").campaigns();
})(jQuery);
