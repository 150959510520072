
var taxIdValidate = {
    cleanMask: function(tax_id){
        tax_id = jQuery.trim(tax_id).replace('/','').replace('.','').replace('.','').replace('-','').replace('_','');
        return tax_id;
    },
    cpf: function(tax_id){
        var numeros, digitos, soma, i, resultado, digitos_iguais = 1;

        if (tax_id.length < 11){
            return false;
        }

        for (i = 0; i < tax_id.length - 1; i++){
            if (tax_id.charAt(i) != tax_id.charAt(i + 1)){
                digitos_iguais = 0;
                break;
            }
        }

        if (!digitos_iguais){
            numeros = tax_id.substring(0,9);
            digitos = tax_id.substring(9);
            soma = 0;

            for (i = 10; i > 1; i--){
                soma += numeros.charAt(10 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(0)){
                return false;
            }

            numeros = tax_id.substring(0,10);
            soma = 0;

            for (i = 11; i > 1; i--) {
                soma += numeros.charAt(11 - i) * i;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(1)) {
                return false;
            }

            return true;
        } else {
            return false;
        }
    }
};

var checkout = {

    modalIdEmailCpfValidate: '.modal-forgot-email',
    modalIdEmailExists: '#modal-email-exists',
    formAccountLoginEmailExists: '#form-account-login-email-exists',
    /*
     * Event for New User Register Click
     *
     **/
    loadRemenberPassView: function(type, callback, modalName) {
        modalName = modalName || 'is-default';
        $('body').modal({remote: '/checkout/index/getRemenberView/?b2014=1&type='+type});
    },

    /*
     * Event for Remenber Password By Email Click
     *
     **/
    checkoutIndexRemenberPasswordByEmail: function(modalName) {
        modalName = modalName || 'is-default';
        //remove error messages
        $('.usps').css('z-index',0);
        $('#CheckoutLogin').find('label.error').remove();

        var redirect_path = $('.sel-checkout-new-client').attr('href');
        checkout.loadRemenberPassView('email', function(data) {
            location.href = redirect_path;
        },modalName);
    },

    /*
     * Event for Remenber Password By Cpf Click
     *
     **/
    checkoutIndexRemenberPasswordByCpf: function(modalName) {
        modalName = modalName || 'is-default';
        $('.usps').css('z-index',0);
        $('#CheckoutLogin').find('label.error').remove();

        var redirect_path = $('.sel-checkout-new-client').attr('href');

        checkout.loadRemenberPassView('tax', function(data) {
            location.href = '/checkout/index/redirectCheckout';
        },modalName);
    },

    /*
     * Set erroros for Modal Validations
     *
     **/
    setModalError: function(errors, parentElement, element) {
        if (typeof parentElement == 'undefined') {
            parentElement = checkout.modalIdEmailCpfValidate;
        } else {
            $(parentElement).addClass('invalidInput');
        }

        if (typeof element !== 'undefined') {
            $(element).addClass('error');
        }

        $(element).unbind('click').click(function() {
            $(this).removeClass('error');
            $(parentElement).removeClass('invalidInput');
        });

        errorElement = $(parentElement + ' .input-description.description-error');
        hotlineElement = $('#hotline');
        errorElement.show();
        errorElement.html('');
        hotlineElement.hide();
        $.each(errors, function(index, error) {
            if (errorElement.html() == '') {
                errorElement.append(error);
                hotlineElement.show();
            }
        });
        errorElement.siblings('input').css('border-color','#e74c3c');

        $(parentElement + ' input').one('focus', function() {
            errorElement.html(' ');
            hotlineElement.hide();
            errorElement.siblings('input').css('border-color','#CCCCCC');
        });
    },

    /*
     * Send a recovery password email
     *
     **/
    sendForgotPassword: function(email,recaptcha,modalName) {
        $.ajax({
            url: '/customer/account/forgotpassword/',
            type: 'GET',
            async: false,
            data: {
                'ForgotPasswordForm[email]': email,
                'ForgotPasswordForm[recaptchabox]': recaptcha,
                'ForgotPasswordForm[json]': 'true',
                'b2014': 1
            },
            success: function(data) {
                if (data.response == true) {
                    $(".modal-container:last").html(data.template);
                } else {
                    if (data.errors.email['noRecordFound']) {
                        data.errors.email['noRecordFound'] = i18n.notModalRegisteredEmail;
                    }
                    checkout.setModalError(data.errors.email);
                }
            }
        });
    },

    /*
     * Send a recovery password email
     *
     **/
    sendForgotPasswordByCpf: function(cpf, callbackLogin, callbackError, modalName) {

        modalName = modalName || 'is-default';

        $.ajax({
            url: '/checkout/index/CheckTaxIdentificationExist/',
            type: 'GET',
            async: false,
            data: {
                'tax': cpf,
                'page': 'checkout',
                'b2014': 1
            },
            success: function(data) {
                if (data.response == true) {

                    $('.cl-generalModal.'+modalName).trigger("click");

                    // this selector must exists on pages where the modal will be prompted (ex.: checkout, login)

                    $(".modal-forgot-email .modal-container").html(data.template);

                    var loginSubmit = function(event) {
                        if (event.which == 13 || event.type != 'keypress') {
                            event.preventDefault();
                            checkout.makeAjaxLogin(
                                '',
                                $(checkout.modalIdEmailCpfValidate + ' #recovered-pass').val(),
                                $(checkout.modalIdEmailCpfValidate + ' #LoginWithCaptchaForm_captcha').val(),
                                $('#email-exists-recaptcha').val(),
                                $(checkout.modalIdEmailCpfValidate + ' input[name=YII_CSRF_TOKEN]').val(),
                                callbackLogin
                            );
                        }
                    };

                    $(checkout.modalIdEmailCpfValidate + ' #do-login').click(loginSubmit);
                    $(checkout.modalIdEmailCpfValidate + ' input').on('keypress', loginSubmit);

                    $(checkout.modalIdEmailCpfValidate + ' #recovered_email_password').click(function(event) {
                        event.preventDefault();
                        checkout.sendForgotPassword($(checkout.modalIdEmailCpfValidate + ' #recovered_email').val());
                    });
                } else {
                    if (typeof callbackError == 'function') {
                        callbackError();
                    } else {
                        checkout.setModalError(data.errors.tax);
                    }
                }
            }
        });
    },

    makeAjaxLogin: function(email, password, captcha, recaptcha, token, callback) {
        if (recaptcha) {
            var formName = 'LoginWithGoogleReCaptchaForm';
        } else {
            var formName = ($('.login-verify-code-input').length > 0) ? 'LoginWithCaptchaForm' : 'LoginForm';
        }

        var data = {
            'YII_CSRF_TOKEN': token
        };

        data[formName + '[email]'] = email;
        data[formName + '[password]'] = password;
        data[formName + '[captcha]'] = captcha;
        data[formName + '[recaptcha]'] = recaptcha;
        data[formName + '[json]'] = 'true';


        $.ajax({
            url: '/checkout/index/DoLogin/',
            type: 'POST',
            async: false,
            data: data,
            success: function(data) {
                if (data.response == true) {
                    return callback(data);
                }

                if (typeof data === 'string') {
                    // will reaload page and open modal with captcha code
                    window.location.hash = 'email-exists';
                    return window.location.reload();
                }

                for (field in data.errors) {
                    checkout.setModalError([data.errors[field][0]]);
                }

                // generate new captcha code
                $('#captcha_image_modal_check_email_button').trigger('click');
            }
        });
    },

    validateEmail : function (elementValue) {
        var emailPattern = /^.+@.+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    },

    checkoutIndexNewCustomer : function (elem, event) {
        event.preventDefault();

        var email = $('#new_email_mod').val();
        var redirect_path = $(elem).attr('href');
        var parentElement = '#email-new-customer-box';
        var elementInput = '#new_email_inp';

        if (email === '') {
            checkout.setModalError([i18n.typeYourEmail], parentElement, elementInput);
            return false;
        }

        if (!checkout.validateEmail(email)) {
            checkout.setModalError([i18n.invalidEmail], parentElement, elementInput);
            return false;
        }

        $(elementInput).removeClass('error');
        $(parentElement).removeClass('invalidInput').addClass('validInput');

        checkout.checkEmailExist(email, function(data) {
            location.href = redirect_path;
        }, function(data) {
            location.href = data.redirect_path;
        });
    },

    checkEmailExist : function (email, callback, callbackLogin,modalName) {
        modalName = modalName || 'is-default';
        if (email == checkout.lastCheckedEmail) {
            checkout.checkEmailExistOnSuccess(checkout.lastCheckedEmailResult, callback, callbackLogin,modalName);
        } else {
            $.ajax({
                url: '/checkout/index/CheckEmailExist/',
                dataType: 'json',
                type: 'GET',
                async: false,
                data: {
                    'email': email,
                    'page': 'checkout'
                },
                success: function(data) {
                    checkout.lastCheckedEmail = email;
                    checkout.lastCheckedEmailResult = data;
                    checkout.checkEmailExistOnSuccess(data, callback, callbackLogin,modalName);
                }
            });
        }
    },

    checkEmailExistOnSuccess : function (data, callback, callbackLogin, modalName) {
        modalName = modalName || 'is-default';

        if (data.response != true) {
            callback(data);
        } else if (typeof callbackLogin != 'undefined') {
            // this selector must exists on pages where the modal will be prompted (ex.: checkout, login)
            var $modal = $('.socialLoginModal').clone();
            $modal.removeClass('socialLoginModal').addClass('modal-social-login-account-create');
            $modal.appendTo('body');
            data.template = data.template.replace('forgot-password-new-link','forgot-password-new-link-modal');
            data.template = data.template.replace('do-login-new','do-login-new-modal');
            $modal.find('.modal-container').html(data.template);
            $modal.find('.modal-dialog').width('auto');
            $modal.modal({innerClassName: 'modal-email-cadastrado', shouldResize: true});

            $('.modal-social-login-account-create input').on('keypress', function(event) {
                if (event.which == 13) {
                    event.preventDefault();
                }
            });

            $('#do-login-new-modal').click(function(event) {
                event.preventDefault();
                $('#form-account-login-email-exists').submit();
            });

            $('#forgot-password-new-link-modal').click(function(event) {
                event.preventDefault();
                checkout.sendForgotPassword($('#new_email_mod').val());
            });
        }
    },

    showEmailExistsModalWithCaptcha : function() {
        var data = {
            'email': $.cookie('RecoverEmailLogin'),
            'page': 'checkout',
            'b2014' : 1
        };

        var openModalWithCaptcha = function(data) {
            if (!data.response) {
                return window.location.href = '/checkout/finish';
            }

            $('body').modal({
                'innerClassName': 'modal-email-exists',
                'content': data.template
            });
        }

        if (window.location.hash.indexOf('email-exists') > 0 && data.email.length > 0) {
            $.getJSON('/checkout/index/CheckEmailExist/', data, openModalWithCaptcha);
        }
    },

    grecaptchaGenerateToken: function (event) {
        event.preventDefault();

        if ($(checkout.formAccountLoginEmailExists).data('show-captcha')
         && $(checkout.formAccountLoginEmailExists).data('enable-google-recaptcha')) {
            grecaptcha.enterprise.ready(function() {
                grecaptcha.enterprise.execute($(checkout.formAccountLoginEmailExists).data('client-key'), {action: 'login'}).then(function(token) {
                    $('#email-exists-recaptcha').val(token);
                    checkout.makeAjaxLogin(
                        $('#RegistrationForm_email').val() || $.cookie('RecoverEmailLogin'),
                        $('#new-pass').val(),
                        $(checkout.modalIdEmailExists + ' #LoginWithCaptchaForm_captcha').val(),
                        $('#email-exists-recaptcha').val(),
                        $(checkout.modalIdEmailExists + ' input[name=YII_CSRF_TOKEN]').val(),
                        function() {
                            location.href = '/checkout/index/redirectCheckout';
                        }
                    );
                });
            });
        }

        if (!($(checkout.formAccountLoginEmailExists).data('show-captcha')
         && $(checkout.formAccountLoginEmailExists).data('enable-google-recaptcha'))) {
            checkout.makeAjaxLogin(
                $('#RegistrationForm_email').val() || $.cookie('RecoverEmailLogin'),
                $('#new-pass').val(),
                $(checkout.modalIdEmailExists + ' #LoginWithCaptchaForm_captcha').val(),
                $('#email-exists-recaptcha').val(),
                $(checkout.modalIdEmailExists + ' input[name=YII_CSRF_TOKEN]').val(),
                function() {
                    location.href = '/checkout/index/redirectCheckout';
                }
            );
        }
    }
};

// TODO: Create a plugin, simplify scripts, create clean code, separeted file
$(document).on("click", '#forgot-password-link', function(event) {
    event.preventDefault();
    var email = $(this).parents('.forgot-password').find('#forgot-password').val();
    var recaptcha = document.getElementById("recaptcha-box-form").value;
    checkout.sendForgotPassword(email, recaptcha);
});


$(document).on("click",'#check-tax-identification',function(event) {
    event.preventDefault();
    var cpf = $(checkout.modalIdEmailCpfValidate + ' #tax-identification').val();
    cpf = taxIdValidate.cleanMask(cpf);

    if (taxIdValidate.cpf(cpf)) {
        checkout.sendForgotPasswordByCpf(cpf,  function(data) {
        location.href = '/checkout/index/redirectCheckout';
        },false,'');
    } else {
        checkout.setModalError([i18n.invalid_cpf]);
    }
});

$(document).on('click','#forgot-password-new-link',function(event) {
    event.preventDefault();
    checkout.sendForgotPassword($('#RegistrationForm_email').val());
});

$(document).ready(function() {
    checkout.showEmailExistsModalWithCaptcha();
});

$(document).on('submit', '#form-account-login-email-exists', checkout.grecaptchaGenerateToken);