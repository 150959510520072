(function ($) {

    'use strict';

    var ProductBox = function (element, options) {
        this.$element = $(element);
        this.options = options;

		this.$element.find('.stock-available').on('loaded', $.proxy(this, 'loaded'));
    }

    ProductBox.DEFAULTS = {
    }

	ProductBox.prototype.loadAvailability = function() {
		this.$element.find('.stock-available:not(.is-loaded)').stockAvailable('load');
	}

	ProductBox.prototype.loaded = function() {
		this.$element.addClass('is-loaded');
	}

    $.fn.productBox = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.productbox');
            var options = $.extend({}, ProductBox.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.productbox', (data = new ProductBox(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).on('success', '.product-box-form' , function(e) {
        $(e.currentTarget).addClass('is-added');
    });

	$(document).on('mouseover click', '.product-box:not(.is-loaded)', function(e) {
		$(e.currentTarget).productBox('loadAvailability');
	});

    $(document).on('click', '.touch .product-box-info', function(e) {
        $(e.currentTarget).parents('.carousel-overflow').find('.product-box-info').removeClass('is-active');
        $(e.currentTarget).addClass('is-active');
    });

})(jQuery);
