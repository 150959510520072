(function($) {

    'use strict';

    var Selectbox = function(element, options) {
        this.$element = $(element);
        this.$target = $('[name="' + options.target + '"]');
        this.options = options;

        this.$element.popover($.extend({
            trigger: 'click'
        }, options));
        this.$element.find('.selectbox-current').attr('data-toggle', 'popover');
        this.$element.find('.selectbox-icon').attr('data-toggle', 'popover');
        this.$element.find('.selectbox-options').addClass('popover');
    }

    Selectbox.DEFAULTS = {
        target: null
    };

    Selectbox.prototype.select = function(option) {
        this.$element.find('.selectbox-option').removeClass('is-selected is-active');

        var $option = $(option);
        $option.addClass('is-selected is-active');
        this.$element.find('.selectbox-current').html($option.html());

        var value = (typeof $option.data('value') !== 'undefined' ? $option.data('value') : $option.html());
        this.$target.attr('value', value);
        this.$element.data('selected', value);
        this.$element.trigger('change');
    }

    Selectbox.prototype.current = function() {
        var $option = this.$element.find('.is-selected');
        $option.find('.selectbox-option-link').addClass('is-disabled');

        this.$element.find('.selectbox-current').html($option.html());
    }

    Selectbox.prototype.activate = function(option) {
        this.$element.find('.selectbox-option').removeClass('is-active');
        $(option).addClass('is-active');
    }

    Selectbox.prototype.changeLocation = function () {
        var itemsPerPage = this.$element.find('#itemsPerPage').val();
        location.assign(itemsPerPage);
    }

    $.fn.selectbox = function(option, value) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.selectbox');
            var options = $.extend({}, Selectbox.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.selectbox', (data = new Selectbox(this, options)));
            if (typeof option == 'string') data[option](value);
        });
    
}
    $(document).on('click', '.selectbox-2:not(.popover-click)', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).selectbox().popover('open');
    });


    $(document).on('click', '.selectbox-2 .selectbox-options .selectbox-option:not(.is-link)', function(e) {
        e.preventDefault();

        var selectbox = $(this).closest('.selectbox-2');
        selectbox.selectbox('select', this).popover('close');
    });

    $(document).on('mouseover', '.selectbox-2 .selectbox-options .selectbox-option', function() {
        var selectbox = $(this).closest('.selectbox-2');
        selectbox.selectbox('activate', this);
    });

    $(document).on('close', '.selectbox-2', function() {
        $(this).selectbox('activate', $(this).find('.selectbox-option.is-selected'));
    });

    $(document).on('change', '[data-change-items-per-page]',function() {
        $(this).selectbox('changeLocation');         
    });

})(jQuery);
